import React, {Component} from "react";
import {Grid, withStyles} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, reduxForm} from "redux-form";
import customInputField from "../../../atoms/CustomInputField";
import cn from "classnames";
import {compose} from "redux";
import {connect} from "react-redux";
import styles from "../styles";
import utilCommon from "../../../../helpers/utilCommon";
import Header from "../../../molecules/AuthV2/Header";
import Loader from "../../../atoms/Loader";
import lodash from "lodash";
import FtlDataForm from "../../ftlDataForm";
import {FORGOT_PASSWORD_SECTION} from "../../../../helpers/enums";
import GoogleSignIn from '../../../forms/AuthV2/Google'

const validate = formProps => {
    const errors = {};
    if (!formProps.password) {
        errors.password = 'Password is a required field';
    }

    if (!formProps.emailId) {
        errors.emailId = 'Email is a required field';
    } else if (!utilCommon.Email_Regex.test(formProps.emailId)) {
        errors.emailId = 'Please enter a valid email address.';
    }

    return errors;
}

class LoginForm extends Component {
    componentDidMount() {
        this.props.changeDialogClass('mediumSmall')
    }

    render() {
        const {
            classes,
            switchForm,
            setShowForm,
            valid,
            handleSubmit,
            submitting,
            loading,
            ftlData
        } = this.props;

        return (
            <form name="LoginForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)}>
                <Header switchForm={switchForm} switchTo={'register'} text={'New to FreightCenter?'}
                        buttonText={'Register'}/>
                <DialogContent className={classes.contentPadding}>
                    <Grid container justify={'center'} alignItems={'center'} direction={'column'}>
                        <Grid item sm={12}>
                            <DialogContentText className={classes.contentTitleText}>
                                Welcome back. Let's get things moving.
                            </DialogContentText>
                        </Grid>
                        <Grid item sm={12}>
                            <DialogContentText className={cn(classes.customerTypeDescription, classes.mb16)}>
                                Sign in to pick up where you left off.
                            </DialogContentText>
                        </Grid>
                        <Grid container spacing={8} className={classes.mb16}>
                            <Grid item xs={12} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                <div className={classes.inputLabelText}>Email Address</div>
                                <Field
                                    name={'emailId'}
                                    type={'email'}
                                    component={customInputField}
                                    disableUnderline
                                    classes={classes}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                <div className={classes.inputLabelText}>Password</div>
                                <Field
                                    name={'password'}
                                    type={'password'}
                                    component={customInputField}
                                    disableUnderline
                                    classes={classes}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12} className={classes.mb16}>
                            <DialogContentText>
                                Forgot your password? <a onClick={() => setShowForm(FORGOT_PASSWORD_SECTION)}>Click here.</a>
                            </DialogContentText>
                        </Grid>
                        {!lodash.isEmpty(ftlData) && <FtlDataForm ftlData={ftlData}/>}
                        <Grid item className={classes.mb16}>
                            <Button disabled={!valid || submitting || loading}
                                    className={cn(classes.headerButton, classes.submitButton)} type="submit"
                                    variant="contained">
                                Get Started
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.mb16}>
                            <GoogleSignIn />
                        </Grid>
                        {loading && <Loader/>}
                    </Grid>
                </DialogContent>
            </form>
        );
    }
}

const component = compose(
    connect(state => ({
        loading: state.common.loading
    }), {}),
    reduxForm({
        form: 'LoginForm',
        validate,
        enableReinitialize: true,
    }),
    withStyles(styles)
)(LoginForm)

export default component;

