import React from 'react';
import {connect} from "react-redux";
import FClogo from '../../../assets/images/logo-new.svg'
import utilCommon from "../../../helpers/utilCommon";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smMenu: false,
        };
    }

    //navigate to routes
    navigateTo = (path) => {
        this.props.history.push(path);
    };

    smMenu = () => {
        this.setState({
            smMenu: !this.state.smMenu,
        });
    };

    render() {
        return (
            <div className="headerSection noShodow">
                <div className="headerWrapper">
                    <div className="logoSection">
                        <h1>
                            <img onClick={() => window.location.href = 'https://www.freightcenter.com/'} src={FClogo}
                                 title="My FreightCenter" alt="My FreightCenter"/>
                        </h1>
                        <a href="tel:18007167608" data-gtm-event-category="gtm-click-phone"
                           data-gtm-event-label="phone-topnav" className="phone-number">800.716.7608</a>
                    </div>
                    <div className={this.state.smMenu ? 'smMenu open' : 'smMenu '} onClick={this.smMenu.bind(this)}>
                        <span/>
                    </div>
                    <div className={this.state.smMenu ? 'hederRight open' : 'hederRight close'}>
                        <div className="topNav">
                            <ul onClick={() => this.smMenu()}>
                                <li onClick={() => window.location.href = 'https://www.freightcenter.com/quote'}
                                    className={this.props.location.pathname == '/home/freight-quote' ? 'active' : ''}>
                                  Get Freight Quotes
                                </li>
                                <li onClick={() => window.location.href = 'https://www.freightcenter.com/track'}
                                    className={this.props.location.pathname == '/home/track' ? 'active' : ''}>
                                  Track
                                </li>
                                <li onClick={() => this.navigateTo('/home/login')}
                                    className={this.props.location.pathname == '/home/login' ? 'active' : ''}>
                                  Login
                                </li>
                            </ul>
                        </div>
                        <div className="searchSection"/>
                    </div>
                </div>
            </div>
        );
    }
}

// state to props mapping
const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
});

// dispatch props
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header)
