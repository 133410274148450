import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import {authHeader} from '../helpers/authHeader';
import * as commonActions from '../store/common/actions'
import * as accountActions from '../store/accountInfo/actions'
import {
    setCustomerOrders,
    setDocumentInfo,
    setIsTransactionsInProcess,
} from '../store/shipment/actions';
import * as type from '../store/shipment/actionTypes'
import * as accountInfoType from '../store/accountInfo/actionTypes'
import * as accountType from '../store/accountInfo/actionTypes'
import utilCommon from '../helpers/utilCommon';
import {encrypt} from '../helpers/crypto';
import shipmentService from './shipmentService'
import saveAs from 'file-saver'
import lodash from 'lodash';
import * as actions from "../store/shipment/actions";
import {NO_INSURANCE_AVAILABLE_BACKEND_MESSAGE} from "../helpers/constants";

// Get customer's shipments by status(es)
export const getCustomerOrders = (dispatch, lang, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'getShipments');
    url += '?' + utilCommon.convertObjectToQueryString(inputDTO)
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch(
                    setCustomerOrders(
                        {
                            orders: response.data.content.shipmentList,
                            totalOrdersCount: response.data.content.count,
                            orderType: inputDTO.orderType
                        }
                    )
                )
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const cancelBooking = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'cancelShipment');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                getCustomerOrders(dispatch, lang, dictionary, {
                    pageNumber: inputDTO.pageNumber,
                    pageSize: inputDTO.pageSize,
                    sortColumn: inputDTO.sortColumn,
                    sortOrder: inputDTO.sortOrder,
                    status: encrypt('[2,3,4,5,6,7,8]')
                })
                utilCommon.showNotification(response.data.message, dictionary, lang)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// get shipment
export const getCurrentShipment = (dispatch, lang, dictionaries, inputDTO, getAddresses) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'getShipments');
    url = url + "?shipmentUuid=" + inputDTO.shipmentUuid + `&pageNumber=1&pageSize=10&sortColumn=shipmentUuid&sortOrder=desc`
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.addToAddressBookFrom || getAddresses) {
                    getAddressBook(dispatch, lang, dictionaries, {
                        shipmentUuid: inputDTO.shipmentUuid,
                        customerZip: response.data.content.shipmentList[0].shipmentFromZip,
                        type: 1
                    })
                }
                if (inputDTO.addToAddressBookTo || getAddresses) {
                    getAddressBook(dispatch, lang, dictionaries, {
                        shipmentUuid: inputDTO.shipmentUuid,
                        customerZip: response.data.content.shipmentList[0].shipmentToZip,
                        type: 2
                    })
                }
                dispatch({type: type.SET_CURRENT_SHIPMENT, shipmentState: response.data.content.shipmentList[0]})
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// get item details
export const getShipmentProductDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'getProductAndInsurance');
    url = url + '?shipmentUuid=' + inputDTO.shipmentUuid + '&customerUuid=' + inputDTO.customerUuid;

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: type.SET_SHIPMENT_PRODUCTS_INSURANCE,
                    shipmentDetailsData: {
                        ...response.data.content,
                        displayInsurance: inputDTO.displayInsurance,
                        insuranceValidated: inputDTO.insuranceValidated,
                        insuranceDeclaredValue: inputDTO.insuranceDeclaredValue,
                        acceptInsurance: inputDTO.acceptInsurance
                    }
                })

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
}

// update location details
export const updateLocationDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'locationDetails');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                getCurrentShipment(dispatch, lang, dictionary, inputDTO)
                utilCommon.showNotification(response.data.message, dictionary, lang)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// update shipment products
export const updateShipmentDetails = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'productDetails');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                getShipmentProductDetails(dispatch, lang, dictionary, {
                    shipmentUuid: inputDTO.shipmentUuid,
                    displayInsurance: inputDTO.displayInsurance,
                    insuranceDeclaredValue: inputDTO.insuranceDeclaredValue,
                    insuranceValidated: inputDTO.insuranceValidated,
                    customerUuid: inputDTO.customerUuid
                })
                utilCommon.showNotification(response.data.message, dictionary, lang)
            } else {
                if(response.data.errors[0] === 22059){
                    Window['history'].push(`/customer/ftl?data=${JSON.stringify({formName:"TL",fid:"form-ftl-long"})}`)
                }
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// update shipment data
export const updateShipmentData = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'fcUpdateShipmentData');
    dispatch(commonActions.setLoader(true));

    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCurrentShipment(dispatch, lang, dictionary, inputDTO);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => dispatch(commonActions.setLoader(false)));
}

export const updateCustomer = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'update');
    dispatch(commonActions.setLoader(true));
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (!response.data.success) {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            } else {
                getAccountInfo(dispatch, lang, dictionary, {id:inputDTO.customerUuid})
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        });
}

export const getAccountInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('login', 'getProfile');

    dispatch(commonActions.setLoader(true))
    dispatch({ type: accountInfoType.SET_CURRENT_CUSTOMER, customerState: {} })
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: accountInfoType.SET_CURRENT_CUSTOMER, customerState: response.data.content.customerList[0] })

            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// update shipment products
export const addOrUpdateTransaction = (dispatch, lang, dictionary, inputDTO, history) => {
    const params = {headers: authHeader()};
    const waitingForExecution = lodash.get(inputDTO, 'waitingForExecution', false);
    let url = getApiUrl('shipment', 'transaction');
    dispatch(commonActions.setLoader(true));

    if (waitingForExecution) {
        dispatch(setIsTransactionsInProcess(true));
    }

    axios[inputDTO.shipmentTransactionUuid ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (history) {
                    history.push('/customer/shipment-details', {
                        shipmentUuid: inputDTO.shipmentUuid,
                        insuranceFlag: true
                    })
                } else {
                    getShipmentProductDetails(dispatch, lang, dictionary, {
                        shipmentUuid: inputDTO.shipmentUuid,
                        displayInsurance: 2,
                        customerUuid: inputDTO.customerUuid
                    })
                    shipmentService.getTransationDetails(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                    shipmentService.getShipmentCharge(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                    utilCommon.showNotification(response.data.message, dictionary, lang)
                    if (lodash.get(inputDTO, 'lateInsurance', false)) {
                        sessionStorage.setItem('showInsuranceAddedModal', true);
                        window.location.reload();
                    }
                    if (waitingForExecution) {
                        dispatch(setIsTransactionsInProcess(false));
                    }
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                });

                if (waitingForExecution) {
                    dispatch(setIsTransactionsInProcess(false));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

//delete a transaction
export const deleteTransaction = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const waitingForExecution = lodash.get(inputDTO, 'waitingForExecution', false);
    let url = getApiUrl('shipment', 'transaction');
    dispatch(commonActions.setLoader(true));

    if (waitingForExecution) {
        dispatch(setIsTransactionsInProcess(true));
    }

    axios['patch'](url, {...inputDTO}, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {

                getShipmentProductDetails(dispatch, lang, dictionary, {
                    shipmentUuid: inputDTO.shipmentUuid,
                    displayInsurance: inputDTO.displayInsurance,
                    acceptInsurance: '',
                    insuranceDeclaredValue: inputDTO.insuranceDeclaredValue,
                    customerUuid: inputDTO.customerUuid
                })
                shipmentService.getTransationDetails(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                shipmentService.getShipmentCharge(dispatch, lang, dictionary, {shipmentUuid: inputDTO.shipmentUuid})
                utilCommon.showNotification(response.data.message, dictionary, lang);

                if (waitingForExecution) {
                    dispatch(setIsTransactionsInProcess(false));
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })

                if (waitingForExecution) {
                    dispatch(setIsTransactionsInProcess(false));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// Deactivate shipment
export const deactivateShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'deactivateShipment');
    dispatch(commonActions.setLoader(true))
    return axios.post(url, {shipmentUuid: inputDTO.shipmentUuid}, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

// get address book
//get getAddresses
export const getAddressBook = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'address');
    url = url + '?customerZip=' + inputDTO.customerZip + "&sortColumn=addedDate&sortOrder=desc";

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.type == 1) {
                    dispatch({
                        type: type.SET_ORIGIN_ADDRESSES,
                        originAddresses: response.data.content.customerAddressList
                    })
                } else {
                    dispatch({type: type.SET_DEST_ADDRESSES, destAddresses: response.data.content.customerAddressList})
                }
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
};

export const deleteAddress = (dispatch, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'customerAddress');

    return axios.patch(url, {customerAddressUuid: inputDTO}, params)
        .then((response) => {
            return response.data.success;
        });
};

//validate insuarnce
export const validateInsurance = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'validateInsurance');

    dispatch(commonActions.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({
                    type: type.SET_INSURANCE_VALIDATE_FLAG, isInsuranceValid: true,
                    insuranceAmount: (response.data.content ? response.data.content.InsurancePremium.replace(/,/g, '').replace(' ', '') : '0.00'),
                    insuranceExcludedId: response.data.content ? response.data.content.ExcludedItemIDMatch : '',
                    insuranceExcludedItemName: response.data.content ? response.data.content.ExcludedItemMatch : ''
                })
            } else {
                response.data.errors.map((error) => {
                    let errorCode = 99996
                    if (error === NO_INSURANCE_AVAILABLE_BACKEND_MESSAGE) {
                        errorCode = 99994
                    }

                    utilCommon.showNotification(errorCode, dictionary, lang)
                })

                dispatch({
                    type: type.SET_INSURANCE_VALIDATE_FLAG,
                    isInsuranceValid: false,
                    insuranceAmount: '0.00',
                    insuranceExcludedId: '',
                    insuranceExcludedItemName: ''
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getPaymentCards = (dispatch, lang, dictionaries) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'customerCards') + '?sortColumn=modifiedDate&sortOrder=desc';
    dispatch(commonActions.setLoader(true))
    dispatch(accountActions.cardsFetched(false));
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({
                    type: accountType.SET_CUSTOMER_CARDS,
                    cards: response.data.content.customerCreditCardsListing
                })

            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally( _ => {
            dispatch(accountActions.cardsFetched(true));
        })
    ;
};

export const addPaymentCard = (dispatch, lang, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'customerCards');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.customerPaymentProfileId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.customerPaymentProfileId) {
                    utilCommon.showNotification('Payment options updated successfully.', dictionaries, lang)
                } else {
                    utilCommon.showNotification('Payment options added successfully.', dictionaries, lang)
                }
                if (inputDTO.dontSaveInProfile != undefined && inputDTO.dontSaveInProfile == "1") {
                    dispatch({
                        type: accountType.SET_LOCALCARDS,
                        card: {...inputDTO, localCard: true, paymentProfileId: response.data.content.paymentProfileId}
                    })
                }
                getPaymentCards(dispatch, lang, dictionaries)
                getAccountInfo(dispatch, lang, dictionaries, {})
                // dispatch({ type: accountType.SET_CUSTOMER_CARDS, cards: response.data.content.customerCreditCardsListing })
            } else {
                response.data.errors && response.data.errors
                    .forEach((e) => utilCommon.showNotification(e, dictionaries, lang, 1));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const deletePaymentCard = (dispatch, lang, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'customerCards');
    dispatch(commonActions.setLoader(true))
    axios.delete(url, {...params, data: inputDTO})
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Payment option deleted successfully.', dictionaries, lang)
                getPaymentCards(dispatch, lang, dictionaries)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionaries, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// get terms and conditions
export const getTermsAndConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'termsAndConditions');


    axios['get'](url, params)
        .then((response) => {

            if (response.data.success && response.data.content.falveyTermsConditionList && response.data.content.falveyTermsConditionList[0]) {
                dispatch({
                    type: type.SET_TERMS_AND_CONDITIONS,
                    termsAndConditions: response.data.content.falveyTermsConditionList[0]
                })

            } else {
                utilCommon.showNotification('99996', dictionary, lang);


            }
        })
        .catch((err) => {

        });
};

// download terms and conditions
export const downloadTC = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'termsAndConditions');
    url = url + '?fcTermConditionUuid=' + inputDTO.customerFcTermUuid
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                let view = utilCommon.convertBase64toBlob(response.data.content.fileContent)
                var blob = new Blob([view], {type: 'application/pdf'});
                saveAs(blob, `${response.data.content.fcTermConditionName}.pdf`);

            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const downloadBOL = (dispatch, lang, dictionary, inputDTO, callback= null) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'documents');
    url = `${url}?shipmentUuid=${inputDTO.shipmentUuid}&bol=1`;
    if (inputDTO.documentsGenerated !== undefined) {
        url += `&documentsGenerated=${inputDTO.documentsGenerated}`;
    }
    if (inputDTO.shipmentDocumentUuid !== undefined) {
        url += `&shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`;
    }
    if (inputDTO.manuallyDownload) {
        url += `&manuallyDownload=1`;
    }

    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                let view = utilCommon.convertBase64toBlob(response.data.content.fileContent)
                var blob = new Blob([view], {type: 'application/pdf'});
                saveAs(blob, `${response.data.content.shipmentUuid + '_' + response.data.content.documentFileName}`);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally(() =>{
            if ( callback ){
                callback();
            }
        });
}

export const getDocumentInfo = (dispatch, lang, dictionary, inputDTO, callback= null) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('shipment', 'documents');
    url = `${url}?shipmentUuid=${inputDTO.shipmentUuid}&bol=1`;

    if (inputDTO.documentsGenerated !== undefined) {
        url += `&documentsGenerated=${inputDTO.documentsGenerated}`;
    }

    if (inputDTO.shipmentDocumentUuid !== undefined) {
        url += `&shipmentDocumentUuid=${inputDTO.shipmentDocumentUuid}`;
    }

    dispatch(commonActions.setLoader(true))
    return axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch(setDocumentInfo(response.data.content));
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally(() =>{
            if ( callback ){
                callback();
            }
        });
}

// get payment options
export const getARPaymentOptions = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'paymentAR') + "?customerUuid=" + inputDTO.customerUuid;

    if (inputDTO.permFlag) {
        url = url + `&permFlag=${inputDTO.permFlag}`
    }
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: type.SET_PAYMENT_AR_OPTIONS,
                    customerARInfo: response.data.content.arInfo,
                    flag: inputDTO.flag
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
        });
};

export const sendEmailToFtl = (queryString) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'sendEmailToFtl') + queryString + `&customerUuid=${utilCommon.getSession('userUuid')}`;
    axios.get(url, params)
        .then((response) => {
            // if (response.data.success) {
            //
            // }
        })
        .catch((err) => {
            console.log(err)
        });
}

// Export customer's shipments by status(es) to excel
export const exportCustomerOrders = (dispatch, inputDTO) => {
    const params = {headers: authHeader(), responseType: 'blob'};
    let url = getApiUrl('customer', 'exportCustomerOrders');
    url += '?' + utilCommon.convertObjectToQueryString(inputDTO) + '&excel=1'
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            let blob = new Blob([response.data], {type:response.data.type});
            saveAs(blob, `${inputDTO.reportName}.xlsx`);
            dispatch(commonActions.setLoader(false))
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getPaymentProfile = (dispatch) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'paymentProfile');
    //dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: accountInfoType.SET_PAYMENTS_PROFILE,
                    paymentsList: response.data.content,
                })
            }
           // dispatch(commonActions.setLoader(false))
        })
        .catch((err) => {
            console.log(err)
            //dispatch(commonActions.setLoader(false))
        });
}

export const payInvoices = (dispatch, paymentProfileId, invoicePayments, dictionary, lang, refreshList) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'payInvoices');
    return axios.put(url, {paymentProfileId: paymentProfileId, invoicePayments: invoicePayments}, params)
        // .then((response) => {
        //     if (response.data.success) {
        //         utilCommon.showNotification('Invoice payment was successful', dictionary, lang)
        //         refreshList();
        //     }else{
        //         utilCommon.showNotification(response.data.errors, dictionary, lang, true)
        //     }
        //     dispatch(commonActions.setLoader(false))
        // })
        // .catch((err) => {
        //     console.log(err)
        //     dispatch(commonActions.setLoader(false))
        // });
}

export const getAchAccounts = (dispatch, lang, dictionaries) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'achAccounts');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({
                    type: accountType.SET_ACH_ACCOUNTS,
                    achAccounts: response.data.content
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        })
        .finally( _ => {
            dispatch(accountActions.cardsFetched(true));
        })
    ;
};

export const addAchAccount = (dispatch, lang, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'addAchAccount');
    dispatch(commonActions.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('ACH account has been added successfully', dictionaries, lang)
                getAchAccounts(dispatch, lang, dictionaries)
            } else {
                response.data.errors && utilCommon.showNotification(response.data.errors, dictionaries, lang, true);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const deleteAchAccount = (dispatch, lang, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('customer', 'deleteAchAccount');
    dispatch(commonActions.setLoader(true))
    axios['patch'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Payment account has been deleted successfully.', dictionaries, lang)
                getAchAccounts(dispatch, lang, dictionaries)
            } else {
                response.data.errors && utilCommon.showNotification(response.data.errors, dictionaries, lang, true);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getStoreCredit = (dispatch) => {
    const params = {headers: authHeader()}
    let url = getApiUrl('customer', 'storeCredit')

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch(accountActions.setCustomerStoreCredit(response.data.data.balance))
            } else {
                dispatch(accountActions.setCustomerStoreCredit(null))
            }
        })
}

export default {
    cancelBooking,
    getCurrentShipment,
    getShipmentProductDetails,
    updateLocationDetails,
    updateShipmentDetails,
    getAddressBook,
    validateInsurance,
    deactivateShipment,
    getPaymentCards,
    addPaymentCard,
    getTermsAndConditions,
    addOrUpdateTransaction,
    deleteTransaction,
    deletePaymentCard,
    downloadTC,
    downloadBOL,
    getDocumentInfo,
    getARPaymentOptions,
    updateCustomer,
    getCustomerOrders,
    deleteAddress,
    sendEmailToFtl,
    exportCustomerOrders,
    payInvoices,
    getPaymentProfile,
    getAchAccounts,
    addAchAccount,
    deleteAchAccount,
    updateShipmentData,
    getStoreCredit
};
