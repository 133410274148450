import utilCommon from './utilCommon'
import { DATA_SOURCE_FC } from './enums';
// api auth headers
export function authHeader() {
    let header = {}

    if (utilCommon.getSession('refreshToken') && !utilCommon.getSession('accessToken')) {
        header.refreshToken = utilCommon.getSession('refreshToken')
    }

    if (utilCommon.getSession('accessToken')) {
        header.accessToken = utilCommon.getSession('accessToken')
    }

    header['X-Data-Source'] = DATA_SOURCE_FC;

    return header
}

export default authHeader;
