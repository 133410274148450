import URLSearchParams from "@ungap/url-search-params";
import moment from "moment";

export const notesDisplayCount = 4;
export const freightCenterId = 1;
export const conventionCenterId = 2;
export const mapURL = 'https://maps.googleapis.com/maps/api/geocode/json?address=zipCode&sensor=true&key=googleKey'
export const zipPredictionsURL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=inputValue&types=(regions)&key=googleKey'
export const SHIPPING_PICKUP_STATUS = 5;
export const SHIPPING_IN_TRANSIT_STATUS = 6;
export const PACKAGE_TYPE_PALLET_DEAFULT = 2;
export const SHIPMENT_ALERT_PROBLEM_OTHER_ID = 8;
export const SHIPMENT_ALERT_PROBLEM_MISSING_ID = 6;
export const SHIPMENT_ALERT_TEXT =  `Shipment alert: problemDescription`;
export const SHIPMENT_DEACTIVATE_STATUS_ID = 10;
export const SHIPMENT_REACTIVATE_TEXT = "This shipment has been deactivated. Click here to reactivate it"
export const SHIPMENT_DEACTIVATION_REASON_OTHER_ID = 5;
export const SHIPMENT_TRANSACTION_FREIGHT_ID =1;
export const SHIPMENT_TRANSACTION_BILLING_ID = 2;
export const SHIPMENT_TRANSACTION_ADJUST_ID = 3;
export const SHIPMENT_TRANSACTION_ONLINE_BOOKING_ID = 1020;
export const SHIPMENT_TRANSACTION_INSURANCE_ADMIN_FEE_ID = 1027;
export const SHIPMENT_TRANSACTION_CONCIERGE_FEE_ID = 1021;
export const SHIPMENT_TRANSACTION_THIRDPARTY_ID = 4;
export const SHIPMENT_TRANSACTION_DISCOUNT_ID = 6;
export const SHIPMENT_TRANSACTION_REFUND_ID = 5;
export const SHIPMENT_TRANSACTION_ADDITIONAL_WEIGHT_ID = 46;
export const SHIPMENT_TRANSACTION_COUPON_ID = 95;
export const SHIPMENT_PAYMENT_REFUND_ID = 2;
export const SHIPMENT_PAYMENT_ID = 1;
export const FACILITY_TYPE_BUSINESS_WTOUT_LIFT = 3;
export const FACILITY_TYPE_BUSINESS_WITH_LIFT = 4;
export const LTL_SHIPMENT_MODE = 1;
export const TL_SHIPMENT_MODE = 3;
export const SHIPMENT_ESTIMATED_STATUS =1;
export const SHIPMENT_PAYMENT_TYPE = 1;
export const PACKAGING_TYPE_PALLET = [1,2,3,4];
export const PACKAGING_TYPE_CRATE = [6];
export const SHIPMENT_PROCESS_REVIEW = 2;
export const SHIPMENT_PAYMENT_PENDING = 3;
export const SHIPMENT_PAYMENT_RECEIVED = 4;
export const COUPON_TYPE_FLAT =1
export const COUPON_TYPE_PERC =2
export const SHIPMENT_CANCEL_STATUS = 8
export const CANCELLATION_PERCENTAGE_ID = 1
export const SHIPMENT_DEACTIVATE_TYPE = 2;
export const AR_ACCOUNT_ID = 7;
export const IMPORTER_OTHER_PARTY = 3;
export const USA_COUNTRY_ID = 228;
export const PAYMENT_INITIATED_STATUS = 0;
export const BUSINESS_TYPE_FIELD = [3,4]
export const LTL_MARKUP = 2;
export const TL_MARKUP = 3;
export const OPEN_OVERDUE_TASK = [1,5]
export const SHIPMENT_TRANSACTION_STATUS_ACTIVE = 1;
export const SHIPMENT_TRANSACTION_STATUS_DELETED = 3;
export const DATA_SOURCE_FC = 1;
export const FACILITY_TYPE_RESIDENTIAL_AREA = 1;
export const FACILITY_TYPE_BUSINESS_WITH_A_DOCK_OR_FORKLIFT_AREA = 2;
export const FACILITY_TYPE_FREIGHT_CARRIER_TERMINAL = 4;
export const FACILITY_TYPE_LIMITED_ACCESS_AREA = 18;
export const EXIT_POP_UP_CONTENT_ID = 1;

export const LTL_QUOTE_FORM = '1';
export const TL_QUOTE_FORM = '3';

export const TO_MANY_LOGIN_ATTEMPTS_ERROR_CODE = 22057

export const SHIPMENT_STATUS_ID_TO_TEXT_STATUS_ROW = {
  11: 'Estimate saved',
  12: 'Open',
  13: 'Processing review',
  14: 'Covered',
  15: 'Booked',
  16: 'Arrived at Pick-up',
  17: 'Loaded',
  18: 'In-transit',
  19: 'Arrived at destination',
  20: 'Delivered',
}

export const SHIPPING_MODE_ID_TL = 3;

export const STORE_CREDIT_PAYMENT_METHOD = 16;

export const MAX_INSURED_VALUE_CEIL = 100000.00;

export const MAX_INSURED_VALUE_ID = 19;

export const OVER_LENGTH_ACC_CODE = 'ELS';
export const OVER_LENGTH_ACC_ID = 68;
export const LIFTGATE_PICKUP_ACC_CODE = 'LGPU';
export const LIFTGATE_DELIVERY_ACC_CODE = 'LGDEL';

export const SIGN_UP_FORM_CUSTOMER_TYPE_INDIVIDUAL = '1';
export const SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS = '2';

export const LOGIN_SECTION = 'login';
export const REGISTER_SECTION = 'register';
export const FORGOT_PASSWORD_SECTION = 'forgotPassword';

export const ONE_TIME_SHIPMENT = 2;
export const ONE_TO_TEN_TIME_SHIPMENT = 3;
export const TEN_PLUS_TIME_SHIPMENT = 4;

export const PICKUP_LOCATION_TYPE = 1;
export const DELIVERY_LOCATION_TYPE = 2;

export const INPUT_TYPE_NUMBER = 1;
export const INPUT_TYPE_TEXT = 2;
export const INPUT_TYPE_FLOAT = 3;
export const INPUT_TYPE_TEXT_WITHOUT_SP_SYMBOLS = 4;

export const TL_PICKUP_ADDRESS_TYPE = 1;
export const TL_DROP_ADDRESS_TYPE = 2;

export const BA_PROCESSING_FEE_TRANSACTION_TYPE_NAME = 'BA Processing Fee';
export const BA_PROCESSING_FEE_TRANSACTION_DESCR = 'Processing fee';
export const RETAIN_PROFIT_TRANSACTION_DESCRIPTION = 'Retain profit';
export const CARRIER_CHANGE_ADJ_TRANSACTION_DESCRIPTION = 'Carrier-change adjustment';

export const initialShipmentPickupAddress = {
  company: null,
  addressLineOne: null,
  addressLineTwo: null,
  zipObj: null,
  city: null,
  stateId: null,
  countryId: null,
  startTime: null,
  endTime: null,
  requestedDate: null,
  requestedStartDate: moment().format('YYYY-MM-DD'),
  requestedEndDate: moment().format('YYYY-MM-DD'),
  referenceNumber: null,
  option: null,
  note: null,
  weight: null,
  pallets: null,
  pieces: null,
  type: 1
}

export const initialShipmentDropAddress = {
  company: null,
  addressLineOne: null,
  addressLineTwo: null,
  zipObj: null,
  city: null,
  stateId: null,
  countryId: null,
  startTime: null,
  endTime: null,
  requestedDate: null,
  requestedStartDate: moment().format('YYYY-MM-DD'),
  requestedEndDate: moment().format('YYYY-MM-DD'),
  referenceNumber: null,
  option: null,
  note: null,
  type: 2
}

export const PaymentTypes = [
    {
      name: 'Credit card',
      id: 1
    },
    {
      name: 'ACH: Checking account',
      id: 2
    },
    {
      name: 'ACH: Savings account',
      id: 3
    }
]

export const DNB_APP_STATUSES = ['approved', 'declined', 'pending'];

export const DefaultItemInShipment = {
  howManyHandlingUnits: '1',
  dimUnit: '2',
  weightUnit: '2',
  itemType: '1',
  dimensionType: true,
  palleteType: 2,
  length: 48,
  width: 40,
  packagingType: 2
};

export const initialTlAcccessorials = {
  escorts: false,
  strapschains: false,
  hazmat: false,
  tarps: false,
  oversized: false,
  team: false,
  permits: false
}

export const DefaultItemInShipmentTL = {
  weightMeasurement: 2,
  productMeasurement: 2,
  noOfItem: 1,
  noOfHandlingUnit: 1,
  palleteType: 2,
  length: 48,
  width: 40,
};

export const DefaultCreditAppReference = {
  companyName: '',
  companyAddress: '',
  zip: '',
  city: '',
  state: '',
  telephone: '',
  email: '',
  type: '',
};

export const AR_APP_BUTTON_CLICKED = 'Customer clicked on A/R application';

export class DefaultPaginationHelper {
  #_defaultPageNumber = 1;
  #_defaultPageSize = 50;
  #_maxVisiblePaginationLinks = 5;
  #_pagingOption = [10, 20, 30, 50, 100];
  #_pageNumber = 1;
  #_pageSize = 50;
  #_isValid = true;

  constructor({pageNumber, pageSize}) {
    const valid = this.validate({pageNumber, pageSize});
    this.#_pageNumber = valid.pageNumber;
    this.#_pageSize = valid.pageSize;
  }

  set defaultPageNumber(value: number) {
    this.#_defaultPageNumber = value;
    return this;
  }

  set defaultPageSize(value: number) {
    this.#_defaultPageSize = value;
    return this;
  }

  set maxVisiblePaginationLinks(value: number) {
    this.#_maxVisiblePaginationLinks = value;
    return this;
  }

  set pagingOption(value: Array) {
    this.#_pagingOption = value;
    return this;
  }

  get defaultPageNumber(): number {
    return this.#_defaultPageNumber;
  }

  get defaultPageSize(): number {
    return this.#_defaultPageSize;
  }

  get maxVisiblePaginationLinks(): number {
    return this.#_maxVisiblePaginationLinks;
  }

  get pagingOption(): number[] {
    return this.#_pagingOption;
  }

  get isValid(): boolean {
    return this.#_isValid;
  }

  get pageNumber(): number {
    return this.#_pageNumber;
  }

  get pageSize(): number {
    return this.#_pageSize;
  }

  validate({pageNumber, pageSize}) {
    return {
      pageNumber : this.validatePageNumber(pageNumber),
      pageSize: this.validatePageSize(pageSize)
    }
  }

  validatePageNumber(pageNumber) {
    if (!pageNumber || !Number.isInteger(pageNumber * 1)) {
      this.#_isValid = false
      return this.#_defaultPageNumber
    }

    return pageNumber
  }

  validatePageSize(pageSize) {
    if (!pageSize || !Number.isInteger(pageSize * 1)) {
      this.#_isValid = false
      return this.#_defaultPageSize
    }

    return pageSize
  }

  getPaginationLink = (pageNumber = null, pageSize = null) => {
    if (pageNumber == null) {
      pageNumber = this.pageNumber
    } else {
      pageNumber = this.validatePageNumber(pageNumber)
    }

    if (pageSize == null) {
      pageSize = this.pageSize
    } else {
      pageSize = this.validatePageNumber(pageSize)
    }

    return `pageNumber=${pageNumber}&pageSize=${pageSize}`
  }

  getDefaultPaginationLink = () => {
    return `pageNumber=${this.#_defaultPageNumber}&pageSize=${this.#_defaultPageSize}`
  }

  getPaginationLabel = (currentItems, totalItems) => {
    return (((this.#_pageNumber - 1) * this.#_pageSize) + 1)
        + ' - '
        + ((totalItems > this.#_pageSize * this.#_pageNumber)
            ? (this.#_pageSize * this.#_pageNumber) : totalItems)
  }

  getPages = (pages) => {
    let mod = pages % 1
    if (mod > 0) {
      pages = parseInt(pages) + 1
    }

    let arr = []
    for (let i = 0; i < pages; i++) {
      arr.push({})
    }

    return arr
  }
}

export class DefaultSortingHelper {
  static validSortColumnNames = [
      'shipmentUuid'
  ]
  #_defaultSortColumn = 'shipmentUuid';
  #_defaultSortOrder = 'desc';
  #_sortColumn = 'shipmentUuid';
  #_sortOrder = 'desc';
  #_isValid = true;

  constructor({sortColumn, sortOrder}) {
    const valid = this.validate({sortColumn, sortOrder});
    this.#_sortColumn = valid.sortColumn;
    this.#_sortOrder = valid.sortOrder;
  }

  validate({sortColumn, sortOrder}) {
    return {
      sortColumn: this.validateSortColumn(sortColumn),
      sortOrder: this.validateSortOrder(sortOrder)
    }
  }

  set defaultSortColumn(value: string) {
    this.#_defaultSortColumn = value;
    return this;
  }

  set defaultSortOrder(value: string) {
    this.#_defaultSortOrder = value;
    return this;
  }

  get defaultSortColumn(): string {
    return this.#_defaultSortColumn;
  }

  get defaultSortOrder(): string {
    return this.#_defaultSortOrder;
  }

  get sortColumn(): string {
    return this.#_sortColumn;
  }

  get sortOrder(): string {
    return this.#_sortOrder;
  }

  get isValid(): boolean {
    return this.#_isValid;
  }

  validateSortColumn(sortColumn) {
    if (!sortColumn || !this.constructor.validSortColumnNames.includes(sortColumn)) {
      this.#_isValid = false
      sortColumn = this.#_defaultSortColumn
    }

    return sortColumn
  }

  validateSortOrder(sortOrder) {
    if (!sortOrder || (sortOrder !== 'desc' && sortOrder !== 'asc')) {
      this.#_isValid = false
      sortOrder = this.#_defaultSortOrder
    }

    return sortOrder
  }

  getSortingLink = (sortColumn = null, sortOrder = null) => {
    if (sortColumn == null) {
      sortColumn = this.sortColumn
    } else {
      sortColumn = this.validateSortColumn(sortColumn)
    }

    if (sortOrder == null) {
      sortOrder = this.sortOrder
    } else {
      sortOrder = this.validateSortOrder(sortOrder)
    }

    return `sortColumn=${sortColumn}&sortOrder=${sortOrder}`
  }

  getDefaultSortingLink = () => {
    return `sortColumn=${this.#_defaultSortColumn}&sortOrder=${this.#_defaultSortOrder}`
  }
}

export class DefaultLinkHelper {
  #_link = '';
  #_isValid = true;
  #_pagination = new DefaultPaginationHelper({});
  #_sorting = new DefaultSortingHelper({});
  #_urlSearchParams;


  constructor(link) {
    this.#_urlSearchParams = new URLSearchParams(link)
    this.#_link = this.validate(link);
  }

  validate(link) {
    if (!link) {
      this.#_isValid = false
      return this.getDefaultLink()
    }

    return link
  }

  get link(): string {
    return this.#_link;
  }

  set link(value: string) {
    this.#_link = value;
    return this;
  }

  get isValid(): boolean {
    return this.#_isValid;
  }

  set isValid(value: boolean) {
    this.#_isValid = value;
    return this;
  }

  get pagination(): DefaultPaginationHelper {
    return this.#_pagination;
  }

  set pagination(value: DefaultPaginationHelper) {
    this.#_pagination = value;
    return this;
  }

  get sorting(): DefaultSortingHelper {
    return this.#_sorting;
  }

  set sorting(value: DefaultSortingHelper) {
    this.#_sorting = value;
    return this;
  }

  get urlSearchParams() {
    return this.#_urlSearchParams;
  }

  set urlSearchParams(value) {
    this.#_urlSearchParams = value;
  }

  getDefaultLink = () => {
    return `?${this.pagination.getDefaultPaginationLink()}&${this.sorting.getDefaultSortingLink()}`
  }

  getLink = (link = null) => {
    if (link == null) {
      link = this.#_link
    }

    return this.validate(link)
  }

  getExtraQuery = () => {
    let extraQuery = ''
    this.urlSearchParams.forEach((v, k) => {
      if (!['pageNumber', 'pageSize', 'sortColumn', 'sortOrder'].includes(k)) {
        extraQuery += `&${k}=${v}`
      }
    })

    return extraQuery
  }

  getLinkByParams = (pageNumber = null, pageSize = null, sortColumn = null, sortOrder = null) => {
    return `?${this.pagination.getPaginationLink(pageNumber, pageSize)}&${this.sorting.getSortingLink(sortColumn, sortOrder)}${this.getExtraQuery()}`
  }
}

