import React from 'react';
 
const renderCKField = ({
input, label, type, select, placeholder, dictionaries, currentLanguage, id, meta: { touched, error, warning },
}) => (
<div className="mb10">
<input {...input} type={type} id={id} checked={!!input.value} className="customCheckBX" />
<span className="data ml10">
{label}{' '}
</span>
{touched && (error && (<span className="invalidTxt">{dictionaries[currentLanguage][error].msg}</span>))}
 
</div>
);
export default renderCKField;