import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import authService from '../../../../services/authService';
import {encrypt} from '../../../../helpers/crypto';

const clientId = "1078296230270-vpahbnq487km7pj280o8hf5ck5khes4j.apps.googleusercontent.com";

class GoogleSignIn extends Component {
    constructor(props) {
        super(props);
    }

    onSuccess = (response) => {
        this.props.login(this.props.currentLanguage, this.props.dictionaries, {googleUser: encrypt(response.credential)})
    }

    onError = () => {
        console.log('Google login failed');
    }

    render() {
        return (
            <GoogleOAuthProvider clientId={clientId}>
                <div className="googleSignIn">
                    <GoogleLogin
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        text={'continue_with'}
                        shape={'circle'}
                        login_uri={'/quick-register'}
                    />
                </div>
            </GoogleOAuthProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
});

const mapDispatchToProps = (dispatch) => ({
    login: (lang, dictionaries, inputDTO) => {
        authService.login(dispatch, lang, dictionaries, inputDTO)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSignIn);
