import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store/configure';
import registerServiceWorker from './registerServiceWorker';

const { registerObserver } = require('react-perf-devtool')

// registerObserver()


if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position){
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
  };
}

const store = configureStore({

}, {});


ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));
// registerServiceWorker();
