// @flow

// /file/{fileId}

export const SET_CUSTOMER_ORDERS = 'shipment/SET_CUSTOMER_ORDERS'
export const SET_CURRENT_SHIPMENT = 'shipment/SET_CURRENT_SHIPMENT';
export const SET_MASTER_DATA = 'shipment/SET_MASTER_DATA';
export const SET_SHIPMENT_PRODUCTS = 'shipment/SET_SHIPMENT_PRODUCTS';
export const SET_ORIGIN_ADDRESSES = 'shipment/SET_ORIGIN_ADDRESSES';
export const SET_DEST_ADDRESSES = 'shipment/SET_DEST_ADDRESSES';
export const SET_INSURANCE_VALIDATE_FLAG = 'shipment/SET_INSURANCE_VALIDATE_FLAG';
export const SET_SHIPMENT_PRODUCTS_INSURANCE = 'shipment/SET_SHIPMENT_PRODUCTS_INSURANCE'
export const SET_TERMS_AND_CONDITIONS = 'shipment/SET_TERMS_AND_CONDITIONS'
export const SET_SHIPMENT_DETAIL = 'shipment/SET_SHIPMENT_DETAIL'
export const CLEAR_TRACKING_INFO = 'shipment/CLEAR_TRACKING_INFO'
export const SET_TRACKING_INFO = 'shipment/SET_TRACKING_INFO'
export const SET_TRACKING_HEADER = 'shipment/SET_TRACKING_HEADER'
export const SET_TRANSACTION_DETAIL = 'shipment/SET_TRANSACTION_DETAIL'
export const SET_SHIPMENT_CHARGES = 'shipment/SET_SHIPMENT_CHARGES'
export const CLEAR_ERROR_MESSAGE = 'shipment/CLEAR_ERROR_MESSAGE'
export const SET_ERROR_MESSAGE = 'shipment/SET_ERROR_MESSAGE'
export const SET_TRANSACTION_CHARGES = 'shipment/SET_TRANSACTION_CHARGES'
export const SET_PAYMENT_DONE = 'shipment/SET_PAYMENT_DONE'
export const TRACKING_INFO_AVAILABLE = 'shipment/TRACKING_INFO_AVAILABLE'
export const CLEAR_SHIPMENT_DETAIL = 'shipment/CLEAR_SHIPMENT_DETAIL'
export const UPDATE_DOCUMENT_PAGING = 'shipmentManagement/UPDATE_DOCUMENT_PAGING'
export const SET_DOCUMENTS = 'shipmentManagement/SET_DOCUMENTS'
export const CLEAR_DOCUMENTS = 'shipmentManagement/CLEAR_DOCUMENTS'
export const SET_PAYMENT_AR_OPTIONS = 'shipmentManagement/SET_PAYMENT_AR_OPTIONS';
export const SET_BOL_STATUS = 'shipmentManagement/SET_BOL_STATUS';
export const SET_ALL_BOL_STATUS = 'shipmentManagement/SET_ALL_BOL_STATUS';
export const SET_SHIPMENT_UUID = 'shipmentManagement/SET_SHIPMENT_UUID';
export const SET_TRACKING_INFO_SEARCH = 'shipmentManagement/SET_TRACKING_INFO_SEARCH';
export const SET_DOCUMENT_INFO = 'shipment/SET_DOCUMENT_INFO';
export const SET_DOCUMENT_INFO_LOADER = 'shipment/SET_DOCUMENT_INFO_LOADER';
export const SET_SH_INFO = 'shipment/SET_SH_INFO';
export const SET_BOL_STATUS_AND_DOCUMENTS = 'shipmentManagement/SET_BOL_STATUS_AND_DOCUMENTS';
export const SET_INSURANCE_STATUS = 'shipment/SET_INSURANCE_STATUS';
export const SET_IS_TRANSACTIONS_IN_PROCESS = 'shipment/SET_IS_TRANSACTIONS_IN_PROCESS';

export const SET_PRODUCT_IMAGES = 'shipment/SET_PRODUCT_IMAGES';
export const SET_VIEW_IMAGE = 'shipment/SET_VIEW_IMAGE';