// @flow
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import service from '../../services/rewardService';

const REDUCER = 'reward';
const REQUEST_SIGNUP = 'reward/request_signup';

export const IDLE = 0;
export const PENDING = 1;
export const FAILED = 2;

const initialState = {
    loading: IDLE,
    currentRequestId: undefined,
    errors: null,
    signup_status: IDLE,
};


export const signup = createAsyncThunk(
    REQUEST_SIGNUP,
    async (arg, { rejectWithValue }) => {
        try {
            const response = await service.signup();
            const {success = true, message = ''} = response.data;

            if (success === false){
                return rejectWithValue(message);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)


const objSlice = createSlice({
    name: REDUCER,
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.pending, (state, action) => {
                state.loading = PENDING
                state.signup_status = PENDING
                state.currentRequestId = action.meta.requestId
                state.errors = null;
            })
            .addCase(signup.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = IDLE
                    state.signup_status = IDLE
                    state.currentRequestId = undefined
                }
            })
            .addCase(signup.rejected, (state, action) => {
                const { requestId } = action.meta
                // console.log('action',action)
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = FAILED
                    state.signup_status = FAILED
                    state.errors = action.payload
                    state.currentRequestId = undefined
                }
            })
    },
})
// export const {  } = objSlice.actions;
export default objSlice.reducer;
