// @flow
// /file/{fileId}

export const SET_QUOTE = 'quoteManagement/SET_QUOTE';
export const SET_CUSTOMERS = 'quoteManagement/SET_CUSTOMERS';
export const SET_CUSTOMER_PRODUCTS = 'quoteManagement/SET_CUSTOMER_PRODUCTS';
export const SET_CUSTOMER_ADDRESS = 'quoteManagement/SET_CUSTOMER_ADDRESS';


export const SET_CUSTOMER = 'quoteManagement/SET_CUSTOMER';

export const SET_QUOTE_QUERY = 'quoteManagement/SET_QUOTE_QUERY';
export const UPDATE_QUOTE_QUERY = 'quoteManagement/UPDATE_QUOTE_QUERY';
export const RESET_QUOTE_QUERY = 'quoteManagement/RESET_QUOTE_QUERY';
export const SET_QUOTE_RESULTS = 'quoteManagement/SET_QUOTE_RESULTS';
export const SET_QUOTE_STEP = 'quoteManagement/SET_QUOTE_STEP';
export const SET_USER_FILTER = 'quoteManagement/SET_USER_FILTER';
export const SET_FACILITY_TYPES = 'quoteManagement/SET_FACILITY_TYPES';
export const SET_CONTRACTS = 'quoteManagement/SET_CONTRACTS';

export const SET_CARRIERS = 'quoteManagement/SET_CARRIERS';

export const SET_MASTER_DATA = 'quoteManagement/SET_MASTER_DATA';

export const SET_ORIGIN_CODE = 'quoteManagement/SET_ORIGIN_CODE';
export const SET_DESTINATION_CODE = 'quoteManagement/SET_DESTINATION_CODE';
export const SET_LOADER = 'quoteManagement/SET_LOADER'
export const SET_QUOTE_RESULT_NAVIGATED = 'quoteManagement/SET_QUOTE_RESULT_NAVIGATED'
export const SET_QUOTE_SEARCH_STRING = 'quoteManagement/SET_QUOTE_SEARCH_STRING'
export const SET_DEST_ZIP_LOADED = 'quoteManagement/SET_DEST_ZIP_LOADED'
export const SET_ORIGIN_ZIP_LOADED = 'quoteManagement/SET_ORIGIN_ZIP_LOADED'
