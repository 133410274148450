// @flow

// /file/{fileId}

export const SET_MESSAGE = 'common/SET_MESSAGE';
export const SET_LOADER = 'common/SET_LOADER';
export const SET_ACCESORIAL = 'common/SET_ACCESORIAL';
export const SET_MASTER_DATA = 'common/SET_MASTER_DATA';
export const DELETE_KEYWORD = 'common/DELETE_KEYWORD';
export const SET_TIMEZONES = 'common/SET_TIMEZONES';
export const SET_LOADING_FACILITY_TYPE = 'common/SET_LOADING_FACILITY_TYPE';
export const SET_CUSTOMER_MASTER_DATA = 'common/SET_CUSTOMER_MASTER_DATA';
export const SET_SHIPMENT_MASTER_DATA = 'common/SET_SHIPMENT_MASTER_DATA';
export const CHANGE_SIGN_UP_FORM = 'common/CHANGE_SIGN_UP_FORM';
export const DISPLAY_PASSWORD_INSECURE_WARNING = 'common/DISPLAY_PASSWORD_INSECURE_WARNING';
export const CHANGE_SIGN_UP_METHOD = 'common/CHANGE_SIGN_UP_METHOD';
export const CHANGE_DISPLAY_SHIPPING_FREQUENCY_FORM = 'common/CHANGE_DISPLAY_SHIPPING_FREQUENCY_FORM';
export const SAVE_SIGN_UP_REQUEST = 'common/SAVE_SIGN_UP_REQUEST';
export const CHANGE_SOCIAL_REQUIRED_DATA = 'common/CHANGE_SOCIAL_REQUIRED_DATA';
export const CHANGE_IS_COMMERCIAL = 'common/CHANGE_IS_COMMERCIAL';
export const SET_REQUIRED_CUSTOMER_DATA = 'quoteManagement/SET_REQUIRED_CUSTOMER_DATA';
export const SET_LINK_DATA = 'common/SET_LINK_DATA';
export const SET_LINK_FORGOT_PASSWORD = 'common/SET_LINK_DATA';
export const SET_TERMS_CONDITIONS = 'common/SET_TERMS_CONDITIONS';
export const TOGGLE_SIDEBAR = 'common/TOGGLE_SIDEBAR';
export const SET_POP_UP_CONTENT_LIST = 'common/SET_POP_UP_CONTENT_LIST'
