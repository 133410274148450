// @flow
import * as type from './actionTypes';
// initial state
const initialState = {
	email: null,
	accessToken: null,
	refreshToken: null,
	emailSent: false,
	accountView: false,
	loginflag: false,
	guestAccessToken: null,
	guestEmail: null,
	guestRefreshToken: null,
	allowGuest: false,
	passwordUpdated: false,
	resetFlag: true,
	messageStatus: null,
	toManyLoginAttempts: false
};
// reducer 
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.LOGIN:
			return {
				...state,
				login: [],
			};

		case type.RESET_FLAG:
			return {
				resetFlag: !state.resetFlag
			}

		case type.EMAIL_SENT:
			return {
				...state,
				emailSent: action.flag
			};

		case type.SET_ACCOUNT_VIEW:
			return {
				...state,
				accountView: {
					firstName: localStorage.getItem('fname'),
					lastName: localStorage.getItem('lname')
				}
			};

		case type.SET_CREDENTIALS:
			return {
				...state,
				accessToken: action.token,
				email: action.email,
				refreshToken: action.refreshToken
			}
		case type.SET_GUEST_CREDENTIALS:
			return {
				...state,
				guestAccessToken: action.token,
				guestEmail: action.email,
				guestRefreshToken: action.refreshToken
			}
		case type.ALLOW_GET_QUOTE:
			return {
				allowGuest: action.allowGuest
			}
		case type.SET_LOGIN_VIEW:
			return {
				loginflag: action.loginflag
			}
		case type.PASSWORD_UPDATED:
			return {
				...state,
				passwordUpdated: action.passwordUpdated
			}
		case type.SET_HEALTH_CHECK:
			return {
			  ...state,
			  messageStatus: action.messageStatus
			}
		case type.SET_TO_MANY_LOGIN_ATTEMPTS:
			return {
				...state,
				toManyLoginAttempts: action.toManyLoginAttempts
			}
		default:
			return state;
	}
};

export default reducer;
