import React from "react";
import {connect} from "react-redux";
// import LoginForm from '../../forms/loginForm';
import Footer from '../../atoms/Footer';
import Loader from '../../atoms/Loader';
import Header from '../../organisms/Header'
import {Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import GuestSignUp from '../../organisms/GuestSignUp';
import * as utilCommon from "../../../helpers/utilCommon";
import GuestSignUpV2 from '../../organisms/GuestSignUpV2';
import ReactGA from "react-ga4";

// load pages
const AuthSection = Loadable({
    loader: () => import('../AuthSection'),
    loading: Loader,
});

const HomePage = Loadable({
    loader: () => import('../HomePage'),
    loading: Loader,
});

const ltlFreightshipping = Loadable({
    loader: () => import('../ltlFreightshipping'),
    loading: Loader,
});

const AccountSettings = Loadable({
    loader: () => import('../MyAccount'),
    loading: Loader,
});

const GetQuote = Loadable({
    loader: () => import('../GetQuote'),
    loading: Loader,
});


class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    // componentWillMount() {
    //     this.props.sendInfoToGA(this.props.history);
    // }

    render() {
        const {match, history, location} = this.props;
        return (
            <div className="page">
                <Header history={this.props.history} location={location}/>
                {/* <UserSection history={this.props.history}/> */}
                <div className="main-panel">
                    <Route exact path={`/home`} component={HomePage}/>
                    <Route exact path={`/home/login`} component={AuthSection}/>
                    <Route exact path={`/home/signup`}
                           render={(routeProps) => <AuthSection signUpTab={true} {...routeProps} />}/>
                    <Route exact path={`/home/account-settings`} component={AccountSettings}/>
                    <Route exact path={`/home/freight-quote`} render={(routeProps) => <GetQuote {...routeProps} />}/>
                    <Route
                        exact
                        path={`/quick-register`}
                        render={(routeProps) => <GuestSignUpV2 {...routeProps}
                                                               form={'register'}
                                                               open={!this.props.user}
                                                               redirect={'/home/freight-quote'}
                                                               handleOpen={() => {}}
                        />}
                    />
                    <Route
                        exact
                        path={`/quick-signin`}
                        render={(routeProps) => <GuestSignUpV2 {...routeProps}
                                                               form={'login'}
                                                               open={!this.props.user}
                                                               redirect={'/home/freight-quote'}
                                                               handleOpen={() => {}}
                        />}
                    />
                    <Route exact path={`/shipping-frequency`}
                           render={(routeProps) => <GuestSignUp {...routeProps} open={!this.props.user}
                                                                handleOpen={() => {
                                                                }}
                                                                form={'frequency'}
                                                                redirect={'/home/freight-quote'}/>}
                    />
                    <Route exact path={`/home/ltlFreightshipping`} component={ltlFreightshipping}/>
                </div>
                <Footer/>
                {this.props.loading && <Loader/>}
            </div>
        );
    }
}

// map state to props
const mapStateToProps = state => ({
    user: utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken')
});
// map dispatch to props
const mapDispatchToProps = (dispatch) => ({});
// connect to store
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);

