const en = {
	//sign up messages
	1003: {
		type: 'error',
		text: 'Date added is required'
	  },
	  1053: {
		type: 'error',
		text: 'added by required'
	  },
	  1013: {
		type: 'error',
		text: 'User is not authorized'
	  },
	  1020: {
		type: 'error',
		text: 'User does not exist'
	  },
	  1017: {
		type: 'error',
		text: 'E-mail is required'
	  },
	  1026: {
		type: 'error',
		text: 'This email address is not registered.'
	  },
	  1002: {
		type: 'error',
		text: 'Invalid activation code'
	  },
	  1004: {
		type: 'info',
		text: 'Your password reset link has been sent, please check your email. Click the link within the email to create a new password. If you cannot find the message in your inbox, please check your junk or spam folders.'
	  },
	  1033: {
		type: 'error',
		text: 'Hire date is required'
	  },
	  1064: {
		type: 'error',
		text: 'Department is required'
	  },
	  1065: {
		type: 'error',
		text: 'Leads Enabled is required'
	  },
	  1031: {
		type: 'error',
		text: 'First name required'
	  },
	  1032: {
		type: 'error',
		text: 'Last name required'
	  },
	  1034: {
		type: 'error',
		text: 'Date should be mm/dd/yyyy'
	  },
	  1035: {
		type: 'error',
		text: 'Quota Required'
	  },
	  1036: {
		type: 'error',
		text: 'Not valid quota id'
	  },
	  1037: {
		type: 'error',
		text: 'Paytype required'
	  },
	  1038: {
		type: 'error',
		text: 'Not a valid paytype'
	  },
	  1039: {
		type: 'error',
		text: 'Extension required'
	  },
	  1040: {
		type: 'error',
		text: 'Top dog required'
	  },
	  1041: {
		type: 'error',
		text: 'Permitted values are 0 and 1'
	  },
	  1042: {
		type: 'error',
		text: 'Status reuired'
	  },
	  1043: {
		type: 'error',
		text: 'Lead enabled required'
	  },
	  1045: {
		type: 'error',
		text: 'Timezone required'
	  },
	  1046: {
		type: 'error',
		text: 'Sales team required'
	  },
	  1047: {
		type: 'error',
		text: 'Sales team id not valid'
	  },
	  1048: {
		type: 'error',
		text: 'Maximum extension range is 1 to 10'
	  },
	  1049: {
		type: 'error',
		text: 'Timezone id is not valid'
	  },
	  1009: {
		type: 'info',
		text: 'User added successfully.'
	  },
	  2020: {
		type: 'info',
		text: 'User updated successfully.'
	  },
	  2015: {
		type: 'info',
		text: 'Information updated'
	  },
	  2019: {
		type: 'info',
		text: 'Information updated'
	  },
	  1021: {
		type: 'error',
		text: 'Email address already exist'
	  },
	  2017: {
		type: 'error',
		text: 'Old Password did not match.'
	  },
	  2018: {
		type: 'error',
		text: 'Old Password required.'
	  },
	  1018: {
		type: 'error',
		text: 'New Password is required.'
	  },
	  1006: {
		type: 'info',
		text: 'Password reset successfully'
	  },
	  1007: {
		type: 'error',
		text: 'Unable to reset the password'
	  },
	  9001: {
		type: 'error',
		text: 'No records found on this page'
	  },

	  // Carrrier Messages
	  3001: {
		type: 'error',
		text: 'cname required'
	  },
	  3002: {
		type: 'error',
		text: 'max_200'
	  },
	  3003: {
		type: 'error',
		text: 'carrier_scan_required'
	  },
	  3004: {
		type: 'error',
		text: 'max_20'
	  },
	  3005: {
		type: 'error',
		text: 'phone_is_required'
	  },
	  3006: {
		type: 'error',
		text: 'max_15'
	  },
	  3007: {
		type: 'error',
		text: 'max_500'
	  },
	  3008: {
		type: 'error',
		text: 'carrier_status_req'
	  },
	  3009: {
		type: 'error',
		text: 'per_mitted_values_0_1_2'
	  },
	  3010: {
		type: 'error',
		text: 'c_on_time_required'
	  },
	  3011: {
		type: 'error',
		text: 'max_100'
	  },
	  3012: {
		type: 'error',
		text: 'damage_claim_required'
	  },
	  3013: {
		type: 'error',
		text: 'carrier ontime required'
	  },
	  3014: {
		type: 'info',
		text: 'Carrier added successfully'
	  },
	  3019: {
		type: 'info',
		text: 'Carrier updated successfully'
	  },
	  3015: {
		type: 'error',
		text: 'c_added_failed'
	  },
	  3016: {
		type: 'error',
		text: 'Carrier name should be unique'
	  },
	  9002: {
		type: 'error',
		text: 'No records found on this page'
	  },
	  2064: {
		type: 'error',
		text: 'Duplicate transit time'
	  },
	  3021: {
		type: 'info',
		text: 'Carriers updated successfully'
	  },
	  3025: {
		type: 'info',
		text: 'Carrier deleted successfully'
	  },
	  2370: {
		type: 'error',
		text: 'Transit time day(s) required'
	  },
	  2371: {
		type: 'error',
		text: 'Transit time day(s) should be numeric'
	  },
	  3029: {
		type: 'error',
		text: 'Carrier SCAC should be unique'
	  },

	  '20091': {
		type: 'info',
		text: 'shipment_quote_success'
	  }, //Get shipment quote successfully
	  '20092': {
		type: 'error',
		text: 'shipment_quote_success_failed'
	  }, //Error in shipment quote
	  '20093': {
		type: 'info',
		text: 'shipment_created_success'
	  }, // Shipment created successfully
	  '20094': {
		type: 'error',
		text: 'shipment_created_failed'
	  }, // Error in shipment cretaion
	  '20095': {
		type: 'info',
		text: 'get_image_success'
	  }, // get shipment image successfully
	  '20096': {
		type: 'error',
		text: 'get_image_failed'
	  }, // Error in get shipment image
	  '20105': {
		type: 'info',
		text: 'initialize_shipment_success'
	  }, // initialize shipment success
	  '20106': {
		type: 'error',
		text: 'initialize_shipment_fail'
	  }, // initialize shipment fail
	  '20107': {
		type: 'info',
		text: 'track_shipment_succ'
	  }, // track shipment success
	  '20108': {
		type: 'error',
		text: 'track_shipment_failed'
	  }, // track shipment fail
	  '20109': {
		type: 'info',
		text: 'cancel_shipment_succ'
	  }, // cancel shipment success
	  '20110': {
		type: 'error',
		text: 'cancel_shipment_failed'
	  }, // cancel shipment fail
	  // Customer Messages
	  '1101': 'user_task_uuid_max_36', //user task uuid max 36
	  '1102': 'user_task_uuid_unique', //user task uuid unique
	  '1103': 'user_uuiduser_task_required', //user uuiduser task required
	  '1104': 'user_uuiduser_task_not_exists', //user uuiduser task not exists
	  '1105': 'customer_uuiduser_task_not_exists', //customer uuiduser task not exists
	  '1106': 'shipment_uuid_max_36', //shipment uuid max 36
	  '1107': 'task_action_id_required', //task action id required
	  '1108': 'task_action_id_not_exists', //task action id not exists
	  '1109': 'task_description_required', //task description required
	  '1110': 'task_description_max_500', //task description max 500
	  '1111': 'assigned_user_uuid_not_exists', //assigned user uuid not exists
	  '1112': 'due_date_time_date_format_Y_m_d_H_i_s', //due date time date format Y m d H i s
	  '1113': 'customer_invite_in_0_1', //customer invite in 0 1
	  '1114': 'user_invite_in_0_1', //user invite in 0 1
	  '1115': 'customer_email_email', //customer email email
	  '1116': 'customer_email_max_100', //customer email max 100
	  '1117': 'task_status_in_0_1_2', //task status in 0 1 2
	  '1118': 'added_byuser_task_required', //added byuser task required
	  '1119': 'added_byuser_task_not_exists', //added byuser task not exists
	  '1120': 'added_dateuser_task_required', //added dateuser task required
	  '1121': 'added_dateuser_task_date_format_Y_m_d_H_i_s', //added dateuser task date format Y m d H i s
	  '1122': 'modified_byuser_task_required', //modified byuser task required
	  '1123': 'modified_byuser_task_not_exists', //modified byuser task not exists
	  '1124': 'modified_dateuser_task_required', //modified dateuser task required
	  '1125': 'modified_dateuser_task_date_format_Y_m_d_H_i_s', //modified dateuser task date format Y m d H i s
	  '1126': {
		type: 'successs',
		text: 'Task is added successfully'
	  }, //user task succ
	  '1127': {
		type: 'error',
		text: 'Failed to add task'
	  }, //user task failed
	  '1128': {
		type: 'successs',
		text: 'Task is updated Successfully'
	  }, //user task update succ
	  '1129': {
		type: 'error',
		text: 'Failed to update task'
	  }, //user task update fail
	  '1130': 'user_task_del_succ', //user task del succ
	  '1131': 'user_task_del_fail', //user task del fail
	  '1132': 'user_task_uuid_required', //user task uuid required
	  '1133': 'user_task_uuid_not_exists', //user task uuid not exists
	  '1135' : {type: 'error',text: 'Proxy user does not exist'},//Proxy user not found
	  1013 :  {type: 'error',text: 'User is not matched with role'},
	 10540: {type: 'error',text:'User role not found'},
	  4000: 'customer email id req',
	  4001: 'customer email id exists',
	  4002: 'customer first name required',
	  4003: 'customer last name required',
	  4004: 'customer type required',
	  4005: 'customer type required allowed 1 or 2',
	  4006: 'customer country required',
	  4007: 'customer department required',
	  4008: 'customer shipping freq required',
	  4009: 'customer shipping freq 1 or 2',
	  4010: 'customer phone required',
	  4011: 'phone ext required',
	  4012: 'customer alternate phone required',
	  4013: 'phone ext required',
	  4014: 'email already exists',
	  4015: 'customer fax phone required',
	  4016: 'customer bill email required',
	  4017: 'percentage discount required',
	  4018: 'customer master id required',
	  4019: 'customer invoice id required',
	  4020: 'customer invoice id not valid',
	  4021: 'customer invoice id not valid',
	  4022: 'customer invoice id not valid',
	  4023: 'customer state id',
	  4024: 'customer state id invalid',
	  4025: 'company name required',
	  4026: 'max character 100 allowed',
	  4027: 'department required',
	  4028: 'max character 100 allowed',
	  4029: 'max character 100 allowed',
	  4030: 'customer state required',
	  4031: {
		type: 'error',
		text: 'customer added by required'
	  },
	  4032: 'customer added failed',
	  4033: 'customer id required',
	  4034: 'customer id not exits',
	  4035: 'customer address required',
	  4036: 'customer address max 200',
	  4037: 'customer appartment required',
	  4038: 'customer appartment max 200',



	  4039: {
		type: 'info',
		text: 'customer added sucessfully'
	  },
	  4040: {
		type: 'error',
		text: 'Failed to add customer'
	  },
	  4041: {
		type: 'info',
		text: 'customer updated sucessfully'
	  },
	  4042: {
		type: 'error',
		text: 'Failed to update customer'
	  },
	  4537: {
		type: 'info',
		text: 'customer Preferences updated successfully'
	  },
	  4538: {
		type: 'error',
		text: 'Failed to update customer preferences'
	  },
	  4631: {
		type: 'info',
		text: 'customer address added succesfully'
	  },
	  4632: {
		type: 'error',
		text: 'Failed to add customer address'
	  },
	  4633: {
		type: 'info',
		text: 'customer address updated succesfully'
	  },
	  4634: {
		type: 'error',
		text: 'Failed to update customer address'
	  },
	  4639: {
		type: 'info',
		text: 'customer address deleted succesfully'
	  },
	  4640: {
		type: 'error',
		text: 'Failed to delete customer address'
	  },

	  4715: {
		type: 'info',
		text: 'customer note added succesfully'
	  },
	  4716: {
		type: 'error',
		text: 'Failed to add customer note'
	  },

	  '4717': { type: 'info', text: 'customer_note_update_succ' },	 //customer note update succ
	  '4718': { type: 'error', text: 'customer_note_update_fail' },	 //customer note update fail
	  '4719': { type: 'info', text: 'customer_note_del_succ' },	 //customer note del succ
	  '4720': { type: 'error', text: 'customer_note_del_fail' },	 //customer note del fail
	  '4721': { type: 'error', text: 'customer_note_uuid_required' },	 //customer note uuid required
	  '4722': { type: 'error', text: 'customer_note_uuid_not_exists' },	 //customer note uuid not exists
	  '4723': { type: 'error', text: 'customer_note_pin_required' },	 //customer note pin required
	  '4724': { type: 'error', text: 'customer_note_pin_in_0_1' },	 //customer note pin in_0_1


	  4725: {
		type: 'info',
		text: 'customer note is pinned succesfully'
	  },
	  4726: {
		type: 'info',
		text: 'customer note is unpinned succesfully'
	  },
	  4820: {
		type: 'info',
		text: 'customer carrier added succesfully'
	  },
	  4821: {
		type: 'error',
		text: 'Failed to add customer carrier'
	  },
	  4822: {
		type: 'info',
		text: 'customer carrier updated succesfully'
	  },
	  4824: {
		type: 'info',
		text: 'customer carrier updated succesfully'
	  },
	  4823: {
		type: 'error',
		text: 'Failed to update customer carrier'
	  },
	  4827: "carrier preference uuid does not exists",
	  4918: {
		type: 'info',
		text: 'customer detailed info added succesfully'
	  },
	  4919: {
		type: 'error',
		text: 'Failed to add customer detailed info'
	  },
	  4920: {
		type: 'info',
		text: 'customer detailed info updated succesfully'
	  },
	  4921: {
		type: 'error',
		text: 'Failed to update customer detailed info'
	  },
	  "05002": {
		type: 'info',
		text: 'customer product added succesfully'
	  },
	  "05003": {
		type: 'error',
		text: 'Failed to add customer product'
	  },
	  "05004": {
		type: 'info',
		text: 'customer product updated succesfully'
	  },
	  "05005": {
		type: 'error',
		text: 'Failed to update customer product'
	  },
	  '05006': {
		type: 'info',
		text: 'Customer product deleted successfully'
	  }, //customer product del succ
	  '05007': {
		type: 'error',
		text: 'failed to delete product'
	  },

	  '4505': 'customer_uuid customer_preference not exists',
	  '4506': 'download bol only required', //download bol only required
	  '4507': 'download bol only in 0 1', //download bol only in 0 1
	  '4508': 'auto booking enable required', //auto booking enable required
	  '4509': 'auto booking enable in 0 1', //auto booking enable in 0 1
	  '4510': 'non commissionable required', //non commisionable required
	  '4511': 'non commissionable in 0 1', //non commisionable in 0 1
	  '4512': 'send sms required', //send sms required

	  4039: {
		type: 'info',
		text: 'customer added sucessfully'
	  },
	  4040: {
		type: 'error',
		text: 'Failed to add customer'
	  },
	  4041: {
		type: 'info',
		text: 'customer updated sucessfully'
	  },
	  4042: {
		type: 'error',
		text: 'Failed to update customer'
	  },
	  4537: {
		type: 'info',
		text: 'customer Preferences updated successfully'
	  },
	  4538: {
		type: 'error',
		text: 'Failed to update customer preferences'
	  },
	  4631: {
		type: 'info',
		text: 'customer address added succesfully'
	  },
	  4632: {
		type: 'error',
		text: 'Failed to add customer address'
	  },
	  4633: {
		type: 'info',
		text: 'customer address updated succesfully'
	  },
	  4634: {
		type: 'error',
		text: 'Failed to update customer address'
	  },
	  4639: {
		type: 'info',
		text: 'customer address deleted succesfully'
	  },
	  4640: {
		type: 'error',
		text: 'Failed to delete customer address'
	  },

	  4715: {
		type: 'info',
		text: 'customer note added succesfully'
	  },
	  4716: {
		type: 'error',
		text: 'Failed to add customer note'
	  },
	  4725: {
		type: 'info',
		text: 'customer note is pinned succesfully'
	  },
	  4726: {
		type: 'info',
		text: 'customer note is unpinned succesfully'
	  },
	  4820: {
		type: 'info',
		text: 'customer carrier added succesfully'
	  },
	  4821: {
		type: 'error',
		text: 'Failed to add customer carrier'
	  },
	  4822: {
		type: 'info',
		text: 'customer carrier updated succesfully'
	  },
	  4824: {
		type: 'info',
		text: 'customer carrier updated succesfully'
	  },
	  4823: {
		type: 'error',
		text: 'Failed to update customer carrier'
	  },
	  4827: "carrier preference uuid does not exists",
	  4918: {
		type: 'info',
		text: 'customer detailed info added succesfully'
	  },
	  4919: {
		type: 'error',
		text: 'Failed to add customer detailed info'
	  },
	  4920: {
		type: 'info',
		text: 'customer detailed info updated succesfully'
	  },
	  4921: {
		type: 'error',
		text: 'Failed to update customer detailed info'
	  },
	  "05002": {
		type: 'info',
		text: 'customer product added succesfully'
	  },
	  "05003": {
		type: 'error',
		text: 'Failed to add customer product'
	  },
	  "05004": {
		type: 'info',
		text: 'customer product updated succesfully'
	  },
	  "05005": {
		type: 'error',
		text: 'Failed to update customer product'
	  },
	  '05006': {
		type: 'info',
		text: 'Customer product deleted successfully'
	  }, //customer product del succ
	  '05007': {
		type: 'error',
		text: 'failed to delete product'
	  },

	  '4505': 'customer_uuid customer_preference not exists',
	  '4506': 'download bol only required', //download bol only required
	  '4507': 'download bol only in 0 1', //download bol only in 0 1
	  '4508': 'auto booking enable required', //auto booking enable required
	  '4509': 'auto booking enable in 0 1', //auto booking enable in 0 1
	  '4510': 'non commissionable required', //non commisionable required
	  '4511': 'non commissionable in 0 1', //non commisionable in 0 1
	  '4512': 'send sms required', //send sms required

	  '4514': 'rating request lock required', //rating request lock required

	  '4516': 'rating request lock immunity required', //rating request lock immunity required

	  '4518': 'web service enable required', //web service enable required

	  '4520': 'api rating enable required', //api rating enable required

	  '4522': 'api booking enable required', //api booking enable required

	  '4524': 'use in required', //use in required

	  '4526': 'key max 20', //key max 20
	  '4527': 'added by customer preference required', //added by customer preference required
	  '4528': 'added by customer preference not exists', //added by customer preference not exists
	  '4529': 'added date customer preference  required', //added date customer preference  required
	  '4530': 'added date customer preference date format Y m d H i s', //added date customer preference date format Y m d H i s
	  '4531': 'modified by customer preference required', //modified by customer preference required
	  '4532': 'modified by customer preference not exists', //modified by customer preference not exists
	  '4533': 'modified date customer preference required', //modified date customer preference required

	  '4543': 'api customer disable required', //customer uuid customer preference not exists
	  '4544': 'api customer disable in 0 1', //customer uuid customer preference not exists
	  '4604': 'customer address1 required', //customer address1 required
	  '4605': 'customer address1 max 200', //customer address1 max 200
	  '4606': 'customer address2 max 200', //customer address2 max 200
	  '4607': 'customer zip required', //customer zip required
	  '4608': 'customer zip max 15', //customer zip max 15
	  '4609': 'customer city required', //customer city required
	  '4610': 'customer city max 200', //customer city max 200
	  '4611': 'customer state max 200', //customer state max 200
	  '4612': 'customer state id required', //customer state id required
	  '4613': 'customer state id not exists', //customer state id not exists
	  '4614': 'customer country id required', //customer country id required
	  '4615': 'customer country id not exists', //customer country id not exists
	  '4618': 'customer phone required', //customer phone required
	  '4619': 'customer phone max 15', //customer phone max 15
	  '4620': 'customer cell max 15', //customer cell max 15
	  '4621': 'customer email required', //customer email required
	  '4622': 'customer email max 100', //customer email max 100
	  '4641': 'customer state required', //customer state id not exists
	  '4700': 'customer note uuid required', //customer note uuid required
	  '4704': 'customer note required', //customer note required
	  '4705': 'customer note max 500', //customer note max 500
	  '4810': 'preference required', //preference required
	  '4900': 'customer_uuidcustomer_detailed_info_required',
	  '4901': 'customer_uuidcustomer_detailed_info_not_exists',

	  '4902': 'engagement type id required', //engagement type id required
	  '4903': 'engagement type id not exists', //engagement type id not exists
	  '4904': 'industry_id_not_exists',
	  '4905': 'industry_category_id_not_exists',
	  '4924': 'customer uuidcustomer detailed info required', //customer uuidcustomer detailed info required
	  '4925': 'customer uuidcustomer detailed info not exists', //customer uuidcustomer detailed info not exists
	  '4926': 'modified datecustomer detinfo required', //customer uuidcustomer detailed info not exists
	  '4927': 'modified datecustdetinfo date format Y m d', //customer uuidcustomer detailed info not exists
	  '4928': 'modified bycustomer detinfo required', //customer uuidcustomer detailed info not exists
	  '4929': 'modified bycustomer detinfo not exists', //customer uuidcustomer detailed info not exists
	  '4950': 'customer product uuid required', //customer product uuid required
	  '4951': 'customer product uuid max 36', //customer product uuid max 36
	  '4952': 'customer product uuid unique', //customer product uuid unique
	  '4953': 'customer uuidcustomer product required', //customer uuidcustomer product required
	  '4954': 'customer uuidcustomer product not exists', //customer uuidcustomer product not exists
	  '4955': 'product discription max 200', //product discription max 200
	  '4956': 'package type id required', //package type id required
	  '4957': 'package type id not exists', //package type id not exists
	  '4958': 'no of handling unit required', //no of handling unit required
	  '4959': 'no of handling unit number', //no of handling unit number
	  '4960': 'no of handling unit between 0 127', //no of handling unit between 0 127
	  '4961': 'no of line item required', //no of line item required
	  '4962': 'no of line item number', //no of line item number
	  '4963': 'no of line item between 0 127', //no of line item between 0 127
	  '4964': 'total weight required', //total weight required
	  '4965': 'total weight number', //total weight number
	  '4966': 'total weight between 0 999999', //total weight between 0 999999
	  '4967': 'weight measurement required', //weight measurement required
	  '4968': 'weight measurement max 200', //weight measurement max 200
	  '4969': 'nmfc max 10',
	  '4970': 'product width required',
	  '4971': 'product width between 0 999999',
	  '4972': 'product length required',
	  '4973': 'product length between 0 999999',
	  '4974': 'product height required',
	  '4975': 'product height between 0 999999',
	  '4976': 'product volume required',
	  '4977': 'product volume between 0 999999',
	  '4978': 'product measurement required',
	  '4979': 'product measurement number',
	  '4980': 'product measurement between 0 127',
	  '4981': 'measurement in is  required',
	  '4982': 'measurement must be 1 or 2',
	  '4983': 'freight class id required',
	  '4984': 'freight class id not exists',
	  '4985': 'haz-mat item required',
	  '4986': 'haz-mat item in 0 1',
	  '4987': 'stackable item required',
	  '4988': 'stackable item in 0 1',
	  '4989': 'commodity type id required',
	  '4990': 'commodity type id not exists',
	  '4991': 'commodity category id required',
	  '4992': 'commodity category id not exists',
	  '4993': 'product status in 0 1 2',

	  '4906': 'sic code max 6', //sic code max 6
	  '4907': 'year establish date format Y', //year establish date format Y
	  '4908': 'annual revenue number', //annual revenue number
	  '4909': 'annual revenue between 0 99999999', //annual revenue between 0 99999999
	  '4910': 'freight spend number', //freight spend number
	  '4911': 'freight spend between 0 99999999', //freight spend between 0 99999999
	  '4912': 'freight spend wise max 5', //freight spend wise max 5
	  '4913': 'csp qualified in 0 1', //csp qualified in 0 1
	  '4914': 'recommended discount numeric', //recommended discount numeric
	  '4915': 'recommended discount between 0 127', //recommended discount between 0 127
	  '4916': 'other shiping provider numeric in 0 1', //other shiping provider numeric in 0 1
	  '4917': 'provider name max 255', //provider name max 255


	  '4924': 'customer uuidcustomer detailed info required', //customer uuidcustomer detailed info required
	  '4925': 'customer uuidcustomer detailed info not exists', //customer uuidcustomer detailed info not exists
	  '4926': 'modified datecustomer detinfo required', //customer uuidcustomer detailed info not exists
	  '4927': 'modified datecustdetinfo date format Y m d', //customer uuidcustomer detailed info not exists
	  '4928': 'modified bycustomer detinfo required', //customer uuidcustomer detailed info not exists
	  '4929': 'modified bycustomer detinfo not exists', //customer uuidcustomer detailed info not exists
	  '4950': 'customer product uuid required', //customer product uuid required
	  '4951': 'customer product uuid max 36', //customer product uuid max 36
	  '4952': 'customer product uuid unique', //customer product uuid unique
	  '4953': 'customer uuidcustomer product required', //customer uuidcustomer product required
	  '4954': 'customer uuidcustomer product not exists', //customer uuidcustomer product not exists
	  '4955': 'product discription max 200', //product discription max 200
	  '4956': 'package type id required', //package type id required
	  '4957': 'package type id not exists', //package type id not exists
	  '4958': 'no of handling unit required', //no of handling unit required
	  '4959': 'no of handling unit number', //no of handling unit number
	  '4960': 'no of handling unit between 0 127', //no of handling unit between 0 127
	  '4961': 'no of line item required', //no of line item required
	  '4962': 'no of line item number', //no of line item number
	  '4963': 'no of line item between 0 127', //no of line item between 0 127
	  '4964': 'total weight required', //total weight required
	  '4965': 'total weight number', //total weight number
	  '4966': 'total weight between 0 999999', //total weight between 0 999999
	  '4967': 'weight measurement required', //weight measurement required
	  '4968': 'weight measurement max 200', //weight measurement max 200
	  '4969': 'nmfc max 10',
	  '4970': 'product width required',
	  '4971': 'product width between 0 999999',
	  '4972': 'product length required',
	  '4973': 'product length between 0 999999',
	  '4974': 'product height required',
	  '4975': 'product height between 0 999999',
	  '4976': 'product volume required',
	  '4977': 'product volume between 0 999999',
	  '4978': 'product measurement required',
	  '4979': 'product measurement number',
	  '4980': 'product measurement between 0 127',
	  '4981': 'measurement in is  required',
	  '4982': 'measurement must be 1 or 2',
	  '4983': 'freight class id required',
	  '4984': 'freight class id not exists',
	  '4985': 'haz-mat item required',
	  '4986': 'haz-mat item in 0 1',
	  '4987': 'stackable item required',
	  '4988': 'stackable item in 0 1',
	  '4989': 'commodity type id required',
	  '4990': 'commodity type id not exists',
	  '4991': 'commodity category id required',
	  '4992': 'commodity category id not exists',
	  '4993': 'product status in 0 1 2',

	  11019: {
		type: 'info',
		text: 'Customer Contact is added successfully'
	  },

	  4644: {
		type: 'info',
		text: 'Customer reset password e-mail sent successfully.'
	  },



	  50000: {
		type: 'info',
		text: 'Contract added successfully.'
	  },
	  50001: {
		type: 'info',
		text: 'Contract updated successfully'
	  },
	  // shipment Messages
	  '12000': {
		type: 'error',
		text: 'shipment_address_uuid_required'
	  }, //shipment address uuid required
	  '12001': {
		type: 'error',
		text: 'shipment_address_uuid_max_36'
	  }, //shipment address uuid max 36
	  '12002': {
		type: 'error',
		text: 'shipment_uuidshipment_address_required'
	  }, //shipment uuidshipment address required
	  '12003': {
		type: 'error',
		text: 'shipment_uuidshipment_address_not_exists'
	  }, //shipment uuidshipment address not exists
	  '12004': {
		type: 'error',
		text: 'customer_uuidshipment_address_required'
	  }, //customer uuidshipment address required
	  '12005': {
		type: 'error',
		text: 'customer_uuidshipment_address_not_exists'
	  }, //customer uuidshipment address not exists

	  '12006': {
		type: 'error',
		text: 'shipment_from_address1_required'
	  }, //shipment address1 required
	  '12007': {
		type: 'error',
		text: 'shipment_from_address1_max_200'
	  }, //shipment address1 max 200
	  '12008': {
		type: 'error',
		text: 'shipment_from_address2_max_200'
	  }, //shipment address2 max 200
	  '12009': {
		type: 'error',
		text: 'shipment_from_zip_required'
	  }, //shipment zip required
	  '12010': {
		type: 'error',
		text: 'shipment_from_zip_max_15'
	  }, //shipment zip max 15
	  '12011': {
		type: 'error',
		text: 'shipment_from_city_required'
	  }, //shipment city required
	  '12012': {
		type: 'error',
		text: 'shipment_from_city_max_200'
	  }, //shipment city max 200
	  '12013': {
		type: 'error',
		text: 'shipment_from_state_max_200'
	  }, //shipment state max 200
	  '12014': {
		type: 'error',
		text: 'shipment_from_state_id_required'
	  }, //shipment state id required
	  '12015': {
		type: 'error',
		text: 'shipment_from_state_id_not_exists'
	  }, //shipment state id not exists
	  '12016': {
		type: 'error',
		text: 'shipment_from_country_id_required'
	  }, //shipment country id required
	  '12017': {
		type: 'error',
		text: 'shipment_from_country_id_not_exists'
	  }, //shipment country id not exists
	  '12018': {
		type: 'error',
		text: 'shipment_from_phone_required'
	  }, //shipment phone required
	  '12019': {
		type: 'error',
		text: 'shipment_from_phone_max_15'
	  }, //shipment phone max 15

	  '12020': {
		type: 'error',
		text: 'shipment_to_address1_required'
	  }, //shipment address1 required
	  '12021': {
		type: 'error',
		text: 'shipment_to_address1_max_200'
	  }, //shipment address1 max 200
	  '12021': {
		type: 'error',
		text: 'shipment_to_address2_max_200'
	  }, //shipment address2 max 200
	  '12022': {
		type: 'error',
		text: 'shipment_to_zip_required'
	  }, //shipment zip required
	  '12023': {
		type: 'error',
		text: 'shipment_to_zip_max_15'
	  }, //shipment zip max 15
	  '12024': {
		type: 'error',
		text: 'shipment_to_city_required'
	  }, //shipment city required
	  '12025': {
		type: 'error',
		text: 'shipment_to_city_max_200'
	  }, //shipment city max 200
	  '12026': {
		type: 'error',
		text: 'shipment_to_state_max_200'
	  }, //shipment state max 200
	  '12027': {
		type: 'error',
		text: 'shipment_to_state_id_required'
	  }, //shipment state id required
	  '12028': {
		type: 'error',
		text: 'shipment_to_state_id_not_exists'
	  }, //shipment state id not exists
	  '12029': {
		type: 'error',
		text: 'shipment_to_country_id_required'
	  }, //shipment country id required
	  '12030': {
		type: 'error',
		text: 'shipment_to_country_id_not_exists'
	  }, //shipment country id not exists
	  '12031': {
		type: 'error',
		text: 'shipment_to_phone_required'
	  }, //shipment phone required
	  '12032': {
		type: 'error',
		text: 'shipment_to_phone_max_15'
	  }, //shipment phone max 15

	  '12033': {
		type: 'error',
		text: 'added_byshipment_address_required'
	  }, //added byshipment address required
	  '12034': {
		type: 'error',
		text: 'added_byshipment_address_not_exists'
	  }, //added byshipment address not exists
	  '12035': {
		type: 'error',
		text: 'added_dateshipment_address_required'
	  }, //added dateshipment address required
	  '12036': {
		type: 'error',
		text: 'added_dateshipment_address_date_format_Y_m_d H_i_s'
	  }, //added dateshipment address date format Y m d H i s
	  '12037': {
		type: 'error',
		text: 'modified_byshipment_address_required'
	  }, //modified byshipment address required
	  '12038': {
		type: 'error',
		text: 'modified_byshipment_address_not_exists'
	  }, //modified byshipment address not exists
	  '12039': {
		type: 'error',
		text: 'modified_dateshipment_address_required'
	  }, //modified dateshipment address required
	  '12040': {
		type: 'error',
		text: 'modified_dateshipment_address_date_format_Y_m_d H_i_s'
	  }, //modified dateshipment address date format Y m d H i s
	  '12041': {
		type: 'info',
		text: 'shipment_address_succ'
	  }, //shipment address succ
	  '12042': {
		type: 'error',
		text: 'shipment_address_failed'
	  }, //shipment address failed
	  '12043': {
		type: 'info',
		text: 'shipment_address_update_succ'
	  }, //shipment address update succ
	  '12044': {
		type: 'error',
		text: 'shipment_address_update_fail'
	  }, //shipment address update fail
	  '12045': {
		type: 'info',
		text: 'shipment_address_del_succ'
	  }, //shipment address del succ
	  '12046': {
		type: 'error',
		text: 'shipment_address_del_fail'
	  }, //shipment address del fail
	  '12047': {
		type: 'error',
		text: 'shipment_address_uuid_not_exists'
	  }, //shipment address uuid not exists
	  '12048': {
		type: 'info',
		text: '_shipment_add_del_succ'
	  },
	  '12049': {
		type: 'error',
		text: '_shipment_add_del_fail'
	  },

	  '12050': {
		type: 'error',
		text: 'shipment_from_loading_facility_required'
	  }, //shipment from loading facility required
	  '12051': {
		type: 'error',
		text: 'shipment_from_loading_facility_max_200'
	  }, //shipment from loading facility max 200
	  '12052': {
		type: 'error',
		text: 'shipment_from_company_required'
	  }, //shipment from companyrequired
	  '12053': {
		type: 'error',
		text: 'shipment_from_company_max_200'
	  }, //shipment from company max 200
	  '12054': {
		type: 'error',
		text: 'shipment_from_first_name_required'
	  }, //shipment from first name required
	  '12055': {
		type: 'error',
		text: 'shipment_from_first_name_max_200'
	  }, //shipment from first name max 200
	  '12056': {
		type: 'error',
		text: 'shipment_from_last_name_required'
	  }, //shipment from last name required
	  '12057': {
		type: 'error',
		text: 'shipment_from_last_name_max_200'
	  }, //shipment from last name max 200
	  '12058': {
		type: 'error',
		text: 'shipment_from_email_required'
	  }, //shipment from email required
	  '12059': {
		type: 'error',
		text: 'shipment_from_email_max_200'
	  }, //shipment from email max 200
	  '12060': {
		type: 'error',
		text: 'shipment_from_show_required'
	  }, //shipment from show required
	  '12061': {
		type: 'error',
		text: 'shipment_from_show_max_200'
	  }, //shipment from show max 200
	  '12062': {
		type: 'error',
		text: 'shipment_from_venue_required'
	  }, //shipment from venue required
	  '12063': {
		type: 'error',
		text: 'shipment_from_venue_max_200'
	  }, //shipment from venue max 200
	  '12064': {
		type: 'error',
		text: 'shipment_from_move_out_day_required'
	  }, //shipment from move out day required
	  '12065': {
		type: 'error',
		text: 'shipment_from_move_out_day_max_20'
	  }, //shipment from move out day max 20
	  '12066': {
		type: 'error',
		text: 'shipment_from_contact_name_required'
	  }, //shipment from contact name required
	  '12067': {
		type: 'error',
		text: 'shipment_from_contact_name_max_200'
	  }, //shipment from contact name max 200

	  '12068': {
		type: 'error',
		text: 'shipment_to_loading_facility_required'
	  }, //shipment to loading facility required
	  '12069': {
		type: 'error',
		text: 'shipment_to_loading_facility_max_200'
	  }, //shipment to loading facility max 200
	  '12070': {
		type: 'error',
		text: 'shipment_to_company_required'
	  }, //shipment to companyrequired
	  '12071': {
		type: 'error',
		text: 'shipment_to_company_max_200'
	  }, //shipment to company max 200
	  '12072': {
		type: 'error',
		text: 'shipment_to_first_name_required'
	  }, //shipment to first name required
	  '12073': {
		type: 'error',
		text: 'shipment_to_first_name_max_200'
	  }, //shipment to first name max 200
	  '12074': {
		type: 'error',
		text: 'shipment_to_last_name_required'
	  }, //shipment to last name required
	  '12075': {
		type: 'error',
		text: 'shipment_to_last_name_max_200'
	  }, //shipment to last name max 200
	  '12076': {
		type: 'error',
		text: 'shipment_to_email_required'
	  }, //shipment to email required
	  '12077': {
		type: 'error',
		text: 'shipment_to_email_max_200'
	  }, //shipment to email max 200
	  '12078': {
		type: 'error',
		text: 'shipment_to_show_required'
	  }, //shipment to show required
	  '12079': {
		type: 'error',
		text: 'shipment_to_show_max_200'
	  }, //shipment to show max 200
	  '12080': {
		type: 'error',
		text: 'shipment_to_venue_required'
	  }, //shipment to venue required
	  '12081': {
		type: 'error',
		text: 'shipment_to_venue_max_200'
	  }, //shipment to venue max 200
	  '12082': {
		type: 'error',
		text: 'shipment_to_move_in_day_required'
	  }, //shipment to move in day required
	  '12083': {
		type: 'error',
		text: 'shipment_to_move_in_day_max_20'
	  }, //shipment to move in day max 20
	  '12084': {
		type: 'error',
		text: 'shipment_to_contact_name_required'
	  }, //shipment to contact name required
	  '12085': {
		type: 'error',
		text: 'shipment_to_contact_name_max_200'
	  }, //shipment to contact name max 200

	  '12086': {
		type: 'error',
		text: 'shipment_short_id_required'
	  }, //shipment short id is required
	  '12087': {
		type: 'error',
		text: 'shipment_short_id_max_20'
	  }, //shipment short id max 20
	  '12088': {
		type: 'error',
		text: 'shipment_uuid_required'
	  }, //shipment uuid is required
	  '12089': {
		type: 'error',
		text: 'shipment_uuid_max_36'
	  }, //shipment uuid  max 200
	  '12090': {
		type: 'error',
		text: 'customer_uuid_shipment_required'
	  }, //customer uuid shipment is required
	  '12091': {
		type: 'error',
		text: 'customer_uuid_shipment_not_exists'
	  }, //customer uuid shipment not exists
	  '12092': {
		type: 'error',
		text: 'shipment_short_id_required'
	  }, //shipment short id is required
	  '12093': {
		type: 'error',
		text: 'shipment_short_id_max_20'
	  }, //shipment short id max 20
	  '12094': {
		type: 'error',
		text: 'shipment_mode_shipment_required'
	  }, //shipment mode shipment is required
	  '12095': {
		type: 'error',
		text: 'shipment_mode_shipment_not_exists'
	  }, //shipment mode shipment not exists
	  '12096': {
		type: 'error',
		text: 'agent_user_shipment_required'
	  }, //agent user shipment is required
	  '12097': {
		type: 'error',
		text: 'agent_user_shipment_not_exists'
	  }, //agent user shipment max not exists
	  '12098': {
		type: 'error',
		text: 'quote_date_shipment_date_format_Y_m_d_H_i_s'
	  }, //shipment to contact name max 200
	  '12099': {
		type: 'error',
		text: 'pro_number_shipment_max_40'
	  }, //pro number shipment name max 40
	  '12100': {
		type: 'error',
		text: 'po_number_shipment_max_40'
	  }, //po number shipment max 40
	  '12101': {
		type: 'error',
		text: 'pickup_number_shipment_max_40'
	  }, //pick up number shipment max 40
	  '12102': {
		type: 'error',
		text: 'carrier_quote_number_shipment_max_40'
	  }, // carrier quote shipment number max 40    
	  '12103': {
		type: 'error',
		text: 'modified_by_shipment_required'
	  }, // modified by shipment is required
	  '12104': {
		type: 'error',
		text: 'modified_by_shipment_not_exists'
	  }, //modified by shipment does not exists
	  '12105': {
		type: 'error',
		text: 'modified_date_shipment_required'
	  }, //modified date shipment is required
	  '12106': {
		type: 'error',
		text: 'modified_date_shipment_date_format_Y_m_d_H_i_s'
	  }, //modified date shipment not in valid format
	  '12107': {
		type: 'error',
		text: 'carrier_uuid_shipment_not_exists'
	  }, //carrier uuid shipment not exists
	  '12108': {
		type: 'info',
		text: 'Shipment info is added successfully'
	  }, //shipment add success
	  '12109': {
		type: 'error',
		text: 'Failed to add shipment info'
	  }, //shipment add failed
	  '12110': {
		type: 'info',
		text: 'Shipment info updated successfully'
	  }, //shipment update success
	  '12111': {
		type: 'error',
		text: 'Failed to update Shipment info'
	  }, //shipment update failed
	  '12112': {
		type: 'info',
		text: '_shipment_del_succ'
	  }, //shipment delete success
	  '12113': {
		type: 'error',
		text: '_shipment_del_failed'
	  }, //shipment delete failed
	  '12114': {
		type: 'error',
		text: 'added_by_shipment_required'
	  }, // added by shipment is required
	  '12115': {
		type: 'error',
		text: 'added_by_shipment_not_exists'
	  }, //added by shipment does not exists
	  '12116': {
		type: 'error',
		text: 'added_date_shipment_required'
	  }, //added date shipment is required
	  '12117': {
		type: 'error',
		text: 'added_date_shipment_date_format_Y_m_d_H_i_s'
	  }, //added date shipment not in valid format'
	  '12107': {
		type: 'error',
		text: 'carrier_uuid_shipment_not_exists'
	  }, //carrier uuid shipment not exists

	  '12117': {
		type: 'error',
		text: 'added_date_shipment_date_format_Y_m_d_H_i_s'
	  }, //added date shipment not in valid format
	  '12118': {
		type: 'error',
		text: 'shipment_uuid_not_exists'
	  }, //shipment uuid not exists
	  '12119': {
		type: 'error',
		text: 'shipment_address_uuid_not_exists'
	  }, //shipment address uuid not exists
	  '12120': {
		type: 'error',
		text: 'shipment_from_department_max_200'
	  }, //shipment from department max 200
	  '12121': {
		type: 'error',
		text: 'shipment_to_department_max_200'
	  }, //shipment to department max 200
	  '12122': {
		type: 'error',
		text: 'shipment_address_succ'
	  }, //shipment address add success
	  '12123': {
		type: 'error',
		text: 'shipment_address_failed'
	  }, //shipment address add failed
	  '12124': {
		type: 'info',
		text: 'shipment_address_update_succ'
	  }, //shipment address update success
	  '12125': {
		type: 'error',
		text: 'shipment_address_update_fail'
	  }, //shipment address update failed
	  '12126': {
		type: 'info',
		text: '_shipment_add_del_succ'
	  }, //shipment address delete success
	  '12127': {
		type: 'error',
		text: '_shipment_add_del_fail'
	  }, //shipment address delete failed
	  '12145': {
		type: 'error',
		text: 'shipment_from_address_phone_required'
	  }, // shipment address from address phone is required
	  '12146': {
		type: 'error',
		text: 'shipment_from_address_phone_max_15'
	  }, // shipment address from address phone max 15
	  '12147': {
		type: 'error',
		text: 'shipment_to_address_phone_required'
	  }, // shipment address to address phone is required
	  '12148': {
		type: 'error',
		text: 'shipment_to_address_phone_max_15'
	  }, // shipment address to address phone is required
	  '12149': {
		type: 'error',
		text: 'requested_pick_up_start_date_shipment_required'
	  }, //shipment request start pick up date is required
	  '12150': {
		type: 'error',
		text: 'requested_pick_up_start_date_format_Y_m_d_H_i_s'
	  }, //shipment request start pick up date format Y-m-d H:i:s
	  '12151': {
		type: 'error',
		text: 'requested_pick_up_end_date_shipment_required'
	  }, //shipment request end pick up date is required
	  '12152': {
		type: 'error',
		text: 'requested_pick_up_end_date_format_Y_m_d_H_i_s'
	  }, //shipment request end pick up date format Y-m-d H:i:s
	  '12155': {
		type: 'error',
		text: 'special_instructions_shipment_max_250'
	  }, // Shipment Special Instructions max 250
	  '12156': {
		type: 'info',
		text: 'shipment_patch_succ'
	  }, // sipment patch successful
	  '12157': {
		type: 'error',
		text: 'shipment_patch_fail'
	  }, // sipment patch failed
	  12156: {
		type: 'info',
		text: 'shipment is successfully reassigned'
	  },
	  12157: {
		type: 'error',
		text: 'shipment reassignment failed'
	  },
	  '12259': {
		type: 'error',
		text: 'shipment_from_state_required'
	  },
	  '12158': {
		type: "error",
		text: 'shipment_note_uuid_required'
	  }, //shipment note uuid required
	  '12159': {
		type: "error",
		text: 'shipment_note_uuid_max_36'
	  }, //shipment note uuid max 36
	  '12160': {
		type: "error",
		text: 'shipment_uuidshipment_note_required'
	  }, //shipment uuidshipment note required
	  '12161': {
		type: "error",
		text: 'shipment_uuidshipment_note_not_exists'
	  }, //shipment uuidshipment note not exists
	  '12162': {
		type: "error",
		text: 'shipment_note_required'
	  }, //shipment note required
	  '12163': {
		type: "error",
		text: 'shipment_note_max_500'
	  }, //shipment note max 500
	  '12164': {
		type: "error",
		text: 'shipment_note_status_in_0_1_2'
	  }, //shipment note status in 0 1 2
	  '12165': {
		type: "error",
		text: 'added_byshipment_note_required'
	  }, //added byshipment note required
	  '12166': {
		type: "error",
		text: 'added_byshipment_note_not_exists'
	  }, //added byshipment note not exists
	  '12167': {
		type: "error",
		text: 'added_dateshipment_note_required'
	  }, //added dateshipment note required
	  '12168': {
		type: "error",
		text: 'added_dateshipment_note_date_format_Y_m_d H_i_s'
	  }, //added dateshipment note date format Y m d H i s
	  '12169': {
		type: "error",
		text: 'modified_byshipment_note_required'
	  }, //modified byshipment note required
	  '12170': {
		type: "error",
		text: 'modified_byshipment_note_not_exists'
	  }, //modified byshipment note not exists
	  '12171': {
		type: "error",
		text: 'modified_dateshipment_note_required'
	  }, //modified dateshipment note datetime) required
	  '12171': {
		type: "error",
		text: 'modified_dateshipment_note_date_format_Y_m_d H_i_s'
	  }, //modified dateshipment note date format Y m d H i s
	  '12172': {
		type: "info",
		text: 'shipment note added successfully'
	  }, //shipment note succ
	  '12173': {
		type: "error",
		text: 'failed to add shipment note'
	  }, //shipment note failed
	  '12174': {
		type: "info",
		text: 'shipment_note_update_succ'
	  }, //shipment note update succ
	  '12175': {
		type: "error",
		text: 'shipment_note_update_fail'
	  }, //shipment note update fail
	  '12176': {
		type: "info",
		text: 'shipment_note_del_succ'
	  }, //shipment note del succ
	  '12177': {
		type: "error",
		text: 'shipment_note_del_fail'
	  }, //shipment note del fail
	  '12178': {
		type: "error",
		text: 'shipment_note_uuid_required'
	  }, //shipment note uuid required
	  '12179': {
		type: "error",
		text: 'shipment_note_uuid_not_exists'
	  }, //shipment note uuid not exists

	  '12180': {
		type: "error",
		text: 'shipment_note_pin_required'
	  }, //shipment note pin required
	  '12181': {
		type: "error",
		text: 'shipment_note_pin_in_0_1'
	  }, //shipment note pin in_0_1

	  '12182': {
		type: "info",
		text: 'shipment note is pinned'
	  }, //shipment note pinned
	  '12183': {
		type: "info",
		text: 'shipment note is unpinned'
	  }, //shipment  note unpiined

	  '12184': {
		type: 'error',
		text: 'shipment_product_uuid_required'
	  }, //shipment product uuid required
	  '12185': {
		type: 'error',
		text: 'shipment_product_uuid_max_36'
	  }, //shipment product uuid max 36
	  '12186': {
		type: 'error',
		text: 'shipment_product_uuid_unique'
	  }, //shipment product uuid unique
	  '12187': {
		type: 'error',
		text: 'shipment_uuidcshipment_product_required'
	  }, //shipment uuidshipment product required
	  '12188': {
		type: 'error',
		text: 'shipment_uuidshipment_product_not_exists'
	  }, //shipment uuidshipment product not exists
	  '12189': {
		type: 'error',
		text: 'customer_uuidcustomer_product_required'
	  }, //customer uuidcustomer product required
	  '12190': {
		type: 'error',
		text: 'customer_uuidcustomer_product_not_exists'
	  }, //customer uuidcustomer product not exists
	  '12191': {
		type: 'error',
		text: 'product_description_max_200'
	  }, //product discription max 200
	  '12192': {
		type: 'error',
		text: 'package_type_id_required'
	  }, //package type id required
	  '12193': {
		type: 'error',
		text: 'package_type_id_not_exists'
	  }, //package type id not exists
	  '12194': {
		type: 'error',
		text: 'no_of_handling_unit_required'
	  }, //no of handling unit required
	  '12195': {
		type: 'error',
		text: 'no_of_handling_unit_number'
	  }, //no of handling unit number
	  '12196': {
		type: 'error',
		text: 'no_of_handling_unit_between_0_127'
	  }, //no of handling unit between 0 127
	  '12197': {
		type: 'error',
		text: 'no_of_item_required'
	  }, //no of line item required
	  '12198': {
		type: 'error',
		text: 'no_of_item_number'
	  }, //no of line item number
	  '12199': {
		type: 'error',
		text: 'no_of_item_between_0_127'
	  }, //no of line item between 0 127
	  '12200': {
		type: 'error',
		text: 'total_weight_required'
	  }, //total weight required
	  '12201': {
		type: 'error',
		text: 'total_weight_number'
	  }, //total weight number
	  '12202': {
		type: 'error',
		text: 'total_weight_between_0_999999'
	  }, //total weight between 0 999999
	  '12203': {
		type: 'error',
		text: 'weight_measurement_required'
	  }, //weight measurement required
	  '12204': {
		type: 'error',
		text: 'weight_measurement_max_200'
	  }, //weight measurement max 200
	  '12205': {
		type: 'error',
		text: 'nmfc_max_10'
	  }, //nmfc max 10
	  '12206': {
		type: 'error',
		text: 'product_width_required'
	  }, //product width required
	  '12206': {
		type: 'error',
		text: 'product_width_between_0_999999'
	  }, //product width between 0 999999
	  '12207': {
		type: 'error',
		text: 'product_length_required'
	  }, //product length required
	  '12208': {
		type: 'error',
		text: 'product_length_between_0_999999'
	  }, //product length between 0 999999
	  '12209': {
		type: 'error',
		text: 'product_height_required'
	  }, //product height required
	  '12210': {
		type: 'error',
		text: 'product_height_between_0_999999'
	  }, //product height between 0 999999
	  '12211': {
		type: 'error',
		text: 'product_volume_required'
	  }, //product volume required
	  '12212': {
		type: 'error',
		text: 'product_volume_between_0_999999'
	  }, //product volume between 0 999999
	  '12213': {
		type: 'error',
		text: 'product_measurement_required'
	  }, //product measurement required
	  '12214': {
		type: 'error',
		text: 'product_measurement_number'
	  }, //product measurement number
	  '12215': {
		type: 'error',
		text: 'product_measurement_between_0_127'
	  }, //product measurement between 0 127
	  '12216': {
		type: 'error',
		text: 'measurement_in_required'
	  }, //measurement in required
	  '12217': {
		type: 'error',
		text: 'measurement_in_in_1_2'
	  }, //measurement in in 1 2
	  '12218': {
		type: 'error',
		text: 'freight_class_id_required'
	  }, //freight class id required
	  '12219': {
		type: 'error',
		text: 'freight_class_id_not_exists'
	  }, //freight class id not exists
	  '12220': {
		type: 'error',
		text: 'haz-mat_item_required'
	  }, //haz-mat item required
	  '12221': {
		type: 'error',
		text: 'haz-mat_item_in_0_1'
	  }, //haz-mat item in 0 1
	  '12222': {
		type: 'error',
		text: 'stackable_item_required'
	  }, //stackable item required
	  '12223': {
		type: 'error',
		text: 'stackable_item_in_0_1'
	  }, //stackable item in 0 1
	  '12224': {
		type: 'error',
		text: 'commodity_type_id_required'
	  }, //commodity type id required
	  '12225': {
		type: 'error',
		text: 'commodity_type_id_not_exists'
	  }, //commodity type id not exists
	  '12226': {
		type: 'error',
		text: 'commodity_category_id_required'
	  }, //commodity category id required
	  '12227': {
		type: 'error',
		text: 'commodity_category_id_not_exists'
	  }, //commodity category id not exists
	  '12228': {
		type: 'error',
		text: 'product_status_in_0_1_2'
	  }, //product status in 0 1 2
	  '12229': {
		type: 'error',
		text: 'added_byshipment_product_required'
	  }, //added byshipment product required
	  '12230': {
		type: 'error',
		text: 'added_byshipment_product_not_exists'
	  }, //added byshipment product not exists
	  '12231': {
		type: 'error',
		text: 'added_dateshipment_product_required'
	  }, //added dateshipment product required
	  '12232': {
		type: 'error',
		text: 'added_dateshipment_product_date_format_Y_m_d H_i_s'
	  }, //added dateshipment product date format Y m d H i s
	  '12233': {
		type: 'error',
		text: 'modified_byshipment_product_required'
	  }, //modified byshipment product required
	  '12234': {
		type: 'error',
		text: 'modified_byshipment_product_not_exists'
	  }, //modified byshipment product not exists
	  '12235': {
		type: 'error',
		text: 'modified_dateshipment_product_required'
	  }, //modified dateshipment product required
	  '12236': {
		type: 'error',
		text: 'modified_dateshipment_product_date_format_Y_m_d H_i_s'
	  }, //modified dateshipment product date format Y m d H i s
	  '12237': {
		type: 'info',
		text: 'shipment product is added successfully'
	  }, //shipment product succ
	  '12238': {
		type: 'error',
		text: 'failed to add shipment product'
	  }, //shipment product failed
	  '12239': {
		type: 'info',
		text: 'shipment products are updated successfully'
	  }, //shipment product update succ
	  '12240': {
		type: 'error',
		text: 'Failed to update shipment products'
	  }, //shipment product update fail
	  '12241': {
		type: 'info',
		text: 'Shipment product deleted successfully'
	  }, //shipment product del succ
	  '12242': {
		type: 'error',
		text: 'failed to delete shipment product '
	  }, //shipment product del fail
	  '12243': {
		type: 'error',
		text: 'shipment_product_uuid_required'
	  }, //shipment product uuid required
	  '12244': {
		type: 'error',
		text: 'shipment_product_uuid_not_exists'
	  }, //shipment product uuid not exists

	  '12245': {
		type: 'error',
		text: 'shipment_product_item_weight_required'
	  }, //shipment product item weight required
	  '12246': {
		type: 'error',
		text: 'shipment_product_item_weight_number'
	  }, //shipment product item weight should be number
	  '12247': {
		type: 'error',
		text: 'shipment_product_item_weight_between_0_999999'
	  }, //shipment product item weight must be in between 0 to 999999
	  '12248': {
		type: 'error',
		text: 'shipment_product_un_number_required'
	  }, //shipment product un number is required
	  '12249': {
		type: 'error',
		text: 'shipment_product_un_number_number'
	  }, //shipment product un number should be number
	  '12250': {
		type: 'error',
		text: 'shipment_product_un_number_max_4'
	  }, //shipment product un number max 4 digits
	  '12251': {
		type: 'error',
		text: 'shipment_product_emergency_contact_name_required'
	  }, //shipment product emergency contact name is required
	  '12252': {
		type: 'error',
		text: 'shipment_product_emergency_contact_name_max_200'
	  }, //shipment product emergency contact name max 200
	  '12253': {
		type: 'error',
		text: 'shipment_product_emergency_phone_number_required'
	  }, //shipment product emergency phone number is required
	  '12254': {
		type: 'error',
		text: 'shipment_product_emergency_phone_number_max_15'
	  }, //shipment product emergency phone number max 15
	  '12255': {
		type: 'error',
		text: 'shipment_product_alternate_phone_number_max_15'
	  }, //shipment product alternate phone number max 15
	  '12256': {
		type: 'error',
		text: 'shipment_product_un_number_min_4'
	  }, //shipment product un number min 4 characters
	  '12257': {
		type: 'error',
		text: 'shipment_uuidshipment_product_required'
	  }, ////shipment uuid in shipment product is required

	  //alert message
	  '12260': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_uuid_required'
	  }, //shipment alert shipmentAlertUuid required
	  '12261': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_uuid_max_36'
	  }, //shipment alert shipmentAlertUuid max:36
	  '12262': {
		'type': 'error',
		text: 'shipment_alert_shipment_uuid_required'
	  }, //shipment alert shipmentUuid required
	  '12263': {
		'type': 'error',
		text: 'shipment_alert_shipment_uuid_not_exists'
	  }, //shipment alert shipmentUuid not exists
	  '12264': {
		'type': 'error',
		text: 'shipment_alert_problem_description_id_required'
	  }, //shipment alert problemDescriptionId required
	  '12265': {
		'type': 'error',
		text: 'shipment_alert_problem_description_id_not_exists'
	  }, //shipment alert problemDescriptionId not exists
	  '12266': {
		'type': 'error',
		text: 'shipment_alert_problem_note_required'
	  }, //shipment alert problemNote required
	  '12267': {
		'type': 'error',
		text: 'shipment_alert_problem_note_max_255'
	  }, //shipment alert problemNote max 255
	  '12268': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_status_in_0_1'
	  }, //shipment alert shipmentAlertStatus in:0
	  '12269': {
		'type': 'error',
		text: 'shipment_alert_added_by_required'
	  }, //shipment alert addedBy required
	  '12270': {
		'type': 'error',
		text: 'shipment_alert_added_by_not_exists'
	  }, //shipment alert addedBy not exists
	  '12271': {
		'type': 'error',
		text: 'shipment_alert_added_date_required'
	  }, //shipment alert addedDate required
	  '12272': {
		'type': 'error',
		text: 'shipment_alert_added_date_date_format_Y_m_d_H_i_s'
	  }, //shipment alert addedDate date format:Y-m-d H:i:s
	  '12273': {
		'type': 'error',
		text: 'shipment_alert_modified_by_required'
	  }, //shipment alert modifiedBy required
	  '12274': {
		'type': 'error',
		text: 'shipment_alert_modified_by_not_exists'
	  }, //shipment alert modifiedBy not exists
	  '12275': {
		'type': 'error',
		text: 'shipment_alert_modified_date_required'
	  }, //shipment alert modifiedDate required
	  '12276': {
		'type': 'error',
		text: 'shipment_alert_modified_date_date_format_Y_m_d_H_i_s'
	  }, //shipment alert modifiedDate date format:Y-m-d H:i:s
	  '12277': {
		'type': 'info',
		text: 'Shipment alert added successfully'
	  }, //shipment alert successful
	  '12278': {
		'type': 'error',
		text: 'Failed to add shipment alert'
	  }, //shipment alert failed
	  '12279': {
		'type': 'info',
		text: 'Shipment alert resolved successfully'
	  }, //shipment alert update successful
	  '12280': {
		'type': 'error',
		text: 'Failed to resolve shipment alert'
	  }, //shipment alert update failed
	  '12281': {
		'type': 'info',
		text: 'shipment_alert_patch_succ'
	  }, //shipment alert patch successful
	  '12282': {
		'type': 'error',
		text: 'shipment_alert_patch_fail'
	  }, //shipment alert patch failed
	  '12283': {
		'type': 'error',
		text: 'shipment_alert_solution_description_id_required'
	  }, //shipment alert solutionDescriptionId required
	  '12284': {
		'type': 'error',
		text: 'shipment_alert_solution_description_id_not_exists'
	  }, //shipment alert solutionDescriptionId not exists
	  '12285': {
		'type': 'error',
		text: 'shipment_alert_solution_note_required'
	  }, //shipment alert solutionNote required
	  '12286': {
		'type': 'error',
		text: 'shipment_alert_solution_note_max_255'
	  }, //shipment alert solutionNote max 255
	  '12287': {
		'type': 'error',
		text: 'shipment_alert_already_exists'
	  }, //shipment alert already exists
	  '12288': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_uuid_not_exists'
	  }, // shipment alert uuid not exists
	  '12289': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_problem_required'
	  }, // shipment alert problem is required
	  '12290': {
		'type': 'error',
		text: 'shipment_alert_shipment_alert_problem_not_exists'
	  }, // shipment alert problem is not exists
	  '12291': {
		'type': 'info',
		text: 'shipment_alert_problem_add_succ'
	  }, // shipment alert problem add successful
	  '12292': {
		'type': 'error',
		text: 'shipment_alert_problem_add_failed'
	  }, // shipment alert problem add failed
	  '12410': { 'type': 'error', text: 'shipment_alert_agent_user_uuid_max_36' }, //shipment alert agent user uuid max 36
	  '12411': { type: 'error', text: 'shipment_alert_agent_user_uuid_not_exists' }, //shipment alert agent user uuid not exists
	  //alert message
	  /* Shipment Deactivate Codes Starts */
	  '12293': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_required'
	  }, //shipment deactivate shipmentAlertUuid required
	  '12294': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_max_36'
	  }, //shipment deactivate shipmentAlertUuid max:36
	  '12295': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_not_exists'
	  }, //shipment deactivate shipmentUuid required
	  '12296': {
		type: 'error',
		text: 'shipment_deactivate_modified_by_required'
	  }, //shipment deactivate modifiedBy required
	  '12297': {
		type: 'error',
		text: 'shipment_deactivate_modified_by_not_exists'
	  }, //shipment deactivate modifiedBy not exists
	  '12298': {
		type: 'error',
		text: 'shipment_deactivate_modified_date_required'
	  }, //shipment deactivate modifiedDate required
	  '12299': {
		type: 'error',
		text: 'shipment_deactivate_modified_date_date_format_Y_m_d_H_i_s'
	  }, //shipment deactivate modifiedDate date format:Y-m-d H:i:s
	  '12300': {
		type: 'info',
		text: 'shipment is deactivated successfully'
	  }, //shipment deactivate successful
	  '12301': {
		type: 'error',
		text: 'failed to deactivate shipment'
	  }, //shipment deactivate failed
	  '12302': {
		type: 'info',
		text: 'shipment is activated successfully'
	  }, //shipment activate successful
	  '12303': {
		type: 'error',
		text: 'failed to activate shipment'
	  }, //shipment activate failed
	  '12304': {
		type: 'error',
		text: 'shipment_already_deactive'
	  }, //shipment already deactive

	  '12305': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_id_required'
	  }, //shipment cancel deactivate reason id required
	  '12306': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_id_not_exists'
	  }, //shipment cancel deactivate reason id not exists
	  '12307': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_required'
	  }, //shipment cancel deactivate reason required
	  '12308': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_max_150'
	  }, //shipment cancel deactivate reason max 150

	  '12309': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_required'
	  }, //shipment cancel deactivate uuid required
	  '12310': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_max_36'
	  }, //shipment cancel deactivate uuid max 36
	  '12311': {
		type: 'error',
		text: 'shipment_cancel_deactivate_shipment_status_id_required'
	  }, //shipment cancel deactivate shipment status id required
	  '12312': {
		type: 'error',
		text: 'shipment_cancel_deactivate_shipment_status_id_not_exists'
	  }, //shipment cancel deactivate shipment status id not exists
	  '12313': {
		type: 'error',
		text: 'shipment_cancel_deactivate_is_cancel_deactivate_required'
	  }, //shipment cancel deactivate is cancel deactivate required
	  '12314': {
		type: 'error',
		text: 'shipment_cancel_deactivate_is_cancel_deactivate_not_in_data'
	  }, //shipment cancel deactivate is cancel deactivate not in data
	  '12315': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_by_required'
	  }, //shipment cancel deactivate added by required
	  '12316': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_bynot_exists'
	  }, //shipment cancel deactivate added by not exists
	  '12317': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_date_required'
	  }, //shipment cancel deactivate added date required
	  '12318': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_date_format_Y_m_d_H_i_s'
	  }, //shipment cancel deactivate added date not format:Y-m-d H:i:s
	  '12319': {
		type: 'error',
		text: 'shipment_cancel_deactivate_previous_status_id_required'
	  }, //shipment cancel deactivate status id required
	  '12320': {
		type: 'error',
		text: 'shipment_cancel_deactivate_previous_status_id_not_exists'
	  }, //shipment cancel deactivate status id not exists
	  '12321': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_not_exists'
	  }, // shiment cancel deactivate uuid not exists
	  '12322': {
		type: 'info',
		text: 'shipment_cancel_succ'
	  }, // shiment cancel successful
	  '12323': {
		type: 'error',
		text: 'shipment_cancel_failed'
	  }, // shiment cancel falied
	  '12324': {
		type: 'info',
		text: 'Shipment is activated successfully'
	  }, // shiment change  status successful
	  '12325': {
		type: 'error',
		text: 'Failed to activate shipment'
	  }, // shiment change  status falied
	  12326: {
		type: 'error',
		text: 'This shipment cannot be deactivated'
	  },
	  '12327': {
		type: 'info',
		text: 'Shipment transaction added successfully'
	  }, //shipment transaction successful
	  '12328': {
		type: 'error',
		text: 'Failed to add shipment transaction'
	  }, //shipment transaction failed
	  '12329': {
		type: 'info',
		text: 'shipment transaction is reassigned successfully'
	  }, //shipment transaction patch successful
	  '12330': {
		type: 'error',
		text: 'Failed to reassign transaction'
	  }, //shipment transaction patch failed

	  '12331': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_required'
	  }, //shipment transaction uuid required
	  '12332': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_max_36'
	  }, //shipment transaction max 36
	  '12333': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_not_exists'
	  }, //shipment transaction uuid not exists
	  '12334': {
		type: 'error',
		text: 'shipment_transaction_agent_user_uuid_max_36'
	  }, //shipment transaction agent user uuid max 36
	  '12335': {
		type: 'error',
		text: 'shipment_transaction_agent_user_uuid_not_exists'
	  }, //shipment transaction agent user uuid not exists
	  '12336': {
		type: 'error',
		text: 'shipment_alert_shipment_alert_status_in_0_1_2'
	  }, //shipment transaction failed
	  '12337': {
		type: 'error',
		text: 'shipment_alert_modified_by_required'
	  }, //shipment transaction patch successful
	  '12338': {
		type: 'error',
		text: 'shipment_alert_modified_by_not_exists'
	  }, //shipment transaction patch failed
	  '12339': {
		type: 'error',
		text: 'shipment_alert_modified_date_required'
	  }, //shipment transaction patch successful
	  '12340': {
		type: 'error',
		text: 'shipment_alert_modified_date_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction patch failed
	  '12343': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_required'
	  }, //shipment transaction shipmentUuid required
	  '12344': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_not_exists'
	  }, //shipment transaction shipmentUuid not exists
	  '12345': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_required'
	  }, //shipment transaction shipmentShortId required
	  '12346': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_not_exists'
	  }, //shipment transaction shipmentShortId not exists
	  '12347': {
		type: 'error',
		text: 'shipment_transaction_transactionTypeId_required'
	  }, //shipment transaction transactionTypeId required
	  '12348': {
		type: 'error',
		text: 'shipment_transaction_transactionTypeId_not_exists'
	  }, //shipment transaction transactionTypeId not exists
	  '12349': {
		type: 'error',
		text: 'shipment_transaction_transactionSubTypeId_required'
	  }, //shipment transaction transactionSubTypeId required
	  '12350': {
		type: 'error',
		text: 'shipment_transaction_transactionSubTypeId_not_exists'
	  }, //shipment transaction transactionSubTypeId not exists
	  '12351': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_required'
	  }, //shipment transaction shipmentCharge required
	  '12352': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_numeric'
	  }, //shipment transaction shipmentCharge numeric
	  '12353': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_max_10'
	  }, //shipment transaction shipmentCharge max 10
	  '12354': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_required'
	  }, //shipment transaction shipmentCost required
	  '12355': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_numeric'
	  }, //shipment transaction shipmentCost numeric
	  '12356': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_max_10'
	  }, //shipment transaction shipmentCost max 10
	  '12357': {
		type: 'error',
		text: 'shipment_transaction_shipmentDescription_required'
	  }, //shipment transaction shipmentDescription required
	  '12358': {
		type: 'error',
		text: 'shipment_transaction_shipmentDescription_max_40'
	  }, //shipment transaction shipmentDescription max 40
	  '12359': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionStatus_in_0_1_2'
	  }, //shipment transaction shipmentAlertStatus in 0
	  '12360': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionDate_required'
	  }, //shipment transaction shipmentTransactionDate required
	  '12361': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction shipmentTransactionDate date format:Y-m-d H:i:s
	  '12362': {
		type: 'error',
		text: 'shipment_transaction_agentUserUuid_required'
	  }, //shipment transaction agentUserUuid required
	  '12363': {
		type: 'error',
		text: 'shipment_transaction_agentUserUuid_not_exists'
	  }, //shipment transaction agentUserUuid not exists
	  '12364': {
		type: 'error',
		text: 'shipment_transaction_coupon_required'
	  }, //shipment transaction coupon required
	  '12365': {
		type: 'error',
		text: 'shipment_transaction_coupon_max_20'
	  }, //shipment transaction coupon max 20
	  '12366': {
		type: 'error',
		text: 'shipment_transaction_addedBy_required'
	  }, //shipment transaction addedBy required
	  '12367': {
		type: 'error',
		text: 'shipment_transaction_addedBy_not_exists'
	  }, //shipment transaction addedBy not exists
	  '12368': {
		type: 'error',
		text: 'shipment_transaction_addedDate_required'
	  }, //shipment transaction addedDate required
	  '12369': {
		type: 'error',
		text: 'shipment_transaction_addedDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction addedDate date format:Y-m-d H:i:s
	  '12370': {
		type: 'error',
		text: 'shipment_transaction_modifiedBy_required'
	  }, //shipment transaction modifiedBy required
	  '12371': {
		type: 'error',
		text: 'shipment_transaction_modifiedBy_not_exists'
	  }, //shipment transaction modifiedBy not exists
	  '12372': {
		type: 'error',
		text: 'shipment_transaction_modifiedDate_required'
	  }, //shipment transaction modifiedDate required
	  '12373': {
		type: 'error',
		text: 'shipment_transaction_modifiedDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction modifiedDate date format:Y-m-d H:i:s
	  '12336': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_max_36'
	  }, //shipment transaction shipment uuid max 36
	  '12337': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_max_24'
	  }, //shipment transaction shipment short id max 24
	  '12374': {
		type: 'error',
		text: 'shipment_transaction_shipmentCode_max_20'
	  }, //shipment transaction shipmentCode max 20
	  '12375': {
		type: 'error',
		text: 'shipment_transaction_shipmentRate_numeric'
	  }, //shipment transaction shipment rate numeric
	  '12376': {
		type: 'error',
		text: 'shipment_transaction_shipmentRate_between_0_99999999'
	  }, //shipment transaction shipmentRate between 0 to 99999999
	  '12377': {
		type: 'error',
		text: 'shipment_transaction_shipmentFreightClass_numeric'
	  }, //shipment transaction shipmentFreightClass numeric
	  '12378': {
		type: 'error',
		text: 'shipment_transaction_shipmentFreightClass_between_0_99999999'
	  }, //shipment transaction shipmentFreightClass between 0 to 99999999
	  '12379': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeight_numeric'
	  }, //shipment transaction shipmentWeight numeric
	  '12380': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeight_between_0_99999999'
	  }, //shipment transaction shipmentWeight between 0 to 99999999
	  '12381': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeightUnit_numeric'
	  }, //shipment transaction shipmentWeightUnit numeric
	  '12382': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeightUnit_between_0_99999999'
	  }, //shipment transaction shipmentWeightUnit between 0 to 99999999
	  '12383': {
		type: 'error',
		text: 'shipment_transaction_insuranceDeclaredValue_numeric'
	  }, //shipment transaction insuranceDeclaredValue numeric
	  '12384': {
		type: 'error',
		text: 'shipment_transaction_insuranceDeclaredValue_between_0_99999999'
	  }, //shipment transaction insuranceDeclaredValue between 0 to 99999999
	  '12384': {
		type: 'error',
		text: 'shipment_transaction_shipmentDiscountCoupon_max_20'
	  }, //shipment transaction shipmentDiscountCoupon max 20
	  '12385': {
		type: 'error',
		text: 'shipment_transaction_insuranceCertificateNumber_max_100'
	  }, //shipment transaction insuranceCertificateNumber max 100
	  /* Shipment Deactivate Codes Ends */
	  //repeat shipment
	  '12386': {
		type: 'info',
		text: 'Shipment repeated successfully'
	  }, //repeat shipment successful
	  '12387': {
		type: 'error',
		text: 'repeat_shipment_elastic_fail'
	  }, //repeat shipment elastic successful
	  '12388': {
		type: 'info',
		text: 'repeat_shipment_elastic_success'
	  }, //repeat shipment elastic failed
	  '12389': {
		type: 'error',
		text: 'repeat_shipment_shipment_uuid_required'
	  }, //repeat shipment shipment uuid required
	  '12390': {
		type: 'error',
		text: 'repeat_shipment_shipment_uuid_max_36'
	  }, //repeat shipment shipment uuid max 36
	  '12391': {
		type: 'error',
		text: 'repeat_shipment_shipment_uuid_exists'
	  }, //repeat shipment shipment uuid not exists
	  '12392': {
		type: 'error',
		text: 'repeat_shipment_date_added_required'
	  }, //repeat shipment added date required
	  '12393': {
		type: 'error',
		text: 'repeat_shipment_date_added_format_Y_m_d_H_i_s'
	  }, //repeat shipment added data not in Y-m-d H:i:s format
	  '12394': {
		type: 'error',
		text: 'repeat_shipment_added_by_required'
	  }, //repeat shipment added by required
	  '12395': {
		type: 'error',
		text: 'repeat_shipment_added_by_not_exists'
	  }, //repeat shipment added by not exists
	  '12396': {
		type: 'error',
		text: 'repeat_shipment_shipment_short_id_required'
	  }, //repeat shipment shipment short id required
	  '12397': {
		type: 'error',
		text: 'repeat_shipment_shipment_short_id_max_24'
	  }, //repeat shipment shipment short id max 24
	  '12398': {
		type: 'error',
		text: 'repeat_shipment_shipment_short_id_unique'
	  }, //repeat shipment shipment short id unique

	  //repeat shipment
	  /* Shipment Deactivate Codes Starts */
	  '12293': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_required'
	  }, //shipment deactivate shipmentAlertUuid required
	  '12294': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_max_36'
	  }, //shipment deactivate shipmentAlertUuid max:36
	  '12295': {
		type: 'error',
		text: 'shipment_deactivate_shipment_uuid_not_exists'
	  }, //shipment deactivate shipmentUuid required
	  '12296': {
		type: 'error',
		text: 'shipment_deactivate_modified_by_required'
	  }, //shipment deactivate modifiedBy required
	  '12297': {
		type: 'error',
		text: 'shipment_deactivate_modified_by_not_exists'
	  }, //shipment deactivate modifiedBy not exists
	  '12298': {
		type: 'error',
		text: 'shipment_deactivate_modified_date_required'
	  }, //shipment deactivate modifiedDate required
	  '12299': {
		type: 'error',
		text: 'shipment_deactivate_modified_date_date_format_Y_m_d_H_i_s'
	  }, //shipment deactivate modifiedDate date format:Y-m-d H:i:s
	  '12300': {
		type: 'info',
		text: 'shipment is deactivated successfully'
	  }, //shipment deactivate successful
	  '12301': {
		type: 'error',
		text: 'failed to deactivate shipment'
	  }, //shipment deactivate failed
	  '12302': {
		type: 'info',
		text: 'shipment is activated successfully'
	  }, //shipment activate successful
	  '12303': {
		type: 'error',
		text: 'failed to activate shipment'
	  }, //shipment activate failed
	  '12304': {
		type: 'error',
		text: 'shipment_already_deactive'
	  }, //shipment already deactive

	  '12305': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_id_required'
	  }, //shipment cancel deactivate reason id required
	  '12306': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_id_not_exists'
	  }, //shipment cancel deactivate reason id not exists
	  '12307': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_required'
	  }, //shipment cancel deactivate reason required
	  '12308': {
		type: 'error',
		text: 'shipment_cancel_deactivate_reason_max_150'
	  }, //shipment cancel deactivate reason max 150

	  '12309': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_required'
	  }, //shipment cancel deactivate uuid required
	  '12310': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_max_36'
	  }, //shipment cancel deactivate uuid max 36
	  '12311': {
		type: 'error',
		text: 'shipment_cancel_deactivate_shipment_status_id_required'
	  }, //shipment cancel deactivate shipment status id required
	  '12312': {
		type: 'error',
		text: 'shipment_cancel_deactivate_shipment_status_id_not_exists'
	  }, //shipment cancel deactivate shipment status id not exists
	  '12313': {
		type: 'error',
		text: 'shipment_cancel_deactivate_is_cancel_deactivate_required'
	  }, //shipment cancel deactivate is cancel deactivate required
	  '12314': {
		type: 'error',
		text: 'shipment_cancel_deactivate_is_cancel_deactivate_not_in_data'
	  }, //shipment cancel deactivate is cancel deactivate not in data
	  '12315': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_by_required'
	  }, //shipment cancel deactivate added by required
	  '12316': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_bynot_exists'
	  }, //shipment cancel deactivate added by not exists
	  '12317': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_date_required'
	  }, //shipment cancel deactivate added date required
	  '12318': {
		type: 'error',
		text: 'shipment_cancel_deactivate_added_date_format_Y_m_d_H_i_s'
	  }, //shipment cancel deactivate added date not format:Y-m-d H:i:s
	  '12319': {
		type: 'error',
		text: 'shipment_cancel_deactivate_previous_status_id_required'
	  }, //shipment cancel deactivate status id required
	  '12320': {
		type: 'error',
		text: 'shipment_cancel_deactivate_previous_status_id_not_exists'
	  }, //shipment cancel deactivate status id not exists
	  '12321': {
		type: 'error',
		text: 'shipment_cancel_deactivate_uuid_not_exists'
	  }, // shiment cancel deactivate uuid not exists
	  '12322': {
		type: 'info',
		text: 'shipment is cancelled successfully'
	  }, // shiment cancel successful
	  '12323': {
		type: 'error',
		text: 'shipment_cancel_failed'
	  }, // shiment cancel falied
	  '12324': {
		type: 'info',
		text: 'Shipment is activated successfully'
	  }, // shiment change  status successful
	  '12325': {
		type: 'error',
		text: 'Failed to activate shipment'
	  }, // shiment change  status falied
	  '12326': {
		type: 'error',
		text: 'This shipment cannot be deactivated'
	  },
	  12506: {
		type: 'error',
		text: 'This shipment can not be cancelled'
	  },
	  '12327': {
		type: 'info',
		text: 'Shipment transaction added successfully'
	  }, //shipment transaction successful
	  '12328': {
		type: 'error',
		text: 'Failed to add shipment transaction'
	  }, //shipment transaction failed
	  '12329': {
		type: 'info',
		text: 'shipment transaction is reassigned successfully'
	  }, //shipment transaction patch successful
	  '12330': {
		type: 'error',
		text: 'Failed to reassign transaction'
	  }, //shipment transaction patch failed

	  '12331': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_required'
	  }, //shipment transaction uuid required
	  '12332': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_max_36'
	  }, //shipment transaction max 36
	  '12333': {
		type: 'error',
		text: 'shipment_transaction_shipment_transaction_uuid_not_exists'
	  }, //shipment transaction uuid not exists
	  '12334': {
		type: 'error',
		text: 'shipment_transaction_agent_user_uuid_max_36'
	  }, //shipment transaction agent user uuid max 36
	  '12335': {
		type: 'error',
		text: 'shipment_transaction_agent_user_uuid_not_exists'
	  }, //shipment transaction agent user uuid not exists
	  '12336': {
		type: 'error',
		text: 'shipment_alert_shipment_alert_status_in_0_1_2'
	  }, //shipment transaction failed
	  '12337': {
		type: 'error',
		text: 'shipment_alert_modified_by_required'
	  }, //shipment transaction patch successful
	  '12338': {
		type: 'error',
		text: 'shipment_alert_modified_by_not_exists'
	  }, //shipment transaction patch failed
	  '12339': {
		type: 'error',
		text: 'shipment_alert_modified_date_required'
	  }, //shipment transaction patch successful
	  '12340': {
		type: 'error',
		text: 'shipment_alert_modified_date_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction patch failed
	  '12343': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_required'
	  }, //shipment transaction shipmentUuid required
	  '12344': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_not_exists'
	  }, //shipment transaction shipmentUuid not exists
	  '12345': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_required'
	  }, //shipment transaction shipmentShortId required
	  '12346': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_not_exists'
	  }, //shipment transaction shipmentShortId not exists
	  '12347': {
		type: 'error',
		text: 'shipment_transaction_transactionTypeId_required'
	  }, //shipment transaction transactionTypeId required
	  '12348': {
		type: 'error',
		text: 'shipment_transaction_transactionTypeId_not_exists'
	  }, //shipment transaction transactionTypeId not exists
	  '12349': {
		type: 'error',
		text: 'shipment_transaction_transactionSubTypeId_required'
	  }, //shipment transaction transactionSubTypeId required
	  '12350': {
		type: 'error',
		text: 'shipment_transaction_transactionSubTypeId_not_exists'
	  }, //shipment transaction transactionSubTypeId not exists
	  '12351': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_required'
	  }, //shipment transaction shipmentCharge required
	  '12352': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_numeric'
	  }, //shipment transaction shipmentCharge numeric
	  '12353': {
		type: 'error',
		text: 'shipment_transaction_shipmentCharge_max_10'
	  }, //shipment transaction shipmentCharge max 10
	  '12354': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_required'
	  }, //shipment transaction shipmentCost required
	  '12355': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_numeric'
	  }, //shipment transaction shipmentCost numeric
	  '12356': {
		type: 'error',
		text: 'shipment_transaction_shipmentCost_max_10'
	  }, //shipment transaction shipmentCost max 10
	  '12357': {
		type: 'error',
		text: 'shipment_transaction_shipmentDescription_required'
	  }, //shipment transaction shipmentDescription required
	  '12358': {
		type: 'error',
		text: 'shipment_transaction_shipmentDescription_max_40'
	  }, //shipment transaction shipmentDescription max 40
	  '12359': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionStatus_in_0_1_2'
	  }, //shipment transaction shipmentAlertStatus in 0
	  '12360': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionDate_required'
	  }, //shipment transaction shipmentTransactionDate required
	  '12361': {
		type: 'error',
		text: 'shipment_transaction_shipmentTransactionDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction shipmentTransactionDate date format:Y-m-d H:i:s
	  '12362': {
		type: 'error',
		text: 'shipment_transaction_agentUserUuid_required'
	  }, //shipment transaction agentUserUuid required
	  '12363': {
		type: 'error',
		text: 'shipment_transaction_agentUserUuid_not_exists'
	  }, //shipment transaction agentUserUuid not exists
	  '12364': {
		type: 'error',
		text: 'shipment_transaction_coupon_required'
	  }, //shipment transaction coupon required
	  '12365': {
		type: 'error',
		text: 'shipment_transaction_coupon_max_20'
	  }, //shipment transaction coupon max 20
	  '12366': {
		type: 'error',
		text: 'shipment_transaction_addedBy_required'
	  }, //shipment transaction addedBy required
	  '12367': {
		type: 'error',
		text: 'shipment_transaction_addedBy_not_exists'
	  }, //shipment transaction addedBy not exists
	  '12368': {
		type: 'error',
		text: 'shipment_transaction_addedDate_required'
	  }, //shipment transaction addedDate required
	  '12369': {
		type: 'error',
		text: 'shipment_transaction_addedDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction addedDate date format:Y-m-d H:i:s
	  '12370': {
		type: 'error',
		text: 'shipment_transaction_modifiedBy_required'
	  }, //shipment transaction modifiedBy required
	  '12371': {
		type: 'error',
		text: 'shipment_transaction_modifiedBy_not_exists'
	  }, //shipment transaction modifiedBy not exists
	  '12372': {
		type: 'error',
		text: 'shipment_transaction_modifiedDate_required'
	  }, //shipment transaction modifiedDate required
	  '12373': {
		type: 'error',
		text: 'shipment_transaction_modifiedDate_date_format_Y_m_d_H_i_s'
	  }, //shipment transaction modifiedDate date format:Y-m-d H:i:s
	  '12336': {
		type: 'error',
		text: 'shipment_transaction_shipmentUuid_max_36'
	  }, //shipment transaction shipment uuid max 36
	  '12337': {
		type: 'error',
		text: 'shipment_transaction_shipmentShortId_max_24'
	  }, //shipment transaction shipment short id max 24
	  '12374': {
		type: 'error',
		text: 'shipment_transaction_shipmentCode_max_20'
	  }, //shipment transaction shipmentCode max 20
	  '12375': {
		type: 'error',
		text: 'shipment_transaction_shipmentRate_numeric'
	  }, //shipment transaction shipment rate numeric
	  '12376': {
		type: 'error',
		text: 'shipment_transaction_shipmentRate_between_0_99999999'
	  }, //shipment transaction shipmentRate between 0 to 99999999
	  '12377': {
		type: 'error',
		text: 'shipment_transaction_shipmentFreightClass_numeric'
	  }, //shipment transaction shipmentFreightClass numeric
	  '12378': {
		type: 'error',
		text: 'shipment_transaction_shipmentFreightClass_between_0_99999999'
	  }, //shipment transaction shipmentFreightClass between 0 to 99999999
	  '12379': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeight_numeric'
	  }, //shipment transaction shipmentWeight numeric
	  '12380': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeight_between_0_99999999'
	  }, //shipment transaction shipmentWeight between 0 to 99999999
	  '12381': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeightUnit_numeric'
	  }, //shipment transaction shipmentWeightUnit numeric
	  '12382': {
		type: 'error',
		text: 'shipment_transaction_shipmentWeightUnit_between_0_99999999'
	  }, //shipment transaction shipmentWeightUnit between 0 to 99999999
	  '12383': {
		type: 'error',
		text: 'shipment_transaction_insuranceDeclaredValue_numeric'
	  }, //shipment transaction insuranceDeclaredValue numeric
	  '12384': {
		type: 'error',
		text: 'shipment_transaction_insuranceDeclaredValue_between_0_99999999'
	  }, //shipment transaction insuranceDeclaredValue between 0 to 99999999
	  '12384': {
		type: 'error',
		text: 'shipment_transaction_shipmentDiscountCoupon_max_20'
	  }, //shipment transaction shipmentDiscountCoupon max 20
	  '12385': {
		type: 'error',
		text: 'shipment_transaction_insuranceCertificateNumber_max_100'
	  }, //shipment transaction insuranceCertificateNumber max 100
	  12408: {
		type: 'info',
		text: 'shipment insurance cancelled successfully'
	  },
	  12409: {
		type: 'error',
		text: 'shipment insurance cancellation failed'
	  },

	  /* Shipment Deactivate Codes Ends */
	  '12402': { type: 'error', text: 'shipment_transaction_shipmentCharge_between_-100000000_100000000' }, //shipment transaction charge between -100000000 to 100000000
	  '12403': { type: 'error', text: 'shipment_transaction_shipmentCost_between_-100000000_100000000' }, //shipment transaction cost between -100000000 to 100000000
	  '12404': { type: 'error', text: 'shipment_transaction_shipmentPayment_required' }, //shipment transaction shipment payment required
	  '12405': { type: 'error', text: 'shipment_transaction_shipmentPayment_numeric' }, //shipment transaction shipment payment numeric
	  '12406': { type: 'error', text: 'shipment_transaction_shipmentPayment_between_-100000000_100000000' }, //shipment transaction shipment payment between -100000000 to 100000000
	  12407: { type: 'error', text: '3rd party insurance is already added with this shipment' },

	  '3132': { type: 'error', text: 'broker_importer_name_required' }, //broker_importer_name_required
	  '3133': { type: 'error', text: 'broker_importer_phone_required' }, //broker_importer_phone_required
	  '3134': { type: 'error', text: 'broker_importer_email_id_required ' }, //broker_importer_email_id_required
	  '3135': { type: 'error', text: 'customer_broker_name_required' }, //customer_broker_name_required
	  '3136': { type: 'error', text: 'customer_broker_phone_required' }, //customer_broker_phone_required
	  '3137': { type: 'error', text: 'customer_broker_email_required' }, //ccustomer_broker_email_required
	  '3138': { type: 'error', text: 'broker_importer_id_required' }, //ccustomer_broker_email_required
	  '3139': { type: 'error', text: 'customer_broker_type_required' }, //ccustomer_broker_email_required
	  '3140': { type: 'error', text: 'broker_importer_email_id_required' }, //broker importer email id required
	  // process payment messages
	  '15040': { type: 'error', text: 'no_shipment_in_status_2' }, //No shipment in Processing reveiw
	  '15041': { type: 'error', text: 'no_shipment_payment_found' },//no payment found for this shipment
	  '15042': { type: 'error', text: 'no_shipment_in_status_2_found' }, //No shipment in Processing reveiw
	  '15043': { type: 'error', text: 'no_transaction_found' }, //No tranasction found
	  '15044': { type: 'error', text: 'nothing_to_pay_left' },//No amount found for pay
	  '15045': { type: 'error', text: 'partial_payement_not_allowed' },//pariaal payment not allowe
	  '15046': { type: 'error', text: 'not_enough_ar_payement_available' },//ar amount is 0
	  '15047': { type: 'error', text: 'Unable to update payment info' },//unable to update payment info..
	  '15048': { type: 'info', text: 'Payment received successfully' },
	  '15049': { type: 'error', text: 'shipment_status_changed_failed' },
	  // process payment messages
	  //submit to process messages
	  12500: { type: 'error', text: 'shipment Product does not exists' },
	  12501: { type: 'error', text: 'shipment Payment method does not exists' },
	  12502: { type: 'error', text: 'AR Customer does not have enough credit' },
	  12503: { type: 'error', text: 'Customer have amount to be paid' },
	  12504: { type: 'info', text: 'Submit to process successful' },
	  12505: { type: 'error', text: 'Submit to process failed' },

	  //shipment documents
	  '03000': { type: 'error', text: 'shipment_document_uuid_required' },	 //shipment document uuid required
	  '03001': { type: 'error', text: 'shipment_document_uuid_max_36' },	 //shipment document uuid max 36
	  '03002': { type: 'error', text: 'shipment_document_uuid_unique' },	 //shipment document uuid unique
	  '03003': { type: 'error', text: 'shipment_uuidshipment_document_required' },	 //shipment uuidshipment document required
	  '03004': { type: 'error', text: 'shipment_uuidshipment_document_not_exists' },	 //shipment uuidshipment document not exists
	  '03005': { type: 'error', text: 'document_name_required' },	 //document name required
	  '03006': { type: 'error', text: 'document_name_max_255' },	 //document name max 255
	  '03007': { type: 'error', text: 'document_category_id_required' },	 //document category id required
	  '03008': { type: 'error', text: 'document_category_id_in_1_2' },	 //document category id in 1 2
	  '03009': { type: 'error', text: 'document_type_id_required' },	 //document type id required
	  '03010': { type: 'error', text: 'document_type_id_not_exists' },	 //document type id not exists
	  '03011': { type: 'error', text: 'document_page_numeric' },	 //document page numeric
	  '03012': { type: 'error', text: 'document_page_between_0_32767' },	 //document page between 0 32767
	  '03013': { type: 'error', text: 'document_file_name_required' },	 //document file name required
	  '03014': { type: 'error', text: 'document_file_name_max_255' },	 //document file name max 255
	  '03015': { type: 'error', text: 'document_uploaded_file_name_max_255' },	 //document uploaded file name max 255
	  '03016': { type: 'error', text: 'shipment_document_status_in_0_1_2' },	 //shipment document status in 0 1 2
	  '03017': { type: 'error', text: 'added_byshipment_document_required' },	 //added byshipment document required
	  '03018': { type: 'error', text: 'added_byshipment_document_not_exists' },	 //added byshipment document not exists
	  '03019': { type: 'error', text: 'added_dateshipment_document_required' },	 //added dateshipment document required
	  '03020': { type: 'error', text: 'added_dateshipment_document_date_format_Y_m_d_H_i_s' },	 //added dateshipment document date format Y m d H i s
	  '03021': { type: 'error', text: 'modified_byshipment_document_required' },	 //modified byshipment document required
	  '03022': { type: 'error', text: 'modified_byshipment_document_not_exists' },	 //modified byshipment document not exists
	  '03023': { type: 'error', text: 'modified_dateshipment_document_required' },	 //modified dateshipment document required
	  '03024': { type: 'error', text: 'modified_dateshipment_document_date_format_Y_m_d_H_i_s' },	 //modified dateshipment document date format Y m d H i s
	  '03025': { type: 'info', text: 'shipment document added successfully' },	 //shipment document succ
	  '03026': { type: 'error', text: 'shipment_document_failed' },	 //shipment document failed
	  '03027': { type: 'info', text: 'shipment_document_patch_succ' },	 //shipment document patch succ
	  '03028': { type: 'error', text: 'shipment_document_patch_fail' },	 //shipment document patch fail
	  '03029': { type: 'info', text: 'shipment_document_update_succ' },	 //shipment document update succ
	  '03030': { type: 'error', text: 'shipment_document_update_fail' },	 //shipment document update fail
	  '03031': { type: 'info', text: 'shipment_document_del_succ' },	 //shipment document del succ
	  '03032': { type: 'error', text: 'shipment_document_del_fail' },	 //shipment document del fail
	  '03033': { type: 'error', text: 'shipment_document_uuid_required' },	 //shipment document uuid required
	  '03034': { type: 'error', text: 'shipment_document_uuid_not_exists' },	 //shipment document uuid not exists
	  '03035': { type: 'error', text: 'unable_to_upload_document_on_s3' },	 //shipment document uuid not exists
	  '03036': { type: 'error', text: 'shipment_document_contents_max_size_10_mb' },	 //shipment document uuid not exists
	  '03037': { type: 'error', text: 'shipment_document_contents_required' },	 //shipment document uuid not exists
	  '03037': { type: 'error', 'text': 'shipment_document_contents_required' },	 //shipment document uuid not exists
	  '03038': { type: 'error', 'text': 'no_mime_found' },	 //no mime foound
	  '03039': { type: 'error', 'text': 'not_valid_mimie' },	 //no mime foound
	  '03040': { type: 'error', 'text': 'shipment_file_not_found' },	 //no mime foound
	  '03041': { type: 'error', 'text': 'shipment_document_uuid_array' },	 //shipment_document_uuid_array should array
	  '03042': { type: 'error', 'text': 'shipment_email_required' },	 //shipment_email_required
	  '03043': { type: 'error', 'text': 'shipment_email_should_email' },	 //shipment_email_required
	  '03044': { type: 'error', 'text': 'shipment_email_should_array' },	 //shipment_email_should_array
	  '03045': { type: 'info', 'text': 'Document sent to email successfully' },	 //shipment_document_email_succ
	  '03046': { type: 'error', 'text': 'shipment_document_email_fail' },	 //shipment_document_email_fail
	  '20095': { type: 'info', 'text': 'Documents fetched successfully' },
	  20093: { type: 'info', 'text': 'Shipment is booked successfully' },
	  //custom broker
	  '3100': { type: 'error', text: 'customs_broker_uuid_required' },	 //customs broker uuid required
	  '3101': { type: 'error', text: 'customs_broker_uuid_not_exists' },	 //customs broker uuid not exists
	  '3102': { type: 'error', text: 'shipment_uuidshipment_custom_broker_required' },	 //shipment uuidshipment custom broker required
	  '3103': { type: 'error', text: 'shipment_uuidshipment_custom_broker_not_exists' },	 //shipment uuidshipment custom broker not exists
	  '3104': { type: 'error', text: 'broker_importer_id_not_exists' },	 //broker importer id not exists
	  '3105': { type: 'error', text: 'broker_importer_name_max_200' },	 //broker importer name max 200
	  '3106': { type: 'error', text: 'broker_importer_phone_max_15' },	 //broker importer phone max 15
	  '3107': { type: 'error', text: 'broker_importer_email_id_max_100' },	 //broker importer email id max 100
	  '3108': { type: 'error', text: 'broker_importer_email_id_email' },	 //broker importer email id email
	  '3109': { type: 'error', text: 'customer_broker_type_in_1_2' },	 //customer broker type in 1 2
	  '3110': { type: 'error', text: 'customer_broker_name_max_200' },	 //customer broker name max 200
	  '3111': { type: 'error', text: 'customer_broker_phone_max_1' },	 //customer broker phone max 1
	  '3112': { type: 'error', text: 'customer_broker_email_id_max_100' },	 //customer broker email id max 100
	  '3113': { type: 'error', text: 'customer_broker_email_id_email' },	 //customer broker email id email
	  '3114': { type: 'error', text: 'added_byshipment_custom_broker_required' },	 //added byshipment custom broker required
	  '3115': { type: 'error', text: 'added_byshipment_custom_broker_not_exists' },	 //added byshipment custom broker not exists
	  '3116': { type: 'error', text: 'added_dateshipment_custom_broker_required' },	 //added dateshipment custom broker required
	  '3117': { type: 'error', text: 'added_dateshipment_custom_broker_date_format_Y_m_d_H_i_s' },	 //added dateshipment custom broker date format Y m d H i s
	  '3118': { type: 'error', text: 'modified_byshipment_custom_broker_required' },	 //modified byshipment custom broker required
	  '3119': { type: 'error', text: 'modified_byshipment_custom_broker_not_exists' },	 //modified byshipment custom broker not exists
	  '3120': { type: 'error', text: 'modified_dateshipment_custom_broker_required' },	 //modified dateshipment custom broker required
	  '3121': { type: 'error', text: 'modified_dateshipment_custom_broker_date_format_Y_m_d_H_i_s' },	 //modified dateshipment custom broker date format Y m d H i s
	  '3122': { type: 'info', text: 'Custom broker information added successfully' },	 //shipment custom broker succ
	  '3123': { type: 'error', text: 'failed to add custom broker information' },	 //shipment custom broker failed
	  '3124': { type: 'info', text: 'Shipment custom info updated successfully' },	 //shipment custom broker patch succ
	  '3125': { type: 'error', text: 'failed to delete custom broker information' },	 //shipment custom broker patch fail
	  '3126': { type: 'info', text: 'Custom broker information updated successfully' },	 //shipment custom broker update succ
	  '3127': { type: 'error', text: 'Failed to update custom broker information' },	 //shipment custom broker update fail
	  '3128': { type: 'info', text: 'Custom broker information deleted successfully' },	 //shipment custom broker del succ
	  '3129': { type: 'error', text: 'Failed to delete custom broker information ' },	 //shipment custom broker del fail
	  '3130': { type: 'error', text: 'customs_broker_uuid_required' },	 //customs broker uuid required
	  '3131': { type: 'error', text: 'customs_broker_uuid_not_exists' },	 //customs broker uuid not exists

	  //end of shipment messages

	  //Customer Paymen messages
	  '13000': 'customer payment profile uuid required',
	  '13001': 'customer payment profile uuid max 36',
	  '13002': 'customer payment profile uuid unique',
	  '13003': 'customer uuid required',
	  '13004': 'customer uuid not exists',
	  '13005': 'payment profile id max 22',
	  '13006': 'credit card type id required',
	  '13007': 'credit card type id not exists',
	  '13008': 'credit card no required',
	  '13009': 'credit card no max 20',
	  '13010': 'customer first namepayment required',
	  '13011': 'customer first namepayment max 200',
	  '13012': 'customer last namepayment required',
	  '13013': 'customer last namepayment max 200',
	  '13014': 'customer address1 required',
	  '13015': 'customer address1 max 200',
	  '13016': 'customer address2 max 200',
	  '13017': 'customer zippayment required',
	  '13018': 'customer zippayment max 15',
	  '13019': 'customer citypayment required',
	  '13020': 'customer citypayment max 200',
	  '13021': 'customer statepayment max 200',
	  '13022': 'customer state idpayment not exists',
	  '13023': 'customer country idpayment required',
	  '13024': 'customer country idpayment not exists',
	  '13025': 'is primary card in 0 1',
	  '13026': 'customer payment profile status in 0 1 2',
	  '13027': 'added bypayment required',
	  '13028': 'added bypayment not exists',
	  '13029': 'added date required',
	  '13030': 'added date date format Y m d H i s',
	  '13031': 'modified bypayment required',
	  '13032': 'modified bypayment not exists',
	  '13033': 'modified datepayment required',
	  '13034': 'modified datepayment date format Y m d H i s',
	  '13035': 'Payment Profile succ',
	  '13036': 'Payment Profile failed',
	  '13037': 'Payment Profile patch succ',
	  '13038': 'Payment Profile patch fail',
	  '13039': 'Payment Profile update succ',
	  '13040': 'Payment Profile update fail',
	  '13041': 'Payment Profile del succ',
	  '13042': 'Payment Profile del fail',
	  '13044': 'customer payment profile uuid not exists',

	  // AR Payment method errors
	  '14000': 'ar status in 0 1',
	  '14001': 'ar credit limit numeric',
	  '14002': 'ar credit limit between 0 99999999 99',
	  '14003': 'ar grace amount numeric',
	  '14004': 'ar grace amount between 0 99999999 99',
	  '14005': 'default payment method in 0 1',
	  '14006': 'modified byarinfo required',
	  '14007': 'modified byarinfo not exists',
	  '14008': 'modified datearinfo required',
	  '14009': 'modified datearinfo date format Y m d H i s',
	  '14014': 'arinfo update succ',
	  '14015': 'arinfo update fail',
	  '14018': 'customer uuidarinfo required',
	  '14019': 'customer uuidarinfo not exists',



	  // ShipmentPayment messages



	  '15000': 'shipment payment uuid required',
	  '15001': 'shipment payment uuid unique',
	  '15002': 'shipment uuidshipment payment required',
	  '15003': 'shipment uuidshipment payment not exists',
	  '15004': 'shipment uuidshipment payment shipment uuid',
	  '15005': 'payment type id required',
	  '15006': 'payment type id not exists',
	  '15007': 'customer payment profile uuid not exists',
	  '15008': 'transaction reference id max 100',
	  '15009': 'payment type number max 40',
	  '15010': 'payment amount required',
	  '15011': 'payment amount numeric',
	  '15012': 'payment amount between 0 99999999 99',
	  '15013': 'payment date date format Y m d H i s',
	  '15014': 'payment status in 0 1 2 3',
	  '15015': 'added byshipment payment required',
	  '15016': 'added byshipment payment not exists',
	  '15017': 'added date required',
	  '15018': 'added date date format Y m d H i s',
	  '15019': 'modified byshipment payment required',
	  '15020': 'modified byshipment payment not exists',
	  '15021': 'modified dateshipment payment required',
	  '15022': 'modified dateshipment payment date format Y m d H i s',
	  '15023': {type:'info',text:'Shipment has been completed'},
	  '15024': 'shipment payment failed',
	  '15025': 'shipment payment patch succ',
	  '15026': 'shipment payment patch fail',
	  '15027': 'shipment payment update succ',
	  '15028': 'shipment payment update fail',
	  '15029': 'shipment payment del succ',
	  '15030': 'shipment payment del fail',
	  '15031': 'customer uuidshipment payment required',
	  '15032': 'customer uuidshipment payment not exists',
	  '15033': 'customer uuidshipment payment not exists',
	  '15034': 'customer uuidshipment payment not exists',
	  '15035': 'Nothing to pay left',
	  '15036': 'customer infromation not found',
	  '15037': 'ar account not activated',
	  '15038': 'ar account limit exceeds',
	  '15039': 'customer paymentprofile id required',

	  // Coupon Code Error Messages

	  2339: {
		type: 'error',
		text: 'Coupon code added successfully.'
	  },
	  2340: {
		type: 'error',
		text: 'Coupon code add failed.'
	  },
	  2341: {
		type: 'error',
		text: 'Coupon code updated successfully.'
	  },
	  2342: {
		type: 'error',
		text: 'Coupon code update failed.'
	  },
	  2343: {
		type: 'error',
		text: 'Coupon code deleted successfully.'
	  },
	  2344: {
		type: 'error',
		text: 'Coupon code description should have max 255 characters.'
	  },
	  2345: {
		type: 'error',
		text: 'Coupon code is required.'
	  },
	  2346: {
		type: 'error',
		text: 'Coupon code should be unique.'
	  },
	  2347: {
		type: 'error',
		text: 'Coupon code max characters should be 30.'
	  },
	  2348: {
		type: 'error',
		text: 'Coupon code start date is required.'
	  },
	  2349: {
		type: 'error',
		text: 'Coupon code end date is required.'
	  },
	  2350: {
		type: 'error',
		text: 'Coupon code discount type is required.'
	  },
	  2351: {
		type: 'error',
		text: 'Coupon code discount amount is required.'
	  },
	  2352: {
		type: 'error',
		text: 'Coupon code discount amount should be numeric.'
	  },
	  2353: {
		type: 'error',
		text: 'Coupon Code discount amount should be in valid range (0 - 99999999.99).'
	  },
	  2354: {
		type: 'error',
		text: 'Coupon code customer type is required.'
	  },
	  2355: {
		type: 'error',
		text: 'Coupon code number of shipments allowed is required.'
	  },
	  2356: {
		type: 'error',
		text: 'Coupon code id required.'
	  },
	  2357: {
		type: 'error',
		text: 'Coupon code id does not exist.'
	  },
	  2361: {
		type: 'error',
		text: 'Coupon code description is required.'
	  },
	  '2371': { type: 'error', text: 'Invalid coupon code', },
	  '2972': { type: 'error', text: 'Coupon is only available for new customers', },


	  //System Wide Notification
	  2362: {
		type: 'error',
		text: 'System wide notification id is required.'
	  },
	  2363: {
		type: 'error',
		text: 'System wide notification id does not exist.'
	  },
	  2364: {
		type: 'error',
		text: 'System wide notification name is required.'
	  },
	  2365: {
		type: 'error',
		text: 'System wide notification should have max 150 characters.'
	  },
	  2366: {
		type: 'error',
		text: 'System wide notification status is required.'
	  },
	  2367: {
		type: 'error',
		text: 'System wide notification should be numeric.'
	  },
	  2368: {
		type: 'error',
		text: 'System wide notification updated successfully.'
	  },
	  2369: {
		type: 'error',
		text: 'System wide notification updation failed.'
	  },

	  // Fuel Surcharges
	  2293: {
		type: 'error',
		text: 'Fuel surcharge index name should have max 100 characters.'
	  },
	  2075: {
		type: 'error',
		text: 'Fuel Surcharge Index should be unique.'
	  },
	  2405: {
		type: 'error',
		text: 'Fuel cost index should have distinct range.'
	  },
	  2406: {
		type: 'error',
		text: 'Fuel max cost index should have distinct range.'
	  },

	  // Falvey Terms & Conditions
	  2390: {
		type: 'error',
		text: 'Falvey terms & condition name is required.'
	  },
	  2391: {
		type: 'error',
		text: 'Falvey terms & condition name should be unique.'
	  },
	  2392: {
		type: 'error',
		text: 'Falvey terms & condition name should have max 100 characters.'
	  },
	  2393: {
		type: 'error',
		text: 'Falvey terms & condition file content is required.'
	  },
	  2394: {
		type: 'error',
		text: 'Falvey terms & condition filename is required.'
	  },
	  2395: {
		type: 'error',
		text: 'Falvey terms & condition filename should have max 100 characters.'
	  },
	  2396: {
		type: 'error',
		text: 'Falvey terms & condition status is required.'
	  },
	  2397: {
		type: 'info',
		text: 'Falvey terms & condition added successfully.'
	  },
	  2398: {
		type: 'error',
		text: 'Falvey terms & condition addition failed.'
	  },
	  2399: {
		type: 'info',
		text: 'Falvey terms & condition updated successfully.'
	  },
	  2400: {
		type: 'error',
		text: 'Falvey terms & condition updated failed.'
	  },
	  2401: {
		type: 'error',
		text: 'Falvey terms & condition inUse key required.'
	  },
	  2402: {
		type: 'error',
		text: 'Falvey terms & condition id is required.'
	  },
	  2403: {
		type: 'error',
		text: 'Falvey terms & condition id does not exists.'
	  },
	  2404: {
		type: 'info',
		text: 'Falvey terms & condition deleted successfully.'
	  },
	  // TMS Terms & Conditions
	  2372: {
		type: 'error',
		text: 'TMS terms & condition name is required.'
	  },
	  2373: {
		type: 'error',
		text: 'TMS terms & condition name should be unique.'
	  },
	  2374: {
		type: 'error',
		text: 'TMS terms & condition name should have max 100 characters.'
	  },
	  2375: {
		type: 'error',
		text: 'TMS terms & condition file content is required.'
	  },
	  2376: {
		type: 'error',
		text: 'TMS terms & condition filename is required.'
	  },
	  2377: {
		type: 'error',
		text: 'TMS terms & condition filename should have max 100 characters.'
	  },
	  2378: {
		type: 'error',
		text: 'TMS terms & condition status is required.'
	  },
	  2379: {
		type: 'info',
		text: 'TMS terms & condition added successfully.'
	  },
	  2380: {
		type: 'error',
		text: 'TMS terms & condition addition failed.'
	  },
	  2384: {
		type: 'info',
		text: 'TMS terms & condition updated successfully.'
	  },
	  2385: {
		type: 'error',
		text: 'TMS terms & condition updated failed.'
	  },
	  2386: {
		type: 'error',
		text: 'TMS terms & condition isPrimary key required.'
	  },
	  2387: {
		type: 'error',
		text: 'TMS terms & condition id is required.'
	  },
	  2388: {
		type: 'error',
		text: 'TMS terms & condition id does not exists.'
	  },
	  2389: {
		type: 'info',
		text: 'TMS terms & condition deleted successfully.'
	  },
	  // teams
	  2407: {
		type: 'error',
		text: 'Team name is required.'
	  },
	  2408: {
		type: 'error',
		text: 'Team name should have max 255 characters.'
	  },
	  2409: {
		type: 'error',
		text: 'Team uuid is required.'
	  },
	  2410: {
		type: 'error',
		text: 'Team supervisor uuid is required.'
	  },
	  2411: {
		type: 'info',
		text: 'Team added successfully.'
	  },
	  2412: {
		type: 'error',
		text: 'Team addition failed.'
	  },
	  2413: {
		type: 'info',
		text: 'Team updated successfully.'
	  },
	  2414: {
		type: 'error',
		text: 'Team updation failed.'
	  },
	  2415: {
		type: 'error',
		text: 'Team deleted successfully.'
	  },
	  2416: {
		type: 'error',
		text: 'Team id is required.'
	  },
	  2417: {
		type: 'error',
		text: 'Team id does not exists.'
	  },
	  // Financial Settings
	  2418: {
		type: 'error',
		text: 'Financial setting id is required.'
	  },
	  2419: {
		type: 'error',
		text: 'Financial setting id does not exist.'
	  },
	  2420: {
		type: 'error',
		text: 'Financial setting is required.'
	  },
	  2421: {
		type: 'error',
		text: 'Financial setting should be numeric.'
	  },
	  2422: {
		type: 'error',
		text: 'Financial setting should be in valid range.'
	  },
	  2423: {
		type: 'info',
		text: 'Financial setting updated successfully.'
	  },
	  2424: {
		type: 'error',
		text: 'Financial setting updation failed.'
	  },
	  // Common Messages
	  500: {
		type: 'error',
		text: 'Something went wrong.'
	  },
	  999999: {
		type: 'error',
		text: 'Zip Code not found'
	  },
	  999998: {
		type: 'info',
		text: 'Coupon applied successfully'
	  },
	  99997: {
		type: 'info',
		text: 'Insurance price retrieved successfully'
	  },
	  99996: {
		type: 'error',
		text: 'Error in getting insurance price'
	  },
	  99995: {
		type: 'error',
		text: 'Unable to fetch documents'
	  },
	  99994: {
		type: 'error',
		text: 'We’re sorry, Shipment Protection is not available at this time. Please contact us at 800-716-7608 for more information.'
	  },
	  2360: {
		type: 'error',
		text: 'Accessorial code should be unique.'
	  },
	  2228: {
		type: 'error',
		text: 'Facility type already exist. Please use different name.'
	  },
	  2294: {
		type: 'error',
		text: 'Loading facility name should have max 100 characters.'
	  },
	  1059: {
		type: 'error',
		text: 'Unable to fetch data.'
	  },
	  2191: {
		type: 'error',
		text: 'Accessorial should be unique.'
	  },
	  2009: {
		type: 'error',
		text: 'Unable to delete the records.'
	  },

	  // Approved/Blocked IP Address error & info messages
	  2425: {
		type: 'info',
		text: 'IP address added successfully.'
	  },
	  2426: {
		type: 'error',
		text: 'Unable to add IP address.'
	  },
	  2427: {
		type: 'error',
		text: 'IP address is required.'
	  },
	  2428: {
		type: 'error',
		text: 'IP address should be unique.'
	  },
	  2429: {
		type: 'error',
		text: 'IP address should have max 50 characters.'
	  },
	  2430: {
		type: 'error',
		text: 'IP address status required.'
	  },
	  2431: {
		type: 'error',
		text: 'IP address status should be numeric.'
	  },
	  2432: {
		type: 'error',
		text: 'IP address type required.'
	  },
	  2433: {
		type: 'error',
		text: 'IP address type should be numeric.'
	  },
	  2434: {
		type: 'error',
		text: 'IP address comments should have max 255 required.'
	  },
	  2435: {
		type: 'error',
		text: 'IP address id required.'
	  },
	  2436: {
		type: 'error',
		text: 'IP address does not exist.'
	  },
	  2437: {
		type: 'info',
		text: 'IP address updated successfully.'
	  },
	  2438: {
		type: 'error',
		text: 'IP address updation failed.'
	  },
	  2439: {
		type: 'info',
		text: 'IP address deleted successfully.'
	  },

	  // Security Levels Error & Info Messages
	  2441: {
		type: 'info',
		text: 'Security level added successfully.'
	  },
	  2442: {
		type: 'error',
		text: 'Unable to add security level.'
	  },
	  2443: {
		type: 'error',
		text: 'Security level name is required.'
	  },
	  2444: {
		type: 'error',
		text: 'Security level name should be unique.'
	  },
	  2445: {
		type: 'error',
		text: 'Security level name should have max 100 characters.'
	  },
	  2446: {
		type: 'error',
		text: 'Security level description is required.'
	  },
	  2447: {
		type: 'error',
		text: 'Security level description should have max 255 characters.'
	  },
	  2448: {
		type: 'error',
		text: 'Security level status is required.'
	  },
	  2449: {
		type: 'error',
		text: 'Security level status should be numeric.'
	  },
	  2450: {
		type: 'error',
		text: 'Security level id is required.'
	  },
	  2451: {
		type: 'error',
		text: 'Security level does not exist.'
	  },
	  2452: {
		type: 'info',
		text: 'Security level updated successfully.'
	  },
	  2453: {
		type: 'error',
		text: 'Security level updation failed'
	  },
	  2454: {
		type: 'info',
		text: 'Security level deleted successfully.'
	  },

	  // Security Roles Messages
	  3300: {
		type: 'error',
		text: 'Security role uuid is required.'
	  },
	  3301: {
		type: 'error',
		text: 'Security role uuid should be unique.'
	  },
	  3302: {
		type: 'error',
		text: 'Level is required.'
	  },
	  3303: {
		type: 'error',
		text: 'Level id does not exists.'
	  },
	  3304: {
		type: 'error',
		text: 'Role name is required.'
	  },
	  3305: {
		type: 'error',
		text: 'Role name should be max 100 characters.'
	  },
	  3306: {
		type: 'error',
		text: 'Role description is required.'
	  },
	  3307: {
		type: 'error',
		text: 'Role description should be max 255 characters.'
	  },
	  3308: {
		type: 'error',
		text: 'Role status should be 0, 1 or 2.'
	  },
	  3309: {
		type: 'error',
		text: 'Added by is required.'
	  },
	  3310: {
		type: 'error',
		text: 'Added by does not exists.'
	  },
	  3311: {
		type: 'error',
		text: 'Added date is required.'
	  },
	  3312: {
		type: 'error',
		text: 'Added date format should be Y/m/d/H/i/s.'
	  },
	  3313: {
		type: 'error',
		text: 'Modified by is required.'
	  },
	  3314: {
		type: 'error',
		text: 'Modified by does not exists.'
	  },
	  3315: {
		type: 'error',
		text: 'Modified date is required.'
	  },
	  3316: {
		type: 'error',
		text: 'Modified date format should be Y/m/d/H/i/s.'
	  },
	  3317: {
		type: 'info',
		text: 'Role added successfully.'
	  },
	  3318: {
		type: 'error',
		text: 'Role not added successfully.'
	  },
	  3319: {
		type: 'info',
		text: 'Role patch successfully.'
	  },
	  3320: {
		type: 'error',
		text: 'Role patch failed.'
	  },
	  3321: {
		type: 'info',
		text: 'Role updated successfully.'
	  },
	  3322: {
		type: 'error',
		text: 'Role not updated successfully.'
	  },
	  3323: {
		type: 'info',
		text: 'Role deleted successfully.'
	  },
	  3324: {
		type: 'error',
		text: 'Role not deleted successfully.'
	  },
	  3325: {
		type: 'error',
		text: 'Role uuid is required.'
	  },
	  3326: {
		type: 'error',
		text: 'Role uuid does not exists.'
	  },
	  3327: {
		type: 'error',
		text: 'Role name should be unique.'
	  },
	  22058:{
		type: 'error',
		text: 'Please note that your payment has been adjusted to reflect the new charges. The page will reload in a few seconds. If you have any questions, please contact your agent'
	  },
	  22059:{
		type: 'error',
		text: 'Your shipment mode has been changed to TL'
	  },

	  // Quote Messages

	  '20001': 'origin postal code is required',
	  '20002': 'origin postal code minimum 5',
	  '20003': 'origin postal code maximnum 6',
	  '20004': 'destination postal code is required',
	  '20005': 'destination postal code minimum 5',
	  '20006': 'destination postal code maximnum 6',
	  '20007': 'Line item total weight is required',
	  '20008': 'line item total weight should be numeric',
	  '20009': 'line item total weight should be between 1 and 99999999.99',
	  '20010': 'packagedimensions line item length is required',
	  '20011': 'packagedimensions line item length should be numeric',
	  '20012': 'packagedimensions line item length should be between 1 and 99',
	  '20013': 'packagedimensions line item width is required',
	  '20014': 'packagedimensions line item width should be numeric',
	  '20015': 'packagedimensions line item width should be between 1 and 99',
	  '20016': 'packagedimensions line item height is required',
	  '20017': 'packagedimensions line item height should be numeric',
	  '20018': 'packagedimensions line item height should be between 1 and 99',
	  '20019': 'line item total package is required',
	  '20020': 'line item total package should be numeric',
	  '20021': 'Line item package should be between 1 and 99',
	  '20022': 'Line item total Pieces is required',
	  '20023': 'Line item total Pieces should be between 1 and 99',
	  '20024': 'Line item total Pieces should be numeric',
	  '20025': 'weight unit is required',
	  '20026': 'length unit is required',
	  '20027': 'Pick window date is required',
	  '12601': 'request_quote_request_uuid_not_exists',
	  '2430': 'Minimum cost should be less than maximum cost',
	  '2431': 'Fuel surcharge should be between 0 to 100',
	  '20111': 'Invalid carrier',
	  '20112': 'No preference set for contract',
	  '20113': 'Customer is not valid',
	  '20114': 'No results',
	  '5098': 'Contract specific update failed',
	  '5117': 'Contract update  failed',
	  '12508': 'convert shipment failed',
	  '12509': 'Shipment Charge is required',
	  '12510': 'Shipment Cost is required',
	  '12511': 'Shipment Type is required',
	  '12512': 'Shipment Mode is required',
	  '12513': 'Shipment Contract is required',
	  '12514': 'Shipment charges is required',
	  '12517': {
		type: 'error',
		text:'Contract is expired'},
	  '12515':  {
		type: 'error',
		text:'Shipment is expired'},
	  '12516': {
		type: 'error',
		text:'Shipment needs to re run'},
	  '20094': 'Error in shipment cretaion',
	  '20048': 'date must yyyy-mm-dd',
	  '20049': 'date must be future date',
	  12515:'shipment is expired',
	  12516:'Shipment needs to re run',
	  12517:'contract is expired',
'3200'	:{type:'error',text:	'customer_uuid_customer_fc_required'	},	 //customer uuid customer fc required
'3201'	:{type:'error',text:	    'customer_uuid_customer_fc_unique'	},	 //customer uuid customer fc unique
'3202'	:{type:'error',text:	    'customer_type_customer_fc_required'	},	 //customer type customer fc required
'3203'	:{type:'error',text:	    'customer_type_customer_fc_in_1_2'	},	 //customer type customer fc in 1 2
'3204'	:{type:'error',text:	    'customer_shipping_frequency_customer_fc_required'	},	 //customer shipping frequency customer fc required
'3205'	:{type:'error',text:	    'customer_shipping_frequency_customer_fc_number'	},	 //customer shipping frequency customer fc number
'3206'	:{type:'error',text:	    'customer_shipping_frequency_customer_fc_between_0_32767'	},	 //customer shipping frequency customer fc between 0 '32767
'3207'	:{type:'error',text:	    'customer_first_name_customer_fc_required'	},	 //customer first name customer fc required
'3208'	:{type:'error',text:	    'customer_first_name_customer_fc_max_200'	},	 //customer first name customer fc max 200
'3209'	:{type:'error',text:	    'customer_last_name_customer_fc_required'	},	 //customer last name customer fc required
'3210'	:{type:'error',text:	    'customer_last_name_customer_fc_max_200'	},	 //customer last name customer fc max 200
'3211'	:{type:'error',text:	    'customer_company_customer_fc_max_200'	},	 //customer company customer fc max 200
'3212'	:{type:'error',text:	    'customer_department_customer_fc_max_200'	},	 //customer department customer fc max 200
'3213'	:{type:'error',text:	    'customer_position_customer_fc_max_100'	},	 //customer position customer fc max 100
'3214'	:{type:'error',text:	    'customer_website_customer_fc_max_200'	},	 //customer website customer fc max 200
'3215'	:{type:'error',text:	    'customer_email_id_customer_fc_required'	},	 //customer email id customer fc required
'3216'	:{type:'error',text:	    'customer_email_id_customer_fc_max_100'	},	 //customer email id customer fc max 100
'3217'	:{type:'error',text:	    'password_customer_fc_required'	},	 //password customer fc required
'3218'	:{type:'error',text:	    'password_customer_fc_max_60'	},	 //password customer fc max 60
'3219'	:{type:'error',text:	    'password_customer_fc_regex'	},	 //password customer fc regex /^(?
'3221'	:{type:'error',text:	    'timezone_id_customer_fc_not_exists'	},	 //timezone id customer fc not exists
'3222'	:{type:'error',text:	    'registration_date_customer_fc_date_format_Y_m_d_H_i_s'	},	 //registration date customer fc date format Y m d H i s
'3223'	:{type:'error',text:	    'last_contacted_date_customer_fc_date_format_Y_m_d_H_i_s'	},	 //last contacted date customer fc date format Y m d H i s
'3224'	:{type:'error',text:	    'last_shipment_date_customer_fc_date_format_Y_m_d_H_i_s'	},	 //last shipment date customer fc date format Y m d H i s
'3225'	:{type:'error',text:	    'last_quote_date_customer_fc_date_format_Y_m_d_H_i_s'	},	 //last quote date customer fc date format Y m d H i s
'3226'	:{type:'error',text:	    'total_quote_customer_fc_numeric'	},	 //total quote customer fc numeric
'3227'	:{type:'error',text:	    'total_quote_customer_fc_between_0_32767'	},	 //total quote customer fc between 0 32767
'3228'	:{type:'error',text:	    'total_shipment_customer_fc_numeric'	},	 //total shipment customer fc numeric
'3229'	:{type:'error',text:	    'total_shipment_customer_fc_between_0_32767'	},	 //total shipment customer fc between 0 '32767
'3230'	:{type:'error',text:	    'customer_status_customer_fc_in_0_1_2'	},	 //customer status customer fc in 0 1 2
'3231'	:{type:'error',text:	    'ar_status_customer_fc_0_1_2'	},	 //ar status customer fc 0 1 2
'3232'	:{type:'error',text:	    'default_payment_method_customer_fc_in_0_1'	},	 //default payment method customer fc in 0 1
'3233'	:{type:'error',text:	    'agent_user_id_customer_fc_not_exists'	},	 //agent user id customer fc not exists
'3234'	:{type:'error',text:	    'agent_user_uuid_customer_fc_not_exists'	},	 //agent user uuid customer fc not exists
'3235'	:{type:'error',text:	    'engagement_type_id_customer_fc_numeric'	},	 //engagement type id customer fc numeric
'3236'	:{type:'error',text:	    'engagement_type_id_customer_fc_between_0_127'	},	 //engagement type id customer fc between 0 127
'3237'	:{type:'error',text:	    'sic_code_customer_fc_max_6'	},	 //sic code customer fc max 6
'3238'	:{type:'info',text:	    'User is registered successfully'	},	 // customer fc succ
'3239'	:{type:'error',text:	    '_customer_fc_failed'	},	 // customer fc failed
'3240'	:{type:'info',text:	    '_customer_fc_patch_succ'	},	 // customer fc patch succ
'3241'	:{type:'error',text:	    '_customer_fc_patch_fail'	},	 // customer fc patch fail
'3242'	:{type:'info', text:'Success'},	 // customer fc update succ
'3243'	:{type:'error',text:	    '_customer_fc_update_fail'	},	 // customer fc update fail
'3244'	:{type:'error',text:	    '_customer_fc_del_succ'	},	 // customer fc del succ
'3245'	:{type:'error',text:	    '_customer_fc_del_fail'	},	 // customer fc del fail
'3246'	:{type:'error',text:	    'customer_uuid_customer_fc_required'	},	 //customer uuid customer fc required
'3247'	:{type:'error',text:	    'customer_uuid_customer_fc_not_exists'	},	 //customer uuid customer fc not exists
'3248'	:{type:'error',text:	    'customer_company_customer_fc_required'	},	 //customer company
'3249'	:{type:'error',text:	    'news_and_update_required'	},	 //news and update required
'3250'	:{type:'error',text:	    'news_and_update_in_0_1'	},	 //news and update required in 0 1
'3251'	:{type:'error',text:	    'send_sms_required'	},	 //send sms required
'3252'	:{type:'error',text:	    'send_sms_0_1'	},	 //send sms required 0 1
'3253'	:{type:'error',text:	    'This email is already linked with an account'	},	 //email unique
		       
'3254'	:{type:'error',text:	    'discount_required'	},	 //discount req
'3255'	:{type:'error',text:	    'discount_0_1'	},	 //discount in 0 1
'3256'	:{type:'error',text:	    'tracking_0_1'	},	 //tracknig 0 1
'3257'	:{type:'error',text:	    'tracking_required'	},	 //trakcing required
'3258'	:{type:'error',text:	'Invalid username or password'	},	 //Customer Id not found
'3259'	:{type:'info',text:	    'customer_auth'	},	 //Customer authoriszed
'3260'	:{type:'error',text:	    'Invalid username or password'	},	 //Customer authoriszed not
'3261'	:{type:'error','text':'Your new password is same as the current password. Please enter a different password'},
'3262'	:{type:'info',text:	    'customer_password_updated'	},	 //Customer password updated
'3263'	:{type:'error',text:	    'customer_password_updated_failed'	},	 //Customer password updated failed
'3264':{type:'error',text:'curent_password_customer_fc_required'}, //current password required
'3265':{type:'error',text:   'curent_password_customer_fc_regex'},//current password not valid format
'3266':{type:'error',text:   'curent_password_customer_fc_max_60'},//current password max 60
'3267':{type:'error',text:   'Incorrect password. Please try again'},//current pwd not matched
'4642'	:{type:'error','text':	'Account does not exist. Please enter a valid email address' 	},	 //customer email  not exists
'4643'	:{type:'error','text':	    'Account does not exist. Please enter a valid email address' 	},	 //customer email not valid
'4644'	:{type:'info','text':	    'A temporary link to reset your password has been emailed to you' 	},	 //customer mail password sent
'4645'	:{type:'error','text':	    'Unable to send email' 	},	 //customer mail password not sent
'4646'	:{type:'error','text':	    'customer_activation_code_required' 	},	 //customer activation code required
'4647'	:{type:'error','text':	    'customer_activation_not_exists' 	},	 //customer activation code not exists
'4648'	:{type:'info','text':	    'Password reset successful' 	},	 //customer password reset
'4649'	:{type:'error','text':	    'Failed to reset password' 	},	 //customer password failed
12124 : {type:'success','text':'Location details saved successfully'},
'12324'	:{type:'success','text':	    'Shipment status changed' 	},
'12325'	:{type:'error','text':	    'Shipment status not changed' 	},
12300: {type:'success',text:'Quote deleted successfully'},
12239:{type:'success',text:'Products updated successfully'},
12517  	:{type:'error','text':	    "Carrier's contract has expired. Please choose a different carrier."},
12516 	:{type:'error','text':	    'Shipment has to be re-run. Carrier rates have been changed.'},
12351 	:{type:'error','text':	    'Shipment charge req'},
12349 	:{type:'error','text':	    'Shipment transaction subtype required'},
12516 	:{type:'error','text':	    'Shipment has to be re-run. Carrier rates have been changed.'},

'12':{type:'info','text':	    'A temporary link to reset your password has been emailed to you.'},
'3400'	:{type:'error',text:	'customer_fc_term_uuid_required'	},	 //customer fc term uuid required
'3401'	:{type:'error',text:	'customer_fc_term_uuid_unique'	},	 //customer fc term uuid unique
'3402'	:{type:'error',text:	'fc_term_condition_uuid_required'	},	 //fc term condition uuid required
'3403'	:{type:'error',text:	'fc_term_condition_uuid_not_exists'	},	 //fc term condition uuid not exists
'3404'	:{type:'error',text:	'customer_uuid_customer_fc_term_required'	},	 //customer uuid customer fc term required
'3405'	:{type:'error',text:	'customer_uuid_customer_fc_term_not_exists'	},	 //customer uuid customer fc term not exists
'3406'	:{type:'error',text:	'fc_term_accepted_in_1'	},	 //fc term accepted in 1
'3407'	:{type:'error',text:	'added_by_customer_fc_term_required'	},	 //added by customer fc term required
'3408'	:{type:'error',text:	'added_date_customer_fc_term_required'	},	 //added date customer fc term required
'3409'	:{type:'error',text:	'added_date_customer_fc_term_date_format_Y_m_d_H_i_s'	},	 //added date customer fc term date format Y m d H i s
'3410'	:{type:'error',text:	'modified_by_customer_fc_term_required'	},	 //modified by customer fc term required
'3411'	:{type:'error',text:	'modified_date_customer_fc_term_required'	},	 //modified date customer fc term required
'3412'	:{type:'error',text:	'modified_date_customer_fc_term_date_format_Y_m_d_H_i_s'	},	 //modified date customer fc term date format Y m d H i s
'3413'	:{type:'success',text:	'FC terms and conditions are accepted successfully'	},	 // customer fc term succ
'3414'	:{type:'error',text:	'_customer_fc_term_failed'	},	 // customer fc term failed
'3415'	:{type:'error',text:	'_customer_fc_term_patch_succ'	},	 // customer fc term patch succ
'3416'	:{type:'error',text:	'_customer_fc_term_patch_fail'	},	 // customer fc term patch fail
'3417'	:{type:'error',text:	'_customer_fc_term_update_succ'	},	 // customer fc term update succ
'3418'	:{type:'error',text:	'_customer_fc_term_update_fail'	},	 // customer fc term update fail
'3419'	:{type:'error',text:	'_customer_fc_term_del_succ'	},	 // customer fc term del succ
'3420'	:{type:'error',text:	'_customer_fc_term_del_fail'	},	 // customer fc term del fail
'3421'	:{type:'error',text:	'customer_fc_term_uuid_required'	},	 //customer fc term uuid required
'3422'	:{type:'error',text:	'customer_fc_term_uuid_not_exists'	},	 //customer fc term uuid not exists
'3423'	:{type:'error',text:	'fc_term_accepted_required'	},	 //customer fc term required
'3424'	:{type:'error',text:	'fc_not_terms_found'	},	 //customer fc term nto found
 '16000'	:{type:'error',text:	   'customer_falvey_term_uuid_required'	},	 //customer falvey term uuid required
 '16001'	:{type:'error',text:	        'customer_falvey_term_uuid_max_36'	},	 //customer_falvey_term_uuid max 36
 '16002'	:{type:'error',text:	        'customer_falvey_term_fc_term_condition_uuid_required'	},	 //customer_falvey_term_fc_term_condition_uuid_required
 '16003'	:{type:'error',text:	        'customer_falvey_term_customer_uuid_required'	},	 //customer_falvey_term_customer_uuid_required
 '16004'	:{type:'error',text:	        'customer_falvey_term_customer_uuid_not_exists'	},	 //customer_falvey_term_customer_uuid_not_exists
 '16005'	:{type:'error',text:	        'customer_falvey_term_falvey_term_accepted_in_0_1'	},	 //customer_falvey_term_falvey_term_accepted_in_0_1
 '16006'	:{type:'error',text:	        'customer_falvey_term_added_by_required'	},	 //customer_falvey_term_added_by_required
 '16007'	:{type:'error',text:	        'customer_falvey_term_added_by_not_exists'	},	 //customer_falvey_term_added_by_not_exists
 '16008'	:{type:'error',text:	        'customer_falvey_term_added_date_required'	},	 //customer_falvey_term_added_date_required
 '16009'	:{type:'error',text:	        'customer_falvey_term_added_date_format_Y_m_d_H_i_s'	},	 //customer_falvey_term_added_date_format_Y_m_d_H_i_s
 '16010'	:{type:'error',text:	        'customer_falvey_term_modified_by_required'	},	 //customer_falvey_term_modified_by_required
 '16011'	:{type:'error',text:	        'customer_falvey_term_modified_by_not_exists'	},	 //customer_falvey_term_modified_by_not_exists
 '16012'	:{type:'error',text:	        'customer_falvey_term_modified_date_required'	},	 //customer_falvey_term_modified_date_required
 '16013'	:{type:'error',text:	        'customer_falvey_term_modified_date_format_Y_m_d_H_i_s'	},	 // customer_falvey_term_modified_date_format_Y_m_d_H_i_s
 '16014'	:{type:'success',text:	        'customer_falvey_terms_succ'	},	 //customer_falvey_terms_succ
 '16015'	:{type:'error',text:	        'customer_falvey_terms_failed'	},	 //customer_falvey_terms_failed
 '16016'	:{type:'success',text:	        'customer_falvey_terms_update_succ'	},	 //customer_falvey_terms_update_succ
 '16017'	:{type:'error',text:	        'customer_falvey_terms_update_fail'	},	 //customer_falvey_terms_update_fail

'12515': 'Contract is expired',
'12515' 	:{type:'error','text':	    'Quote has expired. Carrier rates may change.'},
'16019'	:{type:'error','text':'customer_falvey_term_customer_signature_required'} , // customer_falvey_term_customer_signature_required
'16020' 	:{type:'error','text':   'customer_falvey_term_customer_signature_max_255'} , // customer_falvey_term_customer_signature_max_255
'16021'	:{type:'error','text':    'customer_falvey_term_fc_term_condition_uuid_not_exists' } , // customer_falvey_term_fc_term_condition_uuid_not_exists

'2560':{type:'error', text:'Coupon code has already been applied to this shipment'},
'2561':{type:'error', text:'This coupon code has already been used'},
'2562':{type:'error', text:"There's been a change to your shipment, please contact your sale agent"},

'12518':{type:'info','text':    'insuarnce deleted successfully' } , // customer_falvey_term_fc_term_condition_uuid_not_exists
'12408':{type:'error','text': 'shipment_cancel_deactivate_terms_accepted_not_in_data'} , // shiment cancel deactivate terms condition must be in 0,1
'12407':{type:'error','text': 'shipment_cancel_deactivate_signature_max_255' }, // shiment cancel deactivate signature max 255
'0011':{type:'error','text':'shipment_and_customer_doesnot_match'},
'0022':{type:'error','text':'customer_payment_profile_uuid_match_with_customer'},
'0033':{type:'error','text':'customer_does_not_match' }, // customer does not match
	//end of sign up messages
1003: { type: 'error', text: 'Date added is required' },
999999: {type:'error',text:'Zip code not found'},
99996:{type:'error',text:'Error in applying insurance'},
99991:{type:'error',text:'No driving directions found'},
99989:{type:'error',text:'No tracking information found.'},
2374:{type:'error', text: 'Coupon code is already applied on this shipment'},
2373:{type:'error', text:'need shipment Uuid'},
'1081':{type:'error', text:'record_not_found'},
4647:{type:'error', text:'Reset password link has been expired.'},
'22000':{type:'error', text:'shipment_pre_rate_quote_response_id_required'} , // shipment_pre_rate_quote_response_id_required
'22001':{type:'error', text:'shipment_pre_rate_quote_response_id_not_exists' } , // shipment_pre_rate_quote_response_id_not_exists
'22002':{type:'error', text:'shipment_pre_rate_quote_shipment_uuid_required'}, // shipment_pre_rate_quote_shipment_uuid_required
'22003':{type:'error', text:'shipment_pre_rate_quote_shipment_uuid_not_exists'}, // shipment_pre_rate_quote_shipment_uuid_not_exists
22004:{type:'error', text:'shipment_pre_rate_quote_modified_by_required' }, // shipment_pre_rate_quote_modified_by_required
22005:{type:'error', text:'shipment_pre_rate_quote_modified_by_not_exists'}, // shipment_pre_rate_quote_modified_by_not_exists
22006	:{type:'error', text:'shipment_pre_rate_quote_modified_date_required' }, // shipment_pre_rate_quote_modified_date_required
22007:{type:'error', text:'shipment_pre_rate_quote_modified_date_format_Y_m_d_H_i_s'}, // shipment_pre_rate_quote_modified_date_format_Y_m_d_H_i_s
22008:{type:'info', text:'shipment_pre_rate_queu_update_succ' }, // shipment_pre_rate_queu_update_succ
22009:{type:'error', text:'shipment_pre_rate_queu_update_failed'}, // shipment_pre_rate_queu_update_failed
999989:{type:'error',text:'No items available to rerun this quote'},
3425 :{ type:'error',text: 'ReCaptcha is not valid' },
12523: {
	type: 'info',
	text: 'Insurance has been cancelled successfully'
},
	22057: {
		type: 'error',
		text: 'Your account has been blocked, please check your email to reset the password.'
	}
}

export default en;
