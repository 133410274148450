import React from 'react';
import {connect} from 'react-redux';
import Loader from '../../atoms/Loader';
import CustomerHeader from '../../organisms/CustomerHeader';
import CustomerFooter from '../../organisms/CustomerFooter';
import {Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import commonService from '../../../services/commonService';
import customerService from '../../../services/customerService';
import accountInfoService from '../../../services/accountInfoService';
import utilCommon from '../../../helpers/utilCommon';
import SingleRate from '../QuoteResults/SingleRateV1';
import lodash from "lodash";
import shipmentService from "../../../services/shipmentService";
import URLSearchParams from "@ungap/url-search-params";
import SideNavbar from '../../organisms/SideNavBar';
import { toggleSideNav } from '../../../store/common/actions';
import {LTL_SHIPMENT_MODE, TL_SHIPMENT_MODE} from "../../../helpers/enums";

// load pages
const AccountSettings = Loadable({
    loader: () => import('../MyAccount'),
    loading: Loader,
});

const CustomerPage = Loadable({
    loader: () => import('../CustomerPage'),
    loading: Loader,
});
const DashboardPage = Loadable({
    loader: () => import('../../pages/Dashboard'),
    loading: Loader,
});

const CreditAppPage = Loadable({
    loader: () => import('../../pages/CreditAppPage'),
    loading: Loader,
});

const HomePage = Loadable({
    loader: () => import('../../pages/HomePage'),
    loading: Loader,
});

const CustomerOrders = Loadable({
    loader: () => import('../../pages/CustomerOrders'),
    loading: Loader,
})

const QuoteResultsMain = Loadable({
	loader: () => import('../../pages/QuoteResults/Full'),
	loading: Loader,
});

const Shipment = Loadable({
    loader: () => import('../../pages/Shipment'),
    loading: Loader,
})

const ShipmentProcessing = Loadable({
    loader: () => import('../../pages/ShipmentProcessing'),
    loading: Loader,
})
const TlShipmentProcessing = Loadable({
    loader: () => import('../../pages/TlShipmentProcessing'),
    loading: Loader,
})

const GetQuote = Loadable({
    loader: () => import('../../pages/GetQuote'),
    loading: Loader,
})

const Rewards = Loadable({
    loader: () => import('../../pages/Rewards'),
    loading: Loader,
})

const ltlFreightshipping = Loadable({
    loader: () => import('../../pages/ltlFreightshipping'),
    loading: Loader
})

const ShipmentDetails = Loadable({
    loader: () => import('../../pages/ShipmentDetails'),
    loading: Loader,
})

const TemplateResolver = Loadable({
    loader: () => import('../../pages/QuoteResults/TemplateResolver'),
    loading: Loader,
})

const InvoicesReceipts = Loadable({
    loader: () => import('../../pages/InvoicesReceiptsPage'),
    loading: Loader,
})

const Unsubscribe = Loadable({
    loader: () => import('../../pages/Unsubscribe'),
    loading: Loader,
})

class CustomerHomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            quoteLoading: false,
        }
    }

    componentWillMount() {
        this.props.getMasterData();
        //this.props.sendInfoToGA(this.props.history);

        if (this.props.user) {
            let lastPartPath = lodash.last(window.location.pathname.split('/'));
            if (lastPartPath === 'view-quote') {
                const urlSearchParams = new URLSearchParams(this.props.location.search);
                let shipmentUuid = urlSearchParams.get('shipmentUuid');
                if (!shipmentUuid) {
                    return;
                }

                this.props.getCurrentShipment(this.props.currentLanguage, this.props.dictionaries, {shipmentUuid: shipmentUuid});
                this.setState({
                    quoteLoading: true
                });

                return;
            }

            const isHash = new RegExp('^[a-f0-9]{32}$');

            if (isHash.test(lastPartPath)) {
                this.props.getDocumentInfoByHash(lastPartPath).then(() => {
                    this.props.history.push(`/customer/shipment-details?shipmentUuid=${this.props.documentInfo.shipmentUuid}`);
                });
            }
        }
    }


    // view quote
    viewQuote = (quote) => {
        this.props.viewQuote(
            this.props.currentLanguage,
            this.props.dictionaries,
            quote,
            this.props.history,
            this.getRateQuery(quote),
            this.props.currentLanguage
        )
    }

    // prepare rateQuery payload
    getRateQuery = (quote) => {
        let self = this;
        let rateQuery = {
            items: [],
            manualShipment: false,
            shippingFromPostalCode: lodash.get(quote, 'shipmentFromZip'),
            shippingToPostalCode: lodash.get(quote, 'shipmentToZip'),
            originFacilityType: lodash.get(quote, 'shipmentFromLoadingFacilityId.id', ''),
            destinationFacilityType: lodash.get(quote, 'shipmentToLoadingFacilityId.id', ''),
            originCity: lodash.get(quote, 'shipmentFromCity'),
            originStateId: lodash.get(quote, 'shipmentFromStateId.id', ''),
            originState: lodash.get(quote, 'shipmentFromState'),
            originCountryId: lodash.get(quote, 'shipmentFromCountryId.id', ''),
            destinationCountryId: lodash.get(quote, 'shipmentToCountryId.id', ''),
            destinationState: lodash.get(quote, 'shipmentToState'),
            destinationStateId: lodash.get(quote, 'shipmentToStateId.id', ''),
            destinationCity: lodash.get(quote, 'shipmentToCity'),
        }

        if (quote.accessorials && Array.isArray(quote.accessorials)) {
            (quote.accessorials || []).map((z, index) => {
                // this.props.accessorials.find(x => x.id == index)
                if (self.props.accessorials.find(x => x.id == z.id).accLocationType == "1") {
                    rateQuery['OAccessorial_' + self.props.accessorials.find(x => x.id == z.id).accCode] = true
                }
                if (self.props.accessorials.find(x => x.id == z.id).accLocationType == "2") {
                    rateQuery['DAccessorial_' + self.props.accessorials.find(x => x.id == z.id).accCode] = true
                }
                if (self.props.accessorials.find(x => x.id == z.id).accLocationType == "3") {
                    rateQuery['Extra_' + self.props.accessorials.find(x => x.id == z.id).accCode] = true
                }
            })
        }

        return rateQuery
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.history.location.pathname === '/customer/view-quote') {
            if ( JSON.stringify(prevProps.shipmentState) !== JSON.stringify(this.props.shipmentState) && this.props.shipmentState ){
                this.viewQuote(this.props.shipmentState);
            }
            return;
        }

        if (prevState.quoteLoading === true ){
            this.setState({
                quoteLoading: false
            })
        }
    }

    get isSidebarEnabled() {
      if (this.props.location.pathname.includes('credit-app')) {
        return true;
      }
      const urls = ['/customer', '/customer/','/customer/dashboard', '/customer/booking', '/customer/saved-quotes', '/customer/rewards'];
      const result = urls.includes(this.props.location.pathname);
      if (!result && this.props.sideNavOpen != false) {
        this.props.toggleSideNav(false);
      }
      return result;
    }

    get marginClasses() {
      if (this.isSidebarEnabled) {
        return this.props.sideNavOpen ? ' sideNavOpen' : ' sideNavHidden';
      }
      return '';
    }

    render() {
        return (
            <div className="page">
                {this.props.user &&
                    <CustomerHeader history={this.props.history} sideNavOpen={this.props.sideNavOpen} isSidebarEnabled={this.isSidebarEnabled} toggleSideNav={this.props.toggleSideNav} location={this.props.location} />
                }
                {this.isSidebarEnabled &&
                <SideNavbar history={this.props.history} />}
                <div className={"main-panel" + this.marginClasses}>
                    {/*<Route exact path={`/customer/dashboard`} component={DashboardPage}/>*/}
                    <Route path={`/customer/credit-app`} render={(routeProps) => <CreditAppPage {...routeProps} />} />
                    <Route exact path={`/customer`} component={DashboardPage}/>
                    <Route exact path={`/customer/ftl`}
                           render={(routeProps) => <CustomerPage {...routeProps} accountInfo={this.props.accountInfo} history={this.props.history}/>}/>
                    <Route exact path={`/customer/home`} component={HomePage}/>
                    <Route exact path={`/customer/ltlFreightshipping`} component={ltlFreightshipping}/>
                    <Route exact path={`/customer/account-settings`} component={AccountSettings}/>
                    <Route exact path={`/customer/shipment`} component={ShipmentProcessing}/>
                    <Route exact path={`/customer/tl-shipment`} component={TlShipmentProcessing}/>
                    <Route exact path={`/customer/booking`}
                           render={(routeProps) => <CustomerOrders {...routeProps} orderType={'Booking'}/>}
                    />
                    <Route exact path={`/customer/saved-quotes`}
                           render={(routeProps) => <CustomerOrders {...routeProps} orderType={'Quote'}/>}
                    />
                    <Route exact
                           path={`/customer/freight-quote`}
                           render={(routeProps) => <GetQuote {...routeProps}/>}
                    />
                    <Route exact path={`/customer/shipment-details`} component={ShipmentDetails}/>
                    <Route exact path={`/customer/rewards`} component={Rewards}/>

                    <Route exact path={`/customer/quote-results/res`} component={TemplateResolver}/>
                    <Route exact path={`/customer/quote-results/c-110`} component={TemplateResolver}/>
                    <Route exact path={`/customer/quote-results/c-1`} component={TemplateResolver}/>
                    <Route exact path={`/customer/quote-results/c-10`} component={TemplateResolver}/>

                    <Route exact path='/customer/single-rate' component={SingleRate}/>
                    <Route exact path='/customer/single-rate-v2' component={SingleRate} />
                    <Route exact path={`/customer/quote-results`} component={QuoteResultsMain}/>
                    <Route exact path={`/customer/invoices`}
                           render={(routeProps) => <InvoicesReceipts {...routeProps} orderType={'Invoices'}/>}
                    />
                    <Route exact path={`/unsubscribe`} component={Unsubscribe}/>
                </div>
                <CustomerFooter history={this.props.history} classes={this.marginClasses}/>
                {this.state.quoteLoading && <Loader/>}
                {this.props.loading && <Loader/>}
                {this.props.documentInfoLoading && <Loader/>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: (utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken')) || state.login.accessToken,
    documentInfo: state.shipment.documentInfo,
    documentInfoLoading: state.shipment.documentInfoLoading,
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    accessorials: state.common.masterData ? state.common.masterData.accessorialList||[]:[],
    accountInfo: state.accountInfo.customerState,
    sideNavOpen: state.common.sideNavOpen,
    shipmentState: state.shipment.shipmentState,
    ratingRequestLock: state.accountInfo.customerState.preference
      ? state.accountInfo.customerState.preference.ratingRequestLock
      : null
});

const mapDispatchToProps = (dispatch) => ({
    getMasterData: () => {
        commonService.getCustomerMasterData(dispatch)
        commonService.getShipmentMasterData(dispatch)
        commonService.getLoadingFacilityType(dispatch)
        customerService.getTermsAndConditions(dispatch)
        accountInfoService.getAccountInfo(dispatch, '', {}, {id: utilCommon.getSession('userUuid')})
        accountInfoService.getTermsAndConditions(dispatch, '', {}, {})
    },
    getDocumentInfoByHash: (hash) => {
        return shipmentService.getDocumentInfoByHash(dispatch, {hash: hash});
    },
    viewQuote: (currentLanguage, dictionaries, inputDTO, history, rateQuery, lang) => {
        dispatch(shipmentService.viewQuote(currentLanguage, dictionaries, inputDTO, history, rateQuery));
        customerService.getARPaymentOptions(dispatch, lang, dictionaries, {
            customerUuid: inputDTO.customerUuid.id,
            permFlag: true
        });
    },
    toggleSideNav: (flag) => dispatch(toggleSideNav(flag)),
    getCurrentShipment: (currentLanguage, dictionaries, data) => {
        customerService.getCurrentShipment(dispatch, currentLanguage, dictionaries, data, true)
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHomePage);

