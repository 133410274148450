// @flow
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import service from '../../services/creditAppService';

const REDUCER = 'creditApp';
const REQUEST_GET = 'creditApp/request_get';
const REQUEST_UPDATE = 'creditApp/request_update';

export const IDLE = 0;
export const PENDING = 1;
export const FAILED = 2;

const initialState = {
    app: {},
    loading: IDLE,
    currentRequestId: undefined,
    errors: null,
    fetch_status: IDLE,
    update_status: IDLE,
};

export const fetch = createAsyncThunk(
    REQUEST_GET,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.get();
            const {success = true, message = ''} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(message);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)

export const create = createAsyncThunk(
    REQUEST_UPDATE,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.create(inputDTO);
            const {success = true, message = ''} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(message);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)

export const update = createAsyncThunk(
    REQUEST_UPDATE,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.update(inputDTO);
            const {success = true, message = '', errors = {}} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(errors);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)


const objSlice = createSlice({
    name: REDUCER,
    initialState: initialState,
    reducers: {
        setStep(state, action) {
            state.app.step = action.payload;
        },
        prevStep(state, action) {
            state.app.step--;
        },
        nextStep(state, action) {
            state.app.step++;
        },
        setError(state, action) {
            state.app.error = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state, action) => {
                state.loading = PENDING
                state.fetch_status = PENDING
                state.currentRequestId = action.meta.requestId
                state.errors = null;
            })
            .addCase(update.pending, (state, action) => {
                state.loading = PENDING
                state.update_status = PENDING
                state.currentRequestId = action.meta.requestId
                state.errors = null;
            })
            .addCase(fetch.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = IDLE
                    state.fetch_status = IDLE
                    state.currentRequestId = undefined
                    Object.assign(state.app, action.payload);
                    // state.app.step = 2;
                }
            })
            .addCase(update.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = IDLE
                    state.update_status = IDLE
                    state.currentRequestId = undefined
                    Object.assign(state.app, action.payload);
                    // state.app.step = 2;
                }
            })
            .addCase(fetch.rejected, (state, action) => {
                const { requestId } = action.meta
                // console.log('action',action)
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = FAILED
                    state.fetch_status = FAILED
                    state.errors = action.payload
                    state.currentRequestId = undefined
                }
            })
            .addCase(update.rejected, (state, action) => {
                const { requestId } = action.meta
                // console.log('action',action)
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = FAILED
                    state.update_status = FAILED
                    state.errors = action.payload
                    state.currentRequestId = undefined
                }
            })
    },
})
export const { prevStep, nextStep, setStep, setError } = objSlice.actions;
export default objSlice.reducer;
