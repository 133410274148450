import * as type from './actionTypes';

export const setDocuments = (documents,totalDocumentCount) => ({
  type: type.SET_DOCUMENTS,
  documents,
  totalDocumentCount
})

export const clearDocuments = () => ({
  type: type.CLEAR_DOCUMENTS
})

export const setBolStatus = (shipmentUuid, status) => ({
  type: type.SET_BOL_STATUS,
  shipmentUuid,
  status
});

export const setAllBolStatus = (shipments, status) => ({
  type: type.SET_ALL_BOL_STATUS,
  shipments: shipments,
  status: status
});

export const clearTrackingSearch = () => ({
  type: type.SET_TRACKING_INFO_SEARCH,
  trackingInfoFound: true
});

export const setTrackingSearchStatus = (status) => ({
  type: type.SET_TRACKING_INFO_SEARCH,
  trackingInfoFound: status
});

export const setDocumentInfo = (info) => ({
  type: type.SET_DOCUMENT_INFO,
  documentInfo: info
});

export const setDocumentInfoLoader = (flag) => ({
  type: type.SET_DOCUMENT_INFO_LOADER,
  documentInfoLoading: flag
});

export const setShInfo = (info) => ({
  type: type.SET_SH_INFO,
  shInfo: info
});

export const setCustomerOrders = ({orders, totalOrdersCount, orderType}) => ({
  type: type.SET_CUSTOMER_ORDERS,
  orders,
  totalOrdersCount,
  orderType
});

export const setIsTransactionsInProcess = (value) => ({
  type: type.SET_IS_TRANSACTIONS_IN_PROCESS,
  isTransactionsInProcess: value
})


export const setProductImages = (productImages) => ({
  type: type.SET_PRODUCT_IMAGES,
  productImages: productImages
});

export const setViewImage = (viewImage) => ({
  type: type.SET_VIEW_IMAGE,
  viewImage: viewImage
});