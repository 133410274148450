import React from 'react';

export default class Info extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {items} = this.props;

        return (
            <div className="info-section">
                <div className="container">
                    <div className="header">
                        <h2><span className="highlighted">Exclusive Benefits</span> Included with Your FreightCenter Rate.</h2>
                        <h6>Shipping benefits you won’t find anywhere else.</h6>
                    </div>
                    <div className="content">
                        {
                            items.slice(0, 3).map( item =>
                                <div className="item">
                                    <div className="picture">
                                        <img src={item.img} srcSet={item.img2x} alt={item.imgAlt}/>
                                    </div>
                                    <div className="info">
                                        <p className="title">{item.title}</p>
                                        <p className="sub-title">{item.subtitle}</p>
                                        <p className="description">{item.description}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
