import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CustomRadioButton from '../atoms/CustomRadioButton';
import Button from '@material-ui/core/Button';

const validate = (formProps) => {
    const labels = ['Customer shipping frequency'];
    const errors = {};
    const requiredFields = ['customerShippingFrequency'];

    requiredFields.map((x,i) => {
        if (!formProps[x]) {
            errors[x] = labels[i] + ' is a required field';
        }
        if (formProps[x] && formProps[x].length === 0) {
            errors[x] = labels[i] + ' is a required field';
        }
    });

    return errors;
}

export class ShippingFrequencyForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { handleSubmit, submitting, valid, pristine, callAPI } = this.props;

        return (
            <div>
                <div className="loginTitle">
                    {/*<h2>My FreightCenter</h2>*/}
                </div>
                <div className="loginOverlay">
                    <div className="loginOverlayinn relative">
                        <div className="rightColumn">
                            <div className="wraper">
                                <form name="CompanyShippingFrequencyForm" onSubmit={handleSubmit} className="shippingFrequencyForm">
                                    <div className="col100">
                                        <h1 className="shippingFrequencyFormCheckBoxTitle2">Shipping Frequency</h1>
                                        <h3 className="shippingFrequencyFormCheckBoxTitle">How often do you ship monthly?</h3>
                                        <div className="shippingFrequencyFormCheckBoxGroup boldlabel">
                                            <Field label="Shipping frequency"
                                                   options={this.props.shippingFrequencyOptions}
                                                   requiredClass name="customerShippingFrequency"
                                                   component={CustomRadioButton}/>
                                        </div>
                                    </div>
                                    <div className="col-grid1">
                                        <div className="col ">
                                            <div className="formAction">
                                                <Button type="submit" variant="contained" disabled={submitting || !valid || this.props.loading} className={(submitting || !valid || this.props.loading) ? "btnInactiveShippingForm" : "btnPrimaryShippingForm"}>
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => ({
    formValues:state.form['CompanyShippingFrequencyForm'] ? state.form['CompanyShippingFrequencyForm'].values:{},
    shippingFrequencyOptions:state.common.masterData ? state.common.masterData.shippingFrequencyList.filter(x=>x.id!=1) :[],
}), (dispatch) => ({}))(reduxForm({
    form: 'CompanyShippingFrequencyForm',
    validate
})(ShippingFrequencyForm));
