import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../atoms/Loader';
import FAQ from './SingleRate/FAQ';
import Info from "./SingleRate/Info";
import Header from "./SingleRate/Header";


class SingleRateV1 extends React.Component {
  render() {

    return (
        <>
          <Header/>

          <Info items={[
              {
                img: '/icons/truck-in-circle.png',
                img2x: '/icons/truck-in-circle@2x.png 2x',
                imgAlt: 'truckInCircle',
                title: 'Expert Assistance',
                subtitle: 'Worry-free shipping',
                description: 'When you ship with FreightCenter, you have a team of experts standing by ready to provide guidance and figure out the best, most affordable way to ship your items.'
              },
              {
                img: '/icons/lock-in-circle.png',
                img2x: '/icons/lock-in-circle@2x.png 2x',
                imgAlt: 'lockInCircle',
                title: 'Simple, Convenient, Fast',
                subtitle: 'As easy as 1-2-3',
                description: 'Your shipment can be picked up as early as tomorrow making it easy, convenient and fast. We’ll waive any cancellation fees if timeframe is not met.'
              },
              {
                img: '/icons/box-in-circle.png',
                img2x: '/icons/box-in-circle@2x.png 2x',
                imgAlt: 'boxInCircle',
                title: 'Save Money',
                subtitle: 'Don’t overpay',
                description: 'We search our large network of fully vetted, top rated carriers to secure significant savings for all your shipping needs.'
              },
            ]}
          />

          <FAQ />

          {this.props.loading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.common.loading || state.quote.loading,
});

export default connect(mapStateToProps, null)(SingleRateV1);
