// @flow
import * as type from './actionTypes';
// initial state
const initialState = {
	items:[]
};
// reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {


		case type.SET_ITEMS:
			return {
				...state,
				items: action.items
			};


		default:
			return state;
	}
};

export default reducer;
