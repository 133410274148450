// @flow
import * as type from './actionTypes';
import {SET_ACH_ACCOUNTS} from "./actionTypes";
// initial state
const initialState = {
	accountInfoUpdated: false,
	cardsFetched: false,
	cards: [],
	localCards: [],
	customerState: {},
	termsAndCondition: {},
	bookingTC:{},
	falveyTermsAndCondition: {},
	achAccounts: []
};
// reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.ACCOUNT_INFO_UPDATED:
			return {
				...state,
				accountInfoUpdated: action.accountInfoUpdated
			};
		case type.SET_CUSTOMER_CARDS:
			return {
				...state,
				cards: action.cards
			};

		case type.SET_LOCALCARDS:
			let currentCards = state.localCards
			currentCards = currentCards.concat([action.card])
			return {
				...state,
				localCards: currentCards
			}

		case type.CLEAR_LOCALCARDS:
			return {
				...state,
				localCards: []
			}

		case type.SET_CURRENT_CUSTOMER:
			return {
				...state,
				customerState: { ...(action.customerState), ...(action.customerState.address) }
			}
		case type.SET_TERMS_AND_CONDITIONS:
			return {
				...state,
				termsAndCondition: action.termsAndCondition,
				bookingTC : action.termsAndCondition.fcTermConditionUuid ? action.termsAndCondition : state.bookingTC
			}
		case type.SET_FALVEY_TERMS_AND_CONDITIONS:
			return {
				...state,
				falveyTermsAndCondition: action.falveyTermsAndCondition,
			}
		case type.CARDS_FETCHED:
			return {
				...state,
				cardsFetched: action.cardsFetched,
			}
		case type.SET_PAYMENTS_PROFILE:
			return {
				...state,
				paymentsProfile: action.paymentsList,
			}

		case type.SET_ACH_ACCOUNTS:
			return {
				...state,
				achAccounts: action.achAccounts
			}

		case type.SET_CUSTOMER_STORE_CREDIT:
			return {
				...state,
				storeCredit: action.storeCredit,
			}

		default:
			return state;
	}
};

export default reducer;
