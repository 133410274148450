const styles = theme => (
  {
    paper: {
      width: 750,
      height: 375,
      position: 'relative',
      overflow: 'visible',
      [theme.breakpoints.down('md')]: {
        width: 400
      },
    },
    contentWrapper: {
      textAlign: 'center',
      padding: '20px 40px',
      width: 450,
      height: 375,
    },
    image: {
      width: '100%',
      height: '100%',
    },
    title: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '42px',
      color: 'black',
    },
    subTitle: {
      fontSize: 22,
      lineHeight: '32px',
      color: 'black',
    },
    text: {
      fontSize: 18,
      color: 'rgb(78, 78, 78)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    closeBlock: {
      cursor: 'pointer',
      position: 'absolute',
      lineHeight: '60px',
      textAlign: 'center',
      right: '-15px',
      top: '-20px',
      color: '#6a6a6a',
      height: 42,
      width: 42,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      background: '#f0f0f0',
      fontSize: 44,
      padding: 0,
      margin: '0 auto',
      transform: 'rotate(360deg)'
    },
    imageWrapper: {
      width: 300,
      height: 375,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
    }
  }
)

export default styles
