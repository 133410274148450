import {commonColors, dropdownDefaultStyles, quoteInputTextStyles} from "../../../assets/jss/commonStyles";

const sectionStyles = {
    border: `1px solid ${commonColors.greyBorder}`,
    minHeight: 90
}

const styles = theme => ({
    headerButton: {
        borderRadius: 20,
        width: 100,
        textTransform: 'none',
        backgroundColor: commonColors.orange,
        color: 'white',
        fontWeight: 600,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: commonColors.orange
        }
    },
    submitButton: {
        width: 'calc(100% - 8px)',
        margin: '0 4px',
        minWidth: '170px'
    },
    header: {
        backgroundColor: '#5c7b99',
        height: 60
    },
    headerText: {
        color: 'white',
        marginRight: 10
    },
    titleContainer: {
        padding: 0,
        marginBottom: 16
    },
    contentTitleText: {
        fontSize: 22,
        textAlign: 'center',
        marginBottom: 24,
        fontWeight: 550,
        color: 'black',
        paddingTop: 8
    },
    contentTitleText2: {
        fontSize: 20,
        textAlign: 'center',
        marginBottom: 24,
        fontWeight: 550,
        color: 'black',
        paddingTop: 8
    },
    customerTypeContainer: {
        marginBottom: 20,
    },
    customerTypeLeftSection: {
        ...sectionStyles,
        borderTopLeftRadius: 9,
        borderBottomLeftRadius: 9,
        borderRight: 'none',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '9px 9px 0px 0px',
            borderRight: `1px solid ${commonColors.greyBorder}`,
            borderBottom: 'none'
        }
    },
    customerTypeRightSection: {
        ...sectionStyles,
        borderTopRightRadius: 9,
        borderBottomRightRadius: 9,
        borderLeft: 'none',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0px 0px 9px 9px',
            borderLeft: `1px solid ${commonColors.greyBorder}`,
            borderTop: 'none'
        }
    },
    customerTypeSelectedSection: {
        backgroundColor: '#5c7b99',
        color: 'white'
    },
    customerTypeTitleText: {
        fontSize: 16,
        fontWeight: 550,
        marginBottom: 8
    },
    customerTypeSubText: {
        fontSize: 14,
        fontWeight: 500
    },
    customerTypeDescription: {
        fontSize: 18,
        color: '#5c7b99',
        fontWeight: 550
    },
    customerTypeIcon: {
        marginRight: 5,
        fontSize: 30
    },
    input_input: {
        borderRadius: 40,
        border: `1px solid ${commonColors.greyBorder}`,
        height: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },
    inputLabelText: {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 8
    },
    mb16: {
        marginBottom: 16
    },
    contentPadding: {
        padding: '0 40px'
    },
    emailDescription: {
        fontSize: 12,
        fontWeight: 400
    },
    inputMargin: {
        marginBottom: 5
    },
    inputContainer: {
        marginBottom: 16
    },
    passwordEndComponent: {
        position: 'absolute',
        right: 0,
        top: 3
    },
    forgotTitleText: {
        fontSize: 26,
        fontWeight: 600,
        color: 'black'
    },
    customerTypeRadioButtonLeft: {
        '&$checked': {
            color: 'white !important'
        },
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-20px',
            bottom: '-20px',
            left: '-25px',
            right: '-195px',
        }
    },
    customerTypeRadioButtonRight: {
        '&$checked': {
            color: 'white !important'
        },
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-20px',
            bottom: '-20px',
            left: '-20px',
            right: '-200px',
        }
    },
    checked: {},
    termsAndConditionsText: {
        fontSize: 10,
        paddingTop: 58,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    contentLeftColumn: {
        paddingRight: 32,
        marginBottom: 10,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 90,
            left: 265,
            width: 1,
            height: 400,
            backgroundColor: `${commonColors.greyBorder}`,
            [theme.breakpoints.down('xs')]: {
                width: 0,
                height: 0,
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
        },
    },
    contentRightColumn: {
        paddingLeft: 40,
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        },
    },
    inputDiv: {
        height: '40px',
        border: `1px solid ${commonColors.greyBorder}`,
        position: 'relative',
        borderRadius: '24px',
        backgroundColor: '#e0e0e0',
        padding: '12px'
    },
    logo: {
        height: 65,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    itemText: {
        fontSize: 12,
        padding: 3,
        lineHeight: '1.5',
        height: 46,
    },
    leftArrow: {
        position: 'absolute',
        top: '20px',
        fontSize: '18px',
        color: 'white',
        cursor: 'pointer'
    },
    recoverPasswordEmailField: {
        width: 300,
        [theme.breakpoints.down('xs')]: {
            width: 229
        },
    },
    select: {
        boxSizing: 'border-box',
        padding: '0px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 12,
        border: '1px solid #ced4da',
        borderRadius: 40,
        height: 40,
        fontWeight: 100,
        ...dropdownDefaultStyles
    },
    root: {
        height: '40px',
        '& $select:focus': {
            background: 'none',
            borderRadius: 40,
        },
        ...quoteInputTextStyles,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    },
});

export default styles;
