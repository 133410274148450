import * as type from './actionTypes';
import { SET_POP_UP_CONTENT_LIST } from './actionTypes'


export const setMessage = (messageType,content) => ({
  type: type.SET_MESSAGE,
  messageType,
  content
});

export const setLoader = (flag) => ({
  type: type.SET_LOADER,
  flag
});

export const setRequiredCustomerData = (flag) => ({
  type: type.SET_REQUIRED_CUSTOMER_DATA,
  flag
});

export const changeSignUpForm = (flag) => ({
  type: type.CHANGE_SIGN_UP_FORM,
  flag
});

export const displayPasswordInsecureWarning = (flag) => ({
  type: type.DISPLAY_PASSWORD_INSECURE_WARNING,
  flag
});


export const changeSignUpMethod = (method) => ({
  type: type.CHANGE_SIGN_UP_METHOD,
  method
});

export const changeDisplayShippingFrequencyForm = (flag) => ({
  type: type.CHANGE_DISPLAY_SHIPPING_FREQUENCY_FORM,
  flag
});

export const saveSignUpRequest = (request) => ({
  type: type.SAVE_SIGN_UP_REQUEST,
  request
});

export const changeSocialRequiredData = (flag) => ({
  type: type.CHANGE_SOCIAL_REQUIRED_DATA,
  flag
});

export const changeIsCommercial = (flag) => ({
  type: type.CHANGE_IS_COMMERCIAL,
  flag
});

export const toggleSideNav = (flag) => ({
  type: type.TOGGLE_SIDEBAR,
  flag
});

export const setPopUpContentList = (popUpContentList) => ({
  type: SET_POP_UP_CONTENT_LIST,
  popUpContentList
});
