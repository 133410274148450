import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import * as actions from '../store/login/actions'
import * as type from '../store/login/actionTypes'
import * as commonType from '../store/common/actionTypes'
import * as commonActions from '../store/common/actions'
import * as utilCommon from '../helpers/utilCommon'
import {TO_MANY_LOGIN_ATTEMPTS_ERROR_CODE} from "../helpers/enums";
import { getFreightQuotePath } from '../helpers/utilCommon';
import {Analytics} from '@aws-amplify/analytics';
import { startSubmit, stopSubmit } from 'redux-form';
import lodash from "lodash";

// var leadsUserData= {};
// Socket for getting online flag for logged in users

setInterval(() => {

	if (!utilCommon.getSession('accessToken')) {
		if (socket) {
			socket.close();
		}
		return;
	}

	// Create a new WebSocket.
	var socket = new WebSocket((window.location.protocol == 'https:' ? 'wss://' : 'ws://') + process.env.REACT_APP_SOCKET_URL.replace('http://', '').replace('https://', '') + '/v1/login-ping', [utilCommon.getSession('accessToken')]);

	// Handle any errors that occur.
	socket.onerror = function (error) {
		console.log('WebSocket Error: ', error);
	};

	// Show a connected message when the WebSocket is opened.
	socket.onopen = function (event) {
		//console.log('WebSocket Open: ', event);
		socket.send(JSON.stringify({}))
	};

	// Handle messages sent by the server.
	socket.onmessage = function (event) {
		var message = event.data;
		//console.log('OnMessage', JSON.parse(event.data))

	};

}, 10000)

// Permissions interceptor
axios.interceptors.response.use(function (response) {
  if (response && response.data && response.data.message && response.data.message == 1028) {
    let flag = false;
    if(utilCommon.getSession('accessToken')){
      flag = true;
    }
    utilCommon.removeSession('accessToken')
	utilCommon.removeSession('refreshToken')
    if(flag){
      login(Window['dispatch'], {}, {}, {
        emailId: utilCommon.getCookie('username'),
        password: utilCommon.getCookie('password')
      },false,true);
    } else {
      if (sessionStorage.getItem('quoteSearchString')) {
        window.location.replace(getFreightQuotePath(sessionStorage.getItem('quoteSearchString')));
      }
    }
    Window['dispatch'](commonActions.setLoader(true))
  } else if (response && response.data && (response.data.message && response.data.message == 401) || (response.data.errors && response.data.errors.includes && response.data.errors.includes(401))) {
    const refreshToken = utilCommon.getSession('refreshToken');
    if (refreshToken) {
      login(Window['dispatch'], {}, {}, {
        refreshToken: refreshToken
      },true);
    }
  } else if (response && response.data && response.data.message && response.data.message == 1030) {
    utilCommon.removeSession('refreshToken')
    utilCommon.removeSession('accessToken')
    utilCommon.clearSession()
    if (sessionStorage.getItem('quoteSearchString')) {
	  window.location.replace(getFreightQuotePath((sessionStorage.getItem('quoteSearchString') || '')));
    } else if (window.location.pathname !== '/home') {
      window.location.replace('/home')
    }
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

//login
export const login = (dispatch, lang, dictionary, inputDTO, guestSignUp, refreshFlag, forceRedirect = false) => {
	dispatch(startSubmit('LoginForm'));
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'login');

	dispatch(commonActions.setLoader(true))
	return axios.post(url, inputDTO, params)
		.then((response) => {
			let redirectUrl = sessionStorage.getItem('loginRedirect');

			dispatch(commonActions.setLoader(false))

			if (response.data.success) {
				if (!guestSignUp && Number(lodash.get(response, 'data.content.responseData.tmpPasswordStatus')) === 1) {
					sessionStorage.setItem('resetPasswordEmail', response.data.content.responseData.emailId);
					sessionStorage.setItem('tmpPasswordStatus', 1);
					window.location.href = '/resetpassword/updateTempPassword';
					return;
				}
				utilCommon.setSession('fname', response.data.content.responseData.firstName)
				utilCommon.setSession('lname', response.data.content.responseData.lastName)
				utilCommon.setSession('userId', response.data.content.responseData.userId)
				utilCommon.setSession('userUuid', response.data.content.responseData.userUuid)
				utilCommon.setSession('username', response.data.content.responseData.firstName)
				utilCommon.setSession('userEmail', response.data.content.responseData.emailId)
				utilCommon.setSession('cellPhone', response.data.content.responseData.cellPhone)
				utilCommon.setSession('company', response.data.content.responseData.customerCompany)
				utilCommon.setSession('accessToken', response.data.content.responseData.accessToken)

				if(!refreshFlag){
					utilCommon.setSession('refreshToken', response.data.content.responseData.refreshToken)
				}

				dispatch(actions.setCredentials(response.data.content.responseData.accessToken,
					response.data.content.responseData.refreshToken, response.data.content.responseData.email_id))

				if (!guestSignUp) {
					if (response.data.content.responseData.customerCompany && !lodash.isUndefined(Window['history'])) {
						Window['history'].push('/customer');
					}

          			if (refreshFlag){
            			window.location.reload()
          			}
        		} else {
					utilCommon.setSession('guestSignUp', true)
					dispatch({ type: type.ALLOW_GET_QUOTE, allowGuest: true })
				}

				if (redirectUrl) {
					sessionStorage.removeItem('loginRedirect');

					if (shouldRedirect(redirectUrl)) {
						window.location.href = redirectUrl;
					}
				}

				dispatch(stopSubmit('LoginForm'));
			} else {
				response.data.errors.map((error) => {
					if (Number(error) !== TO_MANY_LOGIN_ATTEMPTS_ERROR_CODE) {
						utilCommon.showNotification(error, dictionary, lang)
					} else {
						dispatch(actions.setToManyLoginAttempts(true))
					}
				})
				utilCommon.clearSession()
			}

			dispatch(stopSubmit('LoginForm'));
			return response;
		})
		.catch((err) => {
      		utilCommon.clearSession()
			dispatch(commonActions.setLoader(false))
			dispatch(stopSubmit('LoginForm'));
		});
};
//sent a email on forgot password
export const forgotPassword = (dispatch, lang, dictionary, inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'forgotPassword');
	dispatch(commonActions.setLoader(true))
	axios.post(url, inputDTO, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			if (response.data.success) {
				dispatch({ type: type.EMAIL_SENT, flag: true })
				dispatch({ type: type.RESET_FLAG })
				utilCommon.showNotification(response.data.message, dictionary, lang)
			} else {
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false))
		});
};
//reset password
export const resetPassword = (dispatch, lang, dictionary, inputDTO) => {
	const params = { headers: {} };
	const url = getApiUrl('login', 'resetPassword')
	dispatch(commonActions.setLoader(true))

	axios.post(url, inputDTO, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			sessionStorage.removeItem('resetPasswordEmail');
			sessionStorage.removeItem('tmpPasswordStatus');

			if (response.data.success) {
				utilCommon.showNotification(response.data.message, dictionary, lang)
				login(dispatch, lang, dictionary, {
					emailId: inputDTO.customerEmailId,
					password: inputDTO.password
				}, false).then(() => {
					Window['history'].push('/customer');
				})
			} else {
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false))
		});
};
//change password
export const changePassword = (dispatch, lang, dictionary, inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'changePassword');
	dispatch(commonActions.setLoader(true))
	axios.put(url, inputDTO, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			if (response.data.success) {
				dispatch({ type: type.PASSWORD_UPDATED, passwordUpdated: true })
				utilCommon.showNotification(response.data.message, dictionary, lang)
			} else {
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false))
		});
};

export const updateShippingFrequency = (dispatch, lang, dictionary, inputDTO, guestSignUp) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('login', 'updateShippingFrequency');

    dispatch(commonActions.setLoader(true));

    return axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.success) {
                login(dispatch, lang, dictionary, {
                    emailId: inputDTO.customerEmailId,
                    password: inputDTO.password
                }, guestSignUp);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang);
                });
            }

            return response;
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        });
}

// guest sign up
export const guestSignUp = (dispatch, lang, dictionary, inputDTO, guestSignUp) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'guestSignUp');
	dispatch(commonActions.setLoader(true))
	return axios.post(url, inputDTO, params)
		.then((response) => {
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			dispatch(commonActions.setLoader(false))
			if (response.data.success) {
				utilCommon.setSession('customerId', response.data.content.customerId);
				if (!inputDTO.isCommercial) {
					login(dispatch, lang, dictionary, {
						emailId: inputDTO.customerEmailId,
						password: inputDTO.password
					}, guestSignUp)
				}
			} else {
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
			return response;
		})
		.catch((err) => {
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			dispatch(commonActions.setLoader(false))
		});
};

export const linkSocialAccount = (dispatch, lang, dictionary, inputDTO, guestSignUp) => {
	const url = getApiUrl('login', 'linkSocialAccount');
	dispatch(commonActions.setLoader(true));
	const params = { headers: authHeader() };
	if (!inputDTO.email) {
		inputDTO.email = inputDTO.linkEmail;
	}
	axios.post(url, inputDTO, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.success) {
				login(dispatch, lang, dictionary, {
					emailId: inputDTO.email,
					password: inputDTO.password
				}, guestSignUp)
			} else {
				console.log('err',response.data);
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
		})
		.catch((err) => {
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			dispatch(commonActions.setLoader(false))
		});
}

export const facebookSignIn = (dispatch, lang, dictionary, facebookResponse, guestSignUp = false) => {
	const url = getApiUrl('login', 'guestSignUpFacebook');
	dispatch(commonActions.setLoader(true));
	const params = { headers: authHeader() };
	axios.post(url, facebookResponse, params)
		.then((response) => {
			let redirectUrl = sessionStorage.getItem('loginRedirect');
			sessionStorage.removeItem('loginRedirect');
			dispatch(commonActions.setLoader(false));
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			if (response.data.success) {
				utilCommon.setSession('accessToken', response.data.content.accessToken);
				utilCommon.setSession('fname', response.data.content.firstName);
				utilCommon.setSession('lname', response.data.content.lastName);
				utilCommon.setSession('userId', response.data.content.userId);
				utilCommon.setSession('userUuid', response.data.content.userUuid);
				utilCommon.setSession('username', response.data.content.firstName);
				utilCommon.setSession('userEmail', response.data.content.emailId || '');

				if (guestSignUp) {
					utilCommon.setSession('guestSignUp', true);
				}

				dispatch(commonActions.setRequiredCustomerData(response.data.content.social_required_data))
				dispatch({type: commonType.CHANGE_SOCIAL_REQUIRED_DATA, flag: response.data.content.social_required_data});
				dispatch({type: commonType.CHANGE_IS_COMMERCIAL, flag: response.data.content.customerType == 2});

				dispatch({ type: type.ALLOW_GET_QUOTE, allowGuest: true });

				dispatch(
					actions.setCredentials(
						response.data.content.accessToken,
						response.data.content.refreshToken,
						response.data.content.emailId
					)
				);

				if (redirectUrl) {
					window.location.href = redirectUrl;
				}
			} else {
				response.data.errors.map((error) => {
					if (error == 3253) {
						dispatch({
							type: commonType.SET_LINK_DATA,
							linkEmail: facebookResponse.email,
							linkSocialKey: facebookResponse.id,
							linkSocialType: 'facebook'
						});
					} else {
						utilCommon.showNotification(error, dictionary, lang)
					}
				});
				utilCommon.clearSession();
			}
		})
		.catch((err) => {
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method: ''});
			utilCommon.clearSession();
			dispatch(commonActions.setLoader(false));
		});
}

// logs out a new customer
export const logout = (dispatch, lang, dictionary, history) => {
	// utilCommon.clearSession()
	// 	dispatch({ type : 'login/SET_CREDENTIALS' , token : null , email : null, refreshToken : null })
	// 	history.push('/home/login')
	// 	window.location.reload()
  sessionStorage.removeItem('quoteQuery');
  sessionStorage.removeItem('quotePayload');
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'logout');
	dispatch(commonActions.setLoader(true))
	axios.post(url,{}, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			utilCommon.clearSession()
				dispatch({ type : 'login/SET_CREDENTIALS' , token : null , email : null, refreshToken : null })
			history.push('/home/login')
			window.location.reload()
			/*if (response.data.success) {
				utilCommon.clearSession()
				dispatch({ type : 'login/SET_CREDENTIALS' , token : null , email : null, refreshToken : null })
				history.push('/home/login')
				window.location.reload()
			} else {
				// response.data.errors.map((error) => {
					utilCommon.showNotification('Unable to logout', dictionary, lang)
				// })
			}*/
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false))
		});
};


export const resetToken = (dispatch, lang, dictionary, inputDTO,history) => {
	const params = { headers: authHeader() };
	let url = getApiUrl('login', 'resetToken');
	url = url + '?activationCode='+inputDTO.activationCode
	dispatch(commonActions.setLoader(true))
	axios.get(url, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			if (!response.data.success) {
				history.push('/home/login',{redirectionFP:true})
				response.data.errors.map((error) => {
					utilCommon.showNotification(error, dictionary, lang)
				})
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false))
		});
};

export const hashInfo = (dispatch, lang, dictionary, hash) => {
	const params = {headers: {}};
	const data = {hash: hash};
	const url = getApiUrl('login', 'hashInfo');

	sessionStorage.removeItem('resetPasswordEmail')

	axios.post(url, data, params)
		.then((response) => {
			if (response.data.email) {
				sessionStorage.setItem('resetPasswordEmail', response.data.email)
			} else {
				utilCommon.showNotification('Activation code not found.', dictionary, lang, true)
			}
		});
};


// export const healthCheck = (dispatch, lang, dictionary, inputDTO) => {
// 	const params = { headers: authHeader() };
// 	let url = getApiUrl('login', 'healthCheck');
// 	dispatch(commonActions.setLoader(true))
// 	axios.get(url, params)
// 		.then((response) => {
// 			dispatch(commonActions.setLoader(false))
// 			if (response.data.success) {
// 				dispatch({ type: type.SET_HEALTH_CHECK, messageStatus: response.data.content })
// 			} else {
// 				response.data.errors.map((error) => {
// 					utilCommon.showNotification(error, dictionary, lang)
// 				})
// 			}
// 		})
// 		.catch((err) => {
// 			dispatch(commonActions.setLoader(false))
// 	});
// };


export const healthCheck = (dispatch, lang, dictionary, inputDTO) => {
	const params = { headers: authHeader() };
	dispatch(commonActions.setLoader(true))
	dispatch({ type: type.SET_HEALTH_CHECK, messageStatus: 200 })
};

export const shouldRedirect = (url) => {
	return !url.startsWith('/customer/ftl');
}


export default {
	login,
	linkSocialAccount,
	resetPassword,
	forgotPassword,
	changePassword,
	guestSignUp,
	facebookSignIn,
	logout,
	resetToken,
	healthCheck,
	updateShippingFrequency,
	hashInfo
};
