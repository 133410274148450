import * as crypto from 'crypto';
const JWT_REFRESH_ENCRYPTION_KEY = 'IcreonoercIIcreonoercIIcreonoerc';


    export const encrypt = (text)=> {

        let mykey = crypto.createCipher('aes-128-cbc', JWT_REFRESH_ENCRYPTION_KEY);
        let mystr = mykey.update(text, 'utf8', 'hex')
        mystr += mykey.final('hex');
        return mystr

    }




    export const decrypt = (text)=> {

        let mykey = crypto.createDecipher('aes-128-cbc', JWT_REFRESH_ENCRYPTION_KEY);
        var mystr = mykey.update(text, 'hex', 'utf8')
        mystr += mykey.final('utf8');
        return mystr

    }

