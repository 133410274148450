import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import * as commonActions from '../store/common/actions'
import * as actions from './../store/quote/actions'
import * as type from '../store/quote/actionTypes'
import utilCommon from '../helpers/utilCommon'
import { templates } from '../helpers/templates';
import lodash from "lodash";
import { setLoader } from './../store/quote/actions'

export const getQuotesMaster = (dispatch, lang, dictionaries) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'getFaciltyTypes');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FACILITY_TYPES, facilityTypes: response.data.content.facilityTypeAccessorialsList })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });

    const url1 = getApiUrl('quote', 'customerMaster');
    dispatch(commonActions.setLoader(true))
    axios.get(url1, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_MASTER_DATA, master: response.data.content.compositeCustomerDataList })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });

};


// get Products
export const getProducts = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    dispatch(commonActions.setLoader(true))
    const url = getApiUrl('quote', 'getProducts')
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CUSTOMER_PRODUCTS, products: response.data.content.customerProductList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// get Products
export const getAddresses = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    dispatch(commonActions.setLoader(true))
    const url = getApiUrl('customer', 'address')
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CUSTOMER_ADDRESS, addresses: response.data.content.customerAddressList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getQuoteResults = (lang, dictionaries, inputDTO) => (dispatch, getState) => {
    const params = { headers: authHeader() }
    const url = getApiUrl('quote', 'getQuoteResults')

    dispatch(actions.setQuoteResults({
        quoteResults: [],
        quoteRequestUuid: {},
        loading: true,
        progress: 0,
        progressBar: true
    }))

    const quoteResultsTemplate = lodash.get(getState().accountInfo, 'customerState.preference.quoteResultsTemplate', 'Full');
    const template = parseInt(lodash.get(getState().accountInfo, 'customerState.customerShippingFrequency.id', 1).toString());

    let filterResults = (results) => {
        let carriers = []
        let items = []

        results.forEach(item => {
            if (!carriers.includes(item.FCcarrierName)) {
                carriers.push(item.FCcarrierName)
                items.push(item)
            }
        })

        return items;
    }

    if (quoteResultsTemplate !== 'Full') {
        axios.post(url, inputDTO, params)
            .then((response) => {
                const quoteResults = {
                    quoteResults: response.data.content.responseData.rateQuotes,
                    quoteRequestUuid: {"Default": response.data.quoteRequestUuid},
                    template: response.data.content.responseData.template ? response.data.content.responseData.template : templates.full,
                    loading: false
                };

                dispatch(actions.setQuoteResults(quoteResults));
                templates.handleTemplateRoute(template, Window['history']);
            })
    } else {
        let counter = 0;

        const capacityProviderAccountGroups = ['A4', 'B4', 'C6', 'D10'];

        let results = {
            quoteResults: [],
            quoteRequestUuid: {},
            template: templates.full,
            loading: true,
            progress: 0,
            progressBar: true
        };

        capacityProviderAccountGroups.forEach(code => {
            let payload = {
                "capacityProviderAccountGroup": {
                    "code": code
                },
                ...inputDTO,
                "apiConfiguration": {
                    ...inputDTO.apiConfiguration,
                    "timeout": parseInt(code.replace(/\D/, ''))
                }
            }

            axios.post(url, payload, params)
                .then((response) => {
                    counter++;

                    results = {
                        quoteResults: filterResults(lodash.union(results.quoteResults, response.data.content.responseData.rateQuotes)),
                        quoteRequestUuid: {...results.quoteRequestUuid, [code]: response.data.quoteRequestUuid},
                        template: response.data.content.responseData.template ? response.data.content.responseData.template : templates.full,
                        loading: counter !== capacityProviderAccountGroups.length,
                        progress: counter / capacityProviderAccountGroups.length * 100,
                        progressBar: true
                    };
                })
              .catch(error => {
                  console.log('QUOTE FETCH ERROR', error)
                  counter++;
              })
        })

        const intervalId = setInterval(() => {
            dispatch(actions.setQuoteResults(results));

            if (counter === capacityProviderAccountGroups.length) {
                setTimeout(() => {
                    dispatch(actions.setQuoteResults({
                        ...results,
                        progressBar: false
                    }))
                }, 1000)

                clearInterval(intervalId)
                dispatch(setLoader(false))
            }
        }, 1000)

        templates.handleTemplateRoute(template, Window['history']);
    }
}


export const getCarriers = (dispatch, lang, dictionaries) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'getCarriers');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CARRIERS, carriers: response.data.content.carrierList })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });

};

export const createShipment = (dispatch, currentLanguage, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'createShipment');
    inputDTO = utilCommon.addGoogleAdsId(inputDTO);
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment created successfully', dictionary, currentLanguage)
                Window['history'].push('/customer/shipment',{shipmentUuid:response.data.content.shipmentUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, currentLanguage)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const fcCreateShipment = (dispatch, currentLanguage, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'fcCreateShipment');
    inputDTO = utilCommon.addGoogleAdsId(inputDTO);
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment created successfully', dictionary, currentLanguage)
                Window['history'].push('/customer/shipment',{shipmentUuid:response.data.content.shipmentUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, currentLanguage)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// rerun quote
export const reRunQuote = (dispatch, currentLanguage, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'reRunQuote');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment created successfully', dictionary, currentLanguage)
                Window['history'].push('/customer/shipment',{shipmentUuid:response.data.content.shipmentUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, currentLanguage)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// rerun quote
export const fcReRunQuote = (dispatch, currentLanguage, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('quote', 'fcReRunQuote');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Shipment created successfully', dictionary, currentLanguage)
                Window['history'].push('/customer/shipment',{shipmentUuid:response.data.content.shipmentUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, currentLanguage)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const createTLShipment = (dispatch, currentLanguage, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('quote', 'fcCreateTlShipment');

    dispatch(commonActions.setLoader(true))

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))

            if (response.data.success === true) {
                utilCommon.showNotification('Shipment created successfully', dictionary, currentLanguage)
                Window['history'].push('/customer/tl-shipment', {shipmentUuid: response.data.data.id})
            } else {
                if (response.data.errors.length) {
                    response.data.errors.map((error) => {
                        utilCommon.showNotification(error, dictionary, currentLanguage)
                    })
                } else {
                    utilCommon.showNotification(
                        'Something went wrong during create shipment',
                        dictionary,
                        currentLanguage,
                        true
                    );
                }
            }
        })
        .catch((err) => {
            utilCommon.showNotification(
                'Something went wrong during create shipment',
                dictionary,
                currentLanguage,
                true
            );
            dispatch(commonActions.setLoader(false));
        });
};

export default {
    getQuotesMaster,
    getQuoteResults,
    getProducts,
    createShipment,
    fcCreateShipment,
    getCarriers,
    reRunQuote,
    fcReRunQuote,
    getAddresses,
    createTLShipment
};


