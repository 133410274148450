import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import lodash from "lodash";

const create = (inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'creditApp');

	const failResponse = { success: false };
	return axios.post(url, inputDTO, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', true);
			const message = lodash.get(response.data, 'message', '');

			if (result) {
				response.data = lodash.get(response.data, 'dataLists[0]', {});
			}else{
				response.data = {...failResponse, ...{message}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};

const update = (inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'creditApp');

	const failResponse = { success: false };
	return axios.put(url, inputDTO, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', true);
			const message = lodash.get(response.data, 'message', '');
			const errors = lodash.get(response.data, 'errors', '');

			if (result) {
				response.data = lodash.get(response.data, 'dataLists[0]', {});
			}else{
				response.data = {...failResponse, ...{message}, ...{errors}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};

const get = () => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'creditApp');

	const failResponse = { success: false };
	return axios.get(url, params)
		.then((response) => {
			let result = lodash.get(response.data, 'success', false);
			const message = lodash.get(response.data, 'message', '');

			// if (!result) {
			// 	response.data = {...failResponse, ...{message}};
			// }else{
				response.data = lodash.get(response, 'data.dataLists[0]', {});
			// }

			return response;
		})
		.catch((err) => {
			return err;
		});
};



export default {
	create,
	update,
	get,
};
