import React, {Component} from "react";
import {Grid, withStyles} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, reduxForm} from "redux-form";
import {compose} from "redux";
import {connect} from "react-redux";
import styles from "../styles";
import Header from "../../../molecules/AuthV2/Header";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import cn from "classnames";
import customInputField from "../../../atoms/CustomInputField";
import utilCommon from "../../../../helpers/utilCommon";
import Loader from "../../../atoms/Loader";

const validate = formProps => {
    const errors = {}

    if (!formProps.email) {
        errors.email = 'Email address is a required field';
    } else if (!utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }

    return errors
}

class ForgotPasswordForm extends Component {
    componentDidMount() {
        this.props.changeDialogClass('mediumSmall')
    }

    render() {
        const {classes, switchForm, valid, submitting, handleSubmit, loading} = this.props;

        return (
            <form name="ForgotPasswordForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)}>
                <Header switchForm={switchForm} switchTo={'login'} text={'Already have an account?'} buttonText={'Sign In'}/>
                <DialogContent className={classes.contentPadding}>
                    <Grid container justify={'center'} alignItems={'center'} direction={'column'}>
                        <Grid item className={classes.mb16}>
                            <DialogContentText className={classes.forgotTitleText}>
                                Feeling a little forgetful ?
                            </DialogContentText>
                        </Grid>
                        <Grid item className={classes.mb16}>
                            <DialogContentText className={classes.customerTypeDescription}>
                                It happens to us all. No worries though, we've got you covered
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <DialogContentText>
                                Enter your email address
                            </DialogContentText>
                        </Grid>
                        <Grid item className={cn(classes.mb16, classes.recoverPasswordEmailField)}>
                            <Field
                                name={'email'}
                                type={'email'}
                                component={customInputField}
                                disableUnderline
                                classes={classes}
                            />
                        </Grid>
                        <Grid item className={classes.mb16}>
                            <Button disabled={!valid || submitting}
                                    className={cn(classes.headerButton, classes.submitButton)} type="submit"
                                    variant="contained">
                                Recover
                            </Button>
                        </Grid>
                        {loading && <Loader/>}
                    </Grid>
                </DialogContent>
            </form>
        );
    }
}

const component = compose(
    connect(state => ({
        loading: state.common.loading
    }), {}),
    reduxForm({
        form: 'ForgotPasswordForm',
        validate,
        enableReinitialize: true,
    }),
    withStyles(styles)
)(ForgotPasswordForm)

export default component;

