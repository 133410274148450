import React from 'react';
import {connect} from 'react-redux';
import Loader from '../../atoms/Loader';
import authService from '../../../services/authService';
import Dialog from '@material-ui/core/Dialog';
import * as type from '../../../store/login/actionTypes';
import * as commonType from '../../../store/common/actionTypes';
import lodash from "lodash";
import * as utilCommon from "../../../helpers/utilCommon";
import {
    FIRST_REQUESTED_QUOTE_TYPE_LTL,
    FIRST_REQUESTED_QUOTE_TYPE_TL,
    FIRST_REQUESTED_QUOTE_TYPE_UNDEFINED
} from "../../../helpers/constants";
import SignUpForm from "../../forms/AuthV2/SignUpForm";
import LoginForm from "../../forms/AuthV2/LoginForm";
import ManyLoginAttemptErrorSection from "../../pages/AuthSection/ManyLoginAttemptErrorSection/LoginSection";
import ForgotPasswordForm from "../../forms/AuthV2/ForgotPasswordForm";
import ReCAPTCHA from "react-google-recaptcha";
import {
    FORGOT_PASSWORD_SECTION,
    LOGIN_SECTION, ONE_TIME_SHIPMENT, ONE_TO_TEN_TIME_SHIPMENT,
    REGISTER_SECTION,
    SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS, TEN_PLUS_TIME_SHIPMENT
} from "../../../helpers/enums";
import {generatePass} from "../../../helpers/utilCommon";
import cn from "classnames";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

class GuestSignUpV2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            hideAuth: false,
            displayLink: false,
            ftlData: null,
            firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_UNDEFINED,
            showForm: lodash.get(this.props, 'form', 'register'),
            dialogClass: 'mediumSmall'
        }

        this.recaptchaRef = React.createRef();
    }

    changeDialogClass = (className) => {
        this.setState({ dialogClass: className });
    }

    componentWillMount() {
        if (lodash.has(this.props, 'location.search')) {
            if (utilCommon.isFtlQuote(this.props.location.search)) {
                this.setState(
                    {
                        ftlData: this.props.location.search,
                        firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_TL
                    }
                )
            } else if (utilCommon.isLtlQuote(lodash.get(this.props, 'redirect', ''))) {
                this.setState(
                    {
                        firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_LTL
                    }
                )
            }
        }

        let signUpRequest = sessionStorage.getItem('signUpRequest');

        if (signUpRequest) {
            this.props.changeSignUpForm(1);
            this.props.changeSignUpMethod('native');
            this.props.saveSignUpRequest(JSON.parse(signUpRequest));
        }
    }

    //allow guest user or not
    componentWillReceiveProps(nextProps, props) {
        if (nextProps.allowGuest != this.props.allowGuest && nextProps.allowGuest) {
            this.props.handleOpen(false)
            this.props.allowGuest()
        }

        if (nextProps.linkEmail) {
            this.setState({hideAuth: true, displayLink: true});
        }
    }

    handleOpen = () => {
        this.props.handleOpen(false)
        this.props.allowGuest()
    }

    setShowForm = showForm => {
        this.setState({showForm})
    }

    redirectUrl = () => {
        if (this.state.ftlData) {
            return `/customer/ftl${this.state.ftlData}`;
        }

        let url = lodash.get(this.props, 'redirect', '');

        if (url === '/home/freight-quote') {
            let quoteSearchString = sessionStorage.getItem('quoteSearchString');
            url += (lodash.isString(quoteSearchString) ? quoteSearchString : '');
        }

        return url;
    }

    switchForm = (form) => {
        const routes = {
            [REGISTER_SECTION]: '/quick-register',
            [LOGIN_SECTION]: '/quick-signin',
            [FORGOT_PASSWORD_SECTION]: '/quick-signin'
        };

        utilCommon.historyPush(routes[form]);
        this.setShowForm(form)
    }

    guestLogin = (values) => {
        sessionStorage.setItem('loginRedirect', this.redirectUrl());
        this.props.login(values, this.props.currentLanguage, this.props.dictionaries);
    }

    async getRecaptchaValue() {
        try {
            return await this.recaptchaRef.current.executeAsync();
        } catch (error) {
            console.error('Recaptcha Error: ', error);
            return null;
        }
    }

    buildPayload(values, recaptchaValue, timezone) {
        const timezoneId = timezone ? timezone : this.props.timezones[0];

        let payload = {
            isCommercial: true,
            customerFirstName: values.customerFirstName.trim(),
            customerLastName: values.customerLastName.trim(),
            customerEmailId: values.customerEmailId.trim(),
            customerPhone: values.phone,
            customerCell: values.phone,
            password: values.password,
            customerCompany: !lodash.isEmpty(values.customerCompany) && !lodash.isEmpty(values.customerCompany.trim())
                ? values.customerCompany.trim() : (values.customerFirstName.trim() + ' ' + values.customerLastName.trim()),
            customerShippingFrequency: Number(values.estimatedShipmentsPerMonth),
            recaptcha: recaptchaValue,
            customerType: this.props.customerTypeOptions.find(x => x.id == SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS),
            timezoneId: timezoneId,
            firstRequestedQuoteType: this.state.firstRequestedQuoteType,
            //skipReCaptchaCheckForTests: true
        };

        if (values.textUpdates) {
            Object.assign(payload, {
                sendSms: 1,
                newsAndUpdate: 1,
                tracking: 1,
                discount: 1
            });
        }

        if (this.state.ftlData) {
            payload.ftl = 1;
        }

        return payload;
    }

    handleSignUp(payload, values) {
        this.props.signUp(payload, this.props.currentLanguage, this.props.dictionaries)
            .then(res => {
                if (!lodash.get(res, 'data.success', false)) {
                    this.setState({loading: false});
                    utilCommon.historyPush('/quick-register');
                } else {
                    this.props.changeSignUpMethod('native');
                    this.props.saveSignUpRequest(values);
                    this.submitShippingFrequency({
                        customerShippingFrequency: Number(values.estimatedShipmentsPerMonth)
                    })
                }

                if (this.recaptchaRef.current) {
                    this.recaptchaRef.current.reset();
                }
            });
    }

    guestSignUp = async (values) => {
        let recaptchaValue = await this.getRecaptchaValue();
        if (!recaptchaValue) {
            return;
        }

        let timezone = this.props.timezones.find(x => x.name.includes(utilCommon.standardTimezone()));
        if (timezone) {
            timezone = {name: timezone.name, id: timezone.id};
        }

        sessionStorage.setItem('loginRedirect', this.redirectUrl());

        values.password = generatePass();
        this.handleSignUp(
            this.buildPayload(values, recaptchaValue, timezone),
            values
        );
    }

    calculateShippingFrequency = estimatedShipmentsPerMonth => {
        if (estimatedShipmentsPerMonth <= 1) {
            return ONE_TIME_SHIPMENT;
        }

        if (estimatedShipmentsPerMonth > 1 && estimatedShipmentsPerMonth <= 10) {
            return ONE_TO_TEN_TIME_SHIPMENT;
        }

        if (estimatedShipmentsPerMonth > 10) {
            return TEN_PLUS_TIME_SHIPMENT;
        }
    }

    submitShippingFrequency = (values) => {
        const request = this.props.signUpRequest;
        request.customerShippingFrequency = values.customerShippingFrequency;
        request.customerUuid = utilCommon.getSession('customerId');
        this.setState({loading: true});

        switch (this.props.signUpMethod) {
            case 'native': {
                this.props.updateShippingFrequency(
                    request,
                    this.props.currentLanguage,
                    this.props.dictionaries
                )

                break;
            }
        }
    }

    forgotPassword = (values) => {
        this.props.forgotPassword({customerEmailId: values.email}, this.props.currentLanguage, this.props.dictionaries);
    }

    getContent = () => {
        const {showForm} = this.state;

        if (showForm === FORGOT_PASSWORD_SECTION) {
            return <ForgotPasswordForm switchForm={this.switchForm} onSubmit={this.forgotPassword} changeDialogClass = {this.changeDialogClass}/>
        }

        if (showForm === LOGIN_SECTION) {
            if (this.props.toManyLoginAttempts === false) {
                return <LoginForm onSubmit={this.guestLogin} switchForm={this.switchForm} setShowForm={this.setShowForm} changeDialogClass = {this.changeDialogClass}/>;
            } else {
                return <div style={{margin:'0 20px 10px'}}><ManyLoginAttemptErrorSection/></div>
            }
        }

        if (showForm === REGISTER_SECTION) {
            return <SignUpForm onSubmit={this.guestSignUp} switchForm={this.switchForm} changeDialogClass = {this.changeDialogClass}/>;
        }

        return <div></div>
    }

    render() {
        const isLoading = this.props.loading || this.state.loading;

        if (!lodash.isEmpty(this.state.ftlData) && utilCommon.isSpamFtlQuote(this.state.ftlData)) {
            return null;
        }

        return (
            <>
                {
                    !this.state.hideAuth &&
                    <Dialog open={this.props.open}
                            PaperProps={{style: {borderRadius: 25}}}
                            scroll={undefined}
                            aria-labelledby="scroll-dialog-title"
                            maxWidth={'md'}
                            className={cn('DialogBox', this.state.dialogClass, 'not-logged-in')}>
                        {
                            this.getContent()
                        }
                        {isLoading && <Loader/>}
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            size="invisible"
                            sitekey={RECAPTCHA_KEY}
                            badge="bottomleft"
                        />
                    </Dialog>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    signUpRequest: state.common.signUpRequest,
    signUpMethod: state.common.signUpMethod,
    signUpForm: state.common.signUpForm,
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    customerTypeOptions: state.common.customerTypeOptions,
    allowGuest: state.login.allowGuest,
    timezones: state.common.timezones || [],
    linkEmail: state.common.linkEmail,
    user: utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken'),
    toManyLoginAttempts: lodash.get(state, 'login.toManyLoginAttempts', false)
});

const mapDispatchToProps = (dispatch) => ({
    login: (inputDTO, lang, dictionaries) => {
        authService.login(dispatch, lang, dictionaries, inputDTO, false)
    },
    signUp: (inputDTO, lang, dictionaries) => {
        return authService.guestSignUp(dispatch, lang, dictionaries, inputDTO, true);
    },
    allowGuest: () => {
        dispatch({type: type.ALLOW_GET_QUOTE, allowGuest: false})
    },
    changeSignUpForm: (flag) => {
        dispatch({type: commonType.CHANGE_SIGN_UP_FORM, flag});
    },
    changeSignUpMethod: (method) => {
        dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method});
    },
    saveSignUpRequest: (request) => {
        dispatch({type: commonType.SAVE_SIGN_UP_REQUEST, request});
    },
    updateShippingFrequency: (inputDTO, lang, dictionaries) => {
        return authService.updateShippingFrequency(dispatch, lang, dictionaries, inputDTO, true);
    },
    forgotPassword: (inputDTO, lang, dictionaries) => {
        authService.forgotPassword(dispatch, lang, dictionaries, inputDTO)
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestSignUpV2);
