import React from 'react';
import UncheckedRadiobutton from '../images/NewUI/uncheckedRadioButton.svg';
import CheckedRadiobutton from '../images/NewUI/checkedRadioButton.svg';

export const commonColors = {
    orange: '#F8700D',
    hoverOrange: '#f99248',
    blue: '#47749E',
    hoverBlue: '#6b95bd',
    greyBorder: '#BCBCBC',
    textGrey: '#7C7D81',
    secondaryGrey: '#626365',
    black: '#25221D',
    red: 'red',
    lightGreen: '#F9FFFA',
    lightBlue: '#F9FCFF'
}

export const commonInputInputStyles = {
    padding: '0px 10px',
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active': {
        backgroundColor: 'yellow !important',
        color: 'green !important',
    },
};

export const commonInputRootStyles = {
    width: 80,
    height: 45,
};

export const getDropdownInputStyles = (customStyles = {}, withError = false, ...otherStyles) => ({
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected || state.isFocused ? '#e6e6e6' : '#ffffff',
        ...(customStyles.option)
    }),
    container: (base, state) => ({
        ...base,
        width: '100%',
        lineHeight: '20px',
        ...(customStyles.container),
    }),
    control: (base, state) => ({
        ...base,
        borderRadius: 24,
        height: 45,
        borderColor: commonColors.greyBorder,
        backgroundColor: 'white !important',
        boxShadow: 'none',
        '&:hover': {
            borderColor: commonColors.greyBorder,
        },
        '@media (max-width: 1279px)': {
            width: '100%',
        },
        ...(withError && inputRootError),
        ...(customStyles.control),
    }),
    indicatorSeparator: (base, state) => ({
        ...base,
        display: 'none',
        ...(customStyles.indicatorSeparator)
    }),
    menuPortal: (base, state) => ({
        ...base,
        zIndex: 999,
        ...(customStyles.menuPortal)
    }),
    ...otherStyles
})

export const dropdownDefaultStyles = {
    borderColor: commonColors.greyBorder
}

export const quoteInputTextStyles = {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    color: '#25221D'
}

export const disabledButton = {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
}

export const checkedCheckbox = {
    '& svg': {
        fill: commonColors.orange,
    }
}

export const inputRootError = {
    borderColor: 'red',
    transition: 'border-color 0.3s'
}

export const inputLabelDefaultStyles = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: commonColors.textGrey
}

export const getNewUIRadioButtonIcons = () => {
    return {
        icon: <img src={UncheckedRadiobutton} alt="unchecked radiobutton"/>,
        checkedIcon: <img src={CheckedRadiobutton} alt="checked radiobutton"/>
    }
}

export const quoteFormSectionTitle = {
    color: '#47749E',
    fontSize: 32,
    fontWeight: 700,
}

export const pageBackgroundColor = {
    background: "rgba(0,0,0,0)"
}

export const QUOTE = {
    AddItemButton: {
        fontSize: 18,
        fontWeight: 700,
        color: commonColors.blue,
        cursor: 'pointer',
        userSelect: 'none',
    }
}

