import * as type from './actionTypes';


export const setLoader = (flag) => ({
    type: type.SET_LOADER,
    flag
});

export const setQuoteSearchString = (quoteSearchString = false) => ({
    type: type.SET_QUOTE_SEARCH_STRING,
    quoteSearchString
});

export const setQuoteResults = (quoteSearchResults = {}) => ({
    type: type.SET_QUOTE_RESULTS,
    ...quoteSearchResults
});

export const updateQuoteQuery = (quoteQuery = {}) => ({
    type: type.UPDATE_QUOTE_QUERY,
    quoteQuery
});

export const resetQuoteQuery = () => ({
    type: type.RESET_QUOTE_QUERY
});

export const setDestZipLoaded = (destZipLoaded) => ({
    type: type.SET_DEST_ZIP_LOADED,
    destZipLoaded
});

export const setOriginZipLoaded = (originZipLoaded) => ({
    type: type.SET_ORIGIN_ZIP_LOADED,
    originZipLoaded
});
