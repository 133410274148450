import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import lodash from "lodash";

const signup = () => {
	const params = { headers: authHeader() };
	const url = getApiUrl('reward', 'signup');

	const failResponse = { success: false };

	return axios.post(url, {}, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', true);
			const message = lodash.get(response.data, 'message', '');

			if (result) {
				response.data = lodash.get(response.data, 'dataLists[0]', {});
			}else{
				response.data = {...failResponse, ...{message}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};



export default {
	signup,
};
