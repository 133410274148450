import * as type from './actionTypes';

// set login user
export const loginUser = () => ({
  type: type.LOGIN
});


// set logged in user credentials
export const setCredentials = (token,refreshToken,email) => {
  return {
    type: type.SET_CREDENTIALS,
    token: token,
    refreshToken: refreshToken,
    email: email
  };
}
// set guest credentials
export const setGuestCredentials = (token,refreshToken,email) => ({
  type: type.SET_GUEST_CREDENTIALS,
  token: token,
  refreshToken: refreshToken,
  email: email
});

export const setToManyLoginAttempts = (isToManyLoginAttempts) => ({
  type: type.SET_TO_MANY_LOGIN_ATTEMPTS,
  toManyLoginAttempts: isToManyLoginAttempts
});
