// @flow
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import service from '../../services/widgetService';

const REDUCER = 'widgets';
const REQUEST = 'widgets/request';

export const IDLE = 0;
export const PENDING = 1;
export const FAILED = 2;

const initialState = {
    list: [],
    loading: IDLE,
    currentRequestId: undefined,
    errors: null,
};

export const fetch = createAsyncThunk(
    REQUEST,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.get();
            const {success = true, message = ''} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(message);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)

export const create = createAsyncThunk(
    REQUEST,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.create(inputDTO);
            const {success = true, message = ''} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(message);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)

export const update = createAsyncThunk(
    REQUEST,
    async (inputDTO, { rejectWithValue }) => {
        try {
            const response = await service.update(inputDTO);
            const {success = true, message = '', errors = {}} = response.data;
            // console.log('response.data',response.data)

            if (success === false){
                return rejectWithValue(errors);
            }
            return response.data;
        }catch(err){
            // console.log('err',err)
            return rejectWithValue(err.message);
        }
    }
)

export const actionNotify = createAsyncThunk(
    REQUEST,
    async (inputDTO, {rejectWithValue}) => {
        try {
            const response = service.actionNotify(inputDTO);
            const {success = true, message = '', errors = {}} = response.data;
            if (success === false) {
                return rejectWithValue(errors);
            }
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
)

const objSlice = createSlice({
    name: REDUCER,
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state, action) => {
                state.loading = PENDING
                state.currentRequestId = action.meta.requestId
                state.errors = null;
            })
            .addCase(fetch.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = IDLE
                    state.currentRequestId = undefined
                    state.list = action.payload;
                }
            })
            .addCase(fetch.rejected, (state, action) => {
                const { requestId } = action.meta
                // console.log('action',action)
                if (
                    state.loading === PENDING &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = FAILED
                    state.errors = action.payload
                    state.currentRequestId = undefined
                }
            })
    },
})
// export const {  } = objSlice.actions;
export default objSlice.reducer;
