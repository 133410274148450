// @flow
import * as type from './actionTypes';
import moment from 'moment';
import {DefaultItemInShipment, DefaultItemInShipmentTL} from '../../helpers/enums';
import { templates } from '../../helpers/templates';

const now = new Date();
let amount = 1;
switch (now.getDay()) {
  case 5: amount = 3; break;
  case 6: amount = 2; break;
}
const shippingFromPickUpDate = moment(now).add(amount,'days').format().substring(0, 10);


export const initialQuoteState = {
  quoteData: {},
  filterText: '',
  facilityTypes: [],
  quoteStep: 1,
  contracts: [],
  customers: [],
  loading:false,
  quoteResults: [],
  requestedPickupStartDate: null,
  quoteQuery: {
    items: [{ ...DefaultItemInShipment }],
    manualShipment: false,
    shippingFromPickUpDate,
    originFacilityType: '2',
    destinationFacilityType: '2',
  },
  tlQuoteQuery: {
    items:[{...DefaultItemInShipmentTL}],
    equipmentTypeId: 2,
    truckloadType: 1,
    accessorials:{
      escorts: false,
      strapschains: false,
      hazmat: false,
      tarps: false,
      oversized: false,
      team: false,
      permits: false
    },
    footage: 0
  },
  quoteRequestUuid: null,
  shipmentUuid: null,
  selectedCustomer: null,
  originCode: null,
  destinationCode: null,
  carriers: [],
  masterData: {},
  products: [],
  addresses : [],
  quoteResultFlag:false,
  quoteSearchString:false,
  destZipLoaded: false,
  originZipLoaded: false,
  template: templates.full
};

const reducer = (state = { ...initialQuoteState}, action) => {
  switch (action.type) {
    case type.SET_QUOTE:
      return {
        ...state,
        quoteData: action.quoteData
      };

    case type.SET_FACILITY_TYPES:
      return {
        ...state,
        facilityTypes: action.facilityTypes
      };

    case type.SET_CUSTOMER_PRODUCTS:
      return {
        ...state,
        products: action.products
      }

    case type.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.master
      };

    case type.SET_CARRIERS:
      return {
        ...state,
        carriers: action.carriers
      };


    case type.SET_USER_FILTER:
      return {
        ...state,
        filterText: action.filterText
      }

    case type.SET_CUSTOMERS:
      let selectedCustomer = state.selectedCustomer ? action.customers.filter(x => x.customerId == state.selectedCustomer) : null
      return {
        ...state,
        customers: action.customers,
      }

    case type.SET_QUOTE_STEP:
      return {
        ...state,
        quoteStep: action.quoteStep
      }

    case type.SET_CUSTOMER_ADDRESS:
    return {
      ...state,
      addresses: action.addresses
    }

    case type.SET_CONTRACTS:
      return {
        ...state,
        contracts: action.contracts
      }

    case type.SET_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer
      }

    case type.SET_QUOTE_RESULTS:
      return {
        ...state,
        requestedPickupStartDate: action.requestedPickupStartDate,
        quoteResults: action.quoteResults,
        quoteRequestUuid: action.quoteRequestUuid,
        shipmentUuid: action.shipmentUuid,
        template: action.template,
        loading: action.loading,
        progress: action.progress,
        progressBar: action.progressBar
      }

    case type.SET_QUOTE_QUERY:
      return {
        ...state,
        quoteQuery: action.quoteQuery
      }

    case type.UPDATE_QUOTE_QUERY:
      return {
        ...state,
        quoteQuery: {...state.quoteQuery, ...action.quoteQuery}
      }

    case type.RESET_QUOTE_QUERY:
      return {
        ...state,
        quoteQuery: {
          items: [{ ...DefaultItemInShipment }],
          manualShipment: false,
          shippingFromPickUpDate,
          originFacilityType: '2',
          destinationFacilityType: '2',
        }
      }

    case type.SET_ORIGIN_CODE:
      return {
        ...state,
        originCode: action.code
      }

    case type.SET_DESTINATION_CODE:
      return {
        ...state,
        destinationCode: action.code
      }
    case type.SET_LOADER:
      return {
        ...state,
        loading:action.flag
      }
    case type.SET_QUOTE_RESULT_NAVIGATED:
      return {
        ...state,
        quoteResultFlag:action.quoteResultFlag
      }
    case type.SET_QUOTE_SEARCH_STRING:
      return {
        ...state,
        quoteSearchString: action.quoteSearchString
      }
    case type.SET_DEST_ZIP_LOADED:
      return {
        ...state,
        destZipLoaded: action.destZipLoaded
      }
    case type.SET_ORIGIN_ZIP_LOADED:
      return {
        ...state,
        originZipLoaded: action.originZipLoaded
      }
    default:
      return state;
  }
};

export default reducer;
