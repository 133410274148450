import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import * as commonActions from '../store/common/actions'
import * as type from '../store/accountInfo/actionTypes'
import * as utilCommon from '../helpers/utilCommon'

// update account Info
export const updateAccountInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'customerUpdate');
    dispatch(commonActions.setLoader(true))
    return axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.ACCOUNT_INFO_UPDATED, accountInfoUpdated: true })
                getAccountInfo(dispatch, lang, dictionary, {id:inputDTO.customerUuid})
                utilCommon.showNotification(response.data.message, dictionary, lang)
            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }

            return response;
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const unsubscribeEmail = (data) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'unsubscribeEmail');

    return axios.put(url, data, params)
        .then((response) => {
            return response;
        });
};

// get account Info
export const getAccountInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('login', 'getProfile');
    dispatch(commonActions.setLoader(true))
    dispatch({ type: type.SET_CURRENT_CUSTOMER, customerState: {} })
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CURRENT_CUSTOMER, customerState: response.data.content.customerList[0] })

            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// get terms and conditions
export const getTermsAndConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'termsAndConditions');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TERMS_AND_CONDITIONS , termsAndCondition: response.data.content })
            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getInsuranceTermsAndConditions = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('customer', 'insuranceTermsAndConditions');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (response.data.content.falveyTermsConditionList.length > 0) {
                    dispatch({
                        type: type.SET_FALVEY_TERMS_AND_CONDITIONS,
                        falveyTermsAndCondition: response.data.content.falveyTermsConditionList[0]
                    });
                }
            }
            else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// accept updated terms and conditions
export const acceptTermsAndConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'termsAndConditions');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTermsAndConditions(dispatch, lang, dictionary, {id:inputDTO.customerUuid})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const acceptInsuranceTermsAndConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('customer', 'insuranceTC');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// get customer info by email
export const getCustomerByEmail = (customerEmailId, callback) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('login', 'getProfile');
    url = url + `?customerEmailId=${customerEmailId}`
    axios.get(url, params)
        .then((response) => {
            callback(response.data)
        })
        .catch(err => {
            console.error(err);
            callback({})
        })
};

export default {
    updateAccountInfo,
    unsubscribeEmail,
    getAccountInfo,
    getTermsAndConditions,
    getInsuranceTermsAndConditions,
    acceptTermsAndConditions,
    acceptInsuranceTermsAndConditions,
    getCustomerByEmail
};
