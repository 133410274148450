import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import * as commonActions from '../store/common/actions'
import * as commonActionTypes from '../store/common/actionTypes'
// get master data 
export const getMasterData = (dispatch) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'getMasterData');
	
	axios.get(url, params)
		.then((response) => {
			
			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_MASTER_DATA, masterData: response.data.content.masterDataList })
			}
		})
		.catch((err) => {
			
		});

};

export const getTermsConditions = (dispatch) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('login', 'getTermsConditions');

	axios.get(url, params)
		.then((response) => {
			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_TERMS_CONDITIONS, termsConditions: response.data.content.fcTermsCondition[0] });
			}
		});
};

// get timezone 
export const getTimeZone = (dispatch) => {
	const params = { headers: authHeader() };
	let url2 = getApiUrl('login', 'getTimezone');
	axios.get(url2, params)
		.then((response) => {
			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_TIMEZONES, timezones: response.data.content.timeZoneList })
			} else {

			}
		})
		.catch((err) => {

		});
}
// get customer master
export const getCustomerMasterData = (dispatch) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'master');
	
	axios.get(url, params)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_CUSTOMER_MASTER_DATA, customerMasterData: response.data.content.compositeCustomerDataList })
			}
		})
		.catch((err) => {
			
		});

};

// get shipment master
export const getShipmentMasterData = (dispatch) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('shipment', 'master');
	
	axios.get(url, params)
		.then((response) => {
			
			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_SHIPMENT_MASTER_DATA, shipmentMasterData: response.data.content.masterDataList })
			}
		})
		.catch((err) => {
			
		});

};

//get loading facility type
export const getLoadingFacilityType = (dispatch) => {
	const params4 = { headers: authHeader() };
	const url4 = getApiUrl('quote', 'getFaciltyTypes');
	axios.get(url4, params4)
		.then((response) => {

			if (response.data.success) {
				dispatch({ type: commonActionTypes.SET_LOADING_FACILITY_TYPE, facilityList: response.data.content.facilityTypeAccessorialsList })
			}
		})
		.catch((err) => {

		});
}

export default {
	getMasterData,
	getTermsConditions,
	getTimeZone,
	getCustomerMasterData,
	getShipmentMasterData,
	getLoadingFacilityType
};
