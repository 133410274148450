import axios from 'axios';
import getApiUrl from '../helpers/apiUrls'
import { authHeader } from '../helpers/authHeader';
import lodash from "lodash";

const create = (inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'widgets');

	const failResponse = { success: false };
	return axios.post(url, inputDTO, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', false);
			const message = lodash.get(response.data, 'message', '');

			if (result) {
				response.data = lodash.get(response, 'data', []);
			}else{
				response.data = {...failResponse, ...{message}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};

const update = (inputDTO) => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'widgets');

	const failResponse = { success: false };
	return axios.put(url, inputDTO, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', false);
			const message = lodash.get(response.data, 'message', '');
			const errors = lodash.get(response.data, 'errors', '');

			if (result) {
				response.data = lodash.get(response, 'data', []);
			}else{
				response.data = {...failResponse, ...{message}, ...{errors}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};

const get = () => {
	const params = { headers: authHeader() };
	const url = getApiUrl('customer', 'widgets');

	const failResponse = { success: false };
	return axios.get(url, params)
		.then((response) => {
			let result = lodash.get(response.data, 'success', false);
			const message = lodash.get(response.data, 'message', '');

			// if (!result) {
			// 	response.data = {...failResponse, ...{message}};
			// }else{
				const data = lodash.get(response, 'data', [])
				// const tst1 = {
				// 	"id": 8,
				// 	"name": "rewards",
				// 	"number": 8,
				// 	"properties": {
				// 		"status": 0,
				// 		"counter": 1,
				//
				// 	}
				// }
				// const tst2 = {
				// 	"id": 9,
				// 	"name": "rewards",
				// 	"number": 9,
				// 	"properties": {
				// 		"status": 1,
				// 		"counter": 12345,
				//
				// 	}
				// }
				// data.push(tst1);
				// data.push(tst2);
				response.data = Array.isArray(data)?data:[];
			// }

			return response;
		})
		.catch((err) => {
			return err;
		});
};

const actionNotify = (inputDTO) => {
	const params = {headers: authHeader()};
	const url = getApiUrl('customer', 'actionNotify');
	const failResponse = {success: false};
	return axios.post(url, inputDTO, params)
		.then((response) => {
			const result = lodash.get(response.data, 'success', false);
			const message = lodash.get(response.data, 'message', '');
			const errors = lodash.get(response.data, 'errors', '');

			if (result) {
				response.data = lodash.get(response, 'data', []);
			} else {
				response.data = {...failResponse, ...{message}, ...{errors}};
			}
			return response;
		})
		.catch((err) => {
			return err;
		});
};

export default {
	create,
	update,
	get,
	actionNotify
};
