// constants
export const RESIDENTIAL_CUSTOMER_TYPE = 1;
export const COMMERCIAL_CUSTOMER_TYPE = 2;
export const USA_COUNTRY_ID =228;
export const CANADA_COUNTRY_ID =39;
export const MEXICO_COUNTRY_ID =170;
export const COUNTRIES_WITH_STATES = [
    USA_COUNTRY_ID,
    CANADA_COUNTRY_ID,
    MEXICO_COUNTRY_ID
];
export const SCHEDULED_FOR_PICKUP_STATUS =5;
export const BUSINESS_TYPE_LOADING_FACILITY = [3,4];
export const RESIDENTIAL_LOADING_FACILITY_ID = 1;
export const DIMENSION_TYPE = 1;
export const mapURL = 'https://maps.googleapis.com/maps/api/geocode/json?address=zipCode&sensor=true&key=googleKey';
export const zipPredictionsURL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=inputValue&types=(regions)&key=googleKey';
export const STATUS_ACTIVE = 1
export const COMMODITY_TYPE_ID_USED = 1001;
export const COMMODITY_TYPE_ID_NEW = 1002;
export const COMMODITY_CATEGORY_ID_NOT_ALLOWED = [12, 13, 18, 35, 36, 37, 38];
export const FIRST_REQUESTED_QUOTE_TYPE_UNDEFINED = 0;
export const FIRST_REQUESTED_QUOTE_TYPE_LTL = 1;
export const FIRST_REQUESTED_QUOTE_TYPE_TL = 2;

export const INVOICE_STATUS_PENDING = 1;
export const PAYMENT_TYPE_NAME_AR_ACCOUNT = 'AR account';

export const DOCUMENT_TYPE_CUSTOMER_AGREEMENT = 10;
export const INSURANCE_TRANSACTION = 4;

export const NO_INSURANCE_AVAILABLE = 99;
export const INSURANCE_TIER_1 = 1;
export const INSURANCE_TIER_1_DEDUCTIBLE = '10';
export const INSURANCE_TIER_2 = 2;
export const INSURANCE_TIER_2_DEDUCTIBLE = '15';
export const INSURANCE_TIER_3 = 3;
export const INSURANCE_TIER_3_DEDUCTIBLE = '25';
export const NO_INSURANCE_AVAILABLE_BACKEND_MESSAGE = 'This customer is ineligible to purchase shipment protection due to previous claim history.';

export const MAPPING_PACKAGE_TYPE_BY_NAME = {
    'Box': {
        itemType: "3",
        palleteType: "",
        palletCheck: false
    },
    'Pallet': {
        itemType: "",
        palleteType: 2,
        palletCheck: false
    },
    'Drum / Barrell': {
        itemType: "",
        palleteType: "",
        otherItemType: 17,
        palletCheck: false
    },
    'Container': {
        itemType: "",
        palleteType: "",
        otherItemType: 23,
        palletCheck: false
    },
    'Roll': {
        itemType: "",
        palleteType: "",
        otherItemType: 21,
        palletCheck: false
    },
    'Bale': {
        itemType: "",
        palleteType: "",
        otherItemType: 11,
        palletCheck: false
    },
    'Crate': {
        itemType: "2",
        palleteType: "",
        palletCheck: false
    },
    'Drum or Barrel': {
        itemType: "",
        palleteType: "",
        otherItemType: 17,
        palletCheck: false
    },
    'Tube': {
        itemType: "",
        palleteType: "",
        otherItemType: 23,
        palletCheck: false,
    },
    'Skid': {
        itemType: "",
        palleteType: "",
        otherItemType: 22,
        palletCheck: false,
    },
    'Reel': {
        itemType: "",
        palleteType: "",
        otherItemType: 20,
        palletCheck: false
    },
    'Pieces': {
        itemType: "",
        palleteType: "",
        otherItemType: 19,
        palletCheck: false
    },
    'Pail': {
        itemType: "",
        palleteType: "",
        otherItemType: 18,
        palletCheck: false
    },
    'Cylinder': {
        itemType: "",
        palleteType: "",
        otherItemType: 16,
        palletCheck: false
    },
    'Coil': {
        itemType: "",
        palleteType: "",
        otherItemType: 15,
        palletCheck: false
    },
    'Carton': {
        itemType: "",
        palleteType: "",
        otherItemType: 14,
        palletCheck: false
    },
    'Case': {
        itemType: "",
        palleteType: "",
        otherItemType: 13,
        palletCheck: false
    },
    'Can': {
        itemType: "",
        palleteType: "",
        otherItemType: 12,
        palletCheck: false
    },
    'Bucket': {
        itemType: "",
        palleteType: "",
        otherItemType: 10,
        palletCheck: false
    },
    'Bag': {
        itemType: "",
        palleteType: "",
        otherItemType: 9,
        palletCheck: false
    },
    'Bundle': {
        itemType: "",
        palleteType: "",
        otherItemType: 8,
        palletCheck: false
    },
};
