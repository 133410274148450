import React from 'react';
import {connect} from 'react-redux';
import Loader from '../../atoms/Loader';
import {Route, Switch, Redirect} from 'react-router-dom';
import Loadable from 'react-loadable';
import CreditAppProgressBar from '../../molecules/CreditAppProgressBar';
import * as actions from "../../../store/creditApp/reducer";
import lodash from "lodash";


const CreditAppContactInfoPage = Loadable({
    loader: () => import('../CreditAppContactInfoPage'),
    loading: Loader,
});

const TermsPage = Loadable({
    loader: () => import('../CreditAppTermsPage'),
    loading: Loader,
});

const BusinessHistory = Loadable({
    loader: () => import('../CreditAppBusinessHistory'),
    loading: Loader,
});

const Card = Loadable({
    loader: () => import('../CreditAppCard'),
    loading: Loader,
});

const Refs = Loadable({
    loader: () => import('../CreditAppRefs'),
    loading: Loader,
});

const FinalPage = Loadable({
    loader: () => import('../CreditAppFinalPage'),
    loading: Loader,
});


class CreditAppPage extends React.Component {
    steps = {
      [`/customer/credit-app/p1-info`]: 1,
      [`/customer/credit-app/p2-history`]: 2,
      [`/customer/credit-app/p3-references`]: 3,
      //[`/customer/credit-app/p4-card`]: 4,
      [`/customer/credit-app/p5-terms`]: 4,
      [`/customer/credit-app/p6-submit`]: 5,
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.loadCreditApp();
    }

    componentDidUpdate(prevProps, prevState, SS) {
        if(
            this.props.creditApp.loading === actions.IDLE &&
            this.props.history.action === 'POP'
        ){
            this.props.loadCreditApp();
        }

        if(prevProps.creditApp.app.step !== this.props.creditApp.app.step){
            this.props.history.push(this.getStepPath(this.props.creditApp.app.step, true))
        }

        if(this.props.creditApp.errors && prevProps.creditApp.errors !== this.props.creditApp.errors){
            const toRedirect = Object.keys(this.props.creditApp.errors).some(error => error === 'creditApp');
            if (toRedirect){
                this.props.history.push('/customer')
            }
        }
    }

    get step() {
      return this.steps[this.props.location.pathname] || 1;
    }

    getStepPath(step= 1, toLog = false){
        let stepNum = parseInt(step);
        if (isNaN(stepNum) || stepNum < 1){
            stepNum = 1;
        }
        let key = lodash.findKey(this.steps, item => item === stepNum);

        if (!key){
            key = this.getStepPath(1);
        }

        if (toLog){
            // console.log('key!!!', key);
        }

        return key
    }

    render() {
        const {classes, requestStatus, creditApp, loading, ...props} = this.props;

        return (
            <div className='credit-app'>
                {(requestStatus === actions.PENDING || loading)  && <Loader />}
                <CreditAppProgressBar step={this.step} />
                <Switch>
                  <Route exact path={this.getStepPath(1)} component={CreditAppContactInfoPage} />
                  <Route exact  path={this.getStepPath(2)} component={BusinessHistory} />
                  <Route exact  path={this.getStepPath(3)} component={Refs} />
                    {/*<Route exact  path={this.getStepPath(4)} component={Card} />*/}
                  <Route exact  path={this.getStepPath(4)} component={TermsPage} />
                  <Route exact  path={this.getStepPath(5)} component={FinalPage} />
                    <Redirect to={this.getStepPath(creditApp.app.step)} />
                    {/*<Redirect to={this.getStepPath(2)} />*/}
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        creditApp: state.creditApp,
        requestStatus: state.creditApp.loading || actions.IDLE,
        loading: state.common.loading,
    }
};

const mapDispatchToProps = (dispatch) => ({
    loadCreditApp:()=>{
        dispatch(actions.setStep(0));
        dispatch(actions.fetch());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditAppPage);
