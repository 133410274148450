import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import cn from 'classnames';

const getClasses = (requiredClass, touched, error, disabled, newForm) => {

    if (disabled) {
        return 'form-inputFieldrdonly'
    } else {
        if (touched && error) {
            if (requiredClass) {
                if (newForm) {
                    return 'newFormErrorInput';
                }

                return 'form-inputField form-inputFieldError form-inputFieldImp highlighted'
            }

            return 'form-inputField form-inputFieldError highlighted'
        } else {
            if (requiredClass) {
                return 'form-inputField form-inputFieldImp highlighted'
            }

            return 'form-inputField highlighted'
        }
    }
}


const getOptions = (options, value) => {
    return options.filter(x => (x.id.toLowerCase().startsWith(value.toLowerCase()) || x.name.toLowerCase().startsWith(value.toLowerCase())))
}

const isSelected = (options, value) => {
    return options.filter(x => (x.id.toLowerCase() == value.toLowerCase()) || x.name.toLowerCase() == value.toLowerCase()).length > 0
}

const checkAdornment = (handleClickShowPassword, handleMouseDownPassword, showPassword, showPasswordEndAdornmentClasses) => {
    if (showPassword !== undefined) {
        return <InputAdornment position="end" className={showPasswordEndAdornmentClasses}>
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
        </InputAdornment>;
    }
}

function filterChars(value) {
    let result = '';

    for (let i = 0; i < value.length; i++) {
        let c = value.charCodeAt(i);

        if (c >= 32 && c <= 127) {
            result += String.fromCharCode(c);
        }
    }

    return result;
}

const renderField = ({
                         endComponent = false,
                         inputLabelProps = {},
                         style = {},
                         isShrunk = false,
                         classes = {},
                         disableUnderline = false,
                         input,
                         tableSelect,
                         label,
                         options,
                         autoSuggest,
                         spellcheck,
                         placeholder = null,
                         fileType,
                         disabled,
                         multiline,
                         maxLength,
                         requiredClass,
                         checkBoxError,
                         meta: {touched, error, warning, active, visited},
                         handleClickShowPassword,
                         handleMouseDownPassword,
                         showPassword,
                         showMaxLength = false,
                         showPasswordEndAdornmentClasses = {},
                         newForm,
                         disabledLabelStyles,
                         fullWidth = false,
                         ...custom
                     }) => {
    if (isShrunk) {
        inputLabelProps.shrink = true;
    }

    inputLabelProps.classes = {
        root: classes.input_label_root
    };

    let endAdornment = false;

    if (endComponent) {
        endAdornment = <InputAdornment position="end" classes={{
            positionEnd: classes.input_adornment
        }}>
            {endComponent}
        </InputAdornment>
    }

    return (<div style={style}>
        {
            !disabled && <div>
                <TextField
                    multiline={multiline}
                    label={label}
                    className={getClasses(requiredClass, touched, error, disabled, newForm)}
                    margin="normal"
                    hinttext={label}
                    inputProps={{
                        maxLength: maxLength ? maxLength : 255,
                        spellcheck: spellcheck,
                    }}
                    InputProps={{
                        endAdornment: endAdornment ? endAdornment : checkAdornment(handleClickShowPassword, handleMouseDownPassword, showPassword, showPasswordEndAdornmentClasses),
                        onChange: (e) => {
                            e.target.value = filterChars(e.target.value);
                            input.onChange(e);
                        },
                        disableUnderline: disableUnderline,
                        classes: {
                            root: classes.input_root,
                            input: cn(classes.input_input, {
                                [classes.input_input_error]: touched && error
                            }),
                        }
                    }}


                    placeholder={placeholder}
                    InputLabelProps={inputLabelProps}

                    disabled={disabled}
                    floatinglabeltext={label}
                    {...input}
                    {...custom}

                />

                {autoSuggest && !isSelected(options, input.value) && autoSuggest && input.value.length > 0 && getOptions(options, input.value).length > 0 &&
                    <ul className="listItem-custom">
                        {autoSuggest && input.value.length > 0 && getOptions(options, input.value).map(option => (
                            <li
                                key={option.name}
                                value={option.id}
                                onClick={() => {
                                    input.onChange(option.name)
                                }}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ul>}

            </div>
        }
        {
            disabled && <div className="inputField">
                <div className="label">{label}</div>
                <div style={disabledLabelStyles} className="labelData">{input.value}</div>
            </div>
        }

        {!disabled && !autoSuggest && touched && error && !tableSelect && (
            <span className={cn('invalidTxt', classes.input_error)}>
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}

        {
            (!error || error && !touched) && maxLength && showMaxLength && !disabled &&
            <span className={cn('invalidTxt', classes.input_error)}>
                {`${maxLength - input.value.length} out of ${maxLength} characters`}
            </span>
        }

        {!disabled && !autoSuggest && error && tableSelect && (
            <span className={cn('invalidTxt', classes.input_error)}>
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}
        {/* show checkbox error in inoutField */}
        {disabled && checkBoxError && error && (<span className={cn('invalidTxt', classes.input_error)}>
                {error}
            </span>
        )}
        {disabled && checkBoxError && fileType && error && (<span className={cn('invalidTxt', classes.input_error)}>
                {error}
            </span>
        )}

        {!disabled && autoSuggest && touched && !isSelected(options, input.value) && (
            <span className={cn('invalidTxt', classes.input_error)}>
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}
    </div>)
};

export default renderField;
