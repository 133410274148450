import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import './assets/css/material-design-iconic-font.css'
import './components/themes/main.css'
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MainPage from './components/pages/MainPage';
import CustomerHomePage from './components/pages/CustomerHomePage';
import CreditAppPage from "./components/pages/CreditAppPage";
import ResetPassword from './components/pages/ResetPassword';
import commonService from './services/commonService';
import * as utilCommon from './helpers/utilCommon'
import HealthCheck from './components/pages/HealthCheck';
import * as quoteActions from './store/quote/actions';
import ReactGA from 'react-ga';
import authService from "./services/authService";
import { getFreightQuotePath, isValidJsonString } from './helpers/utilCommon';
import {Analytics} from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import {DNB_APP_STATUSES} from "./helpers/enums";
import lodash from "lodash";

// ReactGA.initialize(process.env.REACT_APP_GA_ID);
// ReactGA.ga('set', 'contentGroup2', 'my');

Auth.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
		region: process.env.REACT_APP_AWS_REGION
	}
});

Analytics.configure({
	AWSPinpoint: {
		appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
		region: process.env.REACT_APP_AWS_REGION,
		mandatorySignIn: false
	}
})


const theme = createMuiTheme({
	overrides: {
		MuiCheckbox: {
			root: {
				color: '#4a4a4a',
				'&$checked': {
					color: '#0093d0 !important',
				},
			},
			checked: {
				color: '#0093d0',
			},
		},
		MuiRadio: {
			root: {
				color: '#cdcdcd',
				'&$checked': {
					color: '#0093d0 !important',
				},
			},
			checked: {
				color: '#0093d0',
			},
		},
		MuiListItem: {
			root: {

			},
		},
		MuiMenuItem: {
			root: {
				'&$selected': {
					color: '#0093d0',
				},
			},
		},
		MuiCircularProgress: {
			root: {
				color: '#0093d0 !important',
			},
		},
	},

});


class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			quoteSearchString:'',
			listeningHistory: false,
			ftlData: null
		};
	}

	componentWillMount() {
		if (utilCommon.isFtlQuote(window.location.search)) {
			this.setState(
				{ftlData: window.location.search}
			)
		}

		utilCommon.setGoogleAdsId();
		this.props.getMasterData();
		this.props.setDispatch();
	}
	// check redirected url for user credentials
	getUrl = () => {
		let allowedUrls = [
			'/home/login',
			'/quick-signin',
			'/shipping-frequency'
		];
		let bookingUrls = [
			'/booking/location-details',
			'/booking/shipment-details',
			'/booking/payment-details',
			'/booking/confirmation'
		];

		if (window.location.pathname === '/unsubscribe') {
			return window.location.pathname + window.location.search;
		}

		if (window.location.pathname === '/dnb-credit-app') {
			this.saveDnbRedirectQueryParams();
		}

		if (this.props.user) {
			if (window.location.pathname.includes('/carrier/agreement')) {
				sessionStorage.setItem('carrierAgreementQuery', window.location.search);
				this.props.logout(this.props.currentLanguage,this.props.dictionaries,this.props.history)
				return '/home/login';
			}

			if (window.location.pathname === '/home/freight-quote' && window.location.search) {
				this.setQuoteString();
				return '/customer/freight-quote';
			}

			if (window.location.pathname.substring(0, 20) === '/customer/insurance/') {
				const lastPartPath = lodash.last(window.location.pathname.split('/'))
				return `/customer/shipment-details?shipmentUuid=${lastPartPath}&i=1`
			}

			if (window.location.pathname === '/dnb-credit-app') {
				return '/customer';
			}

			if (
				window.location.pathname.includes('home')
				|| window.location.pathname.includes('quick-register')
				|| window.location.pathname.includes('quick-signin')
				|| window.location.pathname === '/' || window.location.pathname === ''
			) {
				if (this.state.ftlData) {
					const ftlData = this.state.ftlData;
					return `/customer/ftl${ftlData}`;
				}

				if (!utilCommon.getSession('guestSignUp')) {
					return '/customer';
				}

				return '/customer/freight-quote';
			} else if (window.location.pathname === '/customer' || bookingUrls.includes(window.location.pathname)) {
				return '/customer';
			}
			return window.location.pathname + window.location.search;
		} else {
			if (window.location.pathname === '/dnb-credit-app') {
				return '/home/login';
			}

			if (window.location.pathname.includes('/carrier/agreement')) {
				sessionStorage.setItem('carrierAgreementQuery', window.location.search);
				return '/home/login';
			}

			if (window.location.pathname.substring(0, 20) === '/customer/agreement/') {
				sessionStorage.setItem('loginRedirect', window.location.pathname);
				window.location.href = '/home/login';
			}

			if (window.location.pathname.substring(0, 20) === '/customer/insurance/') {
				sessionStorage.setItem('loginRedirect', window.location.pathname);
				window.location.href = '/home/login';
			}

			if (window.location.pathname.substring(0, 20) === '/customer/view-quote') {
				sessionStorage.setItem('loginRedirect', window.location.pathname + window.location.search);
				window.location.href = '/home/login';
			}

			if (window.location.pathname.substring(0, 28) === '/customer/download-document/') {
				sessionStorage.setItem('loginRedirect', window.location.pathname);
				window.location.href = '/home/login';
			}

			if (window.location.pathname.includes('resetpassword')
				|| window.location.pathname.includes('health-check') || allowedUrls.includes(window.location.pathname)) {
				return window.location.pathname;
			}

			if (window.location.pathname === '/quick-register') {
				if (window.location.search) {
					this.setQuoteString();
				}

				return '/quick-register';
			}

			if (
				window.location.pathname.includes('customer') ||
				(
					window.location.pathname === '/' ||
					window.location.pathname === '' ||
					window.location.pathname === '/home' ||
					window.location.pathname === '/home/'
				)
			) {
				const quoteSearchString = sessionStorage.getItem('quoteSearchString');
				if (quoteSearchString) {
					return getFreightQuotePath(quoteSearchString);
				} else {
					return '/home/login';
				}
			} else {
				if (window.location.pathname === '/home/freight-quote' && window.location.search) {
					this.setQuoteString();
					sessionStorage.setItem('loginRedirect', window.location.pathname + window.location.search);

					return '/quick-register';
				}

				return '/home/signup';
			}
		}
	}

	setQuoteString = () => {
		const urlData = (new URLSearchParams(window.location.search)).get('data');

		this.props.setQuoteSearchString(isValidJsonString(urlData) ? JSON.parse(urlData) : '');
		sessionStorage.setItem('quoteSearchString', window.location.search);
	}

	sendInfoToGA(history) {
	  // if (!this.state.listeningHistory) {
	  //   this.setState({listeningHistory: true})
      //   history.listen((location) => {
      //     ReactGA.set({ page: location.pathname });
      //     ReactGA.pageview(location.pathname)
      //   });
      // }
  }

	saveDnbRedirectQueryParams = () => {
		const dnbToken = (new URLSearchParams(window.location.search)).get('dnbToken');
		if (dnbToken !== process.env.REACT_APP_DNB_CREDIT_APP_TOKEN) {
			return;
		}

		const dnbAppStatus = (new URLSearchParams(window.location.search)).get('dnbAppStatus');
		if (!DNB_APP_STATUSES.includes(dnbAppStatus)) {
			return;
		}

		sessionStorage.setItem('dnbToken', dnbToken);
		sessionStorage.setItem('dnbAppStatus', dnbAppStatus);
	}

	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<ToastContainer />
					<MuiThemeProvider theme={theme}>
						<Redirect exact from="" to={this.getUrl()} />
						{/* <Route path='/home' component={MainPage} />						 */}
						<Route path='/home' render={(routeProps)=>(<MainPage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						<Route path='/quick-register' render={(routeProps)=>(<MainPage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						<Route path='/quick-signin' render={(routeProps)=>(<MainPage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						<Route path='/shipping-frequency' render={(routeProps)=>(<MainPage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						{/* <Route path='/customer' component={CustomerHomePage} /> */}
						<Route path='/customer' render={(routeProps)=>(<CustomerHomePage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						<Route path='/unsubscribe' render={(routeProps)=>(<CustomerHomePage {...routeProps} sendInfoToGA={(arg) => this.sendInfoToGA(arg)} />)} />
						<Route path={`/resetpassword/:activationCode`} exact component={ResetPassword} />
						<Route path='/health-check' component={HealthCheck} />
					</MuiThemeProvider>
				</div>
			</BrowserRouter>
		);
	}
}


const mapStateToProps = (state) => ({
	currentLanguage: state.i18nReducer.currentLanguage,
	dictionaries: state.i18nReducer.dictionaries,
	user: utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken')
});

const mapDispatchToProps = (dispatch) => ({
	setDispatch: () => {
		Window['dispatch'] = dispatch
	},
	setQuoteSearchString: (searchString) => {
    dispatch(quoteActions.setLoader(true))
    dispatch(quoteActions.setQuoteSearchString(searchString))
	},
	getMasterData: () => {
		commonService.getMasterData(dispatch)
		commonService.getTimeZone(dispatch)
	},
	logout: (lang, dict, history) => {
		authService.logout(dispatch, lang, dict, history)
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
