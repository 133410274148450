import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSideNav } from '../../../store/common/actions';
import Tooltip from '@material-ui/core/Tooltip';


class SideNavbar extends React.Component {
  state = {};

  navigateTo = (path) => {
    this.props.history.push(`/customer${path}`);
  }

  navigateToManagePayments = () => {
    this.props.history.push({
      pathname:`/customer/account-settings`,
      state: {
        tab: 3
      }
    });
  }

  handleGetQuoteClick = e => {
    e.preventDefault()

    if (this.props.ratingRequestLock) {
      return
    }

    this.navigateTo('/freight-quote')
  }

  getGetQuoteTooltipText = () => {
    return this.props.ratingRequestLock
      ? "We're sorry, there's a problem with your account. Please call 800-716-7608."
      : 'Get a quote'
  }

  render() {
    const ratingRequestLock = this.props.ratingRequestLock

    return (
      <div className={'left-sidebar-nav' + (this.props.sideNavOpen ? ' sideNav-active' : '')} id="left-sidebar-nav">
        <div className="user-account">
          <div className="info" onClick={() => this.navigateTo('')} >
            <div className="avatar">
              {/*<img src="/icons/user-placeholder.png" srcSet="/icons/user-placeholder@2x.png 2x" alt="user-placeholder" className="chronometer-icon" />*/}
              <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
                <g id="Group_246" data-name="Group 246" transform="translate(-19 -20)">
                  <circle id="Ellipse_15" data-name="Ellipse 15" cx="30.5" cy="30.5" r="30.5" transform="translate(19 20)" fill="#fff"/>
                  <g id="user" transform="translate(30.849 30.532)" opacity="0.5">
                    <g id="Group_243" data-name="Group 243" transform="translate(7.614)">
                      <g id="Group_242" data-name="Group 242">
                        <path id="Path_628" data-name="Path 628" d="M121.307,0a10.516,10.516,0,1,0,10.516,10.516A10.544,10.544,0,0,0,121.307,0Z" transform="translate(-110.791)"/>
                      </g>
                    </g>
                    <g id="Group_245" data-name="Group 245" transform="translate(0 21.784)">
                      <g id="Group_244" data-name="Group 244" transform="translate(0)">
                        <path id="Path_629" data-name="Path 629" d="M60.342,255.492a9.806,9.806,0,0,0-1.056-1.929,13.072,13.072,0,0,0-9.047-5.694,1.936,1.936,0,0,0-1.332.321,11.21,11.21,0,0,1-13.226,0,1.723,1.723,0,0,0-1.332-.321,12.977,12.977,0,0,0-9.047,5.694,11.306,11.306,0,0,0-1.056,1.929.976.976,0,0,0,.046.873,18.317,18.317,0,0,0,1.24,1.837,17.455,17.455,0,0,0,2.112,2.388,27.54,27.54,0,0,0,2.112,1.837,20.941,20.941,0,0,0,24.981,0,20.2,20.2,0,0,0,2.112-1.837,21.209,21.209,0,0,0,2.112-2.388,16.1,16.1,0,0,0,1.24-1.837A.784.784,0,0,0,60.342,255.492Z" transform="translate(-24.165 -247.841)"/>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

            </div>
            <div className="names">
              <div className="name">{ this.props.name }</div>
              { false && <div className="title" /> }
            </div>
          </div>
          <div className="expander" onClick={(e) => {this.props.toggleSideNav()}} >
            <img src="/icons/expander.png" srcSet="/icons/expander@2x.png 2x" alt="expander"/>
          </div>
        </div>
        <div className="menu-items">

          <a href={'/customer/freight-quote'}>
            <div className={'row ' + (ratingRequestLock ? 'cursorDefault' : '')} onClick={this.handleGetQuoteClick}>
              <div className={'icon ' + (ratingRequestLock ? 'crossedLink' : '')}>
                {/*<img src="/icons/run-quote.png" srcSet="/icons/run-quote@2x.png 2x" alt="run-quote" />*/}
                <Tooltip title={this.getGetQuoteTooltipText()} placement="right" disableHoverListener={this.props.sideNavOpen}>
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path fill="#555" d="M25.52,22.87v-4.84l2.38-0.64c0.63-0.18,1.01-0.48,1.12-0.89c0.08-0.31-0.01-0.64-0.28-0.92l-3.65-3.4
                        l-4.56-0.88c1-1.16,1.61-2.66,1.61-4.3c0-3.64-2.95-6.59-6.59-6.59c-3.64,0-6.59,2.95-6.59,6.59c0,1.65,0.61,3.16,1.62,4.32
                        l-4.67,0.69l-3.8,3.16l-0.04,0.04l-0.04,0.04c-0.38,0.4-0.53,1-0.37,1.52c0.14,0.48,0.52,0.84,1,0.96l2.88,0.77
                        c0,0-0.01,5.2-0.01,5.28c0,0.01-3.19,0.71-3.19,0.71v3.37l12.34,3.71l15.54-4.09v-3L25.52,22.87z M24.51,22.52v1.33
                        c0,0.72-0.2,0.86-0.59,0.99l-7.87,2.1v-5.33v-4.44l1.05,2.05c0.2,0.33,0.56,0.67,1.2,0.67c0.21,0,0.45-0.04,0.72-0.12l5.49-1.47
                        V22.52z M25.33,13.79l2.66,2.48c-0.06,0.04-0.17,0.1-0.36,0.15l-2.11,0.57l-1.01,0.27l-5.77,1.54c-0.61,0.19-0.72,0.01-0.76-0.07
                        l-1.57-3.09l7.86-2.21l0.53-0.15L25.33,13.79z M12.85,6.56c-0.47-0.43-0.7-0.94-0.7-1.55c0-0.62,0.26-1.13,0.79-1.55
                        c0.53-0.42,1.23-0.66,2.11-0.72V2.17h1.11v0.56C16.97,2.8,17.61,3,18.09,3.34s0.79,0.79,0.92,1.35l-1.94,0.19
                        c-0.12-0.44-0.42-0.74-0.92-0.9v2.21c1.22,0.24,2.06,0.56,2.5,0.95c0.44,0.39,0.67,0.89,0.67,1.5c0,0.68-0.28,1.25-0.84,1.72
                        c-0.31,0.26-0.7,0.46-1.15,0.61c-0.35,0.12-0.74,0.2-1.18,0.25v0.79l-1.11,0.03v-0.79c-0.36-0.03-0.69-0.1-1-0.19
                        c-0.44-0.13-0.82-0.3-1.15-0.54c-0.55-0.41-0.9-0.98-1.06-1.72l2-0.16c0.08,0.3,0.23,0.56,0.46,0.78c0.22,0.22,0.47,0.38,0.75,0.47
                        V7.51C14.05,7.3,13.31,6.98,12.85,6.56z M11.51,12.2c1.12,0.87,2.52,1.39,4.04,1.39c1.55,0,2.96-0.54,4.09-1.43l3.25,0.61
                        l-6.5,1.82l-0.59-0.15l-7.29-1.82L11.51,12.2z M2.91,16.76c-0.36-0.09-0.39-0.57-0.15-0.81l2.87-2.38l0.56-0.47l0.53,0.13l8.84,2.2
                        l-2.38,3.64c-0.1,0.15-0.25,0.22-0.5,0.22c-0.18,0-0.41-0.04-0.7-0.11l-5.43-1.45l-1-0.27L2.91,16.76z M6.54,23.53l0-4.76
                        l5.17,1.38c0.4,0.1,0.7,0.14,0.96,0.14c0.74,0,1.13-0.36,1.34-0.67l1.04-1.59v3.78v5.13l-7.59-2c-0.96-0.27-0.95-0.52-0.93-1.13
                        c0-0.07,0-0.14,0-0.21C6.54,23.59,6.54,23.56,6.54,23.53z M11.65,29.85L4.17,27.7v-2l7.49,2.15V29.85z M28.6,24.99v2.13l-11.1,2.93
                        v-1.96l9.33-2.63L28.6,24.99z"/>
                      <path fill="#555" d="M15.05,3.95c-0.3,0.08-0.54,0.2-0.72,0.38c-0.18,0.18-0.27,0.38-0.27,0.6c0,0.2,0.08,0.38,0.24,0.56
        c0.16,0.17,0.41,0.31,0.74,0.41V3.95z"/>
                      <path fill="#555" d="M17.45,8.83c0-0.25-0.1-0.47-0.3-0.65c-0.2-0.18-0.53-0.32-0.99-0.42v2.2c0.38-0.05,0.69-0.18,0.93-0.39
        C17.33,9.36,17.45,9.11,17.45,8.83z"/>
                    </g>
                  </svg>
                </Tooltip>
              </div>
              <div className="link">Get quote</div>
            </div>
          </a>

          <a href={'/customer/saved-quotes?pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc'}>
            <div className="row" onClick={(e) => {e.preventDefault(); this.navigateTo('/saved-quotes?pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc')}} >
              <div className="icon">
                {/*<img src="/icons/quotes.png" srcSet="/icons/quotes@2x.png 2x" alt="run-quote" />*/}
                <Tooltip title="My quotes" placement="right" disableHoverListener={this.props.sideNavOpen}>
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path fill="#555" d="M15.35,18.66v-3.41c-1.44-0.3-2.5-0.76-3.17-1.38c-0.67-0.62-1.01-1.36-1.01-2.24c0-0.89,0.38-1.64,1.14-2.24
          c0.76-0.61,1.78-0.95,3.04-1.05V7.53h1.6v0.81c1.17,0.1,2.1,0.4,2.79,0.88c0.69,0.49,1.13,1.14,1.32,1.95l-2.79,0.27
          c-0.17-0.64-0.61-1.07-1.32-1.3v3.18c1.77,0.35,2.97,0.81,3.61,1.37c0.64,0.56,0.96,1.28,0.96,2.16c0,0.98-0.4,1.81-1.21,2.48
          c-0.81,0.67-1.93,1.09-3.36,1.24v1.52h-1.6v-1.48c-1.27-0.11-2.31-0.46-3.1-1.05c-0.79-0.59-1.3-1.41-1.52-2.48l2.88-0.23
          c0.12,0.43,0.34,0.81,0.66,1.12C14.59,18.29,14.95,18.52,15.35,18.66z M15.35,10.11c-0.43,0.11-0.78,0.29-1.04,0.55
          c-0.26,0.26-0.39,0.55-0.39,0.86c0,0.29,0.12,0.55,0.35,0.8c0.24,0.25,0.59,0.45,1.07,0.6V10.11z M16.95,18.77
          c0.55-0.08,1-0.26,1.35-0.57c0.35-0.3,0.52-0.66,0.52-1.06c0-0.36-0.15-0.68-0.44-0.94c-0.29-0.26-0.77-0.47-1.43-0.61V18.77z"/>
                    </g>
                    <path fill="#555" d="M10.52,0.79L3.59,7.28v23.93h24.83V0.79H10.52z M27.03,29.82H4.97V7.88l6.17,0.02V2.18h15.88V29.82z"/>
                    <rect x="7.71" y="24.62" fill="#555" width="16.91" height="1.91"/>
                  </svg>
                </Tooltip>
              </div>
              <div className="link">My quotes</div>
            </div>
          </a>

          <a href={'/customer/booking?pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc'}>
            <div className="row" onClick={(e) => {e.preventDefault(); this.navigateTo('/booking?pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc')}} >
              <div className="icon">
                {/*<img src="/icons/shipments.png" srcSet="/icons/shipments@2x.png 2x" alt="shipments" />*/}
                <Tooltip title="My shipments" placement="right" disableHoverListener={this.props.sideNavOpen}>
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <path fill="#555" d="M26,18.68c-1.45,0-2.62,1.17-2.62,2.62c0,1.45,1.17,2.62,2.62,2.62c1.45,0,2.62-1.17,2.62-2.62
        C28.62,19.85,27.45,18.68,26,18.68z M26,22.63c-0.74,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.74,0,1.33,0.6,1.33,1.33
        S26.74,22.63,26,22.63z"/>
                    <path fill="#555" d="M11.06,18.32c-1.64,0-2.98,1.34-2.98,2.98s1.34,2.98,2.98,2.98s2.98-1.34,2.98-2.98S12.7,18.32,11.06,18.32z
         M11.06,22.63c-0.74,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.74,0,1.33,0.6,1.33,1.33S11.8,22.63,11.06,22.63z"/>
                    <path fill="#555" d="M1.57,9.3c-0.48,0-0.87,0.39-0.87,0.87v0c0,0.48,0.39,0.87,0.87,0.87h3.29V9.3H1.57z"/>
                    <path fill="#555" d="M1.57,16.08c-0.48,0-0.87,0.39-0.87,0.87v0c0,0.48,0.39,0.87,0.87,0.87h3.29v-1.75H1.57z"/>
                    <path fill="#555" d="M3.19,12.69c-0.48,0-0.87,0.39-0.87,0.87v0c0,0.48,0.39,0.87,0.87,0.87h1.67v-1.75H3.19z"/>
                    <path fill="#555" d="M30.51,19.29h-0.38v-3.05l-3.33-5.94h-6.08V7.03H5.96v12.26h-0.1c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h0.59
        v1.44c0.8,0,1.15-0.41,1.27-0.96V21.3h0c0-1.06,0.5-2.01,1.27-2.62H7.42V8.56h11.93v10.12h-6.22c0.77,0.61,1.27,1.56,1.27,2.62h7
        v1.44c0.8,0,1.15-0.41,1.27-0.96V21.3h0c0-1.84,1.5-3.34,3.34-3.34c1.84,0,3.34,1.5,3.34,3.34h1.16c0.55,0,1-0.45,1-1
        C31.51,19.74,31.06,19.29,30.51,19.29z M22.4,14.85v-3.37h3.83l2.74,4.74L22.4,14.85z"/>
                  </svg>
                </Tooltip>
              </div>
              <div className="link">My shipments</div>
            </div>
          </a>

          <a href={'/customer/account-settings'}>
            <div className="row" onClick={(e) => {e.preventDefault(); this.navigateToManagePayments()}} >
              <div className="icon">
                {/*<img src="/icons/payments.png" srcSet="/icons/payments@2x.png 2x" alt="payments" />*/}
                <Tooltip title="Payment methods" placement="right" disableHoverListener={this.props.sideNavOpen}>
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path fill="#555" d="M21.11,15.3H2.86c-0.93,0-1.69,0.76-1.69,1.69v11.89c0,0.93,0.76,1.69,1.69,1.69h18.25
          c0.93,0,1.69-0.76,1.69-1.69V16.98C22.8,16.05,22.04,15.3,21.11,15.3z M21.46,28.4c0,0.53-0.43,0.96-0.96,0.96H3.47
          c-0.53,0-0.96-0.43-0.96-0.96V17.46c0-0.53,0.43-0.96,0.96-0.96H20.5c0.53,0,0.96,0.43,0.96,0.96V28.4z"/>
                    </g>
                    <g>
                      <path fill="#555" d="M30.33,14.18L17.43,1.26c-0.66-0.66-1.73-0.66-2.39,0L6.63,9.66c-0.66,0.66-0.66,1.73,0,2.39l2.73,2.74h6.5
          l-4.23-4.24l1.56-1.56l5.79,5.8h2.13c1.21,0,2.2,0.98,2.2,2.2v6.59l7.02-7.02C30.99,15.9,30.99,14.83,30.33,14.18z M26.54,18.34
          L13.48,5.26l2.15-2.15L28.69,16.2L26.54,18.34z"/>
                    </g>
                    <g>
                      <path fill="#555" d="M11.5,25.81v-2.16c-0.92-0.19-1.59-0.48-2.01-0.88c-0.43-0.39-0.64-0.87-0.64-1.42c0-0.56,0.24-1.04,0.73-1.42
          s1.13-0.61,1.93-0.66v-0.51h1.02v0.51c0.74,0.07,1.33,0.25,1.77,0.56c0.44,0.31,0.72,0.72,0.84,1.24l-1.77,0.17
          c-0.11-0.41-0.39-0.68-0.84-0.83v2.02c1.12,0.22,1.88,0.51,2.29,0.87c0.41,0.36,0.61,0.81,0.61,1.37c0,0.62-0.26,1.15-0.77,1.58
          c-0.51,0.43-1.22,0.69-2.13,0.79v0.97H11.5v-0.94c-0.81-0.07-1.46-0.29-1.97-0.67c-0.5-0.37-0.83-0.9-0.97-1.58l1.83-0.14
          c0.07,0.28,0.21,0.51,0.42,0.71C11.02,25.58,11.25,25.72,11.5,25.81z M11.5,20.39c-0.28,0.07-0.5,0.19-0.66,0.35
          c-0.16,0.17-0.25,0.35-0.25,0.55c0,0.18,0.07,0.35,0.22,0.51c0.15,0.16,0.38,0.28,0.68,0.38V20.39z M12.52,25.89
          c0.35-0.05,0.64-0.17,0.85-0.36c0.22-0.19,0.33-0.42,0.33-0.67c0-0.23-0.09-0.43-0.28-0.6c-0.18-0.17-0.49-0.3-0.91-0.38V25.89z"/>
                    </g>
                  </svg>
                </Tooltip>
              </div>
              <div className="link">Payment methods</div>
            </div>
          </a>

          <a href={'/customer/account-settings'}>
            <div className="row" onClick={(e) => {e.preventDefault(); this.navigateTo('/account-settings')}}>
              <div className="icon">
                {/*<img src="/icons/settings.png" srcSet="/icons/settings@2x.png 2x" alt="settings" />*/}
                <Tooltip title="Settings" placement="right" disableHoverListener={this.props.sideNavOpen}>
                  <svg xmlns="http://www.w3.org/2000/svg">

                    <path fill="#555" d="M18.4,18.73h-0.52c0,0,0,0,0,0c-0.1,0-0.19-0.06-0.22-0.16c-0.06-0.15-0.12-0.31-0.19-0.46
        c-0.04-0.09-0.02-0.2,0.05-0.27l0.37-0.37c0.63-0.64,0.63-1.67,0-2.31l-1.32-1.32c-0.64-0.63-1.67-0.63-2.31,0l-0.37,0.37
        c-0.07,0.07-0.18,0.09-0.27,0.05c-0.15-0.07-0.3-0.13-0.46-0.19C13.06,14.03,13,13.95,13,13.85v-0.52c0-0.9-0.73-1.63-1.63-1.63
        H9.49c-0.9,0-1.63,0.73-1.63,1.63v0.52c0,0.1-0.06,0.19-0.16,0.22c-0.15,0.06-0.31,0.12-0.46,0.19c-0.09,0.04-0.2,0.02-0.27-0.05
        l-0.37-0.37c-0.64-0.63-1.67-0.63-2.31,0l-1.32,1.33c-0.63,0.64-0.63,1.67,0,2.31l0.37,0.37c0.07,0.07,0.09,0.18,0.05,0.27
        c-0.07,0.15-0.13,0.3-0.19,0.46c-0.03,0.09-0.13,0.17-0.22,0.15H2.46c-0.9,0-1.63,0.73-1.63,1.63v1.87c0,0.9,0.73,1.63,1.63,1.63
        h0.52c0,0,0,0,0,0c0.1,0,0.19,0.06,0.22,0.16c0.06,0.15,0.12,0.31,0.19,0.46c0.04,0.09,0.02,0.2-0.05,0.27l-0.37,0.37
        c-0.63,0.64-0.63,1.67,0,2.31l1.32,1.32c0.64,0.63,1.67,0.63,2.31,0l0.37-0.37c0.07-0.07,0.18-0.09,0.27-0.05
        c0.15,0.07,0.3,0.13,0.46,0.19c0.09,0.03,0.16,0.12,0.16,0.22v0.52c0,0.9,0.73,1.63,1.63,1.63h1.87c0.9,0,1.63-0.73,1.63-1.63v-0.52
        c0-0.1,0.06-0.19,0.16-0.22c0.15-0.06,0.31-0.12,0.46-0.19c0.09-0.04,0.2-0.02,0.27,0.05l0.37,0.37c0.64,0.63,1.67,0.63,2.31,0
        l1.32-1.32c0.63-0.64,0.63-1.67,0-2.31l-0.37-0.37c-0.07-0.07-0.09-0.18-0.05-0.27c0.07-0.15,0.13-0.3,0.19-0.46
        c0.03-0.09,0.12-0.15,0.22-0.15c0,0,0,0,0,0h0.52c0.9,0,1.63-0.73,1.63-1.63v-1.87C20.03,19.46,19.3,18.73,18.4,18.73z M16.36,23.53
        c-0.05,0.13-0.1,0.25-0.16,0.37c-0.28,0.61-0.15,1.35,0.33,1.83l0.37,0.37c0.05,0.05,0.07,0.11,0.07,0.17
        c0,0.06-0.03,0.13-0.07,0.17l-1.32,1.32c-0.09,0.09-0.25,0.09-0.34,0l-0.37-0.37c-0.48-0.47-1.21-0.6-1.83-0.33
        c-0.12,0.06-0.25,0.11-0.37,0.16c-0.63,0.24-1.05,0.85-1.05,1.52v0.52c0,0.13-0.11,0.24-0.24,0.24H9.49c-0.13,0-0.24-0.11-0.24-0.24
        v-0.52c0-0.67-0.43-1.28-1.05-1.52c-0.13-0.05-0.25-0.1-0.37-0.16c-0.21-0.1-0.44-0.14-0.67-0.14c-0.42,0-0.84,0.16-1.16,0.47
        l-0.37,0.37c-0.09,0.09-0.25,0.09-0.34,0l-1.32-1.32c-0.09-0.09-0.09-0.25,0-0.34l0.37-0.37c0.47-0.48,0.6-1.21,0.33-1.83
        c-0.06-0.12-0.11-0.25-0.16-0.37c-0.24-0.63-0.85-1.05-1.52-1.05H2.46c-0.13,0-0.24-0.11-0.24-0.24v-1.87
        c0-0.13,0.11-0.24,0.24-0.24h0.52c0.68,0,1.28-0.42,1.52-1.05c0.05-0.13,0.1-0.25,0.16-0.37c0.28-0.61,0.15-1.35-0.33-1.83
        L3.96,16.5c-0.05-0.05-0.07-0.11-0.07-0.17c0-0.06,0.02-0.12,0.07-0.17l1.32-1.32c0.09-0.09,0.25-0.09,0.34,0L6,15.2
        c0.48,0.47,1.21,0.6,1.83,0.33c0.12-0.06,0.25-0.11,0.37-0.16c0.63-0.24,1.05-0.85,1.05-1.52v-0.52c0-0.13,0.11-0.24,0.24-0.24h1.87
        c0.13,0,0.24,0.11,0.24,0.24v0.52c0,0.67,0.43,1.28,1.05,1.52c0.13,0.05,0.25,0.1,0.37,0.16c0.61,0.28,1.35,0.15,1.83-0.33
        l0.37-0.37c0.09-0.09,0.25-0.09,0.34,0l1.32,1.32c0.09,0.09,0.09,0.25,0,0.34l-0.37,0.37c-0.47,0.48-0.6,1.21-0.33,1.83
        c0.06,0.12,0.11,0.25,0.16,0.37c0.24,0.63,0.85,1.05,1.52,1.05h0.52c0.13,0,0.24,0.11,0.24,0.24v1.87c0,0.13-0.11,0.24-0.24,0.24
        h-0.52C17.21,22.48,16.6,22.9,16.36,23.53z"/>
                    <path fill="#555" d="M29.14,10.8h-1.48c-0.11-0.01-0.19-0.06-0.22-0.15c-0.15-0.44-0.33-0.88-0.54-1.3
        c-0.04-0.09-0.03-0.2,0.05-0.27L28,8.03c0.64-0.64,0.64-1.67,0-2.31L26,3.74c-0.64-0.64-1.67-0.64-2.31,0l-1.05,1.05
        c-0.07,0.07-0.18,0.09-0.26,0.05c-0.42-0.21-0.86-0.39-1.3-0.54c-0.09-0.03-0.16-0.12-0.16-0.22V2.59c0-0.9-0.73-1.63-1.63-1.63
        h-2.81c-0.9,0-1.63,0.73-1.63,1.63v1.48c0,0.1-0.06,0.19-0.15,0.22c-0.44,0.15-0.88,0.33-1.3,0.54c-0.09,0.04-0.2,0.03-0.27-0.05
        l-1.05-1.05c-0.64-0.63-1.67-0.63-2.31,0L7.79,5.72c-0.64,0.64-0.64,1.67,0,2.31l1.54,1.54c0.13,0.13,0.31,0.21,0.5,0.2
        c0.19,0,0.36-0.08,0.49-0.21c0.26-0.27,0.26-0.7,0-0.97L8.77,7.05c-0.09-0.09-0.09-0.25,0-0.34l1.99-1.99
        c0.09-0.09,0.25-0.09,0.34,0l1.05,1.04c0.49,0.5,1.25,0.62,1.87,0.31c0.36-0.18,0.74-0.34,1.12-0.47c0.66-0.22,1.1-0.84,1.1-1.54
        V2.59c0-0.13,0.11-0.24,0.24-0.24h2.81c0.13,0,0.24,0.11,0.24,0.24v1.47c0,0.7,0.44,1.32,1.1,1.54c0.38,0.13,0.76,0.28,1.12,0.47
        c0.63,0.31,1.38,0.19,1.87-0.31l1.05-1.04c0.09-0.09,0.25-0.09,0.34,0l1.99,1.99c0.09,0.09,0.09,0.25,0,0.34L25.96,8.1
        c-0.5,0.49-0.62,1.25-0.31,1.87c0.18,0.36,0.34,0.74,0.47,1.12c0.22,0.66,0.84,1.1,1.53,1.1c0,0,0,0,0.01,0h1.47
        c0.13,0,0.24,0.11,0.24,0.24v2.81c0,0.13-0.11,0.24-0.24,0.24h-1.47c0,0,0,0-0.01,0c-0.7,0-1.31,0.44-1.54,1.1
        c-0.13,0.38-0.28,0.76-0.47,1.12c-0.31,0.63-0.19,1.38,0.31,1.87l1.05,1.05c0.09,0.09,0.09,0.25,0,0.34l-1.99,1.99
        c-0.09,0.09-0.25,0.09-0.34,0l-1.54-1.53c-0.13-0.13-0.3-0.21-0.49-0.21c-0.18-0.01-0.36,0.07-0.5,0.2
        c-0.13,0.13-0.21,0.3-0.21,0.49c0,0.19,0.07,0.36,0.21,0.51l1.54,1.54c0.32,0.32,0.74,0.48,1.15,0.48c0.42,0,0.84-0.16,1.15-0.48
        l1.99-1.99c0.64-0.64,0.64-1.67,0-2.31l-1.05-1.05c-0.07-0.07-0.09-0.18-0.05-0.26c0.21-0.42,0.39-0.86,0.54-1.3
        c0.03-0.09,0.12-0.16,0.22-0.16c0,0,0,0,0,0h1.47c0.9,0,1.63-0.73,1.63-1.63v-2.81C30.77,11.53,30.04,10.8,29.14,10.8z"/>
                    <g>
                      <g>
                        <path fill="#555" d="M23.13,9.2c-2.38-2.38-6.24-2.38-8.62,0c-0.18,0.19-0.17,0.48,0.01,0.66c0.18,0.18,0.47,0.18,0.65,0
            c2-2.03,5.26-2.05,7.29-0.05s2.05,5.26,0.05,7.29c-0.02,0.02-0.03,0.03-0.05,0.05c-0.19,0.18-0.19,0.48-0.01,0.66
            c0.18,0.19,0.48,0.19,0.66,0.01c0,0,0.01-0.01,0.01-0.01C25.5,15.44,25.5,11.58,23.13,9.2z"/>
                      </g>
                    </g>
                    <path fill="#555" d="M14.64,21.11c0-0.11-0.01-0.22-0.03-0.34c-0.01-0.12-0.03-0.24-0.06-0.36c-0.02-0.08-0.04-0.15-0.06-0.23
        c-0.03-0.1-0.06-0.19-0.09-0.29c-0.02-0.07-0.05-0.13-0.07-0.19c-0.06-0.15-0.13-0.3-0.21-0.44c-0.01-0.01-0.01-0.02-0.02-0.03
        c-0.12-0.22-0.26-0.42-0.42-0.61c-0.01-0.02-0.03-0.03-0.04-0.05c-0.06-0.08-0.13-0.15-0.2-0.22c-0.04-0.04-0.07-0.07-0.11-0.11
        c-0.05-0.04-0.09-0.09-0.14-0.13c-0.06-0.05-0.11-0.1-0.17-0.14l-0.08-0.06c-0.73-0.54-1.61-0.84-2.51-0.84
        c-0.05,0-0.09,0.01-0.14,0.01c-0.1,0-0.2,0.01-0.3,0.02c-0.05,0.01-0.11,0.01-0.16,0.02c-0.15,0.02-0.3,0.05-0.45,0.08
        c-2.26,0.58-3.61,2.89-3.03,5.14c0.38,1.49,1.55,2.65,3.03,3.03c0.01,0,0.02,0,0.02,0c0.16,0.04,0.33,0.07,0.49,0.09
        c0.11,0.01,0.22,0.02,0.33,0.02c0.07,0,0.14,0.01,0.22,0.01c0.13,0,0.27-0.01,0.41-0.02c0.01,0,0.03,0,0.05-0.01
        c0.11-0.01,0.23-0.03,0.35-0.05c0.05-0.01,0.09-0.02,0.14-0.03c0.08-0.02,0.16-0.04,0.25-0.06c0.07-0.02,0.13-0.04,0.19-0.07
        c0.06-0.02,0.13-0.05,0.2-0.07c0.07-0.03,0.12-0.05,0.18-0.08c0.08-0.04,0.16-0.07,0.23-0.11h0c0.44-0.23,0.84-0.53,1.2-0.87
        c0.1-0.09,0.18-0.19,0.25-0.31c0.37-0.59,0.61-1.24,0.71-1.93c0.01-0.09,0.02-0.18,0.03-0.27c0.01-0.08,0.01-0.17,0.01-0.25
        c0-0.03,0-0.05,0-0.08C14.65,21.24,14.64,21.17,14.64,21.11z"/>
                  </svg>
                </Tooltip>
              </div>
              <div className="link">Settings</div>
            </div>
          </a>

        </div>
      </div>
    );
  }
}

SideNavbar.propTypes = {
  history: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {
  const { customerFirstName, customerLastName } = state.accountInfo.customerState;
  return {
    name: `${customerFirstName || ''} ${customerLastName || ''}`,
    sideNavOpen: state.common.sideNavOpen,
    ratingRequestLock: state.accountInfo.customerState.preference
      ? state.accountInfo.customerState.preference.ratingRequestLock
      : null
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleSideNav: () => dispatch(toggleSideNav())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavbar);
