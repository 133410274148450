// @flow

// /file/{fileId}

export const LOGIN = 'login/LOGIN';
export const SET_CREDENTIALS = 'login/SET_CREDENTIALS';
export const EMAIL_SENT = 'login/EMAIL_SENT';
export const SET_ACCOUNT_VIEW = 'login/SET_ACCOUNT_VIEW';
export const SET_LOGIN_VIEW = 'login/SET_LOGIN_VIEW';
export const SET_GUEST_CREDENTIALS = 'login/SET_GUEST_CREDENTIALS';
export const ALLOW_GET_QUOTE = 'login/ALLOW_GET_QUOTE';
export const PASSWORD_UPDATED = 'login/PASSWORD_UPDATED';
export const RESET_FLAG = 'login/RESET_FLAG';
export const SET_HEALTH_CHECK = 'login/SET_HEALTH_CHECK';
export const SET_TO_MANY_LOGIN_ATTEMPTS = 'login/SET_TO_MANY_LOGIN_ATTEMPTS';