import React from 'react';
import utilCommon, { getFormatIncludeCommas } from '../../../../helpers/utilCommon';
import quoteService from '../../../../services/quoteService';
import * as type from '../../../../store/quote/actionTypes';
import { connect } from 'react-redux';
import CustomizedTooltips from '../../../molecules/CustomizedTooltips';
import QuoteResultsBaseClass, { SelectedRate } from '../QuoteResultsBaseClass';
import {withRouter} from "react-router-dom";
import {AccessTime} from "@material-ui/icons";
import lodash from "lodash";


class HeaderSignleRate extends QuoteResultsBaseClass {
    
    componentWillMount() {
      super.componentWillMount();
    }

    componentDidMount() {
      super.componentDidMount();

      setTimeout(()=>{
        if ((!this.props.quoteResults || !this.props.quoteResults.length) && this.props.quotePayload) {
          this.refreshQuote();
        }
      },2)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
      if (!(nextProps.quoteResults && nextProps.quoteResults.length)) {
        // Window['history'].push('/customer/freight-quote')
      }
    }

    updateDimensions() {
      super.updateDimensions();
      this.setState({ width: window.innerWidth});
    }

    goBack = () => {
      this.props.setLoader(false);
      this.navigateTo('/customer/freight-quote');
    }

    refreshQuote = () => {
      const self = this;
      if (this.props.quotePayload) {
        self.props.setLoader(true);
        this.props.getQuoteResults(this.props.currentLanguage, this.props.dictionaries, this.props.quotePayload);
      } else {
        this.goBack();
      }
    }

    get shippingWording() {
      const items = this.props.quoteQuery.items;

      const weight = items.reduce((a, b) => a + utilCommon.convertWeightToPound(b.weightUnit, b.weightPerHandlingUnit) * Number(b.howManyHandlingUnits), 0);
      const count = items.reduce((a, b) => a + Number(b.howManyHandlingUnits), 0);
      return `${getFormatIncludeCommas(count)} ${items.every(i => i.palleteType == 2) ? 'pallet' : 'item'}${items.length > 1 ? 's' : ''} weighing ${getFormatIncludeCommas(weight)}lbs`;
    }

    get extraServices() {
      const self = this;
      const accessorials = self.getAccessorioals(self.props.quoteQuery);
      return accessorials.map(i => i.name).join(', ');
    }

    toggleRate = () => {
      if (this.props.quoteResults[0].FCGuaranteedRateQuoteDetail) {
        this.setState({selectedRate: this.state.selectedRate === SelectedRate.standard ? SelectedRate.guaranteed : SelectedRate.standard });
      }
    }

    get isMobile() {
      return this.state.width < 768;
    }

    getPriceToDisplay = (price, onlineBookingDiscount) => {
      return getFormatIncludeCommas((parseFloat(price) + onlineBookingDiscount).toFixed(2)).split('.');
    }

    render() {
        const rate = this.props.quoteResults.hasOwnProperty(0) ? this.props.quoteResults[0] : false;
        const {changeCaptionButton} = this.props;
        // for testing
        // rate && (rate.FCGuaranteedRateQuoteDetail = rate.rateQuoteDetail);

        return (
            <div className="single-rate">
                {/* <div className="result-banner">
                    <div className="text-center">
                        <AccessTime className="access-time"/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        Requested pickup dates and estimated transit times may be delayed due to increased seasonal volume of shipments and the current
                        pressure of supply chain disruptions.
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <AccessTime className="access-time"/>
                    </div>
                </div> */}
                <div className={'results ' + (rate && rate.FCGuaranteedService == '1' ? SelectedRate.guaranteed : this.state.selectedRate) + (!rate ? ' empty' : '')}>
                    {rate && 
                        <div className="container">
                            <div className="left-side">
                                <h1>Shipment Details</h1>
                                <div className="details">
                                    <div className="row">
                                        <div className="icon">
                                            <img src="/icons/box.png" srcSet="/icons/box@2x.png 2x" alt="box" className="box-icon"/>
                                        </div>
                                        <span className="header">Shipping: </span>{this.shippingWording}
                                    </div>
                                    
                                    {this.props.quoteQuery.shippingFromZip && 
                                        <div className="row">
                                            <div className="icon">
                                                <img src="/icons/arrows.png" srcSet="/icons/arrows@2x.png 2x" alt="arrows" className="arrows-icon"/>
                                            </div>
                                            <span className="header">From & To: </span>{`${this.props.quoteQuery.shippingFromZip.zip} (${this.props.quoteQuery.shippingFromZip.city}) to ${this.props.quoteQuery.shippingToZip.zip} (${this.props.quoteQuery.shippingToZip.city})`}
                                        </div>
                                    }
                                    
                                    <div className="row">
                                        <div className="icon">
                                            <img src="/icons/truck.png" srcSet="/icons/truck@2x.png 2x" alt="truck" className="truck-icon"/>
                                        </div>
                                        <span className="header">Via: </span>Less Than Truck Load (LTL)
                                        <CustomizedTooltips header="Less Than Truckload (LTL)"
                                                            content="LTL is like car pooling for freight, combining shipments from multiple customers to save money"/>
                                    </div>
                                    
                                    {rate && 
                                        <div className="row">
                                            <div className="icon">
                                                <img src="/icons/chronometer.png" srcSet="/icons/chronometer@2x.png 2x" alt="chronometer" className="chronometer-icon"/>
                                            </div>
                                            <span className="header">Estimated Transit Time: </span>{rate.FCtransitDays || rate.transitDays} Business Day{(rate.FCtransitDays || rate.transitDays) > 1 ? 's' : ''}
                                            <CustomizedTooltips header="Estimated Transit Time"
                                                                content="Requested pickup dates and estimated transit times may be delayed due to the current pressure of supply chain disruptions."/>
                                        </div>
                                    }
                                    
                                    {this.extraServices.length > 0 &&
                                        <div className="row">
                                            <div className="icon">
                                                <img src="/icons/dots.png" srcSet="/icons/dots@2x.png 2x" alt="dots" className="dots-icon"/>
                                            </div>
                                            <span className="header">Extra Services: </span>{this.extraServices}
                                            <CustomizedTooltips header="Extra Services"
                                                                content="Adding extra services may increase the estimated transit time of your shipment."/>
                                        </div>
                                    }
                                    
                                    {rate && 
                                        <div className="row">
                                            <div className="icon">
                                                <img src="/icons/location.png" srcSet="/icons/location@2x.png 2x" alt="location" className="location-icon"/>
                                            </div>
                                            <span className="header">{100 - rate.FCDemageClimPer}% </span>of shipments arrive damage-free
                                        </div>
                                    }
                                    
                                    {rate && 
                                        <div className="row">
                                            <div className="icon">
                                                <img src="/icons/fast.png" srcSet="/icons/fast@2x.png 2x" alt="fast" className="fast-icon"/>
                                            </div>
                                            <span className="header">{rate.FCOntimPercentage}% </span>of deliveries are made on-time
                                        </div>
                                    }
                                </div>
    
                                <span className="link" onClick={() => this.goBack()}>
                                    &lt; Edit Shipment
                                </span>
                            </div>
    
                            {rate.FCGuaranteedService != '1' && 
                                <div className={'right-side' + (rate && rate.FCGuaranteedRateQuoteDetail ? '' : ' standard-only')}>
                                    <div className="header">
                                        <h2>Your online rate{rate && rate.FCGuaranteedRateQuoteDetail ? 's' : ''}
                                            &nbsp;&nbsp;<CustomizedTooltips header="Online Booking Savings"
                                                                content="Maximize your savings by booking your shipment online instead of over the phone."/>
                                        </h2>
                                    </div>
                                    
                                    <div className="rates">
                                        {rate && 
                                            <div className="selected" onClick={() => this.isMobile && this.toggleRate()}>
                                                {this.isMobile && <div className="arrow-down"/>}
                                                {!this.isMobile && 
                                                    <p className="value">
                                                        <sup className="currency">&#36;</sup>{this.state.selectedRate === SelectedRate.standard
                                                        ? this.getPriceToDisplay(rate.rateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'rateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]
                                                        : this.getPriceToDisplay(rate.FCGuaranteedRateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'FCGuaranteedRateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]}
                                                        <sub className="label">{this.state.selectedRate === SelectedRate.standard ? 'Standard Delivery' : 'Guaranteed Delivery'}</sub>
                                                        <div style={{display: "block"}}>Special Online Rate</div>
                                                    </p>
                                                }
                                                
                                                {this.isMobile && 
                                                    <p className="value">
                                                        <sup className="currency">&#36;</sup>{this.getPriceToDisplay(rate.rateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'rateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]}
                                                        <sub className="label">Standard Delivery</sub>
                                                        <div style={{fontSize: 10, display: "block"}}>Special Online Rate</div>
                                                    </p>
                                                }
                                                <button className="mobile-booking-btn" onClick={() => this.fcCreateShipment(rate, SelectedRate.standard)}>{changeCaptionButton === true ? 'Select' : 'Book Shipment'}</button>
                                            </div>
                                        }
                                        
                                        {rate && rate.FCGuaranteedRateQuoteDetail &&
                                            <div className="not-selected" onClick={() => this.toggleRate()} style={this.isMobile ? {top: 0} : {}}>
                                                {this.isMobile && <div className="arrow-down"/>}
                                                {!this.isMobile && 
                                                    <p className="value">
                                                        <sup className="currency">&#36;</sup>{this.state.selectedRate === SelectedRate.standard
                                                        ? this.getPriceToDisplay(rate.FCGuaranteedRateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'FCGuaranteedRateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]
                                                        : this.getPriceToDisplay(rate.rateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'rateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]}
                                                        <sub className="label">{this.state.selectedRate === SelectedRate.standard ? 'Guaranteed Delivery' : 'Standard Delivery'}</sub>
                                                    </p>
                                                }
                                                {this.isMobile && 
                                                    <p className="value">
                                                        <sup className="currency">&#36;</sup>{this.getPriceToDisplay(rate.FCGuaranteedRateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'FCGuaranteedRateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]}
                                                        <sub className="label">Guaranteed Delivery</sub>
                                                        <div style={{fontSize: 10, display: "block"}}>Special Online Rate</div>
                                                    </p>
                                                }
                                                <button className="mobile-booking-btn" onClick={() => this.fcCreateShipment(rate, SelectedRate.guaranteed)}>{changeCaptionButton === true ? 'Select' : 'Book Shipment'}</button>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="action-btn">
                                        <button onClick={() => this.fcCreateShipment(rate, this.state.selectedRate)}>{changeCaptionButton === true ? 'Select' : 'Book Shipment'}</button>
                                    </div>
                                    
                                    <div className="share-link">
                                        <img src="/icons/share-option.png" srcSet="/icons/share-option@2x.png 2x" alt="share"/> Share
                                    </div>
                                </div>
                            }
    
                            {/*********  Guaranteed ONLY  *********/}
                            {rate.FCGuaranteedService == '1' && 
                                <div className='right-side guaranteed-only'>
                                    <div className="header">
                                        <h2>Your online rate
                                            &nbsp;&nbsp;<CustomizedTooltips header="Online Booking Savings"
                                                                content="Maximize your savings by booking your shipment online instead of over the phone."/>
                                        </h2>
                                    </div>
                                    <div className="rates">
                                        {rate &&
                                            <div className="selected">
                                                {this.isMobile && <div className="arrow-down"/>}
                                                <p className="value">
                                                    <sup className="currency">&#36;</sup>{this.getPriceToDisplay(rate.rateQuoteDetail.FCTotalCharges, Number(lodash.get(rate, 'rateQuoteDetail.onlineBookingDiscount.amount', 0)))[0]}
                                                    <sub className="label">Guaranteed Delivery</sub>
                                                    <div style={{display: "block"}}>Special Online Rate</div>
                                                </p>
                                                <button className="mobile-booking-btn" onClick={() => this.fcCreateShipment(rate, SelectedRate.standard)}>{changeCaptionButton === true ? 'Select' : 'Book Shipment'}</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="action-btn">
                                        <button onClick={() => this.fcCreateShipment(rate, this.state.selectedRate)}>{changeCaptionButton === true ? 'Select' : 'Book Shipment'}</button>
                                    </div>
                                    <div className="share-link">
                                        <img src="/icons/share-option.png" srcSet="/icons/share-option@2x.png 2x" alt="share"/> Share
                                    </div>
                                </div>
                            }
                            {/*********  Guaranteed ONLY  *********/}
                        </div>
                    }
                    
                    {!rate && 
                        <div className="no-result">
                            <img src="/icons/exclamation.png" srcSet="/icons/exclamation@2x.png 2x" alt="truckInCircle"/>
                            <h2 className="header">OUR APOLOGIES</h2>
                            <p className="info-text">It looks like we’re experiencing some difficulties on our end. Please
                                check back in a moment. Thank you.</p>
                            <div className="buttons">
                                <button onClick={() => this.refreshQuote()}>Refresh</button>
                                <button onClick={() => this.goBack()}>Back to quote</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    ...QuoteResultsBaseClass.mapStateToProps(state),

    template: state.quote.template,
    loading: state.common.loading || state.quote.loading,
    quotePayload: sessionStorage.getItem('quotePayload') && JSON.parse(sessionStorage.getItem('quotePayload'))
});

const mapDispatchToProps = dispatch => ({
    ...QuoteResultsBaseClass.mapDispatchToProps(dispatch),

    getQuoteResults: (lang, dictionaries, inputDTO) => {
        dispatch(quoteService.getQuoteResults(lang, dictionaries, inputDTO))
    },
    setLoader: (loading) => {
        dispatch({ type: type.SET_LOADER, loading })
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderSignleRate));
