import React from 'react';
import PropTypes from 'prop-types';


class CreditAppProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="credit-app-progress-bar">
        <div className="first-row">
          <div className={"point"  + (this.props.step > 0 ? ' filled' : '')} />
          <div className="space" />
          <div className={"point"  + (this.props.step > 1 ? ' filled' : '')} />
          <div className="space" />
          <div className={"point"  + (this.props.step > 2 ? ' filled' : '')} />
          <div className="space" />
          <div className={"point"  + (this.props.step > 3 ? ' filled' : '')} />
          <div className="space" />
          <div className={"point"  + (this.props.step > 4 ? ' filled' : '')} />
        </div>
        <div className="second-row">
          <div className="description">Contact Info</div>
          <div className="description">Business History</div>
          <div className="description">References</div>
          <div className="description">Terms</div>
          <div className="description">End</div>
        </div>
      </div>
    );
  }
}

CreditAppProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default CreditAppProgressBar;
