import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AttentionIcon from "@material-ui/icons/Error";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: '#fff',
    maxWidth: 312,
    fontSize: theme.typography.pxToRem(12),
    padding: '20px',
  },
}))(Tooltip);

const styles = theme => ({
  arrowPopper: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #489BE8 transparent`,
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#489BE8 transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor:  `transparent #489BE8 transparent transparent`
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #489BE8`,
      },
    },
  },

  blueIcon: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #707070 transparent`,
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#707070 transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor:  `transparent #707070 transparent transparent`
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #707070`,
      },
    },
  },

  arrowPopperV3: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #f16a2b transparent`,
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `#f16a2b transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor:  `transparent #f16a2b transparent transparent`
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent #f16a2b`,
      },
    },
  },
  arrowArrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  closeLink: {
    float: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  tooltip:{
    backgroundColor: '#489BE8'
  },
  tooltipV3:{
    backgroundColor: '#f16a2b'
  }
});


class CustomizedTooltips extends React.Component {
  state = {
    arrowRef: null,
    open: false,
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = (e = {}) => {
    e.stopPropagation && e.stopPropagation();
    e.preventDefault && e.preventDefault();
    this.setState({ open: true });
    return false;
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const { classes, header, content, changeColor, blueIcon, cursorPointer } = this.props;
    const title = (
        <React.Fragment>
          <b style={{ fontWeight: 'bold' }}>{header}</b>
          <br/><br/>
          <p>{content}</p>
          <span className={classes.closeLink} onClick={this.handleTooltipClose}>OK</span>
          <span className={classes.arrowArrow} ref={this.handleArrowRef}/>
        </React.Fragment>
    );

    return (
        <ClickAwayListener onClickAway={this.handleTooltipClose}>
          <div className={'customized-tooltip' + (cursorPointer ? ' cursor-pointer' : '')}>
            <HtmlTooltip
                placement="right"
                title={title}
                classes={{
                  popper: ( changeColor === true) ? classes.arrowPopperV3 : (!blueIcon ? classes.arrowPopper : classes.blueIcon),
                  tooltip: ( changeColor === true) ? classes.tooltipV3 : (!blueIcon ? classes.tooltip : {})
                }}
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    modifiers: {
                      arrow: {
                        enabled: Boolean(this.state.arrowRef),
                        element: this.state.arrowRef,
                      },
                    },
                  },
                }}
                onClose={this.handleTooltipClose}
                open={this.state.open}
                disableFocusListener
                disableHoverListener
                disableTouchListener>
              {!blueIcon ?
                  <img src="/icons/attention.png"
                       srcSet="/icons/attention@2x.png 2x"
                       alt="attention"
                       onClick={this.handleTooltipOpen}
                       className="attention-icon"
                  /> :
                  <AttentionIcon
                      className="blue-attention-icon"
                      onClick={this.handleTooltipOpen}
                  />
              }
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
    );
  }
}

CustomizedTooltips.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTooltips);
