import React from 'react';
import {connect} from 'react-redux';
import FClogo from '../../../assets/images/logo-new.svg';
import Button from '@material-ui/core/Button';
import authService from '../../../services/authService';

class CustomerHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smMenu: false,
            menu: [
                {
                    url: '/customer',
                    name: 'My FreightCenter'
                },
                {
                    url: '/customer/booking',
                    params: 'pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc',
                    name: 'Bookings'
                },
                {
                    url : '/customer/saved-quotes',
                    params: 'pageNumber=1&pageSize=50&sortColumn=shipmentUuid&sortOrder=desc',
                    name: 'Saved Quotes'
                }
            ]
        };
    }

    //navigate to routes
    navigateTo = (path) => {
        this.props.history.push(path)
    }

	// logout
	logOut = ()=>{

		// this.props.removeAccesToken()
		this.props.logout(this.props.currentLanguage,this.props.dictionaries,this.props.history)
		// this.props.history.push('/home/login')
		// window.location.reload()

	}

	// return true if location pathname is same as current menu
	enableActive= (currentMenuItem,pathName)=>{
		if(pathName == currentMenuItem || pathName == currentMenuItem+'/'){
			return true;
		}
		return false;
	}

	smMenu = () => {
		this.setState({
			smMenu:!this.state.smMenu
		})

	}

    handleClick = (e, url) => {
        e.preventDefault();
        if ( url !== null) {
            this.navigateTo(url);
        }
    }

    getActivePageClass = (currentMenuItem) => {
        return this.enableActive(currentMenuItem, this.props.location.pathname) ? 'active' : '';
    }

    render() {
        return (
            <div className="headerSection">
                <div className="headerWrapper">
                    <div className="logoSection">
                        <h1><img onClick={() => window.location.href = '/'}
                                 src={FClogo}
                                 title="My FreightCenter"
                                 alt="My FreightCenter"
                            />
                        </h1>
                        <a href="tel:18007167608" data-gtm-event-category="gtm-click-phone"
                           data-gtm-event-label="phone-topnav" className="phone-number">800.716.7608</a>
                    </div>

                    {!this.props.isSidebarEnabled &&
                        <div className={this.state.smMenu ? 'smMenu open' : 'smMenu '} onClick={this.smMenu.bind(this)}>
                            <span/>
                        </div>
                    }
                    <div className={this.state.smMenu ? 'hederRight open' : 'hederRight close'} onClick={() => this.smMenu()}>
                        <div className="topNav account">
                            <ul>
                                {
                                    this.state.menu.map( (menuItem) => {
                                        let url = menuItem.url;

                                        if ( menuItem.hasOwnProperty('params'))
                                            url += '?' + menuItem.params;

                                        return (
                                            <li onClick={(e) => this.handleClick(e, url)}
                                                className={this.getActivePageClass(menuItem.url)}
                                            >
                                                <a href={url}>{menuItem.name}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="accountSection relative"><Button><i class="zmdi zmdi-account-circle"/></Button>
                            <div className="popupMenu">
                                <ul className="menuList">
                                    <li onClick={(e) => this.handleClick(e,'/customer/account-settings')}>
                                        My Account
                                    </li>
                                    <li onClick={(e) => this.logOut()} className={''}>Log Out</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {this.props.isSidebarEnabled &&
                        <div className={this.props.sideNavOpen ? 'sdMenu open' : 'sdMenu'} onClick={() => this.props.toggleSideNav()}>
                            <span/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
});

// map dispatch to props
const mapDispatchToProps = dispatch => ({
	removeAccesToken : () => {
        dispatch({ type : 'login/SET_CREDENTIALS' , token : null , email : null, refreshToken : null })
	},
	logout:(lang,dict,history)=>{
		authService.logout(dispatch,lang,dict,history)
	}
});
// connect to store
export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader)
