// @flow

// https://github.com/diegohaz/arc/wiki/Reducers
import camelCase from 'lodash/camelCase';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';

// App initial state
const reducers = {
  i18nReducer,
  form,
};

// Combines all reducers
const req = (require: any).context('.', true, /\.\/.+\/reducer\.js$/);
req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'));
  reducers[storeName] = req(key).default;
});

export default combineReducers(reducers);
