let apiUrls = {
    baseUrl: process.env.REACT_APP_API_URL,
    localBaseUrl: process.env.REACT_APP_API_URL,
    live: {
        login: {
            getMasterData: {
                url: '/composite-master?status=1',
                version: '/v1'
            },
            getTermsConditions: {
                url: '/master/get-terms-condition',
                version: '/v1'
            },
            signUp: {
                url: '/customer/registration',
                version: '/v1'
            },
            guestSignUp: {
                url: '/customer/short-registration',
                version: '/v1'
            },
            updateShippingFrequency: {
                url: '/customer/shipping-frequency',
                version: '/v1',
            },
            guestSignUpFacebook: {
                url: '/customer/sign-up-facebook',
                version: '/v1',
            },
            linkSocialAccount: {
                url: '/customer/link-social',
                version: '/v1'
            },
            login: {
                url: '/customer-login',
                version: '/v1'
            },
            resetPassword: {
                url: '/customer/reset-pwd',
                version: '/v1'
            },
            forgotPassword: {
                url: '/customer/forgot-pwd',
                version: '/v1'
            },
            changePassword: {
                url: '/customer/password',
                version: '/v1'
            },
            getTimezone: {
                url: '/timezone',
                version: '/v1'
            },
            getProfile: {
                url: '/customer/profile',
                version: '/v1'
            },
            logout:{
                url: '/fc-logout',
                version: '/v1'
            },
            resetToken:{
                url:'/customer/reset-token',
                version:'/v1'
            },
            hashInfo: {
                url: '/customer/hash-info',
                version: '/v2'
            },
            // healthCheck: {
            //     url: '/health-check',
            //     version: '/v1'
            // },
        },
        reward:{
            signup: {
                url: '/reward/signup',
                version: '/v1'
            },
        },
        customer: {
            auth: {
                url: '/login',
                version: '/v1'
            },
            getSavedQuotes: {
                url: '/shipment',
                version: '/v1'
            },
            getShipments: {
                url: '/list-shipment',
                version: '/v1'
            },
            master: {
                url: '/master/composite-customer',
                version: '/v1'
            },
            customerCards: {
                url: '/payment/fc-card',
                version: '/v1'
            },
            achAccounts: {
                url: '/fc/ach',
                version: '/v1'
            },
            addAchAccount: {
                url: '/fc/ach/enroll',
                version: '/v1'
            },
            deleteAchAccount: {
                url: '/fc/ach/delete-payment-account',
                version: '/v1'
            },
            address: {
                url: '/customer/fc-address',
                version: '/v1'
            },
            customerAddress: {
                url: '/customer/address',
                version: '/v1'
            },
            termsAndConditions:{
                url: '/customer/fc-terms-and-conditions',
                version: '/v1'
            },
            insuranceTC: {
                url: '/fc-customer-falvey-term',
                version: '/v1'
            },
            insuranceTermsAndConditions: {
                url: '/master/fc-falvey-terms-condition',
                version: '/v1'
            },
            customerUpdate: {
                url: '/customer/update-registration',
                version: '/v1'
            },
            unsubscribeEmail: {
                url: '/customer/unsubscribe-email',
                version: '/v1'
            },
            paymentAR: {
                url: '/payment/ar-info',
                version: '/v1'
            },
            update: {
                url: '/fc-customer',
                version: '/v1',
            },
            sendEmailToFtl: {
                url: '/send-email-to-ftl',
                version: '/v1'
            },
            creditApp:{
                url: '/fc/credit-app',
                version: '/v1'
            },
            widgets:{
                url: '/customer/widgets',
                version: '/v1'
            },
            actionNotify:{
                url: '/customer/widgets/action-notify',
                version: '/v2'
            },
            exportCustomerOrders: {
                url: '/fc/export-customer-orders',
                version: '/v1'
            },
            paymentProfile: {
                url: '/fc/ach',
                version: '/v1'
            },
            payInvoices:{
                url: '/fc/ach/payment',
                version: '/v1'
            },
            storeCredit: {
                url: '/customer/store-credit',
                version: '/v2'
            },
        },
        shipment: {
            shipment: {
                url: '/shipment',
                version: '/v1'
            },
            master:{
                url: '/master/composite-shipment',
                version: '/v1'
            },
            facility: {
                url: '/master/loading-facility',
                version: '/v1'
            },
            updateShipmentInfo: {
                url: '/shipment-info',
                version: '/v1'
            },
            fcUpdateShipmentData: {
                url: '/fc-shipment-data',
                version: '/v1'
            },
            getProductAndInsurance: {
                url: '/shipment/fc-product-and-insurance',
                version: '/v1'
            },
            validateInsurance: {
                url: '/shipment/fc-calculate-insurance',
                version: '/v1'
            },
            transaction: {
                url: '/shipment/fc-transaction',
                version: '/v1'
            },
            deactivateShipment: {
                url: '/shipment/fc-deactivate',
                version: '/v1'
            },
            locationDetails: {
                url: '/shipment/fc-address',
                version: '/v1'
            },
            productDetails: {
                url: '/shipment/fc-product',
                version: '/v1'
            },
            termsAndConditions: {
                url: '/master/fc-falvey-terms-condition',
                version: '/v1'
            },
            tracking:{
                url:'/fc-shipment-tracking',
                version:'/v1'
            },
            trackingDetails:{
                url:'/fc-shipment-tracking-details',
                version:'/v1'
            },
            shipmentDetail: {
                url: '/shipment/fc-shipment-details',
                version: '/v1'
            },
            documentInfo: {
                url: '/shipment/document-info',
                version: '/v1'
            },
            shInfo: {
                url: '/shipment/sh-info',
                version: '/v1'
            },
            signCA: {
                url: '/shipment/sign-ca',
                version: '/v1'
            },
            signCRA: {
                url: '/shipment/sign-cra',
                version: '/v2'
            },
            transactionDetails : {
                url : '/shipment/fc-transaction-detail',
                version : '/v1'
            },
            shipmentCharge : {
                url : '/shipment/fc-shipment-charges',
                version : '/v1'
            },
            shipmentPayment : {
                url : '/shipment/fc-shipment-payment',
                version : '/v1'
            },
            cancelShipment:{
                url : '/shipment/fc-cancel',
                version : '/v1'
            },
            documents:{
                url : '/shipment/fc-documents-preview',
                version : '/v1'
            },
            getDocuments: {
                url : '/shipment/documents',
                version : '/v1'
            },
            documentBol: {
                url: '/shipment/documents/bol',
                version: '/v1'
            },
            documentPreview: {
                url: '/shipment/documents-preview',
                version: '/v1'
            },
            editShipmentName: {
                url: '/fc/edit-shipment-name',
                version: '/v1'
            },
            cra:{
                url : '/shipment/cra-documents-preview',
                version : '/v1'
            },
            uploadImage: {
                url: '/shipment/upload-image',
                version: '/v2'
            },
            getProductImages: {
                url: '/shipment/product/images',
                version: '/v2'
            },
            deleteImage: {
                url: '/shipment/delete-image',
                version: '/v2'
            },
            updateTLShipment:{
                url: '/tl/shipment',
                version: '/v1'
            }
        },
        quote: {
            getFaciltyTypes: {
                url: '/master/facility-type-accessorial?status=1&visible=1',
                version: '/v1'
            },
            getQuoteResults: {
                url: '/fc-get-rate-quote',
                version: '/v1'
            },
            createShipment: {
                url: '/fc-shipment',
                version: '/v1'
            },
            fcCreateTlShipment: {
                url: '/tl/fc-create-shipment',
                version: '/v1'
            },
            fcCreateShipment: {
                url: '/fc-create-shipment',
                version: '/v1'
            },
            getCarriers: {
                url: '/fc-carrier',
                version: '/v1'
            },
            customerMaster: {
                url: '/master/composite-customer',
                version: '/v1'
            },
            getProducts: {
                url: '/customer/fc-product?pageSize=1000&pageNumber=1&sortColumn=addedDate&sortOrder=desc',
                version: '/v1'
            },
            viewQuote:{
                url: '/fc-view-shipment',
                version: '/v1'
            },
            reRunQuote : {
                url: '/shipment/fc-re-run',
                version: '/v1'
            },
            fcReRunQuote : {
                url: '/shipment/fc-rerun',
                version: '/v1'
            }
        },
        settings: {
            popUpContent: {
                url: '/pop-up-content',
                version: '/v2'
            }
        }
    },
    local: {
        login: {
            getMasterData: {
                url: '/composite-master?status=1',
                version: '/v1'
            }
        },
        customer: {
            auth: {
                url: '/login',
                version: '/v1'
            }
        },
        zipCode: {
            list: {
                url: '/zipcode/list',
                version: '/v1'
            }
        },
    },
}
// generate api url
function getApiUrl(key, name) {
    let url
    if (apiUrls.baseUrl != null) {
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = apiUrls.baseUrl + apiUrls.live[key][name]['version'] + apiUrls.live[key][name]['url']
        } else {
            if (apiUrls.local[key] && apiUrls.local[key][name]) {
                url = apiUrls.localBaseUrl + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
            }
        }
    } else {
        if (apiUrls.local[key] && apiUrls.local[key][name]) {
            url = apiUrls.localBaseUrl + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
        }
    }
    return url;
}

export function getBaseUrl(){
    return apiUrls.baseUrl
}

export function getApiPath(key, name){
    const path = getApiUrl(key, name);
    return path.substr(getBaseUrl().length);
}

export default getApiUrl
