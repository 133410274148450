import React from "react";

const ManyLoginAttemptErrorSection = () => {
    return (
        <div className='manyLoginAttemptErrorSection'>
            <h1>Oops, there was a problem</h1>

            <h2><b>Sorry, you've made too many failed atempts.</b></h2>

            <p>
                We blocked your account to protect it against unauthorized access.
                Please check your email for a link to reset your password and unlock your account.
            </p>
        </div>
    );
}

export default ManyLoginAttemptErrorSection;