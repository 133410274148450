// @flow

// /file/{fileId}

export const ACCOUNT_INFO_UPDATED = 'accountInfo/ACCOUNT_INFO_UPDATED';
export const SET_CURRENT_CUSTOMER = 'accountInfo/SET_CURRENT_CUSTOMER'
export const SET_CUSTOMER_CARDS = 'accountInfo/SET_CUSTOMER_CARDS'
export const SET_LOCALCARDS = 'accountInfo/SET_LOCALCARDS'
export const SET_TERMS_AND_CONDITIONS = 'accountInfo/SET_TERMS_AND_CONDITIONS'
export const SET_FALVEY_TERMS_AND_CONDITIONS = 'accountInfo/SET_FALVEY_TERMS_AND_CONDITIONS'
export const CLEAR_LOCALCARDS = 'accountInfo/CLEAR_LOCALCARDS'
export const CARDS_FETCHED = 'accountInfo/CARDS_FETCHED'
export const SET_PAYMENTS_PROFILE = 'accountInfo/SET_PAYMENTS_PROFILE'
export const SET_ACH_ACCOUNTS = 'accountInfo/SET_ACH_ACCOUNTS'
export const SET_CUSTOMER_STORE_CREDIT = 'accountInfo/SET_CUSTOMER_STORE_CREDIT'


