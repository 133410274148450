import React from 'react';

const getTitle = type => {
  switch (type) {
    case 'error':
      return 'Error!';
    case 'warn':
      return 'Warning!';
    case 'info':
      return 'Info';
    default:
      return 'Successful';
  }
};

const getIcon = type => {
  switch (type) {
    case 'error':
    case 'warn':
      return 'zmdi zmdi-alert-triangle white';
    default:
      return 'zmdi zmdi-check-circle white';
  }
};

const Toaster = ({
  msg, type,
}) => (
    <div>
      <h1>
        {/*<i className={getIcon(type)}/>*/}
        {getTitle(type)}
        {/*<span>1 m ago</span>*/}
      </h1>
      <p>{msg}</p>
    </div>

);

export default Toaster;
