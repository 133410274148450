// @flow
import * as type from './actionTypes';
import {SET_PRODUCT_IMAGES, SET_SHIPMENT_PRODUCT_IMAGES, SET_VIEW_IMAGE} from "./actionTypes";
// initial state
const initialState = {
    shipmentState: {},
    shipmentMasterData: {},
    originAddresses: [],
    destAddresses: [],
    isInsuranceValid: false,
    insuranceAmount: 0.00,
    insuranceExcludedId: 0,
    insuranceExcludedItemName: '',
    shipmentProduct: [],
    orderType: '',
    shipmentDetailsData: {},
    termsAndConditions: '',
    trackingHeader: {},
    trackingInfoList: [],
    documents: [],
    transactionDetails: [],
    shipmentCharge: 0,
    shipmentCost: 0,
    shipmentPayment: 0,
    hasError: false,
    errorMessage: '',
    transactionCharge: 0,
    transactionCharges: {},
    paymentDone: false,
    hasTrackingInfo: false,
    paymentTypeFlag: '1',
    customerARInfo: {
        arStatus: false,
        arCreditLimit: 0,
        arGraceAmount: 0,
        defaultPaymentMethod: false
    },
    bolState: {},
    trackingInfoFound: true,
    documentInfo: {},
    shInfo: {},
    insuranceStatus: false,
    isTransactionsInProcess: false,
    productImages: {},
    viewImage: ''
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_MASTER_DATA:
            return {
                ...state,
                shipmentMasterData: action.shipmentMasterData
            }

        case type.SET_TRANSACTION_DETAIL: {
            return {
                ...state,
                transactionDetails: action.transactionDetails
            }
        }

        case type.SET_SHIPMENT_CHARGES:
            return {
                ...state,
                shipmentCharge: action.shipmentCharges.shipmentCharge,
                shipmentCost: action.shipmentCharges.shipmentCost,
                shipmentPayment: action.shipmentCharges.shipmentPayment,
            }

        case type.SET_TRANSACTION_CHARGES:
            return {
                ...state,
                transactionCharge: action.transactionCharges.shipmentCharge,
                transactionCharges: action.transactionCharges
            }

        case type.SET_CUSTOMER_ORDERS:
            return {
                ...state,
                [`orders${action.orderType}`]: action.orders,
                [`totalOrdersCount${action.orderType}`]: action.totalOrdersCount,
                orderType: action.orderType
            }

        case type.SET_CURRENT_SHIPMENT:
            return {
                ...state,
                shipmentState: action.shipmentState
            }

        case type.SET_SHIPMENT_PRODUCTS:
            return {
                ...state,
                shipmentProduct: action.shipmentProduct
            }

        case type.SET_INSURANCE_VALIDATE_FLAG:
            return {
                ...state,
                isInsuranceValid: action.isInsuranceValid,
                insuranceAmount: action.insuranceAmount,
                insuranceExcludedId: action.insuranceExcludedId,
                insuranceExcludedItemName: action.insuranceExcludedItemName

            }

        case type.SET_SHIPMENT_DETAIL:
            return {
                ...state,
                shipmentDetail: {...action.shipmentDetails, ...action.shipmentDetails.shipmentList[0]}
            }

        case type.SET_ORIGIN_ADDRESSES:
            return {
                ...state,
                originAddresses: action.originAddresses
            }

        case type.SET_DEST_ADDRESSES:
            return {
                ...state,
                destAddresses: action.destAddresses
            }

        case type.SET_SHIPMENT_PRODUCTS_INSURANCE:
            return {
                ...state,
                shipmentDetailsData: action.shipmentDetailsData
            }

        case type.SET_TERMS_AND_CONDITIONS:
            return {
                ...state,
                termsAndConditions: action.termsAndConditions
            }

        case type.SET_TRACKING_INFO:
            return {
                ...state,
                trackingInfoList: action.trackingInfoList
            }

        case type.SET_TRACKING_HEADER:
            return {
                ...state,
                trackingHeader: action.trackingHeader
            }

        case type.CLEAR_TRACKING_INFO:
            return {
                ...state,
                trackingHeader: {},
                trackingInfoList: []
            }

        case type.SET_ERROR_MESSAGE:
            return {
                ...state,
                hasError: true,
                errorMessage: action.quoteResultErrMsg
            }

        case type.CLEAR_ERROR_MESSAGE:
            return {...state, hasError: false, errorMessage: ''}

        case type.SET_PAYMENT_DONE:
            return {...state, paymentDone: action.paymentDone}

        case type.TRACKING_INFO_AVAILABLE:
            return {
                ...state,
                hasTrackingInfo: action.hasTrackingInfo
            }

        case type.CLEAR_SHIPMENT_DETAIL:
            return {
                ...state,
                shipmentDetails: {}
            }

        case type.SET_DOCUMENTS:
            return {
                ...state,
                documents: action.documents,
                totalDocumentCount: action.totalDocumentCount
            };

        case type.CLEAR_DOCUMENTS:
            return {
                ...state,
                documents: [],
                totalDocumentCount: 0
            };

        case type.SET_PAYMENT_AR_OPTIONS:
            return {
                ...state,
                customerARInfo: action.customerARInfo,
                paymentTypeFlag: action.flag ? '0' : '1'
            }

        case type.SET_BOL_STATUS:
            const res = {...state};

            if (!state.bolState.hasOwnProperty(action.shipmentUuid) || state.bolState.hasOwnProperty(action.shipmentUuid) &&
                 action.status !== state.bolState[action.shipmentUuid].bolStatus)
            {
                res.bolState[action.shipmentUuid.toString()] = {
                    bolStatus: action.status,
                    documentsGenerated: action.documentsGenerated,
                };

                return res;
            }
            return state;

        case type.SET_ALL_BOL_STATUS:
            const temp = {...state};
            const shipments = Object.keys(action.shipments);
            let change = false;

            shipments.forEach( (id) => {
                const shipment = action.shipments[id];
                const shipmentUuid = shipment.shipmentUuid;
                const documentsGenerated = shipment.documentsGenerated;
                const bolState = state.bolState[id] || {};

                if (!state.bolState.hasOwnProperty(shipmentUuid) || state.bolState.hasOwnProperty(shipmentUuid) &&
                    (action.status !== state.bolState[shipmentUuid].bolStatus || bolState.documentsGenerated !== documentsGenerated) )
                {
                    temp.bolState[shipmentUuid.toString()] = {
                        bolStatus: action.status,
                        documentsGenerated: documentsGenerated,
                    };
                    change = true;
                }
            });

            return (change === true) ? temp : state;

        case type.SET_SHIPMENT_UUID:
            const r = {...state};
            if (!r.shipmentDetail) r.shipmentDetail = {};
            r.shipmentDetail.shipmentUuid = action.shipmentUuid;
            return r;

        case type.SET_TRACKING_INFO_SEARCH:
            return {
                ...state,
                trackingInfoFound: action.trackingInfoFound
            }

        case type.SET_DOCUMENT_INFO:
            return {
                ...state,
                documentInfo: action.documentInfo
            }

        case type.SET_DOCUMENT_INFO_LOADER:
            return {
                ...state,
                documentInfoLoading: action.documentInfoLoading
            }

        case type.SET_SH_INFO:
            return {
                ...state,
                shInfo: action.shInfo
            }
        case type.SET_INSURANCE_STATUS:
            return {
                ...state,
                insuranceStatus: action.insuranceStatus
            }
        case type.SET_IS_TRANSACTIONS_IN_PROCESS:
            return {
                ...state,
                isTransactionsInProcess: action.isTransactionsInProcess
            }

        case type.SET_PRODUCT_IMAGES:
            return {
                ...state,
                productImages: action.productImages
            }

        case type.SET_VIEW_IMAGE:
            return {
                ...state,
                viewImage: action.viewImage
            }

        default:
            return state;
    }
};

export default reducer;
