import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import utilCommon from '../../helpers/utilCommon'
import Button from '@material-ui/core/Button';
import commonService from "../../services/commonService";
import * as type from "../../store/common/actionTypes";


// form validations

const validate = (formProps) => {
    const errors: any = {};
    if (!formProps.email) {
        errors.email = 'Email is a required field';
    }
    else if(!utilCommon.Email_Regex.test(formProps.email)){
        errors.email = 'Please enter a valid email address'
    }
    if (!formProps.confirmPassword) {
        errors.confirmPassword = 'Confirm Password is a required field';
    }
    if (!formProps.password) {
        errors.password = 'Password is a required field';
    } 
    else if (formProps.password.length<6 ||formProps.password.length >20) {
        errors.password = 'Password should be minimum of 6 characters';
    } else if (
        formProps.password.charAt(0) === ' ' ||
        formProps.password.charAt(0) === '\t' ||
        formProps.password.charAt(0) === '\n' ||
        formProps.password.charAt(formProps.password.length - 1) === ' ' ||
        formProps.password.charAt(formProps.password.length - 1) === '\t' ||
        formProps.password.charAt(formProps.password.length - 1) === '\n'
    ) {
        errors.password = 'Space bar is prohibited as first or last character';
    }
    else if(formProps.password && formProps.confirmPassword && formProps.confirmPassword!=formProps.password){
        errors.confirmPassword = 'The entered passwords don’t match'
    }

    return errors;
}

export class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        setInterval(() => {
            this.props.change('email', sessionStorage.getItem('resetPasswordEmail'))
        }, 1000);
    }

    checkPassword = (e) => {
        if (e.target.value) {
            let password = e.target.value;

            if (password.length >= 6) {
                if (
                    /^[a-z]+$/.test(password) ||
                    /^[A-Z]+$/.test(password) ||
                    /^\d+$/.test(password) ||
                    password === 'querty'
                ) {
                    if (!this.props.displayPasswordInsecureWarning) {
                        this.props.changeDisplayPasswordInsecureWarning(true);
                    }
                } else {
                    if (this.props.displayPasswordInsecureWarning) {
                        this.props.changeDisplayPasswordInsecureWarning(false);
                    }
                }
            } else {
                this.props.changeDisplayPasswordInsecureWarning(false);
            }
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    render() {
        const { handleSubmit, submitting,valid } = this.props;
        return (
            <form name="ResetPasswordForm" onSubmit={handleSubmit}>               
                <div className="form-group">
                    <div className="col-grid1">
                        <div className="col">
                            <Field label="Email" disabled={true} requiredClass name="email" component={CustomInputField} />
                        </div>
                    </div>
                    <div className="col-grid1">
                        <div className="col">
                        <Field
                            label="New password"
                            requiredClass
                            maxLength={20}
                            type="password"
                            name="password"
                            autoFocus={true}
                            component={CustomInputField}
                            onChange={this.checkPassword}
                        />
                        </div>
                    </div>
                    {this.props.displayPasswordInsecureWarning && <div className="col-grid1" style={{"margin-top": "8px"}}>
                        <span className="invalidTxt">
                            You have chosen a very weak password that can be easily compromised. You can create a much
                            stronger password by adding at least 1 number, 1 upper case letter and 1 special character
                        </span>
                    </div>}
                    <div className="col-grid1">
                        <div className="col">
                        <Field label="Confirm new password" requiredClass type="password" name="confirmPassword" component={CustomInputField} />
                        </div>
                    </div>
                    <div className="col-grid1">
                        <div className="col">
                        <Button type="submit" variant="contained" disabled={submitting || !valid || this.props.loading} color="primary" className={(submitting || !valid || this.props.loading) ?"btnInactive":"btnSecondary"}>
                        Submit
                        </Button>
                        </div>
                    </div>
                </div>
                
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    changeDisplayPasswordInsecureWarning: (flag) => {
        dispatch({type: type.DISPLAY_PASSWORD_INSECURE_WARNING, flag});
    },
});

// connect to store
export default connect((state) => ({
    loading: state.common.loading,
    displayPasswordInsecureWarning: state.common.displayPasswordInsecureWarning ?
        state.common.displayPasswordInsecureWarning : false,
}), mapDispatchToProps)(reduxForm({
    form: 'ResetPasswordForm',
    validate
})(ResetPasswordForm));
