import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import lodash from 'lodash'
import UncheckedIcon from '../../../assets/images/NewUI/uncheckedCheckbox.svg';
import CheckedIcon from '../../../assets/images/NewUI/checkedCheckbox.svg';

const newUncheckedIcon = <img style={{backgroundColor: 'white'}} src={UncheckedIcon} alt="unchecked icon"/>
const newCheckedIcon = <img style={{backgroundColor: 'white'}} src={CheckedIcon} alt="checked icon"/>

const CustomCheckBox = ({
                            input, checkThis, disabled, label, meta: {touched, error, warning}, classes, newUiIcons = false
                        }) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={input.value}
                    classes={{
                        checked: lodash.get(classes, 'checkboxChecked', ''),
                        root: lodash.get(classes, 'checkboxRoot', '')
                    }}
                    {...(newUiIcons ? {icon: newUncheckedIcon, checkedIcon: newCheckedIcon}: {})}
                />
            }
            label={label}
            disabled={disabled}
            value={input.value}
            onChange={(e) => {
                input.onChange(e)
            }}
            classes={{label: lodash.get(classes, 'formControlLabel', '')}}
            onBlur={(e) => {
                input.onBlur(e)
            }}
            className={input.value == true ? "checkBoxWrap checked" : "checkBoxWrap"}
        />
    </div>

);
export default CustomCheckBox;
