import React from 'react';
import max from 'lodash/max';
import min from 'lodash/min';
import lodash from 'lodash';
import { SHIPMENT_PAYMENT_ID } from '../../../helpers/enums';
import quoteService from '../../../services/quoteService';
import utilCommon from '../../../helpers/utilCommon';
import * as  shipmentType from '../../../store/shipment/actionTypes';
import * as type from '../../../store/quote/actionTypes';
import * as actions from '../../../store/quote/actions';


export const SelectedRate = {
  standard: 'n',
  guaranteed: 'g',
};

export default class QuoteResultsBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupDestination: false,
      carrierNotUse: false,
      cDetailcollapsible: -1,
      dayFilter: -1,
      scrollTopoffset: 0,
      showCharges: -1,
      showChargesType: '1', // 1 - Normal,  2 - Gauranteed

      selectedRate: SelectedRate.standard,
      width: 0,
    };
  }

  navigateTo = (path)=>{
    this.props.history.push(path)
  }
  popupDestination = () => {
    this.setState({
      popupDestination: !this.state.popupDestination
    })
  }

  toggleCarrierDetail = (index) => {
    if (this.state.cDetailcollapsible == index) {
      this.setState({ cDetailcollapsible: -1 })
    }
    else {
      this.setState({ cDetailcollapsible: index })
    }
  }

  updateDimensions() {
    if (document.getElementById("getTopOffset") !== null) {
      const myRef = document.getElementById("getTopOffset").offsetTop
      this.setState({
        scrollTopoffset: window.innerHeight - myRef
      })
    }
  }

  componentWillMount() {
    Window['history'] = this.props.history
    this.props.getQuotesMaster(this.props.currentLanguage, this.props.dictionaries)
    // if (this.props.quoteQuery && this.props.quoteQuery.shippingToPostalCode) {
    // } else {
    // Window['history'].push('/customer/freight-quote');
    // }
    this.props.updateQuoteResFlag()
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    window.scrollTo(0, 0);
    const quoteResults = sessionStorage.getItem('quoteResults');
    if (quoteResults){
      this.props.setQuoteResults(JSON.parse(quoteResults));
    }

    const value = sessionStorage.getItem('quoteQuery');
    if (value) {
      this.props.setQuoteQuery(JSON.parse(value));
    }
  }

  componentWillUnmount() {
    this.props.resetQuoteQuery();
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    this.props.removeToasterMessage()
    // setTimeout(() => sessionStorage.removeItem('quoteQuery'), 2000);
    // sessionStorage.removeItem('quotePayload');
  }

  //calculate profile %
  getProfilePercentage = () => {
    let profit = (this.props.totalCharge - this.props.totalCost);
    return (this.props.totalCost) !== 0 ? (profit ? ((profit * 100) / ((this.props.totalCost))).toFixed(2) : 0.00) : 0.00;
  }
  // amount paid
  amountPaid = () => {
    return this.props.payments.filter(x => x.paymentTypeId == SHIPMENT_PAYMENT_ID).length > 0 ? this.props.payments.filter(x => x.paymentTypeId == SHIPMENT_PAYMENT_ID)[0].totalPayTypeAmount : '';
  }
  // amount paid format
  getAmountPaid = () => {
    let paymentAmount = this.amountPaid()
    return paymentAmount ? `$ ${Math.abs(paymentAmount).toFixed(2)}` : '$ 0.00';
  }

  hideALert = () => {
    this.setState({ showCharges: -1 })
  }

  getPackageType = (item) => {
    if (item.palletCheck || item.palleteType) {
      return "PLT"
    }

    if (item.itemType && item.itemType == 2) {
      return "CRATE"
    }

    if (item.itemType && item.itemType == 3) {
      return "BOX"
    }

    if (item.otherItemType) {
      return this.props.packageTypes.find(x => x.id == item.otherItemType).packageTypeCode
    }
  }

  getAccessorioals = (quoteValues) => {
    let accessorials = []
    Object.keys(quoteValues).filter(x => x.startsWith('OAccessorial_') || x.startsWith('DAccessorial_') || x.startsWith('Extra_')).filter(y => quoteValues[y] == true).map(z => {
      accessorials.push({
        id: this.props.accessorials.find(y => y.accCode == z.split('_')[1]).id,
        name: this.props.accessorials.find(y => y.accCode == z.split('_')[1]).name
      })
    })
    return accessorials
  }

  getShipmentProducts = (quoteValues) => {
    let products = []
    quoteValues.items.map(x => {
      products.push({
        // "customerUuid": quoteValues.customerUuid ? quoteValues.customerUuid.id : quoteValues.selectCustomer.customerUuid,
        "noOfHandlingUnit": parseInt(x.howManyHandlingUnits),
        "noOfItem": 1,
        "itemWeight": utilCommon.convertWeightToPound(x.weightUnit, x.weightPerHandlingUnit),
        "totalWeight": utilCommon.convertWeightToPound(x.weightUnit, x.weightPerHandlingUnit)* x.howManyHandlingUnits,
        "weightMeasurement": 2,
        "productWidth": utilCommon.convertLengthToInch(x.dimUnit, x.width),
        "productLength": utilCommon.convertLengthToInch(x.dimUnit, x.length),
        "productHeight": utilCommon.convertLengthToInch(x.dimUnit, x.height),
        "productVolume": null,
        "productMeasurement": 2,
        "measurementIn": 1,
        "freightClassId": x.calculatedFreightClass ? x.calculatedFreightClass : null,
        "haz-matItem": x['haz-matItem'] ? "1" : "0",
        "stackableItem": x.stackable ? "1" : "0",
        previousfreightClassId:x['previousCalculatedFreightClass'],
        "packageTypeId": this.props.packageTypes.filter(y => {
          if (x.palleteType) {
            return y.packageTypeCode == this.getPackageType(x) && y.id == x.palleteType
          } else {
            return y.packageTypeCode == this.getPackageType(x)
          }
        })[0],
        productDiscription: x.productDiscription,
        nmfc: x.nmfc,
        commodityTypeId: x.commodityTypeId,
        commodityCategoryId: x.commodityCategoryId
      })
    })
    return products
  }

  createShipment = (selectedRate, type) => {
    sessionStorage.removeItem('quoteQuery');
    let self = this
    let shipmentValues = Object.assign({}, selectedRate)
    if (self.props.shipmentUuid) {
      let payload = {
        "shipmentUuid": self.props.shipmentUuid,
        "shipmentCharge": type === SelectedRate.standard ? selectedRate.rateQuoteDetail.FCTotalCharges : selectedRate.FCGuaranteedRateQuoteDetail.FCTotalCharges,
        "shipmentCost": type === SelectedRate.standard ? selectedRate.rateQuoteDetail.total : selectedRate.FCGuaranteedRateQuoteDetail.total,
        "carrierUuid": {
          id: self.props.carriers.find(x => x.carrierScac == selectedRate.carrierCode).carrierUuid,
          name: self.props.carriers.find(x => x.carrierScac == selectedRate.carrierCode).name
        },
        "carrierCode":selectedRate.carrierCode,
        "contractUuid": {
          id: selectedRate.FCContractUUID ? selectedRate.FCContractUUID : '',
          name: selectedRate.FCContractName ? selectedRate.FCContractName : ''

        },
        "carrierQuoteNumber": selectedRate.capacityProviderQuoteNumber,
        FCPreRateQuoteResponseId:selectedRate.FCPreRateQuoteResponseId,
        quoteRequestUuid: self.props.quoteRequestUuid,
        "charges": selectedRate.rateQuoteDetail.charges ? (type === SelectedRate.standard ? selectedRate.rateQuoteDetail.charges : selectedRate.FCGuaranteedRateQuoteDetail.charges) : [],
        "shipmentContractType":type,
        totalTravelDistance:selectedRate.FCTotalTravelDistance,
        transitDay: type === SelectedRate.standard ? (selectedRate.FCtransitDays ? selectedRate.FCtransitDays : selectedRate.transitDays) : selectedRate.FCGuaranteedTransitDay,
      }
      if(this.props.requestedPickupStartDate){
        payload.requestedPickupStartDate = this.props.requestedPickupStartDate;
      }
      self.props.reRunQuote(self.props.currentLanguage, self.props.dictionaries, payload)
    } else {
      let payload = {
        // "customerUuid": self.props.quoteQuery.customerUuid,
        // "customerShortId": self.props.quoteQuery.customerShortId || 121212121212,
        "shippingModeId": {
          "id": 1,
          "name": "LTL",
        },
        "shipmentType": 0,
        "quoteDate": selectedRate.FCQuoteDate,
        "carrierQuoteNumber": selectedRate.capacityProviderQuoteNumber,
        "shipmentStatusId": {
          "id": "1",
          "name": "Estimate saved"
        },
        carrierUuid: self.props.carriers.find(x => x.carrierScac == selectedRate.carrierCode),
        "carrierCode":selectedRate.carrierCode,
        "requestedPickupStartDate": self.props.quoteQuery.shippingFromPickUpDate + " 08:00:00",
        "requestedPickupEndDate": self.props.quoteQuery.shippingFromPickUpDate + " 17:00:00",
        "shipmentCharge": type === SelectedRate.standard ? selectedRate.rateQuoteDetail.FCTotalCharges : selectedRate.FCGuaranteedRateQuoteDetail.FCTotalCharges,
        "shipmentCost": type === SelectedRate.standard ? selectedRate.rateQuoteDetail.total : selectedRate.FCGuaranteedRateQuoteDetail.total,
        "shipmentPayment": "0.00",
        "shipmentFrom": self.props.quoteQuery.shippingFromPostalCode,
        "shipmentTo": self.props.quoteQuery.shippingToPostalCode,
        "shipmentBookingDate": null,
        "actualPickupDate": null,
        "re-runQuote": 0,
        "shipmentAlert": 1,
        "shipmentSource": 1,
        "falveyTerms": 1,
        "fcTerms": 1,
        "quoteId": selectedRate.capacityProviderQuoteNumber,
        "quoteRequestDate": selectedRate.FCQuoteDate,
        "quoteResponseDate": null,
        "quoteRequest": null,
        "quoteResponse": null,
        "shipmentFromLoadingFacilityId": self.props.facilityTypes.find(x => x.id == self.props.quoteQuery.originFacilityType),
        "shipmentFromZip": self.props.quoteQuery.shippingFromPostalCode,
        "shipmentFromCity": self.props.quoteQuery.originCity ? self.props.quoteQuery.originCity : '',
        "shipmentFromState": self.props.quoteQuery.originState ? self.props.quoteQuery.originState : '',
        "shipmentFromStateId": self.props.quoteQuery.originStateId ? self.props.states.find(x => x.id == self.props.quoteQuery.originStateId) : null,
        "shipmentFromCountryId": self.props.quoteQuery.originCountryId ? this.props.countries.find(x => x.id == self.props.quoteQuery.originCountryId) : null,
        shipmentFromLastName: self.props.quoteQuery.shipmentFromLastName ? self.props.quoteQuery.shipmentFromLastName : '',
        shipmentFromCompany: self.props.quoteQuery.shipmentFromCompany ? self.props.quoteQuery.shipmentFromCompany : '',
        shipmentFromFirstName: self.props.quoteQuery.shipmentFromFirstName ? self.props.quoteQuery.shipmentFromFirstName : '',
        shipmentToLastName: self.props.quoteQuery.shipmentToLastName ? self.props.quoteQuery.shipmentToLastName : '',
        shipmentToCompany: self.props.quoteQuery.shipmentToCompany ? self.props.quoteQuery.shipmentToCompany : '',
        shipmentToFirstName: self.props.quoteQuery.shipmentToFirstName ? self.props.quoteQuery.shipmentToFirstName : '',
        "shipmentToLoadingFacilityId": self.props.facilityTypes.find(x => x.id == self.props.quoteQuery.destinationFacilityType),
        "shipmentToZip": self.props.quoteQuery.shippingToPostalCode,
        "shipmentToCity": self.props.quoteQuery.destinationCity ? self.props.quoteQuery.destinationCity : '',
        "shipmentToState": self.props.quoteQuery.destinationState ? self.props.quoteQuery.destinationState : '',
        "shipmentToStateId": self.props.quoteQuery.destinationStateId ? self.props.states.find(x => x.id == self.props.quoteQuery.destinationStateId) : null,
        "shipmentToCountryId": self.props.quoteQuery.destinationCountryId ? this.props.countries.find(x => x.id == self.props.quoteQuery.destinationCountryId) : null,
        "accessorials": self.getAccessorioals(self.props.quoteQuery),
        "requestedPickupDate": self.props.quoteQuery.shippingFromPickUpDate,
        "startTime": "00:08:00",
        "endTime": "17:00:00",
        shipmentFromAddress1:self.props.quoteQuery.shipmentFromAddress1 ? self.props.quoteQuery.shipmentFromAddress1 : '',
        shipmentFromAddress2:self.props.quoteQuery.shipmentFromAddress1 ? self.props.quoteQuery.shipmentFromAddress2 : '',
        shipmentFromPhone:self.props.quoteQuery.shipmentFromPhone ? self.props.quoteQuery.shipmentFromPhone : '',
        shipmentToAddress1:self.props.quoteQuery.shipmentToAddress1 ? self.props.quoteQuery.shipmentToAddress1 : '',
        shipmentToAddress2:self.props.quoteQuery.shipmentToAddress1 ? self.props.quoteQuery.shipmentToAddress2 : '',
        shipmentToPhone:self.props.quoteQuery.shipmentToPhone ? self.props.quoteQuery.shipmentToPhone : '',
        shipmentFromEmailId:self.props.quoteQuery.shipmentFromEmailId ? self.props.quoteQuery.shipmentFromEmailId : '',
        shipmentToEmailId:self.props.quoteQuery.shipmentToEmailId ? self.props.quoteQuery.shipmentToEmailId : '',
        shipmentFromAltEmailId: lodash.get(self.props, 'quoteQuery.shipmentFromAltEmailId', ''),
        shipmentToAltEmailId: lodash.get(self.props, 'quoteQuery.shipmentToAltEmailId', ''),
        shipmentToOffset:self.props.quoteQuery.shipmentToOffset,
        shipmentFromOffset:self.props.quoteQuery.shipmentFromOffset,
        products: self.getShipmentProducts(self.props.quoteQuery),
        "charges": selectedRate.rateQuoteDetail.charges ? (type === SelectedRate.standard ? selectedRate.rateQuoteDetail.charges : selectedRate.FCGuaranteedRateQuoteDetail.charges) : [],
        quoteRequestUuid: self.props.quoteRequestUuid,
        "contractUuid": {
          id: selectedRate.FCContractUUID ? selectedRate.FCContractUUID : '',
          name: selectedRate.FCContractName ? selectedRate.FCContractName : ''
        },
        totalTravelDistance:selectedRate.FCTotalTravelDistance,
        transitDay: type === SelectedRate.standard ? (selectedRate.FCtransitDays ? selectedRate.FCtransitDays : selectedRate.transitDays) : selectedRate.FCGuaranteedTransitDay,
        "shipmentContractType":type,
        FCPreRateQuoteResponseId:selectedRate.FCPreRateQuoteResponseId
      }
      self.props.createShipment(self.props.currentLanguage, self.props.dictionaries, payload)
    }
  }

  fcCreateShipment = (selectedRate, type) => {
    sessionStorage.removeItem('quoteQuery');
    let quoteRequestUuid = '';
    if (lodash.isString(this.props.quoteRequestUuid) || lodash.isNumber(this.props.quoteRequestUuid)) {
      quoteRequestUuid = this.props.quoteRequestUuid;
    } else {
      quoteRequestUuid = this.props.quoteRequestUuid[selectedRate.capacityProviderAccountGroup.code];
    }

    let payload = {
      type: type,
      quoteRequestUuid: quoteRequestUuid,
      capacityProviderQuoteNumber: selectedRate.capacityProviderQuoteNumber,
      contractUuid: selectedRate.FCContractUUID,
      isZipCodeAlertShipment: lodash.get(this.props, 'quoteQuery.isZipCodeAlertShipment', false)
    };

    if (this.props.shipmentUuid) {
      payload = {
        ...payload,
        shipmentUuid: this.props.shipmentUuid
      }
      this.props.fcReRunQuote(this.props.currentLanguage, this.props.dictionaries, payload)
    } else {
      this.props.fcCreateShipment(this.props.currentLanguage, this.props.dictionaries, payload)
    }

    utilCommon.analytics('RateSelected', {
      type: type === SelectedRate.standard ? 'standard' : 'guaranteed',
      carrier: selectedRate.FCContractName ? selectedRate.FCContractName : ''
    })
  }

  getOrdered = (list)=>{
    return utilCommon.numberSort((list||[]) , 'FCDisplayOrder').slice()
  }

  getSortedCarriers = (list)=>{
    let firstLevelSort = utilCommon.numberSort((list||[]) , 'FCCarrierGroup').slice();
    let groupByObj = lodash.groupBy(firstLevelSort,'FCCarrierGroup')
    let results = [];
    for(let x in groupByObj){
      let secondLevelSort = utilCommon.numberSort((groupByObj[x]||[]) , 'FCTotalCharges').slice()
      results = results.concat(secondLevelSort)
    }
    return results;
  }

  static getMinMaxTransitDays = (quoteResults) => {
    let transitDays = []
    quoteResults.filter(x => x.rateQuoteDetail && !x.errorMessages).map(x => {
      if (x.FCtransitDays) {
        transitDays.push(x.FCtransitDays)
      } else {
        transitDays.push(x.transitDays)
      }
      if (x.FCGuaranteedTransitDay) {
        transitDays.push(x.FCGuaranteedTransitDay)
      }
    })
    return [min(transitDays), max(transitDays)]
  }

  static mapStateToProps = state => ({
      currentLanguage: state.i18nReducer.currentLanguage,
      dictionaries: state.i18nReducer.dictionaries,
      quoteResults: state.quote.quoteResults,
      requestedPickupStartDate: state.quote.requestedPickupStartDate ? state.quote.requestedPickupStartDate : null,
      shipmentUuid: state.quote.shipmentUuid,
      minMaxTransitDays: this.getMinMaxTransitDays(state.quote.quoteResults),
      quoteQuery: state.quote.quoteQuery,
      facilityTypes: state.quote.facilityTypes,
      countries: utilCommon.nullCheck(state.common.masterData) ? state.common.masterData.countryList : [],
      states: utilCommon.nullCheck(state.common.masterData) ? state.common.masterData.stateList : [],
      originCode: state.quote.originCode,
      destinationCode: state.quote.destinationCode,
      carriers: state.quote.carriers,
      packageTypes: utilCommon.nullCheck(state.quote.masterData) ? state.quote.masterData.packageTypeList : [],
      accessorials: utilCommon.nullCheck(state.common.masterData) ? state.common.masterData.accessorialList : [],
      quoteRequestUuid: state.quote.quoteRequestUuid,
      hasError: state.shipment.hasError,
      errorMessage: state.shipment.errorMessage,
  })

  static mapDispatchToProps = dispatch => ({
    fcCreateShipment: (lang, dictionaries, payload) => {
      quoteService.fcCreateShipment(dispatch, lang, dictionaries, payload)
    },
    createShipment: (lang, dictionaries, payload) => {
      quoteService.createShipment(dispatch, lang, dictionaries, payload)
    },
    reRunQuote: (lang, dictionaries, payload) => {
      quoteService.reRunQuote(dispatch, lang, dictionaries, payload)
    },
    fcReRunQuote: (lang, dictionaries, payload) => {
      quoteService.fcReRunQuote(dispatch, lang, dictionaries, payload)
    },
    setQuoteQuery: (query) => {
      dispatch({ type: type.SET_QUOTE_QUERY, quoteQuery: query })
    },
    setQuoteResults: (results) => {
      dispatch(actions.setQuoteResults(results))
    },
    resetQuoteQuery: () => {
      dispatch({ type: type.RESET_QUOTE_QUERY })
    },
    removeToasterMessage: () => {
      dispatch({ type: shipmentType.CLEAR_ERROR_MESSAGE })
    },
    getQuotesMaster: (lang, dictionaries) => {
      quoteService.getQuotesMaster(dispatch, lang, dictionaries)
    },
    updateQuoteResFlag:()=>{
      dispatch({ type: type.SET_QUOTE_RESULT_NAVIGATED, quoteResultFlag:true })
    },
  })

}
