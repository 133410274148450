import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField';
import utilCommon from '../../helpers/utilCommon';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Button from '@material-ui/core/Button';
import CustomInput from "../atoms/customInput";
// form validations
const validate = (formProps) => {
    const errors = {};
    if (!formProps.password) {
        errors.password = 'Password is a required field';
    }

    if (formProps.showCustomEmail && !formProps.email) {
        errors.email = 'Email is a required field';
    }

    return errors;
}

export class LinkSocialForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            showCustomEmail: false,
        }
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    }

    changeShowCustomEmail = (flag) => {
        this.setState({showCustomEmail: flag});
        this.props.change('showCustomEmail', flag);
    }

    render() {
        const { handleSubmit, submitting, valid, pristine, callAPI } = this.props;

        return (
            <div>
                <h2 className='title'>Link to your existing account</h2>
                <h3>Welcome back {this.props.linkEmail}</h3>
                <a style={{'margin-bottom': '10px', 'font-size': '15px'}} href="javascript:void(0)"
                                                onClick={() => this.changeShowCustomEmail(!this.state.showCustomEmail)}>Change</a>
                <br/>
                <p style={{'font-size': '16px', 'margin-top': '26px'}}>A FreightCenter account with this email already exists. Please enter your FreightCenter password to link it to your Facebook account.</p>
                <form name="LinkSocialForm" onSubmit={handleSubmit} className="LinkSocialForm">
                    <div className="form-group">
                        {this.state.showCustomEmail && <div className="col-grid1">
                            <div className="col">
                                <Field label="Email Address" name="email" autoFocus={true}
                                       component={CustomInputField} requiredClass/>
                            </div>
                        </div>}
                        <div style={{'overflow': 'hidden', 'height': '0'}}>
                            <Field
                                component={CustomInput}
                                name="linkEmail"
                                type="hidden"
                                style={{ height: 0 }}
                            />
                            <Field
                                component={CustomInput}
                                name="showCustomEmail"
                                type="hidden"
                                style={{ height: 0 }}
                            />
                            <Field
                                component={CustomInput}
                                name="linkSocialKey"
                                type="hidden"
                                style={{ height: 0 }}
                            />
                            <Field
                                component={CustomInput}
                                name="linkSocialType"
                                type="hidden"
                                style={{ height: 0 }}
                            />
                        </div>
                        <div className="col-grid1">
                            <div className="col">
                                <Field label="Password" type={this.state.showPassword ? 'text' : 'password'}
                                       name="password" showPassword={this.state.showPassword}
                                       handleClickShowPassword={this.handleClickShowPassword.bind(this)}
                                       handleMouseDownPassword={this.handleMouseDownPassword.bind(this)}
                                       component={CustomInputField} requiredClass/>
                            </div>
                        </div>
                        <div className="col-grid1">
                            <div className="col">
                                <div className="formAction floatLeft">
                                    <Button type="submit" variant="contained"
                                            disabled={submitting || this.props.loading || !valid }
                                            className={(submitting || pristine || !valid || this.props.loading) ? "btnInactive" : "btnSecondary"}>
                                        Link your account
                                    </Button>
                                </div>
                                {this.props.displayForgotPassword && <div className="formActionRight">
                                    <p>Forgot your password?</p> <a href="javascript:void(0)"
                                                                    onClick={() => this.props.displayForgotPassword(true)}>Click
                                    here.</a>
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

// connect to store

export default connect((state) => ({
    loading: state.common.loading,
    linkEmail: state.common.linkEmail,
    linkSocialKey: state.common.linkSocialKey,
    linkSocialType: state.common.linkSocialType,
    showCustomEmail: false,
    email: '',
    password: '',
    initialValues: {
        linkEmail: state.common.linkEmail,
        linkSocialKey: state.common.linkSocialKey,
        linkSocialType: state.common.linkSocialType,
        showCustomEmail: false,
        email: '',
        password: ''
    }
}), {})(reduxForm({
    form: 'LinkSocialForm',
    validate
})(LinkSocialForm));
