import * as type from './actionTypes';

export const setCards = (cards = []) => ({
    type: type.SET_CUSTOMER_CARDS,
    cards: cards
});

export const cardsFetched = (status) => ({
    type: type.CARDS_FETCHED,
    cardsFetched: status
});

export const setCustomerStoreCredit = (storeCredit) => ({
    type: type.SET_CUSTOMER_STORE_CREDIT,
    storeCredit: storeCredit
});