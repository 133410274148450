import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import utilCommon from '../../helpers/utilCommon'
import Button from '@material-ui/core/Button';
import lodash from "lodash";
import * as type from "../../store/common/actionTypes";
import FtlDataForm from "./ftlDataForm";

// form validations
const validate = (formProps) => {
    const errors = {};
    if (!formProps.password) {
        errors.password = 'Password is a required field';
    }

    if (!formProps.emailId) {
        errors.emailId = 'Email is a required field';
    } else if (!utilCommon.Email_Regex.test(formProps.emailId)) {
        errors.emailId = 'Please enter a valid email address.';
    }

    return errors;
}

export class GuestLoginForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    //allow guest user or not
    componentWillReceiveProps(nextProps,props){
		if(nextProps.allowGuest!=this.props.allowGuest && nextProps.allowGuest){
			this.props.handleOpen(false)

		}

	}

    checkPassword = (e) => {
        if (e.target.value) {
            let password = e.target.value;

            if (password.length >= 6) {
                if (
                    /^[a-z]+$/.test(password) ||
                    /^[A-Z]+$/.test(password) ||
                    /^\d+$/.test(password) ||
                    password === 'querty'
                ) {
                    if (!this.props.displayPasswordInsecureWarning) {
                        this.props.changeDisplayPasswordInsecureWarning(true);
                    }
                } else {
                    if (this.props.displayPasswordInsecureWarning) {
                        this.props.changeDisplayPasswordInsecureWarning(false);
                    }
                }
            } else {
                this.props.changeDisplayPasswordInsecureWarning(false);
            }
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    render() {
        const { handleSubmit, submitting, valid, showFbButton, ftlData={}, pristine, callAPI } = this.props;

        const facebookAppKey = process.env.REACT_APP_FACEBOOK_KEY;

        return (
            <div>
                <form name="GuestLoginForm" onSubmit={handleSubmit} className="GuestLoginForm">
                    <div className="form-group">
                        <div className="col-grid1">
                            <div className="col">
                                <Field label="Email Address" name="emailId" autoFocus={true}
                                       component={CustomInputField} requiredClass/>
                            </div>
                        </div>
                        <div className="col-grid1">
                            <div className="col">
                                <Field
                                    label="Password"
                                    type="password"
                                    name="password"
                                    component={CustomInputField}
                                    requiredClass
                                    onChange={this.checkPassword}
                                />
                            </div>
                        </div>
                        <div className="col-grid1">
                            <div className="col">
                                <div style={{'width': '100%'}} className="formAction floatLeft">
                                    <Button type="submit" variant="contained" style={{'width': '100%'}}
                                            disabled={submitting || this.props.loading}
                                            className={(submitting || pristine || this.props.loading) ? "btnInactive" : "btnSecondary"}>
                                        Login
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <p>Forgot your password? <a href="javascript:void(0)"
                                                    onClick={() => this.props.displayForgotPassword(true)}>Click
                            here.</a></p>
                    </div>
                    {!lodash.isEmpty(ftlData) && <FtlDataForm ftlData={ftlData}/>}
                </form>
                <div className="col-grid1 NotRegisteredSection">
                    <h2>Not Registered ?</h2>
                    <Button className="btnPrimary" onClick = {
                        (e) => {
                            this.props.displayLoginSection(false);
                            if (lodash.get(this.props, 'isQuickForm', '')) {
                                utilCommon.historyPush( '/quick-register');
                            }
                        }
                    }>CLICK HERE TO REGISTER</Button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    changeDisplayPasswordInsecureWarning: (flag) => {
        dispatch({type: type.DISPLAY_PASSWORD_INSECURE_WARNING, flag});
    },
});

// connect to store

export default connect((state) => ({
    loading: state.common.loading,
    allowGuest:state.login.allowGuest,
    displayPasswordInsecureWarning: state.common.displayPasswordInsecureWarning ?
        state.common.displayPasswordInsecureWarning : false,
}), mapDispatchToProps)(reduxForm({
    form: 'GuestLoginForm',
    validate
})(GuestLoginForm));
