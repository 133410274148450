import React from 'react';
import URLSearchParams from "@ungap/url-search-params";

class FtlDataForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const urlSearchParams = new URLSearchParams(this.props.ftlData);
        let message = 'Shipping ' + urlSearchParams.get('wght') + 'lbs from ' + urlSearchParams.get('zipo')
            + ' to ' + urlSearchParams.get('zipd') + ' on a ' + urlSearchParams.get('eqip')
            + '. || Notes: ' + urlSearchParams.get('desc') + ' || Pickup Date: ' + urlSearchParams.get('date')
            + ' || # of Pickups: ' + urlSearchParams.get('nump') + ' || # of Drops: ' + urlSearchParams.get('numd')
            + ' || Declared Value: ' + urlSearchParams.get('valu') + ' || Accesorials: ' + urlSearchParams.get('xtra');
        return (
            <div className='hidden'>
                <input type="text" id="eqip" name="eqip" value={urlSearchParams.get('eqip')}/>
                <input type="text" id="zipo" name="zipo" value={urlSearchParams.get('zipo')}/>
                <input type="text" id="zipd" name="zipd" value={urlSearchParams.get('zipd')}/>
                <input type="text" id="wght" name="wght" value={urlSearchParams.get('wght')}/>
                <input type="text" id="desc" name="desc" value={urlSearchParams.get('desc')}/>
                <input type="text" id="tms_contact_source" name="tms_contact_source" value="Online Customer"/>
                <input type="text" id="fc_declared_customer_type" name="fc_declared_customer_type" value="Business - FTL"/>
                <input type="text" name="declared_value" value={urlSearchParams.get('valu')}/>
                <input type="text" name="number_of_pickups" value={urlSearchParams.get('nump')}/>
                <input type="text" name="number_of_drops" value={urlSearchParams.get('numd')}/>
                <input type="text" name="pickup_date" value={urlSearchParams.get('date')}/>
                <input type="text" name="accesorials" value={urlSearchParams.get('xtra')}/>
                <input type="text" id="fid"  name="fid"  value={urlSearchParams.get('fid')}/>
                <input type="text" id="fc_form_type_used" name="fc_form_type_used" value="FTL - Long"/>
                <input type="text" id="fc_registration_form_used" name="fc_registration_form_used" value="FTL"/>
                <input type="text" id="message" name="message" value={message}/>
            </div>
        )
    }
}

export default FtlDataForm
