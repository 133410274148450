import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Grid } from '@material-ui/core'
import utilCommon from '../../../helpers/utilCommon'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import CrossIcon from '../../../assets/images/cross.svg'

class ExitPopUp extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const { open, onClose, classes, exitPopUpContent } = this.props

    if (!exitPopUpContent) {
      return null
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <Grid container>
          <Grid container
                item
                className={classes.contentWrapper}
                direction={'column'}
                justify={'space-between'}
          >
            <Grid item>
              {
                exitPopUpContent.logoSection &&
                exitPopUpContent.logoSection.imageContent &&
                <img
                  height={'60px'}
                  width={'220px'}
                  src={
                    URL.createObjectURL(
                      new Blob(
                        [
                          utilCommon.convertBase64toBlob(
                            exitPopUpContent.logoSection.imageContent,
                          ),
                        ],
                        { type: 'image/jpg' },
                      ),
                    )
                  }
                  alt="Image Field"
                />
              }
            </Grid>
            <Grid
              item
              container
              justify={'center'}
              spacing={8}
            >
              <Grid item xs={12}>
                <div className={classes.title}>
                  {exitPopUpContent.titleField}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.subTitle}>
                  {exitPopUpContent.subtitleField}
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.text}>
                {exitPopUpContent.textField}
              </div>
            </Grid>
          </Grid>
          <Grid container
                item
                className={classes.imageWrapper}
          >
            {
              exitPopUpContent.imageField &&
              exitPopUpContent.imageField.imageContent &&
              <img
                className={classes.image}
                src={
                  URL.createObjectURL(
                    new Blob(
                      [
                        utilCommon.convertBase64toBlob(
                          exitPopUpContent.imageField.imageContent,
                        ),
                      ],
                      { type: 'image/jpg' },
                    ),
                  )
                }
                alt="Image Field"
              />
            }
          </Grid>
        </Grid>
        <div className={classes.closeBlock}
             onClick={() => this.props.onClose()}>
          <img width={'18px'} src={CrossIcon} alt="Cross icon"/>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ExitPopUp)
