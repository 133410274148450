import React from 'react';
import MaskedComponent from 'react-text-mask';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import cn from 'classnames';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import datePipe from './datePipe';


const autoCorrectedDatePipe = datePipe('mm/dd/yyyy', {minYear:1900, maxYear: new Date().getFullYear()})

const masks = {
    numbers: {mask:createNumberMask({
                         decimalPlaces: 0,
                         locale: 'en-US',
                         allowEmpty:true,
                         allowNegative:false,
                         stringValue:true,
                         allowLeadingZeroes:false,
                         prefix: '',
                         suffix: '',
                     })
    },
    money: {mask:createNumberMask({
                         decimalPlaces: 2,
                         locale: 'en-US',
                         allowEmpty:true,
                         allowNegative:false,
                         stringValue:true,
                         allowLeadingZeroes:true,
                         prefix: '$',
                         suffix: ''
                     })
    },

    ein: {
        mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    duns: {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    //(123) 123-1234
    phone: {
        mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    date: {
        mask: [ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    expireDate: {
        mask: [ /\d/, /\d/, '/', /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    cardNumber: {
        mask: [ /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/ ],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    achAccount: {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    },
    routingNumber: {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        placeholderChar: '\u2000',
        showMask: true,
        guide: false,
        keepCharPositions: true
    }
}

function TextMaskCustom(props) {
    const { inputRef, currentMask = 'numbers', ...other } = props;
    // console.log('props', props)
    // console.log('mask', masks[currentMask])


    const defaultProps = masks[currentMask]

    const currentProps = {...other};

    return (
        <MaskedComponent
            {...currentProps}
            {...defaultProps}
            ref={inputRef}
        />
    );
}

class MaskedInput extends React.Component {


    render() {
        const {
            endComponent = false,
            maxLength = null,
            placeholder,
            inputProps,
            style = {},
            isShrunk,
            label,
            input,
            disabled,
            requiredClass,
            disableUnderline = false,
            classes = {},
            newForm = false,
            meta: {touched, error}
        } = this.props;

        const inputLabelProps = {};
        if (isShrunk){
            inputLabelProps.shrink = true;
        }

        inputLabelProps.classes = {
            root: classes.input_label_root
        };

        let endAdornment = false;
        if (endComponent){
            const Adornment = React.cloneElement(endComponent, {
                props: {ext_error:touched && error}
            });
            endAdornment = <InputAdornment position="end" classes={{
                positionEnd: classes.input_adornment
            }}>
                {Adornment}
            </InputAdornment>
        }

        return (
            <div style={style}>
                {!disabled &&
                    <FormControl margin="normal"
                                 className={
                        cn(
                            'form-inputField highlighted',
                            {'form-inputFieldImp': requiredClass},
                            {'form-inputFieldError': (touched && error)},
                            {'newFormErrorInput': (newForm && touched && error)}
                        )
                    }
                    >
                        {label && <InputLabel {...inputLabelProps} className={touched && error ? '' : ''}>{label}</InputLabel>}
                        <Input
                            value={input.value || ''}
                            onChange={(e) => {
                                let value = e.target.value;

                                if (maxLength){
                                    value = (value||'').substring(0, maxLength)
                                }

                                if (inputProps.currentMask === 'numbers'){
                                    value = value.replace(/^0+/, '');
                                }
                                e.target.value = value;

                                input.onChange(e)
                            }}
                            endAdornment={endAdornment}
                            inputComponent={TextMaskCustom}
                            inputProps={{...inputProps, maxLength:maxLength}}
                            disableUnderline={disableUnderline}
                            placeholder={placeholder}
                            classes={{
                                root: classes.input_root,
                                input: cn(classes.input_input, {
                                    [classes.input_input_error]: touched && error
                                }),
                            }}
                            maxLength={maxLength}
                            {...input}
                        />
                    </FormControl>
                }
                {disabled &&
                    <div className="inputField">
                        <div className="label">{label}</div>
                        <div className="labelData">{input.value}</div>
                    </div>
                }

                {!newForm && error && touched && (<span className={cn('invalidTxt', classes.input_error)}>
                    {error}
                </span>)}
            </div>
        );
    }
}

export default MaskedInput;
