import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import utilCommon from '../../helpers/utilCommon'
import Button from '@material-ui/core/Button';
import * as type from '../../store/login/actionTypes'

// form validations
const validate = formProps => {
    const errors = {}
    if (!formProps.email) {
        errors.email = 'Email address is a required field';
    } else if (!utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }
    return errors
}

export class ForgotPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillReceiveProps(nextProps,props){
        if(nextProps.emailSent !=this.props.emailSent && nextProps.emailSent){
            this.props.updateEmailSent(false)
            this.props.displayForgot(false)
        }
    }

    render() {
        const { handleSubmit, submitting, valid, className='' } = this.props;
        return (
            <form name="ForgotPasswordForm" onSubmit={handleSubmit} className={"ForgotPasswordForm " + className}>
            <div className="form-group">
                <div className="col-grid1 mb0">
                    <div className="col">
                        <h2>Forgot Password</h2>
                    </div>
                </div>
                <div className="col-grid1">
                    <div className="col">
                    <Field label="Email Address" name="email" autoFocus={true} component={CustomInputField} requiredClass />
                    </div>
                </div>
                <div className="col-grid1">
                    <div className="col buttonSet">
                    <Button type="submit" disabled={submitting || !valid || this.props.loading} variant="contained"  className={(submitting || !valid || this.props.loading)?'btnInactive':'btnSecondary'}>
                        Recover
                    </Button>
                    <Button type="button" onClick={()=>this.props.displayForgot(false)} className="btnCancel">
                        Cancel
                    </Button>
                    </div>
                </div>
            </div>
            </form>
        )
    }
}
const mapDispatchToProps = (dispatch)=>({
    updateEmailSent:()=>{
        dispatch({type:type.EMAIL_SENT,flag:false})
    }
})
// connect to store
export default connect((state) => ({
    loading: state.common.loading,
    emailSent:state.login.emailSent
}), mapDispatchToProps)(reduxForm({
    form: 'ForgotPasswordForm',
    validate,
})(ForgotPasswordForm));
