import React, {useState} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getNewUIRadioButtonIcons} from "../../../assets/jss/commonStyles";


const renderCustomRadioButton = ({
                                     input, label, options, disabled, meta: {touched, error, warning}, customChecked, newUiIcons = false
                                 }) => (
    <div>
        {
            !disabled && <RadioGroup
                className="radioGroup"
                value={input.value ? input.value.toString() : input.value}
                onChange={(e) => input.onChange(e)}
            >
                {options.map((option, index) => {
                    const [focused, setFocused] = useState(0);
                    return (
                        <FormControlLabel key={index} value={option.id ? option.id.toString() : option.id}
                                          control={
                                              <Radio onFocus={() => setFocused(true)}
                                                     onBlur={() => setFocused(false)}
                                                     {...(newUiIcons ? getNewUIRadioButtonIcons() : {})}
                                              />
                                          }
                                          label={option.name}
                                          className={(option.id == input.value ? "input.value radio-col checked" : "radio-col") + (focused && customChecked ? ' highlighted-on-focus' : '')}/>
                    );
                })}
            </RadioGroup>
        }
        {
            disabled && <RadioGroup
                className="radioGroup"
                value={input.value.toString()}
                onChange={(e) => input.onChange(e)}
            >
                <span
                    className="radioViewlabel">{options.filter(x => x.id == input.value) && options.filter(x => x.id == input.value).length > 0 ? options.filter(x => x.id == input.value)[0].text : ''}</span>
            </RadioGroup>
        }
        {!disabled && touched && error && (input.value === "" && (<span className="invalidTxt">
				{error}
            </span>
        ))}
        {!disabled && error == "Please provide atleast one valid IP address" && (<span className="invalidTxt">
				{error}
			</span>
        )}
    </div>
);

export default renderCustomRadioButton;
