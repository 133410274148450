import React from 'react';

export default class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [false, false, false, false, false, false]
    };
  }

  handleClick (index) {
    const isOpened = this.state.opened[index];
    const value = [false, false, false, false, false, false];
    value[index] = !isOpened;
    this.setState({opened: value});
  }

  getOpenIcon(index) {
    const isOpened = this.state.opened[index];
    if (isOpened) {
      return {
        src: '/icons/minus.png',
        srcSet: '/icons/minus@2x.png 2x',
      }
    } else {
      return {
        src: '/icons/plus.png',
        srcSet: '/icons/plus@2x.png 2x',
      }
    }
  }

  render() {
    return (
      <div className="faq-block">
        <div className="faq-header">
          <h1>FAQ - Frequently Asked Questions</h1>
          <h6>Quick info, for your most asked shipping questions. Visit our <a href="https://www.freightcenter.com/help/faq" target="_blank">FAQ’s page</a> for additional topics or if you
            can’t find what you’re looking for, shoot us an <a href="mailto:questions@freightcenter.com">email</a>. </h6>
        </div>
        <div className="questions">
          <div className={'item' + (this.state.opened[0] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(0)}>
              <img src="/icons/calendar.png" srcSet="/icons/calendar@2x.png 2x" alt="calendar" className="question-icon" />
              <span>How do I reschedule or cancel a shipment?</span>
              <img {...this.getOpenIcon(0)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              To reschedule or cancel a shipment, contact your shipping agent or FreightCenter customer service at <a href="tel:18007167608">800-716-7608</a> or email <a href="mailto:wecare@freightcenter.com">wecare@freightcenter.com</a>.  To receive a refund, you must cancel within 30 days. However, to avoid a fee, you must cancel within 24 hours and the fee will be waived if rebooked within 1 year of original book date.
            </div>
          </div>
          <div className={'item' + (this.state.opened[1] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(1)}>
              <img src="/icons/direction.png" srcSet="/icons/direction@2x.png 2x" alt="direction" className="question-icon" />
              <span>What can be shipped as freight and what are my options?</span>
              <img {...this.getOpenIcon(1)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              Freight shipments typically weigh 150 pounds and up, are more than 108 inches in length and more that 165 inches in both length & girth (<a target="_blank" href="https://blog.freightcenter.com/are-you-shipping-freight-or-parcel">calculate girth</a>) combined. The most common freight option modes are full truckload and less-than-truckload. To help you determine which mode is best for your shipment, check out our post <a target="_blank" href="https://blog.freightcenter.com/how-to-select-the-best-shipping-method-for-your-freight">How to Select the Best Shipping Method for Your Freight</a>.
            </div>
          </div>
          <div className={'item' + (this.state.opened[2] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(2)}>
              <img src="/icons/Solid.png" srcSet="/icons/Solid@2x.png 2x" alt="Solid" className="question-icon" />
              <span>Do I need the exact dimensions & weight of my shipment?</span>
              <img {...this.getOpenIcon(2)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              Never estimate your freight dimensions or weight. With LTL shipping, it’s extremely important to provide exact weight, length, width and height measurements. Incorrect or estimated measurements and weight can result in a costly carrier adjustment after delivery of freight. Carriers need exact dimensions and weight to know how much freight will fit on one truck as well as ensuring compliance with DOT weight regulations.
            </div>
          </div>
          <div className={'item' + (this.state.opened[3] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(3)}>
              <img src="/icons/boxes.png" srcSet="/icons/boxes@2x.png 2x" alt="boxes" className="question-icon" />
              <span>How do I package my freight?</span>
              <img {...this.getOpenIcon(3)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              To avoid damage during transit and handling, crating and palletizing your shipment is best practice and wrapping pallets with shrink wrap further protects your cargo. For best practices and packaging tips, take a look at our blog post <a target="_blank" href="https://blog.freightcenter.com/keys-to-shipping-happiness-part-4-practical-freight-packaging">Practical Freight Packaging</a>.
            </div>
          </div>
          <div className={'item' + (this.state.opened[4] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(4)}>
              <img src="/icons/clock.png" srcSet="/icons/clock@2x.png 2x" alt="clock" className="question-icon" />
              <span>When can I expect the carrier to deliver my shipment?</span>
              <img {...this.getOpenIcon(4)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              Delivery times are estimates and while carriers are reliable, shipments do not typically include a guaranteed delivery or transit time unless added as an optional service requested during the quote process. You can also request expedited freight services which places a priority over regular freight deliveries but is not guaranteed. Keep in mind that the pickup day is not included in the estimated transit time.
            </div>
          </div>
          <div className={'item' + (this.state.opened[5] ? ' open' : '')}>
            <div className="question" onClick={() => this.handleClick(5)}>
              <img src="/icons/copy.png" srcset="/icons/copy@2x.png 2x" alt="copy" className="question-icon" />
              <span>What is a BOL?</span>
              <img {...this.getOpenIcon(5)} alt="boxes" className="open-icon" />
            </div>
            <div className="answer">
              A Bill of Lading or BOL is standard paperwork that provides the customer, carrier and driver with all the pertinent information related to shipping costs and transport information (date of shipment, number of units, freight class, weight etc.). It serves as a receipt of freight services. Once a shipment has been booked, the BOL is created and can be downloaded from your MyFreightCenter account and 2 copies should be printed. One copy must be presented to the carrier at pickup and the other copy is retained by the shipper for their records.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
